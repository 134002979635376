import React from 'react';
import NormalColumn from './NormalColumn';
import DetailRow from './DetailRow';

export default function Product({ product, details }) {
    const firstRowStyle = { borderTop: 1, borderColor: '#000000' };

    return (
        <>
            <NormalColumn
                style={{
                    gridColumnStart: 2,
                    gridRowSpan: details.length,
                    ...firstRowStyle
                }}
            >
                {product.name}
                {!!product.deleted ? <span className="text-warning"> (deleted)</span> : null}
            </NormalColumn>
            {details.map((detail, index) => (
                <DetailRow
                    key={detail.id}
                    product={product}
                    detail={detail}
                    lastDetailForProduct={index === details.length - 1}
                    style={index === 0 ? firstRowStyle : {}}
                />
            ))}
            {details.length === 0 ? (
                <DetailRow product={product} style={firstRowStyle} lastDetailForProduct={true} />
            ) : null}
            <span className="grid-cs-1 grid-ce-last" style={{ backgroundColor: '#E8EEF6', paddingBottom: '1px' }} />
        </>
    );
}
