import React from 'react';
import DeletedText from './DeletedText';

const SecondaryColumn = ({ children, className = '', style = {}, deleted }) => {
    return (
        <div className={`p-2 font-weight-bold ${className}`} style={{ backgroundColor: '#DAE4F1', ...style }}>
            <DeletedText deleted={deleted}>{children}</DeletedText>
        </div>
    );
};

export default SecondaryColumn;
