import React from 'react';
import { Button } from 'reactstrap';
import { DebugSection } from './DebugSection';

export const SaveCancelButton = ({
    onSave,
    onCancel,
    disableSave,
    disableCancel,
    pullRight,
    hideCancelButton,
    hideSaveButton,
    saveButtonContent,
    debugSections
}) => (
    <div className={pullRight ? 'pull-right' : 'w-100'}>
        {debugSections ? <DebugSection sections={debugSections} /> : null}
        <Button
            type="button"
            className="mr-2"
            color="warning"
            onClick={() => {
                if (onCancel) onCancel();
            }}
            disabled={disableCancel}
            hidden={hideCancelButton ? true : false}
        >
            Cancel
        </Button>
        {onSave ? (
            <Button
                type="button"
                disabled={disableSave}
                color="primary"
                onClick={() => onSave()}
                hidden={hideSaveButton}
            >
                Save
            </Button>
        ) : (
            <Button disabled={disableSave} type="submit" color="primary" hidden={hideSaveButton}>
                {saveButtonContent || 'Save'}
            </Button>
        )}
    </div>
);
