import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';

export const apiAgreementApprove = (
    AgreementHeaderId,
    notes,
    successTitle,
    successMessage,
    callback,
    onError
) => async dispatch => {
    const type = ENDPOINTS.API.AGREEMENT_APPROVE;
    const data = {
        notes
    };

    dispatch(
        apiGetPost(
            type,
            ['agreement', 'Authorise', AgreementHeaderId],
            data,
            {},
            undefined,
            true,
            (dispatch, type, response) => {
                onError && onError();
                toastr.error('Error Approving', response.message);
            },
            undefined,
            successTitle,
            successMessage,
            callback
        )
    );
};
