import React, { useContext } from 'react';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { useMeetingsByCompanyById } from '../../Meeting/Selectors/useMeetingsByCompanyId';

const CompanyMeetingsContext = React.createContext({
    companyId: undefined,
    meetings: [],
    isLoading: false
});

export const CompanyMeetingsConsumer = CompanyMeetingsContext.Consumer;

const CompanyMeetingsProvider = ({ companyId, children }) => {
    const isLoading = useIsLoading([ENDPOINTS.API.COMPANY]);
    const meetings = useMeetingsByCompanyById(companyId);
    return (
        <CompanyMeetingsContext.Provider value={{ companyId, meetings, isLoading }}>
            {children}
        </CompanyMeetingsContext.Provider>
    );
};

export const useCompanyMeetingsContext = () => {
    const state = useContext(CompanyMeetingsContext);
    if (state === undefined) {
        throw new Error(`useCompanyMeetingsContext must be used inside CompanyMeetingsProvider`);
    }
    return state;
};

export default CompanyMeetingsProvider;
