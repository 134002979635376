import { ENDPOINTS } from '../constants';
import { toastr } from 'react-redux-toastr';
import { apiDelete } from '../apiHelpers';

export const apiAgreementDetailDelete = (
    agreementDetailIds,
    successTitle,
    successMessage,
    callback
) => async dispatch => {
    const type = ENDPOINTS.API.AGREEMENT_DETAIL_DELETE;

    dispatch(
        apiDelete(
            type,
            ['agreement', 'detail'],
            agreementDetailIds,
            {},
            undefined,
            true,
            error => {
                toastr.error(type, `Response ${error.message}`);
            },
            successTitle,
            successMessage,
            callback
        )
    );
};
