import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';
import { cookieClear } from '../reducers/cookies';
import { replace } from 'connected-react-router/lib';

export const RESET_REDUCER = 'RESET_REDUCER';

export const resetReducers = (path, forceRefresh) => async dispatch => {
    try {
        dispatch({ type: RESET_REDUCER });

        cookieClear();

        if (path) {
            dispatch(push(path));
        }

        if (forceRefresh) {
            dispatch(replace('/'));
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }

        return true;
    } catch (error) {
        toastr.error('Error', `Response ${error.message}`);
        return false;
    }
};
