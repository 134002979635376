import React from 'react';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { DeleteAgreementModal } from "../../common/Modal/ConfirmDeleteModal";
            
export default function DeleteAgreementButton({ agreementHeaderId }) {

    const agreementHeaderIds = [agreementHeaderId];
    return (
        <DeleteAgreementModal agreementHeaderIds={agreementHeaderIds}>
            {({ triggerModal }) => (
        <ActionButton
            buttonText={'Delete'}
            fontawesomeIcon="fa fa-trash"
            bootstrapColor="secondary"
            onClickAction={() => {
                triggerModal();
                    }}
                />
            )}
        </DeleteAgreementModal>
    );
}
