import React from 'react';
import { connect } from 'react-redux';
import { isAnyLoading } from '../../../reducers/Rest/loadingStates';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import UpdatePasswordForm from './UpdatePasswordForm';

const SetupAccountForm = ({ token, sendResetPassword, loadingStates, className }) => {
    if (isAnyLoading(loadingStates)) {
        return <ShowLoadingSpinner isLoading color="#fff" />;
    }

    return (
        <UpdatePasswordForm
            class={className}
            formText={'Please set a password for your account.'}
            buttonText={'Update Password'}
            token={token}
            sendResetPassword={sendResetPassword}
        />
    );
};

const mapStateToProps = ({ loadingStates }) => ({ loadingStates });

export default connect(mapStateToProps)(SetupAccountForm);
