import React from 'react';
import { webSiteHttp } from '../../reducers/util';

export const DisplayIcon = props => {
    const { href } = props;

    return href === '...' ? (
        '...'
    ) : href ? (
        <a href={webSiteHttp(href)} target="_blank" rel="noopener noreferrer">
            <img alt="Website Favicon" className="mr-2" src={`https://plus.google.com/_/favicon?domain=${href}`} />
            {href}
        </a>
    ) : (
        'Unknown'
    );
};
