import { createStore, applyMiddleware, compose } from 'redux';
import _ from 'lodash';
import { routerMiddleware } from 'connected-react-router';
import { offline } from '@redux-offline/redux-offline';
import thunk from 'redux-thunk';
import reduxPromise from 'redux-promise';
import { createBrowserHistory } from 'history';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import rootReducer from './modules';
import * as axios from 'axios';
import { cookieGetToken } from './reducers/cookies';
import { persistCallback } from './index';
import defaultQueue from '@redux-offline/redux-offline/lib/defaults/queue';
import { toastrError } from './reducers/Api/Errors/errors';

export const history = createBrowserHistory();
history.listen(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
});

const initialState = {};

export const ADD_ROI_BEARER_TOKEN = 'ADD_ROI_BEARER_TOKEN';

const effect = (effect, _action) => {
    if (_.get(effect, 'headers.Authorization') === ADD_ROI_BEARER_TOKEN) {
        const token = cookieGetToken();
        const authEffect = _.cloneDeep(effect);
        authEffect.headers.Authorization = `Bearer ${token}`;
        return axios(authEffect);
    }
    return axios(effect);
};

const discard = (error, _action, _retries) => {
    const { request, response } = error;
    if (response === 401) {
        return true;
    }
    if (!response || response.status >= 500 || response.status <= 400) {
        if (_retries > 1) {
            toastrError(error, _action);
            return true;
        }
    }
    if (!request) {
        throw error;
    } // There was an error creating the request

    if (!response) return false; // There was no response

    return 400 <= response.status && response.status < 500;
};

const customOfflineConfig = {
    ...offlineConfig,
    effect,
    discard,
    rehydrate: true,
    persistCallback: () => persistCallback(),
    queue: {
        ...defaultQueue,
        enqueue(array, action) {
            return [...array.filter(x => !(x.entityId && x.entityId === action.entityId)), action];
        }
    },
    persistOptions: {
        blacklist: [
            'address',
            'agreementForm',
            'agreement',
            'login',
            'settings',
            'loadingStates',
            'forms',
            'modals',
            'companySummaries',
            'resetPassword',
            'versions'
        ]
    }
};

const enhancers = [offline(customOfflineConfig)];
const middleware = [thunk, reduxPromise, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension());
    }
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const store = createStore(rootReducer(history), initialState, composedEnhancers);

export default store;
