import { basicColumnDef, choiceListColumnDef, idColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

const agreementDetailColumnDefs = {
    id: idColumnDef('Agreement Detail Id', 'id'),
    productId: basicColumnDef('Product', 'productId'),
    agreementHeaderId: basicColumnDef('Agreement Header Id', 'agreementHeaderId'),
    productFamilyName: {
        ...basicColumnDef('Product Family Name', 'productFamilyName')
    },
    productName: {
        ...basicColumnDef('Product Name', 'productName')
    },
    manufacturerDiscount: {
        ...basicColumnDef('Manufacturer Discount', 'manufacturerDiscount'),
        type: 'number'
    },
    dealerDiscount: {
        ...basicColumnDef('Dealer Discount', 'dealerDiscount'),
        type: 'number'
    },
    discountUnits: {
        ...choiceListColumnDef('Discount Unit', 'discountUnits', 'DiscountUnits')
    },
    notes: {
        ...basicColumnDef('Notes', 'notes'),
        type: 'textarea'
    }
};

export default agreementDetailColumnDefs;
