import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const makeSelector = () =>
    createSelector(
        state => state.agreementHeaders,
        (_, parentId) => parentId,
        (agreementHeaders, parentId) => agreementHeaders.find(e => e.parentId === parentId)
    );

export const useAgreementByParentId = parentId => {
    const selector = useMemo(makeSelector, []);
    return useSelector(state => selector(state, parentId));
};
