import React, { useContext } from 'react';
import StatusChangeModal from '../Modals/StatusChangeModal';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';
import ApproveAgreementButton from './ApproveAgreementButton';


export default function ApproveDeclineBuittonsInModal() {
    const { agreementHeaderId } = useContext(AgreementHeaderContext);

    return (
        <StatusChangeModal>
            {({ updateAgreementStatus }) => (
                <>
                    <ApproveAgreementButton agreementHeaderId={agreementHeaderId} />
                    <ActionButton
                        buttonText="Decline agreement"
                        fontawesomeIcon="fa-times"
                        bootstrapColor="secondary"
                        onClickAction={() => updateAgreementStatus(agreementHeaderId, 'Decline')}
                    />
                </>
            )}
        </StatusChangeModal>
    );
}
