import _ from 'lodash';

export const SET_TABLE_SORT_COLUMN = 'SET_TABLE_SORT_COLUMN';

export const setTableSortColumn = (tableName, columnName) => dispatch => {
    dispatch({
        type: SET_TABLE_SORT_COLUMN,
        table: tableName,
        column: columnName
    });
};

/* Not sure if to put this in reducers or actions or where */
export const sort_table = (sortRules, unsortedRows, tableName) => {
    let index = _.findIndex(sortRules, x => x.table === tableName);
    if (index === -1) {
        return unsortedRows;
    }
    let sortedList = _.sortBy(unsortedRows, sortRules[index].column);
    return sortRules[index].sort_asc ? sortedList : _.reverse(sortedList);
};
