import React from 'react';
import { Alert } from 'reactstrap';

export const AlertIfNoRows = props =>
    props.isLoading ? (
        <Alert color="info">Searching...</Alert>
    ) : (props.rows && props.rows.length > 0) || props.isLoading ? (
        props.children
    ) : props.message ? (
        <Alert color="warning">{props.message}</Alert>
    ) : null;
