import { makePostRequest } from '../apiHelpers';
import { ENDPOINTS } from '../constants';

export const apiUserLogPost = (descriptor, entity, url) => async (dispatch, getState) => {
    const type = ENDPOINTS.API.USERLOG_POST;
    try {
        if (!getState().offline.online) {
            return false;
        }

        const result = await makePostRequest(['user', 'log', 'history'], {}, { descriptor, entity, url });

        dispatch({
            type,
            data: result.data
        });

        return true;
    } catch (error) {
        return false;
    }
};
