import React, { useContext } from 'react';
import HeaderColumn from './HeaderColumn';
import { AgreementDetailsTableContext } from './AgreementDetailsTableProvider';

export default function HeaderRow() {
    const { showActionsColumn } = useContext(AgreementDetailsTableContext);
    return (
        <>
            <HeaderColumn style={{ gridColumn: '1 /span 3' }}>
                <div>Terms -</div>
                <div>Includes Free of Charge Paint on all Models shown, unless otherwise stated</div>
            </HeaderColumn>
            <HeaderColumn>
                Hyundai UK
                <br />
                Discount
            </HeaderColumn>
            <HeaderColumn>
                Expected Dealer
                <br />
                Discount
            </HeaderColumn>
            <HeaderColumn>Total Discount</HeaderColumn>
            {showActionsColumn && <HeaderColumn />}
        </>
    );
}
