import React from 'react';
import { PreventClickThrough } from './Table/PreventClickThrough';
import { hexToRgb, rgbaOpacity } from '../../reducers/util';
import _ from 'lodash';

const ButtonGroup = props => {
    const { selectedKey, className, choiceList, onButtonClick, canChange } = props;

    return (
        <div className={`${className ? className : ''} ButtonGroup`}>
            {choiceList
                .filter(choice => canChange || selectedKey === choice.key)
                .map(choice => {
                    const selected = selectedKey === choice.key;
                    const className = `btn ButtonGroup__button ${selected ? 'selected' : ''}`;
                    const colorArray = hexToRgb(_.get(choice, 'color[0]', '#CCCCCC'));

                    const style = { padding: props.small ? '3px 8px' : '8px' };

                    if (selected) {
                        style.backgroundColor = rgbaOpacity(colorArray, 1);
                    }

                    return (
                        <button
                            disabled={selected}
                            style={style}
                            className={className}
                            key={typeof choice.key === 'boolean' ? (choice.key ? 'true' : 'false') : choice.key}
                            onClick={event => {
                                PreventClickThrough(event);
                                onButtonClick(choice.key);
                            }}
                        >
                            {choice.value}
                        </button>
                    );
                })}
        </div>
    );
};

export default ButtonGroup;
