import { ENDPOINTS } from '../constants';
import { toastr } from 'react-redux-toastr';
import { apiGetPost } from '../apiHelpers';

export const apiAddendumDetailUpdate = (addendumDetail, successTitle, successMessage, callback) => async dispatch => {
    const type = ENDPOINTS.API.ADDENDUM_DETAIL_UPDATE;

    dispatch(
        apiGetPost(
            type,
            ['agreement', 'addendum', 'detail', 'update'],
            addendumDetail,
            {},
            undefined,
            true,
            error => toastr.error('Error updating detail', `Response ${error.message}`),
            {},
            successTitle,
            successMessage,
            callback
        )
    );
};
