import React from 'react';
import { connect } from 'react-redux';
import ReactiveForm from '../../common/ReactiveForm';
import { withRouter } from 'react-router-dom';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import { apiAgreementApprove } from '../../../actions/Api/AgreementHeader/apiAgreementApprove';
import { apiAgreementDecline } from '../../../actions/Api/AgreementHeader/apiAgreementDecline';

const FORM_NAME = 'StatusChangeForm';

function handleSubmit(statusChangeType, agreementHeaders, form, approveAgreement, declineAgreement, toggle) {
    statusChangeType === 'Approve'
        ? approveAgreement(agreementHeaders.id, form.notes, 'Success', 'Agreement Approved', toggle)
        : declineAgreement(agreementHeaders.id, form.notes, 'Success', 'Agreement Declined', toggle);
    toggle();
}

const StatusChangeForm = ({
    agreementHeaders,
    statusChangeType,
    approveAgreement,
    declineAgreement,
    resources,
    notes,
    toggle
}) => {
    const initialValues = {
        notes: notes ? agreementHeaders.notes : null
    };

    return (
        <ReactiveForm
            initialValues={initialValues}
            resources={resources}
            formName={FORM_NAME}
            cancel={toggle}
            saveButtonContent={`${statusChangeType} Agreement`}
            handleSubmit={form =>
                handleSubmit(statusChangeType, agreementHeaders, form, approveAgreement, declineAgreement, toggle)
            }
        >
            <ReactiveForm.Section>
                <ReactiveForm.Text columnDef={agreementHeaderColumnDefs.notes} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
};

const mapStateToProps = (state, props) => ({
    agreementHeaders: state.agreementHeaders.find(e => e.id === props.notes),
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    approveAgreement: (id, notes, successTitle, successMessage, callback) =>
        dispatch(apiAgreementApprove(id, notes, successTitle, successMessage, callback)),
    declineAgreement: (id, notes, successTitle, successMessage, callback) =>
        dispatch(apiAgreementDecline(id, notes, successTitle, successMessage, callback))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(StatusChangeForm));
