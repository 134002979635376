import axios from 'axios';
import { replace } from 'connected-react-router';
import { makeAuthUrl } from './apiAuthHelpers';
import { makeHeaders, makePath } from '../Api/apiHelpers';
import { RESET_REDUCER } from '../resetReducers';
import { cookieClear } from '../../reducers/cookies';

export const apiAuthUserLogout = emailAddress => async (dispatch, getState) => {
    if (emailAddress) {
        const data = { emailAddress };

        axios({
            method: 'POST',
            url: makeAuthUrl(getState(), makePath(['auth', 'logout']), {}),
            headers: makeHeaders(getState()),
            data
        });
    }
    try {
        cookieClear();
    } catch (e) {
        console.log('Error Clearing Cookies', e);
    }

    dispatch(replace('/'));

    dispatch({ type: RESET_REDUCER });

    return true;
};
