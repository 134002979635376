import React from 'react';
import PropTypes from 'prop-types';

const faIcon = (color, id, className) => (
    <i style={color ? { color: color } : undefined} className={`fa d-inline ${id} mr-2 ${className || ''}`} />
);

const IconFactory = ({ icon, isVisible = true }) => {
    if (!icon || !isVisible) return null;

    const { type, color, id } = icon;
    switch (type) {
        case 'fa':
            return faIcon(color, id);
        default:
            console.warn(`ICON NOT FOUND type:${type} id:${id}`);
            return null;
    }
};

IconFactory.propTypes = {
    isVisible: PropTypes.bool,
    icon: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    })
};

export default IconFactory;
