import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactiveForm from '../../common/ReactiveForm';
import agreementAddendumDetailsColumnDefs from '../agreementAddendumDetailsColumnDefs';
import useInitialValues from '../../common/Hooks/useInitialValues';
import { apiAddendumDetailUpdate } from '../../../actions/Api/AgreementHeader/apiAddendumDetailUpdate';
import editAgreementAddendumDetailFormValidation from './editAgreementAddendumDetailFormValidation';

function handleSubmit(form, dispatch, toggle) {
    dispatch(apiAddendumDetailUpdate(form, 'Success', 'Product Saved', toggle));
}

const EditAgreementAddendumDetailForm = ({ detail, isLoading, toggle }) => {
    const dispatch = useDispatch();
    const resources = useSelector(state => state.resources);
    const initialValues = useInitialValues(
        detail,
        [{ field: 'id' }, { field: 'discountUnits' }, { field: 'manufacturerDiscount' }, { field: 'dealerDiscount' }],
        {
            id: detail.id,
            discountUnits: detail.discountUnits,
            manufacturerDiscount: detail.manufacturerDiscount,
            dealerDiscount: detail.dealerDiscount
        }
    );

    return !isLoading ? (
        <>
            <ReactiveForm
                initialValues={initialValues}
                resources={resources}
                formName="editAgreementAddendumDetailForm"
                cancel={toggle}
                validationSchema={editAgreementAddendumDetailFormValidation}
                handleSubmit={form => handleSubmit(form, dispatch, toggle)}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Text
                        columnDef={agreementAddendumDetailsColumnDefs.manufacturerDiscount}
                        placeholder="Manufacturer Discount"
                    />
                    <ReactiveForm.Text
                        columnDef={agreementAddendumDetailsColumnDefs.dealerDiscount}
                        placeholder="Dealer Discount"
                    />
                    <ReactiveForm.Choicelist
                        columnDef={agreementAddendumDetailsColumnDefs.discountUnits}
                        placeholder="Discount Unit"
                    />
                </ReactiveForm.Section>
            </ReactiveForm>
        </>
    ) : (
        <></>
    );
};

export default EditAgreementAddendumDetailForm;
