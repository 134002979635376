import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {apiMeetingPost} from '../../../actions/Api/Meeting/apiMeetingPost';
import {REST_TIME_FORMAT} from '../../../actions/Api/apiHelpers';
import {getPrimaryAddress} from '../../../reducers/Api/Company/company';
import ReactiveForm from '../../common/ReactiveForm';
import meeting from '../meeting';
import validation from './validation';
import _ from 'lodash';
import {COMMUNICATION_CAPTION} from "../../Communication/Forms/EditCommunicationForm";

const FORM_NAME = 'editMeetingForm';
const meetingActivityRegardingOptions = resources => _.get(resources, 'choiceList.communicationActivityRegarding', []).filter(x => !COMMUNICATION_CAPTION.includes(x.key));

class EditMeetingForm extends React.Component {
    handleSubmit = formValues => {
        formValues.descriptor = formValues.subject;
        const meeting = {
            ...formValues,
            assignee: {
                id: parseInt(formValues.assigneeId, 10)
            },
            person: {
                id: parseInt(formValues.personId, 10)
            }
        };

        this.props.save(meeting, this.props.onCancel);
    };

    emptyForm = () => {
        const {
            user: {userId, descriptor},
            meetingId,
            companyId,
            person
        } = this.props;

        const startDate = moment()
            .hour(9)
            .minute(0)
            .seconds(0)
            .format(REST_TIME_FORMAT);

        const endDate = moment()
            .hour(17)
            .minute(0)
            .seconds(0)
            .format(REST_TIME_FORMAT);

        return {
            activityRegarding: '',
            action: 'Meeting',
            companyId,
            meetingId,
            opportunityId: '',
            subject: '',
            note: '',
            status: 'Pending',
            location: '',
            assigneeId: userId.toString(),
            assigneeName: descriptor,
            assignee: {
                id: userId,
                descriptor
            },
            personId: (person && person.id) || '',
            person: {
                id: (person && person.id) || '',
                descriptor: (person && person.descriptor) || ''
            },
            company: {
                id: companyId,
                descriptor: `${this.props.companies.find(x => x.companyId === companyId).descriptor}`
            },
            startDate,
            endDate,
            createdBy: userId,
            createdDate: undefined,
            updatedBy: userId,
            updatedDate: undefined
        };
    };

    render() {
        const {meetingId, companyId, meetings, companies, onCancel, resources, people} = this.props;
        let initialValues = meetings.find(x => x.meetingId === meetingId);

        if (!initialValues && meetingId < 0) {
            initialValues = this.emptyForm();
            initialValues.meetingId = meetingId;
        }

        initialValues.assigneeId = ((initialValues.assignee && initialValues.assignee.id) || '').toString();
        initialValues.companyId = ((initialValues.company && initialValues.company.id) || '').toString();
        initialValues.personId = ((initialValues.person && initialValues.person.id) || '').toString();

        initialValues.personId = _.get(initialValues, 'person.id');

        const primaryAddress = getPrimaryAddress(companies, companyId);
        if (primaryAddress) {
            initialValues.location = primaryAddress.postCode;
        }
        const peopleChoices = people
            .filter(person => person.companyId.toString() === initialValues.companyId)
            .map(person => ({
                key: person.personId,
                value: [person.salutation, person.firstName, person.lastName].filter(x => !!x).join(' ')
            }));
        return (
            <ReactiveForm
                initialValues={initialValues}
                cancel={onCancel}
                resources={resources}
                formName={FORM_NAME}
                validationSchema={validation}
                handleSubmit={this.handleSubmit}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Date showTimeSelect={true} columnDef={meeting.startDate}/>
                    <ReactiveForm.Date showTimeSelect={true} columnDef={meeting.endDate}/>
                    <ReactiveForm.Choicelist columnDef={meeting.activityRegarding} options={meetingActivityRegardingOptions(resources)}/>
                    <ReactiveForm.Choicelist columnDef={meeting.personId} options={peopleChoices}/>
                    <ReactiveForm.Choicelist columnDef={meeting.action} disabled/>
                    <ReactiveForm.Text columnDef={meeting.subject}/>
                    <ReactiveForm.Choicelist columnDef={meeting.assigneeId}/>
                    <ReactiveForm.Choicelist columnDef={meeting.status}/>
                    <ReactiveForm.Choicelist columnDef={meeting.location}/>
                    <ReactiveForm.Choicelist columnDef={meeting.meetingMode}/>
                    <ReactiveForm.Text columnDef={meeting.note}/>
                </ReactiveForm.Section>
            </ReactiveForm>
        );
    }
}

const mapStateToProps = state => ({
    meetings: state.meetings,
    communications: state.communications,
    companies: state.companies,
    user: state.user,
    opportunities: state.opportunities,
    people: state.people,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (meeting, cancel) => {
        dispatch(apiMeetingPost(meeting));
        if (cancel) {
            cancel();
        }
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditMeetingForm);
