import React, { useContext } from 'react';
import { DeleteTableRowButton } from '../../common/Buttons/TableButtons';
import { useDispatch } from 'react-redux';
import ConfirmButton from '../../common/Buttons/ConfirmButton';
import { AgreementHeaderContext } from '../../AgreementHeader/Provider/AgreementHeaderProvider';
import { apiAddendumDetailDelete } from '../../../actions/Api/AgreementAddendumDetail/apiAddendumDetailDelete';

const AddendumDetailDeleteButton = ({ detail, hideDeleteButton }) => {
    const { isLive } = useContext(AgreementHeaderContext);
    const dispatch = useDispatch();
    if (isLive || !!detail.deleted) {
        return null;
    }

    return (
        <div style={{ float: 'right' }}>
            {!hideDeleteButton ? (
                <ConfirmButton
                    popoverId={`d${detail.id}`}
                    title="Delete Detail"
                    isWorking={false}
                    isWorkingTitle="Deleting Detail"
                    onConfirm={() => {
                        dispatch(apiAddendumDetailDelete(detail.id, 'Success', 'Addendum Detail Deleted'));
                    }}
                >
                    {({ toggle }) => <DeleteTableRowButton id={`d${detail.id}`} onClick={toggle} />}
                </ConfirmButton>
            ) : null}
        </div>
    );
};

export default AddendumDetailDeleteButton;
