import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ListGroupItem } from 'reactstrap';
import { calendarOptions } from '../../../reducers/calendarOptions';
import _ from 'lodash';

const rowTypes = {
    Company: {
        icon: 'fa-building',
        color: 6
    },
    Agreement: {
        icon: 'fa-thumbs-up',
        color: 5
    },
    Template: {
        icon: 'fa-thumbs-o-up',
        color: 4
    },
    Promotion: {
        icon: 'fa-star',
        color: 3
    },
    Addendum: {
        icon: 'fa-pencil',
        color: 2
    },
    Communication: {
        icon: 'fa-link',
        color: calendarOptions.faceToFace.color
    }
};

const BrowserHistoryRow = ({ entity, url, link, descriptor }) => {
    const { icon, color } = _.get(rowTypes, entity, { icon: 'fa-link', color: 'secondary' });
    const dispatch = useDispatch();

    return (
        <ListGroupItem
            tag="button"
            type="button"
            action
            key={url}
            className={`hover-pointer background-hover background-color-${color}`}
            onClick={() => dispatch(push(url))}
        >
            <i className={`fa ${icon} mr-3`} />
            {descriptor}
        </ListGroupItem>
    );
};

export default BrowserHistoryRow;
