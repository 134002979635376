import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';
import { toastr } from 'react-redux-toastr';

export const apiAgreementHeaderUpdate = (agreementHeader, successTitle, successMessage, onResult) => async dispatch => {
    const type = ENDPOINTS.API.AGREEMENT_CREATE_TEMPLATE;

    dispatch(
        apiGetPost(
            type,
            ['agreement'],
            agreementHeader,
            {},
            onResult,
            true,
            error => {
                toastr.error(type, `Response ${error.message}`);
            },
            { agreementHeaderId: agreementHeader.agreementHeaderId || -1 },
            successTitle,
            successMessage
        )
    );
};
