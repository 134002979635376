import React from 'react';
import BigCalendar from 'react-big-calendar';
import { connect } from 'react-redux';
import moment from 'moment';
import SimpleCard from '../../common/Card/SimpleCard';
import CommunicationCalendar from '../../Calendar/Pages/CommunicationCalendar';
import { onNavigate, onView, getDefaultDate } from '../../Calendar/calendarNavigation';
import useIsWindowWidthOver from '../../common/Hooks/useIsWindowWidthOver';
import { DebugSection } from '../../common/DebugSection';
import PageSection from '../../common/PageSection';
import CalendarFilter from '../../Calendar/Components/CalendarFilter';
import CalendarActionButtons from '../../Calendar/Components/CalendarActionButtons';
import CalendarEventsProvider from '../../Calendar/Components/CalendarEventsProvider';

const CalendarPage = props => {
    const {
        match: {
            params: { month, year }
        },
        user
    } = props;

    const has2columns = useIsWindowWidthOver(1440);

    let defaultView = props.match.params.view;
    BigCalendar.momentLocalizer(moment);

    const end = moment()
        .startOf('month')
        .year(year)
        .month(month);
    const start = end.clone().add(-1, 'month');

    return (
        <CalendarEventsProvider start={start} end={end} userId={user.userId}>
            {({ events, filter, setDates, createEvent }) => {
                return (
                    <PageSection title="Calendar">
                        <div className={has2columns ? 'd-grid page-grid' : ''}>
                            <div className={has2columns ? 'grid-cs-1 grid-ce-3' : ''}>
                                <SimpleCard>
                                    <div style={{ height: '100%' }} className="pt-3">
                                        <CommunicationCalendar
                                            events={events.map(({ start, end, ...rest }) => ({
                                                start: start.toDate(),
                                                end: end.toDate(),
                                                ...rest
                                            }))}
                                            defaultView={defaultView}
                                            eventStyleGetter={({ type }) => ({
                                                className: `border-0 rounded-0 m-0 p-0 background-hover ${type.className}`
                                            })}
                                            onNavigate={(date, view) => onNavigate(date, view, props, setDates)}
                                            defaultDate={getDefaultDate(props).toDate()}
                                            onView={view => onView(view, props)}
                                            views={['month', 'work_week', 'day']}
                                        />
                                    </div>
                                </SimpleCard>
                            </div>
                            <div>
                                <div className="mb-4">
                                    <CalendarActionButtons
                                        getDefaultDate={() => getDefaultDate(props)}
                                        createEvent={createEvent}
                                    />
                                    <CalendarFilter filter={filter} />
                                </div>
                            </div>
                            <DebugSection sections={[{ name: 'events', object: events }]} />
                        </div>
                    </PageSection>
                );
            }}
        </CalendarEventsProvider>
    );
};

const mapStateToProps = state => ({
    user: state.user,
    offline: state.offline,
    meetings: state.meetings,
    visitActions: state.visitActions,
    events: state.events,
    communications: state.communications,
    resources: state.resources
});

export default connect(
    mapStateToProps,
    undefined
)(CalendarPage);
