import React from 'react';
import useModalForm from '../../common/Hooks/useModalForm';
import { Modal, ModalBody } from 'reactstrap';
import { TemplateForm } from '../Forms/TemplateForm';
import { AppModalHeader } from '../../common/AppModalHeader';
import _ from 'lodash';

export const TemplateModal = ({ children }) => {
    const { toggle, isOpen, openModal, formValue } = useModalForm();

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} className={'modal-lg'}>
                <AppModalHeader toggle={toggle}>
                    {_.has(formValue, 'id') ? 'Edit Template' : 'Add Template'}
                </AppModalHeader>
                <ModalBody>
                    <TemplateForm toggle={toggle} template={formValue} />
                </ModalBody>
            </Modal>
            {children({
                templateAction: template => {
                    openModal(template);
                }
            })}
        </React.Fragment>
    );
};
