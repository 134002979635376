import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import CompleteMeetingForm from '../Forms/CompleteMeetingForm';
import useModalForm from '../../common/Hooks/useModalForm';

const CompleteMeetingModal = ({ children }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();

    return (
        <>
            {isOpen ? (
                <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
                    <AppModalHeader toggle={toggle}>Complete Meeting</AppModalHeader>
                    <ModalBody>
                        <CompleteMeetingForm
                            onCancel={toggle}
                            meetingId={formValue.meetingId}
                            companyId={formValue.companyId}
                        />
                    </ModalBody>
                </Modal>
            ) : null}
            {children({
                completeMeeting: ({ companyId, meetingId }) => openModal({ companyId, meetingId })
            })}
        </>
    );
};

export default CompleteMeetingModal;
