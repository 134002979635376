import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';

export const apiCreateAgreementEvidence = (file, onResultCallback) => async dispatch => {
    const type = ENDPOINTS.API.NOT_IN_REDUX;

    dispatch(
        apiGetPost(
            type,
            ['AgreementEvidence', 'evidence'],
            file,
            {},
            undefined,
            true,
            error => toastr.error(type, `Response ${error.message}`),
            {},
            undefined,
            undefined,
            onResultCallback
        )
    );
};
