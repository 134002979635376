import { useMemo } from 'react';

function useProductFamilyOptions(productFamily, allowDeleteProductFamilies) {
    return useMemo(
        () =>
            productFamily
                .filter(p => allowDeleteProductFamilies || !p.deleted)
                .map(family => ({
                    key: family.id,
                    value: family.name
                })),
        [productFamily, allowDeleteProductFamilies]
    );
}

export default useProductFamilyOptions;
