import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';
import { toastr } from 'react-redux-toastr';

export const apiRemoveAgreementFromAddendum = (
    addendumId,
    agreementHeaderId,
    successTitle,
    successMessage
) => async dispatch => {
    const type = ENDPOINTS.API.ADDENDUM_REMOVE_AGREEMENT;

    dispatch(
        apiGetPost(
            type,
            ['agreement', 'addendum', addendumId, 'remove'],
            agreementHeaderId,
            {},
            undefined,
            true,
            error => {
                toastr.error(type, `Response ${error.message}`);
            },
            undefined,
            successTitle,
            successMessage
        )
    );
};
