import * as yup from 'yup';
import moment from 'moment';

const validation = yup
    .object()
    .shape({
        activityRegarding: yup.string().required('Activity Regarding is required'),
        location: yup.string().required('Location is required'),
        subject: yup.string().required('Subject is required'),
        note: yup.string().required('Description is required'),
        assigneeId: yup.string().required('Assignee is required'),
        startDate: yup.date(),
        endDate: yup.date()
    })
    .test('end-after-start', function(value) {
        const { startDate, endDate } = value;
        return (
            moment(startDate).isBefore(moment(endDate)) ||
            this.createError({
                // Formik will use the error "path" as the key in the errors; set this to whatever you want
                path: 'endDate',
                message: 'Close Date Must be after the Meeting Date'
            })
        );
    });

export default validation;
