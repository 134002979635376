import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Button, InputGroupAddon } from 'reactstrap';
import FormikText from '../../common/FormkControls/FormikText';
import { apiCompanySearch } from '../../../actions/Api/Company/apiCompanySearch';
import { AlertIfNoRows } from '../../common/Table/AlertIfNoRows';
import { makeGetOnline } from '../../../selectors/Offline/offlineSelectors';
import { makeGetCompanySearchLoading } from '../../../selectors/Rest/loadingSelectors';
import { makeGetSearchResults } from '../../../selectors/Api/Company/companySearchSelector';
import CompaniesTable from '../Tables/CompaniesTable';
import MoreResultsWarning from '../Components/MoreResultsWarning';

const MAX_RESULTS = 10;
const ITEMS_PER_PAGE = 5;

class CompanySearchForm extends React.Component {
    state = { changed: false };

    render() {
        const {
            search,
            online,
            searchResults: { companies, totalResults },
            breakpoint,
            isLoading,
            placeholder
        } = this.props;

        return (
            <Formik
                initialValues={{ keyword: '' }}
                onSubmit={({ keyword }) => {
                    this.setState({ changed: false });
                    search(keyword, MAX_RESULTS);
                }}
                render={formikProps => {
                    formikProps.formName = 'companySearchForm';
                    return (
                        <form onSubmit={formikProps.handleSubmit}>
                            <FormikText
                                hideLabel
                                placeholder={placeholder}
                                fieldName="keyword"
                                {...formikProps}
                                handleChange={e => {
                                    if (!this.state.changed) {
                                        this.setState({ changed: true });
                                    }
                                    formikProps.handleChange(e);
                                }}
                                disabled={!online}
                                inputGroupAddons={
                                    <InputGroupAddon addonType="append">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={formikProps.values.keyword === '' || !online}
                                        >
                                            Search
                                        </Button>
                                    </InputGroupAddon>
                                }
                            />
                            <MoreResultsWarning
                                maxResults={MAX_RESULTS}
                                totalResults={totalResults}
                                show={
                                    formikProps.values.keyword &&
                                    !isLoading &&
                                    !this.state.changed &&
                                    totalResults > MAX_RESULTS
                                }
                            />
                            <AlertIfNoRows
                                message={
                                    formikProps.values.keyword && !isLoading && !this.state.changed
                                        ? 'No Matching Records Found. Please refine your criteria.'
                                        : null
                                }
                                rows={companies}
                                isLoading={isLoading}
                            >
                                <CompaniesTable
                                    breakpoint={breakpoint}
                                    rows={companies || []}
                                    isLoading={isLoading}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                />
                            </AlertIfNoRows>
                        </form>
                    );
                }}
            />
        );
    }
}

const makeMapStateToProps = () => {
    const getOnline = makeGetOnline();
    const getIsLoading = makeGetCompanySearchLoading();
    const getSearchResults = makeGetSearchResults();
    return (state, props) => ({
        online: getOnline(state, props),
        isLoading: getIsLoading(state, props),
        searchResults: getSearchResults(state, props)
    });
};

const mapDispatchToProps = dispatch => ({
    search: (keyword, maxResults) => dispatch(apiCompanySearch(keyword, maxResults))
});

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(CompanySearchForm);
