import React from 'react';
import { connect } from 'react-redux';
import { FormatAgreementDiscount } from '../../AgreementDetails/AgreementDetailsTable/DetailRow';
import useProductAndFamilyForDetail from '../../AgreementDetails/Hooks/useProductAndFamilyForDetail';
import AddendumProductName from './AddendumProductName';
import AgreementDetailEditButton from '../../AgreementDetails/Buttons/AgreementDetailEditButton';
import AgreementDetailDeleteButton from '../../AgreementDetails/Buttons/AgreementDetailDeleteButton';

const AddendumProductDetailChange = ({ newProduct, productFamily, detail, editAgreementDetail, readOnly }) => {
    const { family, product } = useProductAndFamilyForDetail(newProduct, productFamily, detail);

    if (!family || !product) {
        console.error('AddendumProductDetailChange Detail does not have product or product family', {
            detail,
            product,
            family
        });
        return null;
    }

    return (
        <div style={!!detail.deleted ? { textDecoration: 'line-through' } : {}}>
            {!readOnly && (
                <>
                    <AgreementDetailEditButton detail={detail} editAgreementDetail={editAgreementDetail} />
                    <AgreementDetailDeleteButton detail={detail} />
                </>
            )}
            <AddendumProductName familyName={family.name} productName={product.name} />
            <ul style={{ paddingLeft: 25 }}>
                <li>Will be added to agreements</li>
                {detail.manufacturerDiscount ? (
                    <li>
                        Manufacturer discount will be{' '}
                        <b>
                            <FormatAgreementDiscount field="manufacturerDiscount" agreementDetail={detail} />
                        </b>
                    </li>
                ) : null}
                {detail.dealerDiscount ? (
                    <li>
                        Dealer discount will be{' '}
                        <b>
                            <FormatAgreementDiscount field="dealerDiscount" agreementDetail={detail} />
                        </b>
                    </li>
                ) : null}
            </ul>
        </div>
    );
};

const mapStateToProps = ({ newProduct, productFamily }) => ({
    newProduct,
    productFamily
});

export default connect(mapStateToProps)(AddendumProductDetailChange);
