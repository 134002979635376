import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import moment from 'moment';
import { visitActionsQuery } from '../../../actions/Api/VisitAction/apiVisitActionsFilter';
import { apiEventFilter } from '../../../actions/Api/Event/apiEventFilter';
import { apiMeetingFilter } from '../../../actions/Api/Meeting/apiMeetingFilter';
import { apiCommunicationFilter } from '../../../actions/Api/Communication/apiCommunicationFilter';
import eventTypes from '../eventTypes';
import CalendarEventsFilter from './CalendarEventsFilter';
import EditEventModal from '../../Events/Modals/EditEventModal';
import EditVisitActionModal from '../../VisitActions/Modals/EditVisitActionModal';
import EditCommunicationModal from '../../Communication/Modals/EditCommunicationModal';

function filterAndMapMeetings(meetings, momentStart, momentEnd, userId, companyId) {
    return filterBetweenAndBelongsTo(meetings, momentStart, momentEnd, userId, companyId).map(meeting => ({
        id: meeting.meetingId,
        type: meeting.meetingMode === 'FacetoFace' ? eventTypes.FaceToFaceMeeting : eventTypes.TelephoneMeeting,
        data: meeting,
        start: moment(meeting.startDate),
        end: moment(meeting.endDate)
    }));
}

function filterAndMapCommunications(communications, momentStart, momentEnd, userId, companyId, editCommunication) {
    return filterBetweenAndBelongsTo(communications, momentStart, momentEnd, userId, companyId).map(communication => ({
        id: communication.communicationId,
        type: eventTypes.ScheduledContact,
        data: communication,
        start: moment(communication.startDate),
        end: moment(communication.endDate),
        customEdit: () => editCommunication(communication)
    }));
}

function filterAndMapVisitActions(visitActions, momentStart, momentEnd, userId, companyId, editVisitAction) {
    return filterBetweenAndBelongsTo(visitActions, momentStart, momentEnd, userId, companyId).map(visitAction => ({
        id: visitAction.visitActionId,
        type: eventTypes.VisitReportAction,
        data: visitAction,
        customEdit: () => editVisitAction(visitAction),
        start: moment(visitAction.startDate),
        end: moment(visitAction.endDate)
    }));
}

function filterAndMapEvents(visitActions, momentStart, momentEnd, userId, companyId, editEvent) {
    return filterBetweenAndBelongsTo(visitActions, momentStart, momentEnd, userId, companyId).map(event => ({
        id: event.eventId,
        type: {
            ...(event.eventType === 'CSMAdminDay'
                ? eventTypes.AdminDay
                : event.eventType === 'CSMAnnualLeave'
                ? eventTypes.Holiday
                : eventTypes.Event)
        },
        data: event,
        customEdit: () => editEvent(event),
        start: moment(event.startDate),
        end: moment(event.endDate)
    }));
}

function filterBetweenAndBelongsTo(items, momentStart, momentEnd, userId, companyId) {
    return items.filter(
        ({ startDate, assignee, company, status }) =>
            (!status || status !== 'Cancelled') &&
            moment(startDate).isSameOrAfter(momentStart) &&
            moment(startDate).isBefore(momentEnd) &&
            (!userId || (assignee && userId === assignee.id)) &&
            (!companyId || (company && companyId === company.id))
    );
}

const CalendarEventsProvider = props => {
    return (
        <EditCommunicationModal>
            {({ editCommunication }) => (
                <EditVisitActionModal>
                    {({ editVisitAction }) => (
                        <EditEventModal>
                            {({ editEvent, createEvent }) => (
                                <CalendarEventsProviderWrapped
                                    editVisitAction={editVisitAction}
                                    createEvent={createEvent}
                                    editCommunication={editCommunication}
                                    editEvent={editEvent}
                                    {...props}
                                />
                            )}
                        </EditEventModal>
                    )}
                </EditVisitActionModal>
            )}
        </EditCommunicationModal>
    );
};

const CalendarEventsProviderWrapped = ({
    getEvents,
    data,
    start,
    end,
    shouldFetchData,
    userId,
    companyId,
    createEvent,
    editEvent,
    editVisitAction,
    editCommunication,
    children
}) => {
    const [[momentStart, momentEnd], setDates] = useState([moment(start), moment(end)]);

    const callbackGetEvents = useCallback(
        (momentStart, momentEnd) => !shouldFetchData || getEvents(momentStart, momentEnd, userId),
        [getEvents, userId, shouldFetchData]
    );

    useEffect(() => {
        callbackGetEvents(momentStart, momentEnd);
    }, [callbackGetEvents, momentStart, momentEnd]);

    const events = useMemo(
        () => filterAndMapEvents(data.events, momentStart, momentEnd, userId, companyId, editEvent),
        [data.events, momentStart, momentEnd, userId, companyId, editEvent]
    );

    const communications = useMemo(
        () =>
            filterAndMapCommunications(
                data.communications,
                momentStart,
                momentEnd,
                userId,
                companyId,
                editCommunication
            ),
        [data.communications, momentStart, momentEnd, userId, companyId, editCommunication]
    );
    const visitActions = useMemo(
        () => filterAndMapVisitActions(data.visitActions, momentStart, momentEnd, userId, companyId, editVisitAction),
        [data.visitActions, momentStart, momentEnd, userId, companyId, editVisitAction]
    );
    const meetings = useMemo(() => filterAndMapMeetings(data.meetings, momentStart, momentEnd, userId, companyId), [
        data.meetings,
        momentStart,
        momentEnd,
        userId,
        companyId
    ]);

    return (
        <CalendarEventsFilter events={[...events, ...communications, ...visitActions, ...meetings]}>
            {({ filteredEvents, filter }) => children({ events: filteredEvents, filter, setDates, createEvent })}
        </CalendarEventsFilter>
    );
};

const makeMapStateToProps = () => {
    const getState = createSelector(
        [state => state.events, state => state.communications, state => state.visitActions, state => state.meetings],
        (events, communications, visitActions, meetings) => {
            return {
                data: { events, communications, visitActions, meetings }
            };
        }
    );
    return (state, props) => getState(state, props);
};

const mapDispatchToProps = dispatch => ({
    getEvents: (start, end, userId) => {
        dispatch(
            visitActionsQuery()
                //.statusIn(['Pending', 'InProgress'])
                .isBetween(start, end)
                .isAssignedToCurrentUser()
                .run()
        );
        dispatch(
            apiEventFilter({
                startDate: start,
                endDate: end,
                assigneeId: userId
                //status: ['Pending', 'InProgress']
            })
        );
        dispatch(
            apiMeetingFilter({
                startDate: start,
                endDate: end,
                assigneeId: userId
                //status: ['Pending', 'InProgress']
            })
        );
        dispatch(
            apiCommunicationFilter({
                startDate: start,
                endDate: end,
                assigneeId: userId
                //status: ['Pending', 'InProgress']
            })
        );
    }
});

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(CalendarEventsProvider);
