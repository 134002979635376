import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { SaveCancelButton } from '../../common/SaveCancelButton';
import { Formik } from 'formik';
import FormikText from '../../common/FormkControls/FormikText';
import { Prompt } from 'react-router-dom';
import { apiMeetingPost } from '../../../actions/Api/Meeting/apiMeetingPost';

const MeetingNotesForm = ({ meeting, saveMeeting }) => (
    <Formik
        onSubmit={({ engageNotes }) =>
            saveMeeting({
                ...meeting,
                engageNotes
            })
        }
        initialValues={{ engageNotes: meeting.engageNotes || '' }}
        render={formikProps => {
            formikProps.formName = 'meetingNotesForm';
            const commNotes = meeting.engageNotes || '';

            return (
                <Fragment>
                    <Prompt
                        key="NavigationPrompt"
                        when={formikProps.values.engageNotes !== commNotes}
                        message={location =>
                            `Meeting Notes have not been saved are you sure that you want to exit this page.`
                        }
                    />
                    <FormikText fieldName="engageNotes" {...formikProps} type="textarea" hideLabel />
                    <div className="w-100 text-right">
                        <SaveCancelButton
                            onSave={formikProps.handleSubmit}
                            disableSave={formikProps.values.engageNotes === commNotes}
                            onCancel={() => formikProps.setValues({ engageNotes: commNotes })}
                            disableCancel={formikProps.values.engageNotes === commNotes}
                        />
                    </div>
                </Fragment>
            );
        }}
    />
);

const mapDispatchToProps = dispatch => ({
    saveMeeting: meeting => dispatch(apiMeetingPost(meeting))
});

export default connect(
    null,
    mapDispatchToProps
)(MeetingNotesForm);
