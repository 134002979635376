import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import _ from 'lodash';
import { getFieldsNotUpdated, setPropertiesSyncing, setPropertySyncing } from '../helper';
const initialState = [];

const propertiesToIgnoreForErrors = [
    'person',
    'company',
    'assignee',
    'updatedDate',
    'start',
    'end',
    'assigneeName',
    'calendarOption'
];

export const getFleetProfileUpdateErrors = (local, remote) => {
    return getFieldsNotUpdated(local, remote, propertiesToIgnoreForErrors);
};

const updateCompany = (state, { data }) => {
    data.profile.companyId = data.companyId;
    return [data.profile, ...state.filter(x => data.profile.fleetProfileId !== x.fleetProfileId)];
};

const updateFleetProfilePostRollback = (state, { meta, payload }) => {
    const rollbackTo = meta.unmodifiedFleetProfile;
    rollbackTo.syncing = false;
    rollbackTo.error = payload.message;
    return [_.clone(rollbackTo), ...state.filter(x => x.fleetProfileId === rollbackTo.fleetProfileId)];
};

const updateFleetProfilePostCommit = (state, action) => {
    const {
        meta: { fleetProfile: local },
        payload: { data: remote }
    } = action;
    return [
        remote,
        ...state.filter(x => local.fleetProfileId !== x.fleetProfileId && remote.fleetProfileId !== x.fleetProfileId)
    ];
};

const updateFleetProfilePost = (state, { data }) => {
    const original = state.find(x => data.fleetProfileId === x.fleetProfileId);
    if (!original) {
        return state;
    }
    setPropertiesSyncing(original, data);
    return [data, ...state.filter(x => data.fleetProfileId !== x.fleetProfileId)];
};

const updateFleetProfilePostItem = (state, { data: { fleetProfileId, field, value } }) => {
    const profile = state.find(x => x.fleetProfileId === fleetProfileId);
    profile[field] = value;
    setPropertySyncing(profile, field);

    return [_.clone(profile), ...state.filter(x => x.fleetProfileId === profile.fleetProfileId)];
};

export function fleetProfiles(state = initialState, action) {
    switch (action.type) {
        case RESET_REDUCER:
            return initialState;
        case ENDPOINTS.API.COMPANY:
            return updateCompany(state, action);
        case ENDPOINTS.API.COMPANY_DATA_GET:
            return action.data.companies.length ? updateCompany(state, { data: action.data.companies[0] }) : state;
        case ENDPOINTS.API.COMPANY_OFFLINE_COMMIT:
            return updateCompany(state, action.payload);
        case ENDPOINTS.API.FLEET_PROFILE_POST_ROLLBACK:
            return updateFleetProfilePostRollback(state, action);
        case ENDPOINTS.API.FLEET_PROFILE_POST_COMMIT:
            return updateFleetProfilePostCommit(state, action);
        case ENDPOINTS.API.FLEET_PROFILE_POST:
            return updateFleetProfilePost(state, action);
        case ENDPOINTS.API.FLEET_PROFILE_POST_ITEM:
            return updateFleetProfilePostItem(state, action);
        default:
            return state;
    }
}
