import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { agreementsQuery } from '../../../actions/Api/AgreementHeader/apiAgreementFilter';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { RenderTable } from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import _ from 'lodash';
import moment from 'moment';
import { apiTemplateTest } from '../../../actions/Api/Template/apiTemplateTest';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';

const query = {
    type: ENDPOINTS.API.TEST_TEMPLATE_COMPANY_AGREEMENTS_FILTER,
    fetchCompanies: true,
    fetchDetails: false,
    fetchPersons: false,
    fetchParentHeader: false,
    fetchAddendumHeader: false,
    agreementTypeFilter: { included: ['Agreement'], excluded: [] },
    addPathToUserLog: '',
    termsTypeFilter: { included: [], excluded: [] },
    statusFilter: { included: ['Approved', 'Live'], excluded: [] },
    productFilter: { included: [], excluded: [] }
};

const TestTemplateForm = ({ data }) => {
    const isSearchLoading = useIsLoading([ENDPOINTS.API.TEST_TEMPLATE_COMPANY_AGREEMENTS_FILTER]);
    const isTestLoading = useIsLoading([ENDPOINTS.API.TEMPLATE_TEST]);
    const [searchTerm, setSearchTerm] = useState('');
    const dispatch = useDispatch();

    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    const handleSubmit = () => {
        dispatch(
            agreementsQuery({
                ...query
            })
                .filterCompanyName(searchTerm.length ? searchTerm : undefined)
                .run()
        );
    };

    return (
        <div>
            <Button
                disabled={isSearchLoading}
                className="btn-success"
                style={{ float: 'right' }}
                onClick={event => handleSubmit(event)}
            >
                {isSearchLoading ? <ShowLoadingSpinner isLoading={isSearchLoading} size={20} /> : 'Search'}
            </Button>
            <div style={{ overflow: 'hidden', paddingRight: '.5em' }}>
                <Input
                    type="text"
                    placeholder="Enter part or all of a company name"
                    value={searchTerm}
                    onChange={handleChange}
                    style={{ width: '100%' }}
                />
            </div>
            <br />
            <RenderTable
                id={undefined}
                breakpoint={undefined}
                itemsPerPage={5}
                headings={[
                    { value: 'Name', field: 'name', sortField: 'name', sort: true, searchable: true },
                    { value: 'Company Name', field: 'companyName', sortField: 'companyName', sort: true },
                    { value: 'Start Date', field: 'startDate', sortField: 'startDate', sort: true },
                    { value: 'End Date', field: 'endDate', sortField: 'endDate', sort: true },
                    { value: 'Terms Type', field: 'termsType', sortField: 'termsType', sort: true, right: true },
                    {
                        value: 'Agreement Type',
                        field: 'agreementType',
                        sortField: 'agreementType',
                        sort: true,
                        right: true
                    },
                    {
                        value: 'Agreement Approval Team',
                        field: 'agreementApprovalTeam',
                        sortField: 'agreementApprovalTeam',
                        sort: true,
                        right: true
                    },
                    { value: 'Status', field: 'status', sortField: 'status', sort: true, right: true },
                    { value: 'Action', field: 'action', sort: false, preventClickThrough: true }
                ]}
                filteredRows={mapStateDataToData(data, dispatch, isTestLoading)}
                showCount={true}
            />
        </div>
    );
};

const mapStateDataToData = ({ agreementHeaders = [], companies = [] }, dispatch, isTestLoading) => {
    return agreementHeaders.map(agreementHeader => ({
        companyName: _.get(
            companies.find(company => company.companyId === agreementHeader.companyId),
            'name',
            'No company name'
        ),
        startDate: moment(agreementHeader.startDate).format('DD-MM-YYYY'),
        endDate: moment(agreementHeader.endDate).format('DD-MM-YYYY'),
        action: (
            <Button
                disabled={isTestLoading}
                className="btn-info"
                onClick={() => dispatch(apiTemplateTest(agreementHeader))}
            >
                {isTestLoading ? <ShowLoadingSpinner isLoading={isTestLoading} size={20} /> : 'TEST'}
            </Button>
        ),
        ...agreementHeader
    }));
};

const mapStateToProps = state => ({
    data: state.testTemplateData ? state.testTemplateData : {}
});

export default connect(mapStateToProps)(TestTemplateForm);
