import React from 'react';
import AgreementHeaderTableCard from './AgreementHeaderTableCard';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import { connect } from 'react-redux';
import { agreementsQuery } from '../../../actions/Api/AgreementHeader/apiAgreementFilter';
import RedirectNotLoggedIn from '../../app/RedirectNotLoggedIn';

export const filterAgreements = user => agreementHeaders => {
    return agreementHeaders.filter(agreementHeader => {
        return (
            agreementHeader.agreementType === 'Agreement' &&
            agreementHeader.status === 'Draft' &&
            (user.canApproveAgreement === 'Y' || agreementHeader.createdBy === user.userId) &&
            (agreementHeader.agreementApprovalTeam === user.agreementApprovalTeam ||
                user.agreementApprovalTeam === 'Admin')
        );
    });
};

const DraftAgreementsTable = ({ showFilter, user }) => {
    return (
        <>
            <RedirectNotLoggedIn
                change={1}
                onLoggedInDispatchArray={[
                    agreementsQuery()
                        .filterAgreementType('Agreement')
                        .filterStatus('Draft')
                        .shouldFetchDetails(false)
                        .shouldFetchPersons(false)
                        .shouldFetchCompanies(true)
                        .run()
                ]}
            />
            <AgreementHeaderTableCard
                filter={filterAgreements(user)}
                tableIsNotInCard={true}
                title="Draft Agreements"
                noItemsText="There are no agreements to show"
                showFilter={showFilter}
                columns={[
                    agreementHeaderColumnDefs.companyName,
                    agreementHeaderColumnDefs.startDate,
                    agreementHeaderColumnDefs.endDate,
                    agreementHeaderColumnDefs.termsType,
                    agreementHeaderColumnDefs.agreementApprovalTeam,
                    agreementHeaderColumnDefs.status
                ]}
            />
        </>
    );
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(DraftAgreementsTable);
