import { useState } from 'react';

const useToggleOpen = (initialIsOpen = false) => {
    const [isOpen, setIsOpen] = useState(initialIsOpen);

    return {
        toggle: () => setIsOpen(!isOpen),
        setIsOpen,
        isOpen
    };
};

export default useToggleOpen;
