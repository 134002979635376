import React, { useContext } from 'react';
import AgreementHeaderTableCard from './AgreementHeaderTableCard';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import { CompanyAgreementHeadersContext } from '../../Company/Provider/CompanyAgreementHeadersProvider';

const CompanyAgreementsTable = ({ isLoading }) => {
    const { agreementHeaders } = useContext(CompanyAgreementHeadersContext);

    return (
        <AgreementHeaderTableCard
            rows={agreementHeaders}
            isLoading={isLoading}
            title="Agreement"
            noItemsText="There are no agreements to show"
            tableIsNotInCard={true}
            columns={[
                agreementHeaderColumnDefs.startDate,
                agreementHeaderColumnDefs.endDate,
                agreementHeaderColumnDefs.name,
                agreementHeaderColumnDefs.termsType,
                agreementHeaderColumnDefs.agreementApprovalTeam,
                agreementHeaderColumnDefs.status
            ]}
        />
    );
};

export default CompanyAgreementsTable;
