import { ENDPOINTS } from '../../../actions/Api/constants';
import _ from 'lodash';
import { RESET_REDUCER } from '../../../actions/resetReducers';

const initialState = [];

const productFamily = (state = initialState, { type, data }) => {
    switch (type) {
        case ENDPOINTS.API.PRODUCTS_FILTER:
            return _.get(data, 'productFamilies');
        case RESET_REDUCER:
            return initialState;
        default:
            return state;
    }
};

export default productFamily;
