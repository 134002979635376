import React from 'react';
import { useDispatch } from 'react-redux';
import ReactiveForm from '../../common/ReactiveForm';
import { apiFinaliseAgreement } from '../../../actions/Api/AgreementHeader/apiFinaliseAgreement';
import * as Yup from 'yup';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';

const FORM_NAME = 'FinalizeAgreementForm';

function handleSubmit(dispatch, form, toggle) {
    dispatch(apiFinaliseAgreement(form.agreementHeaderId, form.notes, 'Success', 'Agreement Finalised', toggle));
    toggle();
}

export default function FinalizeAgreementForm({ finalizeAgreement, agreementHeaderId, toggle }) {
    const initialValues = {
        agreementHeaderId,
        notes: ''
    };
    const dispatch = useDispatch();
    return (
        <ReactiveForm
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
                notes: Yup.string().required('Please enter notes.')
            })}
            formName={FORM_NAME}
            cancel={toggle}
            saveButtonContent={'Finalise Agreement'}
            handleSubmit={form => handleSubmit(dispatch, form, toggle)}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Text columnDef={{ ...basicColumnDef('Notes', 'notes'), type: 'textarea' }} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
}
