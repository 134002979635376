import { offlinePost } from '../apiHelpers';
import { ENDPOINTS } from '../constants';

export const apiBandingPost = banding => async (dispatch, getState) => {
    dispatch(
        offlinePost(
            ENDPOINTS.API.BANDING_POST,
            ENDPOINTS.API.BANDING_POST_COMMIT,
            ENDPOINTS.API.BANDING_POST_ROLLBACK,
            banding,
            getUnmodified(banding, getState),
            getPostData(banding),
            banding.bandingId,
            ['company', 'banding'],
            {}
        )
    );
};

const getUnmodified = (event, getState) => {
    let unmodified = getState().bandings.find(x => event.bandingId === x.bandingId);
    if (!unmodified) {
        unmodified = { ...event };
    }
    return unmodified;
};

const getPostData = banding => {
    const data = { ...banding };
    if (data.bandingId < 0) {
        data.bandingId = null;
    }
    return data;
};
