import { ENDPOINTS } from '../constants';
import { getRestFormatDateTimeNow, offlinePost } from '../apiHelpers';

export const apiPersonEdit = (person, originalPerson) => async (dispatch, getState) =>
    dispatch(
        offlinePost(
            ENDPOINTS.API.PERSON_POST,
            ENDPOINTS.API.PERSON_POST_COMMIT,
            ENDPOINTS.API.PERSON_POST_ROLLBACK,
            person,
            getUnmodified(person, getState, originalPerson),
            getPostData(person),
            person.personId,
            ['person'],
            {}
        )
    );

const getUnmodified = (person, getState, originalPerson) => {
    let unmodifiedPerson = getState().people.find(x => person.personId === x.personId);
    if (!unmodifiedPerson) {
        unmodifiedPerson = originalPerson || { ...person };
    }
    unmodifiedPerson.error = undefined;
    return unmodifiedPerson;
};

const getPostData = person => {
    const data = { ...person };
    if (data.personId < 0) {
        data.personId = null;
        data.createdDate = getRestFormatDateTimeNow();
    }
    data.updatedDate = getRestFormatDateTimeNow();
    return data;
};
