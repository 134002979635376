import React from 'react';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import person from '../person';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import PersonEditModal from '../Modal/PersonEditModal';

class PersonTable extends React.Component {
    static defaultProps = {
        breakpoint: 500,
        itemsPerPage: 5,
        noItemsText: 'There are no people to show',
        columns: [person.descriptor, person.businessEmailLink, person.businessPhone]
    };

    render() {
        const {
            loadingStates,
            rows,
            columns,
            title,
            breakpoint,
            itemsPerPage,
            noItemsText,
            companyId,
            ...rest
        } = this.props;
        const tableDefinition = new TableDefinition(columns);

        return (
            <PersonEditModal>
                {({ editPerson, createPerson }) => (
                    <ChoiceListFilteredTableNoDashboardData
                        id={'PersonTable'}
                        tableDefinition={tableDefinition}
                        breakpoint={breakpoint}
                        noItemsText={noItemsText}
                        itemsPerPage={itemsPerPage}
                        rows={rows}
                        columnDefsId={person.id}
                        tableDefinitionColumns={tableDefinition.columns}
                        onRowClick={editPerson}
                        isLoading={false}
                        tableIsNotInCard={false}
                        cardButtons={[
                            {
                                text: 'Add Person',
                                fontAwesomeName: 'fa-plus',
                                onClick: () => createPerson(companyId)
                            }
                        ]}
                        title={title}
                        {...rest}
                    />
                )}
            </PersonEditModal>
        );
    }
}

export default PersonTable;
