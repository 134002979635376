import React from 'react';
import { Alert } from 'reactstrap';

const filterErrors = ({ errors, touched, submitCount }) => {
    return Object.keys(errors).reduce((acc, key) => {
        if (!touched || submitCount > 0 || touched[key]) {
            acc.push({ key, error: errors[key] });
        }
        return acc;
    }, []);
};

export default props => {
    const errs = filterErrors(props);
    return errs.length ? (
        <Alert color="danger" className="mt-4">
            <p>
                <b>Please correct the following before submitting again:</b>
            </p>
            <ul>
                {errs.map(x => (
                    <li key={x.key}>{x.error}</li>
                ))}
            </ul>
        </Alert>
    ) : null;
};
