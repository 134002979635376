import React, { useContext } from 'react';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';
import ShowAgreementEvidence from './ShowAgreementEvidence';
import UploadAgreementEvidence from './UploadAgreementEvidence';
import { useAgreementEvidenceContext } from '../Provider/AgreementEvidenceProvider';

const AgreementEvidence = () => {
    const {
        agreementHeaderId,
        agreementState: { isAgreement, canAddEvidence }
    } = useContext(AgreementHeaderContext);

    const { agreementEvidence, setAgreementEvidence } = useAgreementEvidenceContext();

    return isAgreement ? (
        <ShowAgreementEvidence agreementHeaderId={agreementHeaderId} agreementEvidence={agreementEvidence}>
            {canAddEvidence ? (
                <UploadAgreementEvidence
                    agreementHeaderId={agreementHeaderId}
                    onResultCallback={setAgreementEvidence}
                />
            ) : null}
        </ShowAgreementEvidence>
    ) : null;
};

export default AgreementEvidence;
