import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Alert, ListGroup, ListGroupItem } from 'reactstrap';
import MapArray from '../../common/MapArray';
import BrowserHistoryRow from './BrowserHistoryRow';
import { getBrowsingHistoryFromState } from '../../../selectors/Api/User/userSelector';

const BrowserHistory = ({ title, filteredHistory }) => {
    return (
        <ListGroup className="mb-4">
            <ListGroupItem active tag="h5">
                {title ? title : 'Recently Visited'}
            </ListGroupItem>
            {filteredHistory.length > 0 ? (
                <MapArray from={filteredHistory}>
                    <BrowserHistoryRow />
                </MapArray>
            ) : (
                <Alert color="warning">There is no browsing history</Alert>
            )}
        </ListGroup>
    );
};

const makeMapStateToProps = () => {
    const getState = createSelector(
        [getBrowsingHistoryFromState, (state, props) => props.urlsStartWith],
        (browsingHistory, urlsStartWith) => ({
            filteredHistory: filteredHistory(browsingHistory, urlsStartWith)
        })
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(BrowserHistory);

const filteredHistory = (browsingHistory, urlsStartWith) => {
    return urlsStartWith ? browsingHistory.filter(x => x.url.indexOf(urlsStartWith) === 0) : browsingHistory;
};
