import React from 'react';
import { FormatDate } from '../../common/FormatDate';
import SyncingOrErrorIndicator from '../../common/SyncingOrErrorIndicator';

export default ({ showSync, values, field, showTime, ...rest }) =>
    showSync ? (
        <div>
            <SyncingOrErrorIndicator isSyncing={values.syncing} isError={values.error} />
            <FormatDate showTime={showTime} date={values[field]} {...rest} />
        </div>
    ) : (
        <FormatDate showTime={showTime} date={values[field]} {...rest} />
    );
