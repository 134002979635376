export const PreventClickThrough = event => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
};

export const PreventClickThroughAllowDefault = event => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
};
