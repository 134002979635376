import React from "react";
import PropTypes from "prop-types";
import { FormFeedback, InputGroup, Input, InputGroupAddon, InputGroupText } from "reactstrap";
import FormikFormGroup from "./FormikFormGroup";

const FormikText = (props) => {
    const {
        values,
        formName,
        fieldName,
        handleChange,
        handleBlur,
        type,
        errors,
        touched,
        hideLabel,
        placeholder,
        disabled,
        inputGroupAddons,
        inputClassName,
        groupClassName,
        labelClassName,
        inputGroupClassName,
        children,
        title,
        min,
        max,
        prefix,
        collapsible,
        defaultCollapsed,
        inputAddon,
    } = props;
    const invalid = touched[fieldName] && errors[fieldName];

    const value = values[fieldName] ? values[fieldName] : "";

    return (
        <FormikFormGroup
            fieldName={fieldName}
            formName={formName}
            hideLabel={hideLabel}
            title={title}
            type={type}
            className={groupClassName}
            labelClassName={labelClassName}
            collapsible={collapsible}
            defaultCollapsed={defaultCollapsed}
            values={values}
        >
            <InputGroup className={inputGroupClassName}>
                {prefix && (
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>{prefix}</InputGroupText>
                    </InputGroupAddon>
                )}
                <Input
                    type={type}
                    invalid={invalid ? true : false}
                    name={fieldName}
                    onChange={handleChange}
                    minLength={min}
                    maxLength={max}
                    onBlur={handleBlur}
                    value={value} // is this ok to set it to empty stirng //index.js:2177 Warning: `value` prop on `input` should not be null. Consider using an empty string to clear the component or `undefined` for uncontrolled components.
                    placeholder={placeholder}
                    disabled={disabled}
                    className={inputClassName || type !== "number" ? "" : "form-control-lg"}
                    style={{ minHeight: "38px" }}
                />
                {inputAddon}
                {inputGroupAddons}
                {invalid && <FormFeedback tooltip>{errors[fieldName]}</FormFeedback>}
            </InputGroup>
            {max && (value === "" ? max : max - value.length) + " remaining characters"}
            {children}
        </FormikFormGroup>
    );
};

FormikText.propTypes = {
    values: PropTypes.object.isRequired,
    formName: PropTypes.string,
    fieldName: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    type: PropTypes.string,
};

FormikText.defaultProps = {
    type: "text",
};

export default FormikText;
