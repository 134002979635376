import React from 'react';
import PageSection from '../../common/PageSection';
import RedirectNotLoggedIn from '../RedirectNotLoggedIn';
import Layout3Columns, { Column1, Column2, Column3 } from '../../Dashboard/Components/Layout3Columns';
import AgreementsPageActions from '../../AgreementHeader/Buttons/AgreementsPageActionButtons';
import AgreementTabs from '../../AgreementHeader/Tabs/AgreementsTabs';
import DeclinedAgreementsTable from '../../AgreementHeader/Tables/DeclinedAgreementsTable';
import AgreementSearchFormAndTable from '../../AgreementHeader/Search/AgreementsSearchFormAndTable';
import BrowserHistory from '../../BrowserHistory/Tables/BrowserHistory';
import { useHistory } from 'react-router';
import { apiTemplateGet } from '../../../actions/Api/Template/apiTemplateGet';

export default function AgreementsPage() {
    const { goBack } = useHistory();

    return (
        <PageSection onBackClicked={goBack} title={'Agreements'}>
            <RedirectNotLoggedIn onLoggedInDispatchArray={[apiTemplateGet()]} />
            <Layout3Columns>
                <Column1>
                    <BrowserHistory title="Recent Agreements" urlsStartWith="/dashboard/agreement/" />
                </Column1>
                <Column2>
                    <AgreementSearchFormAndTable />
                    <DeclinedAgreementsTable />
                    <AgreementTabs />
                </Column2>
                <Column3>
                    <AgreementsPageActions />
                </Column3>
            </Layout3Columns>
        </PageSection>
    );
}
