//All the hide functions are passed formik props so you can test values if required
const editAgreementHeaderFormConfig = {
    Addendum: {
        hyundaiAgreementId: () => undefined,
        hideCompany: () => true,
        hidePerson: () => true,
        hideStartDate: () => true,
        hideEndDate: () => true,
        hideName: () => false,
        hideHyundaiAgreementId: () => true,
        hideTermsType: () => true,
        hideApprovalTeam: () => true,
        hideExpectedVolume: () => true,
        hideAddendumUpdatesStartDate: () => false,
        hideAddendumUpdatesEndDate: () => false,
        hideAddendumDatesSection: () => false,
        hideNotes: () => false,
        hideTemplates: () => true,
        isDisabled: ({ values }) => !values.canAmend,
        templateChoiceIsDisabled: () => true,
        hideTemplateReset: () => true,
        editAddendumUpdatesStartDateDisabled: ({ values }) => !values.addendumUpdatesStartDateEnabled,
        editAddendumUpdatesEndDateDisabled: ({ values }) => !values.addendumUpdatesEndDateEnabled
    },
    Agreement: {
        hyundaiAgreementId: () => '',
        hideCompany: () => false,
        hidePerson: () => false,
        hideStartDate: () => false,
        hideEndDate: () => false,
        hideName: () => false,
        hideHyundaiAgreementId: () => false,
        hideTermsType: () => false,
        hideApprovalTeam: () => false,
        hideExpectedVolume: () => false,
        hideAddendumUpdatesStartDate: () => true,
        hideAddendumUpdatesEndDate: () => true,
        hideAddendumDatesSection: () => true,
        hideNotes: () => false,
        hideTemplates: () => false,
        isDisabled: ({ values }) => !values.canAmend,
        templateChoiceIsDisabled: ({ values }) =>
            !values.canAmend || values.termsType === undefined || values.status === 'Live',
        hideTemplateReset: ({ values }) => !values.canAmend || values.id < 0,
        editAddendumUpdatesStartDateDisabled: () => true,
        editAddendumUpdatesEndDateDisabled: () => true
    },
    Promotion: {
        hyundaiAgreementId: () => undefined,
        hideCompany: () => true,
        hidePerson: () => true,
        hideStartDate: () => false,
        hideEndDate: () => false,
        hideName: () => false,
        hideHyundaiAgreementId: () => true,
        hideTermsType: () => true,
        hideApprovalTeam: () => true,
        hideExpectedVolume: () => true,
        hideAddendumUpdatesStartDate: () => true,
        hideAddendumUpdatesEndDate: () => true,
        hideAddendumDatesSection: () => true,
        hideNotes: () => false,
        hideTemplates: () => true,
        isDisabled: ({ values }) => !values.canAmend,
        templateChoiceIsDisabled: () => true,
        hideTemplateReset: () => true,
        editAddendumUpdatesStartDateDisabled: () => true,
        editAddendumUpdatesEndDateDisabled: () => true
    },
    Template: {
        hyundaiAgreementId: () => undefined,
        hideCompany: () => true,
        hidePerson: () => true,
        hideStartDate: () => true,
        hideEndDate: () => true,
        hideName: () => false,
        hideHyundaiAgreementId: () => true,
        hideTermsType: () => false,
        hideApprovalTeam: () => true,
        hideExpectedVolume: () => true,
        hideAddendumUpdatesStartDate: () => true,
        hideAddendumUpdatesEndDate: () => true,
        hideAddendumDatesSection: () => true,
        hideNotes: () => false,
        hideTemplates: () => true,
        isDisabled: ({ values }) => !values.canAmend,
        templateChoiceIsDisabled: () => true,
        hideTemplateReset: () => true,
        editAddendumUpdatesStartDateDisabled: () => true,
        editAddendumUpdatesEndDateDisabled: () => true
    }
};

export default editAgreementHeaderFormConfig;
