import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import _ from 'lodash';
import { Formik } from 'formik';
import FormikText from '../../common/FormkControls/FormikText';
import FormikSelectChoicelist from '../../common/FormkControls/FormikSelectChoicelist';
import FormikForm from '../../common/FormkControls/FormikForm';
import { apiMeetingPost } from '../../../actions/Api/Meeting/apiMeetingPost';

const MEETING_FORM_NAME = 'completeMeetingForm';

const schemaFor = meeting =>
    meeting && meeting.roiBooked === 'Y'
        ? yup.object().shape({
              status: yup.string().required(),
              meetingNotes: yup.string(),
              rating: yup.string().required(),
              feedback: yup.string()
          })
        : yup.object().shape({
              status: yup.string().required(),
              meetingNotes: yup.string(),
              rating: yup.string(),
              feedback: yup.string()
          });

class CompleteMeetingForm extends React.Component {
    handleSubmit = formValues => {
        const { meetingId, meetings } = this.props;
        const meeting = meetings.find(x => x.meetingId === meetingId);

        this.props.save(
            {
                ...meeting,
                ...formValues
            },
            this.props.onCancel
        );
    };

    render() {
        const { meetingId, meetings } = this.props;
        const meeting = meetings.find(x => x.meetingId === meetingId);

        const initialValues = {
            status: 'Complete',
            rating: 'G1',
            feedback: _.get(meeting, 'feedback', '') || '',
            note: _.get(meeting, 'note', '') || ''
        };

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={schemaFor(meeting)}
                onSubmit={this.handleSubmit}
                render={formikProps => {
                    formikProps.formName = MEETING_FORM_NAME;
                    return (
                        <FormikForm
                            formikProps={formikProps}
                            cancel={this.props.onCancel}
                            resources={this.props.resources}
                            debugSections={[{ name: 'Form', object: formikProps.values }]}
                        >
                            <div className="col-12">
                                {/*
                                <FormikSelectChoicelist
                                    emptyOption="Select a status"
                                    fieldName="status"
                                    choiceList="communicationStatus"
                                    filter={x => ['Complete', 'Postponed'].includes(x.value)}
                                    {...formikProps}
                                />
                                */}
                                <FormikSelectChoicelist
                                    emptyOption="Select a rating..."
                                    fieldName="rating"
                                    choiceList="communicationAppointmentRating"
                                    filter={x => !x.value.startsWith('G') && x.value !== 'Overdue'}
                                    {...formikProps}
                                />
                                <FormikText fieldName="feedback" type="textarea" {...formikProps} />
                            </div>
                        </FormikForm>
                    );
                }}
            />
        );
    }
}

const mapStateToProps = state => ({
    meetings: state.meetings,
    companies: state.companies,
    user: state.user,
    opportunities: state.opportunities,
    people: state.people,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (communication, cancel) => {
        dispatch(apiMeetingPost(communication));
        cancel();
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CompleteMeetingForm);
