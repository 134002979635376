import React from 'react';
import ChangePasswordForm from '../Forms/ChangePasswordForm';

class ChangePasswordPage extends React.Component {
    render = () => {
        return (
            <div className="login-background d-flex no-block justify-content-center align-items-center">
                <ChangePasswordForm token={this.props.match.params.token} className="bg-white p-4" />
            </div>
        );
    };
}

export default ChangePasswordPage;
