import React from 'react';
import ActionButton from '../../common/ActionButtons/ActionButton';
import EditAgreementDetailModal from '../../AgreementDetails/Modals/EditAgreementDetailModal';

const AddendumAddProductButton = ({ agreementHeaderId }) => {
    return (
        <EditAgreementDetailModal>
            {({ editAgreementDetail }) => (
                <ActionButton
                    buttonText={'Add Product to Agreements'}
                    fontawesomeIcon="fa-plus"
                    bootstrapColor="secondary"
                    onClickAction={() =>
                        editAgreementDetail(undefined, agreementHeaderId, undefined, undefined, true, false)
                    }
                />
            )}
        </EditAgreementDetailModal>
    );
};

export default AddendumAddProductButton;
