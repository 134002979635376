import * as yup from 'yup';

const validation = yup.object().shape({
    descriptor: yup.string().required('Band Name is required'),
    carLcv: yup.string().required('Car/Lcv is required'),
    numberOfVehicles: yup.string().required('Number of Vehicles is required'),
    // manufacturers: yup.string().required('Manufacturers is required'),
    // segment: yup.string().required('Segment is required'),
    // manufacturerPolicy: yup.string().required('Manufacturer Policy is required'),
    // reason: yup.string().required('Reason is required'),
    // fundingMethod: yup.string().required('Funding Method is required'),
    // decisionBasedOn: yup.string().required('Decision Based On is required'),
    // minPricePoint: yup.string().required('Min Price Point is required'),
    // changeCycleMonths: yup.string().required('Change Cycle Months is required'),
    // changeCycleMiles: yup.string().required('Change Cycle Miles is required'),
    // fuelType: yup.string().required('Fuel Type is required'),
    // co2Cap: yup.string().required('CO2 Cap is required'),
    // benchmarkManufacturer: yup.string().required('Benchmark Manufacturer is required'),
    // benchmarkModel: yup.string().required('Benchmark Model is required'),
    // benchmarkPrice: yup.string().required('Benchmark Price is required'),
    hyundaiSalesOpportunity: yup.string().required('Hyundai Sales Opportunity is required'),
    // anecdotalNotes: yup.string().required('Anecdotal Notes is required'),
    // maxPricePoint: yup.string().required('Max Price Point is required')
});

export default validation;
