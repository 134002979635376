import React from 'react';
import { dashboardDataGetField } from '../../Dashboard/Factories/DashboardHelpers';
import { connect } from 'react-redux';
import { DisplayField } from '../DisplayField';

export const FieldDetails = ({ values, fields, idColumnDef, resources, onRowClick = undefined, actions = {} }) => {
    const row = mapToRow(values, idColumnDef, resources, fields, onRowClick, actions);

    return fields.map(({ title, field, type }) => (
        <DisplayField key={field} title={title} value={row[dashboardDataGetField(type, field)]} />
    ));
};

const mapStateToProps = state => ({
    resources: state.resources
});

export const ConnectedFieldDetails = connect(mapStateToProps)(FieldDetails);

const mapToRow = (values, idColumnDef, resources, fields, onRowClick, actions) => {
    const row = { ...values };
    //always need an id for react keys

    row.id = idColumnDef.mapping(resources, values, actions);

    fields
        .filter(x => x.hasOwnProperty('mapping'))
        .forEach(({ field, mapping }) => (row[field] = mapping(resources, values, field, actions)));

    fields
        .filter(x => x.hasOwnProperty('valueToSortOnMapping'))
        .forEach(
            ({ field, valueToSortOnMapping }) =>
                (row[`${field}-raw`] = valueToSortOnMapping(resources, values, field, actions))
        );

    fields
        .filter(x => !x.mapToDisplayedValue && x.hasOwnProperty('type'))
        .forEach(({ field, type }) => (row[dashboardDataGetField(type, field)] = row[field]));

    fields
        .filter(x => x.hasOwnProperty('mapToDisplayedValue'))
        .forEach(
            ({ field, type, mapToDisplayedValue }) =>
                (row[dashboardDataGetField(type, field)] = mapToDisplayedValue(resources, values, field, actions))
        );

    row.onClick = onRowClick ? () => onRowClick(row) : undefined;
    return row;
};

export const mapToRows = (rows, idColumnDef, resources, fields, onRowClick, actions) =>
    rows.map(row => mapToRow(row, idColumnDef, resources, fields, onRowClick, actions));
