import React from 'react';
import { connect } from 'react-redux';
import { apiOpportunityEdit } from '../../../actions/Api/Opportunity/apiOpportunityEdit';
import ReactiveForm from '../../common/ReactiveForm';
import opportunity from '../opportunity';
import validation from './opportunityValidation';
import _ from 'lodash';
import { Alert } from 'reactstrap';

const FORM_NAME = 'opportunityForm';

const emptyForm = {
    description: '',
    stage: '',
    wonLostReason: '',
    wonLostOther: '',
    opportunitySize: 0,
    model: '',
    note: '',
    closedDate: new Date(),
    openedDate: new Date(),
    dueDate: new Date(),
    opportunityId: -1,
    companyId: -1
};

function updateFieldWithValueIfNotSet(initialValues, columnDef, defaultValue) {
    const value = _.get(initialValues, columnDef.field);
    initialValues[columnDef.field] = value ? value : defaultValue;
}

class OpportunityForm extends React.Component {
    state = {
        leasingCompanyFilterId: null
    };

    handleSubmit = form => {
        const { onCancel } = this.props;

        if (['W', 'L'].indexOf(form.stage) < 0) {
            form.closedDate = '';
        }

        this.props.save(form, onCancel);
    };

    render() {
        const { onCancel, opportunityId, companyId, resources, companies, user } = this.props;

        const company = companies.find(x => x.companyId === companyId);

        if (!company) {
            return (
                <Alert color="danger">
                    {opportunityId} No company found for company id {companyId}
                </Alert>
            );
        }

        const initialValues =
            opportunityId < 0
                ? { ...emptyForm, opportunityId, companyId }
                : {
                      ...this.props.opportunities.find(opportunity => opportunity.opportunityId === opportunityId)
                  };

        updateFieldWithValueIfNotSet(initialValues, opportunity.companyName, company.name);
        updateFieldWithValueIfNotSet(initialValues, opportunity.opportunitySize, 0);
        updateFieldWithValueIfNotSet(initialValues, opportunity.note, '');
        updateFieldWithValueIfNotSet(initialValues, opportunity.closedDate, new Date());
        initialValues.assigneeId = initialValues.assigneeId
            ? initialValues.assigneeId.toString()
            : user.userId.toString();

        return (
            <ReactiveForm
                initialValues={initialValues}
                cancel={onCancel}
                resources={resources}
                formName={FORM_NAME}
                validationSchema={validation}
                handleSubmit={this.handleSubmit}
            >
                <ReactiveForm.Section title="">
                    <ReactiveForm.Text columnDef={opportunity.companyName} disabled />
                    <ReactiveForm.Choicelist columnDef={opportunity.opportunityYear} />
                    <ReactiveForm.Text columnDef={opportunity.description} />
                    <ReactiveForm.Choicelist columnDef={opportunity.stage} />
                    <ReactiveForm.Choicelist
                        columnDef={opportunity.wonLostReason}
                        filter={(item, { stage }) => {
                            if (stage === 'L') return !item.value.startsWith('W');
                            if (stage === 'W') return !item.value.startsWith('L');
                            return true;
                        }}
                        hide={({ values }) => ['W', 'L'].indexOf(values.stage) < 0}
                    />
                    <ReactiveForm.Text
                        columnDef={opportunity.wonLostOther}
                        hide={({ values }) =>
                            _.get(values, `${opportunity.wonLostReason.field}`, '') !== 'LOther' ||
                            _.get(values, `${opportunity.stage.field}`, '') !== 'L'
                        }
                    />
                    <ReactiveForm.Text columnDef={opportunity.opportunitySize} />
                    <ReactiveForm.Text columnDef={opportunity.hyundaiOpportunitySize} />
                    <ReactiveForm.Choicelist columnDef={opportunity.model} />
                    <ReactiveForm.Text columnDef={opportunity.note} />

                    <ReactiveForm.Date
                        columnDef={opportunity.openedDate}
                        hide={({ values }) => values.opportunityId > 0}
                    />
                    <ReactiveForm.Date columnDef={opportunity.dueDate} />
                    <ReactiveForm.Date
                        columnDef={opportunity.closedDate}
                        hide={({ values }) => ['W', 'L'].indexOf(values.stage) < 0}
                    />
                </ReactiveForm.Section>
            </ReactiveForm>
        );
    }
}

const mapStateToProps = state => ({
    opportunities: state.opportunities,
    companies: state.companies,
    resources: state.resources,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    save: (opportunity, onCancel) => {
        dispatch(apiOpportunityEdit(opportunity));
        onCancel();
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OpportunityForm);
