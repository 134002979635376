import React, { useContext } from 'react';
import NormalColumn from './NormalColumn';
import SecondaryColumn from './SecondaryColumn';
import _ from 'lodash';
import ButtonAddProduct from '../AgreementDetailsTableButtons/ButtonAddProduct';
import ButtonEditDetail from '../AgreementDetailsTableButtons/ButtonEditDetail';
import ButtonDeleteDetail from '../AgreementDetailsTableButtons/ButtonDeleteDetail';
import ButtonDeclineDetail from '../AgreementDetailsTableButtons/ButtonDeclineDetail';
import ButtonApproveDetail from '../AgreementDetailsTableButtons/ButtonApproveDetail';
import { AgreementDetailsTableContext } from './AgreementDetailsTableProvider';

export function FormatAgreementDiscount({ field, agreementDetail }) {
    return (
        <FormatDiscount
            discount={_.get(agreementDetail, field, '')}
            discountUnits={_.get(agreementDetail, 'discountUnits', '')}
        />
    );
}

const FormatDiscount = React.memo(function FormatDiscount({ discount, discountUnits }) {
    return (
        <>
            {discount
                ? discountUnits === 'Percent'
                    ? `${discount.toFixed(2)}%`
                    : discountUnits === 'Pounds'
                    ? `£${discount.toFixed(2)}`
                    : ''
                : ''}
        </>
    );
});

const DetailRow = ({ product, detail, showProduct, lastDetailForProduct }) => {
    const isDeleted = _.get(detail, 'deleted', null) !== null;
    const isDeclined = _.get(detail, 'status', null) === 'Declined';
    const lastRowDeclined = lastDetailForProduct && isDeclined;

    const { showActionsColumn } = useContext(AgreementDetailsTableContext);

    const backgroundColor = lastRowDeclined ? '#FF000022' : '#E8EEF6';

    return (
        <>
            <NormalColumn style={{ gridColumnStart: 3 }}>{_.get(detail, 'notes', '')}</NormalColumn>
            <SecondaryColumn style={{ backgroundColor }} className="text-right" deleted={isDeleted}>
                <FormatAgreementDiscount field="manufacturerDiscount" agreementDetail={detail} />
            </SecondaryColumn>
            <SecondaryColumn style={{ backgroundColor }} className="text-right" deleted={isDeleted}>
                <FormatAgreementDiscount field="dealerDiscount" agreementDetail={detail} />
            </SecondaryColumn>
            <NormalColumn className="text-right" deleted={isDeleted}>
                <FormatAgreementDiscount field="totalDiscount" agreementDetail={detail} />
            </NormalColumn>

            {showActionsColumn && lastDetailForProduct ? (
                <div className="m-2 text-right my-auto">
                    <ButtonApproveDetail agreementDetail={detail} isDeleted={isDeleted} />
                    <ButtonDeclineDetail agreementDetail={detail} isDeleted={isDeleted} />
                    <ButtonAddProduct
                        product={product}
                        agreementDetail={detail}
                        lastDetailForProduct={lastDetailForProduct}
                        isDeleted={isDeleted}
                        isDeclined={isDeclined}
                    />
                    <ButtonEditDetail product={product} agreementDetail={detail} isDeleted={isDeleted} />
                    <ButtonDeleteDetail agreementDetail={detail} isDeleted={isDeleted} />
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default DetailRow;
