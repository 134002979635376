import { ENDPOINTS } from '../../../actions/Api/constants';
import _ from 'lodash';
import moment from 'moment';
import { RESET_REDUCER } from '../../../actions/resetReducers';

function filterToMatchFunction({
    agreementType,
    agreementHeaderId,
    status,
    endDateAfter,
    endDateBefore,
    parentId,
    ...rest
}) {
    const start = endDateAfter === undefined ? undefined : moment(endDateAfter);
    const end = endDateBefore === undefined ? undefined : moment(endDateBefore);

    return header => {
        return (
            (!agreementType || header.agreementType === agreementType) &&
            (!status || header.status === status) &&
            ((!start && !end) ||
                ((!start || start.isAfter(moment(header.endDate))) &&
                    (!end || end.isBefore(moment(header.endDate))))) &&
            (!agreementHeaderId || header.id === agreementHeaderId) &&
            (!parentId || header.parentId === parentId)
            //TODO: May need more matches
        );
    };
}

function filterState(state, filter, newData) {
    const matchesState = filterToMatchFunction(filter);
    const filteredState = state.filter(
        headerInState => !newData.some(header => headerInState.id === header.id) && !matchesState(headerInState)
    );
    return [...newData, ...filteredState];
}

function simpleFilterState(state, newData) {
    return [...newData, ...state.filter(headerInState => !newData.some(header => headerInState.id === header.id))];
}

const initialState = [];

const agreementHeaders = (state = initialState, { type, data, filter = {} }) => {
    switch (type) {
        case ENDPOINTS.API.COMPANY_DATA_GET:
        case ENDPOINTS.API.AGREEMENT_CREATE_TEMPLATE:
        case ENDPOINTS.API.AGREEMENT_CREATE_DRAFT:
        case ENDPOINTS.API.FINALISE_AGREEMENT:
        case ENDPOINTS.API.CREATE_PROMOTION_AGREEMENT_FOR_COMPANY:
        case ENDPOINTS.API.SWITCH_AGREEMENT_TEMPLATE:
        case ENDPOINTS.API.APPLY_ADDENDUM:
        case ENDPOINTS.API.AGREEMENT_MAKE_TEMPLATE_EDITABLE:
        case ENDPOINTS.API.ADDENDUM_REMOVE_AGREEMENT:
        case ENDPOINTS.API.AGREEMENT_TEMPLATE_POST:
        case ENDPOINTS.API.EMAIL_AGREEMENT_POST:
            const newData = _.get(data, 'agreementHeaders', []);
            return simpleFilterState(state, newData);
        case ENDPOINTS.API.AGREEMENT_FILTER:
        case ENDPOINTS.API.AGREEMENT_GET:
        case ENDPOINTS.API.AGREEMENT_APPROVE:
        case ENDPOINTS.API.AGREEMENT_DECLINE:
        case ENDPOINTS.API.PROMOTION_GET:
        case ENDPOINTS.API.AGREEMENT_DETAIL_DECLINE:
            return filterState(state, filter, _.get(data, 'agreementHeaders', []));
        case ENDPOINTS.API.AGREEMENT_SET_ADDENDUM:
            return [...state, ...data];
        case ENDPOINTS.API.AGREEMENT_DELETE:
            const deleted =  _.get(data, 'agreementHeaders', []);
            if(!deleted) return state;
            return [...state.filter(a => !deleted.map(d => d.id).includes(a.id))];

        case RESET_REDUCER:
            return initialState;
        default:
            return state;
    }
};

export default agreementHeaders;
