import React from 'react';
import { ActionButtonGroup } from '../../common/ActionButtons/ActionButtons';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { useDispatch } from 'react-redux';
import { apiDownloadAgreementEvidence } from '../../../actions/Api/AgreementHeader/apiDownloadAgreementEvidence';
import { ListGroupItem } from 'reactstrap';

export default function ShowAgreementEvidence({ agreementEvidence, children }) {
    const dispatch = useDispatch();
    return (
        <ActionButtonGroup title={'Agreement Evidence'}>
            {agreementEvidence.map((item, i) => (
                <ActionButton
                    buttonText={`${item.fileName}`}
                    fontawesomeIcon="fa-download"
                    bootstrapColor="white"
                    onClickAction={() => dispatch(apiDownloadAgreementEvidence(item))}
                    key={i}
                />
            ))}
            {agreementEvidence.length ? null : (
                <ActionButton
                    tag="button"
                    type="button"
                    buttonText={'No evidence has currently been uploaded for this agreement.'}
                    bootstrapColor="white"
                    fontawesomeIcon="fa-warning"
                    disabled={true}
                />
            )}
            {children ? (
                <ListGroupItem tag="div" type="div" className="list-group-item-action hover-pointer">
                    {children}
                </ListGroupItem>
            ) : null}
        </ActionButtonGroup>
    );
}
