import React from "react";
import { connect } from "react-redux";
import ReactiveForm from "../../common/ReactiveForm";
import { withRouter } from "react-router-dom";
import agreementDetailColumnDefs from "../agreementDetailColumnDefs";
import { apiAgreementDetailDecline } from "../../../actions/Api/AgreementDetail/apiAgreementDetailDecline";

const FORM_NAME = "DeclineAgreementDetailForm";

function handleSubmit(agreementDetail, form, declineAgreementDetail, toggle) {
    declineAgreementDetail(agreementDetail.id, form.notes, "Success", "Agreement Detail Declined", toggle);
}

const DeclineAgreementDetailForm = ({ agreementDetail, declineAgreementDetail, resources, notes, toggle }) => {
    const initialValues = {
        notes: notes ? agreementDetail.notes : null,
    };

    return (
        <>
            <p>
                <span>Manufacturer Discount: </span> {agreementDetail.manufacturerDiscount}
                {agreementDetail.discountUnits === "Percent" ? "%" : agreementDetail.discountUnits}
            </p>
            <p>
                <span>Expected Dealer Discount: </span> {agreementDetail.dealerDiscount}
                {agreementDetail.discountUnits === "Percent" ? "%" : agreementDetail.discountUnits}
            </p>
            <p>
                <span>Total Discount: </span> {agreementDetail.totalDiscount}
                {agreementDetail.discountUnits === "Percent" ? "%" : agreementDetail.discountUnits}
            </p>
            <ReactiveForm
                initialValues={initialValues}
                resources={resources}
                formName={FORM_NAME}
                cancel={toggle}
                saveButtonContent={"Decline Agreement Detail"}
                handleSubmit={(form) => handleSubmit(agreementDetail, form, declineAgreementDetail, toggle)}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Text columnDef={agreementDetailColumnDefs.notes} max="255" />
                </ReactiveForm.Section>
            </ReactiveForm>
        </>
    );
};

const mapStateToProps = (state, props) => ({
    agreementDetail: state.agreementDetails.find((e) => e.id === props.id),
    resources: state.resources,
});

const mapDispatchToProps = (dispatch) => ({
    declineAgreementDetail: (id, notes, successTitle, successMessage, callback) =>
        dispatch(apiAgreementDetailDecline([id], notes, successTitle, successMessage, callback)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DeclineAgreementDetailForm));
