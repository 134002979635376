import React from 'react';
import { Link } from 'react-router-dom';

export const SidebarNavLink = ({ onLinkOpen, isVisible, name, link, icon }) =>
    isVisible ? (
        <li className="nav-item sidebar-nav-item border-bottom">
            <Link className="nav-link m-0 p-0" to={link}>
                <div className="py-3 px-4" onClick={() => onLinkOpen && onLinkOpen()}>
                    {icon ? <i className={`fa d-inline mr-3 fa-${icon}`} /> : null}
                    {name}
                </div>
            </Link>
        </li>
    ) : null;

export default SidebarNavLink;
