import React from 'react';
import PropTypes from 'prop-types';
import { NavRouterLink } from './NavRouterLink/index';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

const NavigationItems = ({ onLinkOpen, navigation }) => {
    const user = useSelector(state => state.user);

    const className = 'd-none d-md-inline-flex';
    const isInApprovementTeam = _.get(user, 'agreementApprovalTeam');
    const canCreateTemplate = _.get(user, 'canCreateTemplate', 'N') === 'Y';

    return (
        <React.Fragment>
            {navigation && navigation.home && !navigation.home.hideFromToolbar && (
                <NavRouterLink
                    onLinkOpen={onLinkOpen}
                    icon="home"
                    className={className}
                    isVisible
                    name="Home"
                    link="/dashboard/home"
                />
            )}
            {navigation && navigation.companies && !navigation.companies.hideFromToolbar && (
                <NavRouterLink
                    onLinkOpen={onLinkOpen}
                    icon="building"
                    className={className}
                    isVisible
                    name="Companies"
                    link="/dashboard/companies"
                />
            )}
            {navigation && navigation.calendar && !navigation.calendar.hideFromToolbar && (
                <NavRouterLink
                    onLinkOpen={onLinkOpen}
                    icon="calendar"
                    className={className}
                    isVisible
                    name="Calendar"
                    link={`/calendar/month/${moment().year()}/${moment().month() + 1}/${moment().date()}`}
                />
            )}
            {navigation &&
                navigation.agreements &&
                isInApprovementTeam !== undefined &&
                isInApprovementTeam.trim() !== '' &&
                !navigation.agreements.hideFromToolbar && (
                    <NavRouterLink
                        onLinkOpen={onLinkOpen}
                        icon="thumbs-up"
                        className={className}
                        isVisible
                        name="Agreements"
                        link={`/dashboard/agreements`}
                    />
                )}
            {navigation && navigation.templates && canCreateTemplate && !navigation.templates.hideFromToolbar && (
                <NavRouterLink
                    onLinkOpen={onLinkOpen}
                    icon="file"
                    className={className}
                    isVisible
                    name="Templates"
                    link={`/dashboard/templates`}
                />
            )}
            {navigation &&
                Object.entries(navigation)
                    .filter(([key, value]) => value.isNamedDashboard && !value.hideFromToolbar)
                    .map(([key, value]) => (
                        <NavRouterLink
                            key={key}
                            onLinkOpen={onLinkOpen}
                            icon={value.icon}
                            className={className}
                            isVisible
                            name={value.name}
                            link={`/dashboard/${key}`}
                        />
                    ))}
        </React.Fragment>
    );
};

NavigationItems.propTypes = {
    onLinkOpen: PropTypes.func,
    isSidebar: PropTypes.bool
};

export default NavigationItems;
