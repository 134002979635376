function filterApprovedDetails(details) {
    return details.filter(detail => detail.status === 'Approved' && detail.deleted === null);
}
function filterDeclinedDetails(details) {
    return details.filter(detail => detail.status === 'Declined');
}
function filterModifiedDetails(details) {
    return details.filter(detail => detail.status === 'Modified' && detail.deleted === null);
}

function group(details, productFamily, newProduct) {
    return productFamily.map(family => ({
        family,
        products: newProduct
            .filter(p => p.productFamilyId === family.id)
            .map(product => ({ product, details: details.filter(x => x.productId === product.id) }))
    }));
}

const productAndDetailsDropdownFilters = {
    showAll: {
        value: 'Show All',
        filter: (details, products, productFamily) =>
            group(
                details,
                productFamily,
                products.filter(product => !product.deleted || details.some(detail => product.id === detail.productId))
            )
    },
    showApprovedRowsOnly: {
        value: 'Approved Details',
        filter: (details, products, productFamily) => {
            const approvedDetails = filterApprovedDetails(details);
            return group(
                approvedDetails,
                productFamily,
                products.filter(product =>
                    approvedDetails.some(approvedDetail => approvedDetail.productId === product.id)
                )
            );
        }
    },
    showDeclinedRowsOnly: {
        value: 'Show All Declined Details',
        filter: (details, products, productFamily) => {
            const approvedDetails = filterDeclinedDetails(details);
            return group(
                approvedDetails,
                productFamily,
                products.filter(product =>
                    approvedDetails.some(approvedDetail => approvedDetail.productId === product.id)
                )
            );
        }
    },
    showRequireApprovalRowsOnly: {
        value: 'Show All Details that Require Approval',
        filter: (details, products, productFamily) => {
            const approvedDetails = filterModifiedDetails(details);

            return group(
                approvedDetails,
                productFamily,
                products.filter(product =>
                    approvedDetails.some(approvedDetail => approvedDetail.productId === product.id)
                )
            );
        }
    },
    hideDeletedRows: {
        value: "Hide All Deleted Product's Families And Products",
        filter: (details, products, productFamily) => {
            return group(
                details,
                productFamily.filter(productFamily => !productFamily.deleted),
                products.filter(product => !product.deleted)
            );
        }
    },
    hideApprovedRows: {
        value: 'Hide All Approved Details',
        filter: (details, products, productFamily) => {
            const approvedDetails = filterApprovedDetails(details);
            const filteredDetails = details.filter(
                detail => !approvedDetails.some(approvedDetail => approvedDetail.productId === detail.productId)
            );

            return group(
                filteredDetails,
                productFamily,
                products.filter(
                    product =>
                        !product.deleted ||
                        (filteredDetails.some(detail => product.id === detail.productId) &&
                            !filteredDetails[filteredDetails.length - 1].deleted)
                )
            );
        }
    }

    /* editAgreement:{
        value: 'All Rows',
    }*/
};

export default productAndDetailsDropdownFilters;
