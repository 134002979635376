import { useMemo } from 'react';

function useProductOptions(newProduct, allowDeletedProducts = true) {
    return useMemo(
        () =>
            newProduct
                .filter(p => allowDeletedProducts || !p.deleted)
                .map(product => ({
                    key: product.id,
                    value: product.name,
                    productFamilyId: product.productFamilyId,
                    groupId: product.productFamilyId
                })),
        [newProduct, allowDeletedProducts]
    );
}
export default useProductOptions;
