import React from 'react';
import { connect } from 'react-redux';
import PageSection from '../../common/PageSection/index';
import { cookieGetUserId } from '../../../reducers/cookies';
import { apiOpportunityFilter } from '../../../actions/Api/Opportunity/apiOpportunityFilter';
import UpcomingCommunications from '../../Dashboard/Components/UpcomingCommunications';
import SimpleCard from '../../common/Card/SimpleCard';
import BrowserHistory from '../../BrowserHistory/Tables/BrowserHistory';
import ErrorsList from '../../Errors/ErrorsList';
import OutstandingMeetingsTable from '../../Meeting/Tables/OutstandingMeetingsTable';
import RedirectNotLoggedIn from '../RedirectNotLoggedIn';
import Next7DaysAgendaCard from '../../Calendar/Cards/Next7DaysAgendaCard';
import CompanySearchForm from '../../CompanySummary/Forms/CompanySearchForm';
import Layout3Columns, { Column1, Column2, Column3 } from '../../Dashboard/Components/Layout3Columns';
import OutstandingVisitActionsTable from '../../VisitActions/Tables/OutstandingVisitActionsTable';
import { apiEventFilter } from '../../../actions/Api/Event/apiEventFilter';
import { apiMeetingFilter } from '../../../actions/Api/Meeting/apiMeetingFilter';
import OutstandingOpportunitiesTable from '../../Opportunity/Tables/OutstandingOpportunitiesTable';
import { visitActionsQuery } from '../../../actions/Api/VisitAction/apiVisitActionsFilter';
import moment from 'moment';

interface IProps {
	userId?: number;
}

// @ts-ignore
// @ts-ignore
const HomePage: React.SFC<IProps> = ({ userId }) => (
	<PageSection key={`Home`} title={`Home`}>

			<RedirectNotLoggedIn
				onLoggedInDispatchArray={[
					visitActionsQuery()
						.isAssignedToCurrentUser()
						.isBetween(moment().add(-12, 'months'), moment().add(1, 'days'))
						.statusIn(['Pending', 'Overdue'])
						.run(),
					apiOpportunityFilter({
						stages: ['Opportunity'],
						assigneeId: parseInt(cookieGetUserId())
					}, true),
					apiEventFilter({
						startDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 35),
						endDate: new Date(Date.now() + 1000 * 60 * 60 * 24 * 35),
						assigneeId: userId
					}),
					apiMeetingFilter({
						startDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 35),
						endDate: new Date(Date.now() + 1000 * 60 * 60 * 24 * 35),
						assigneeId: userId
					})
				]}
			/>

		<Layout3Columns>
			<Column1>
				<UpcomingCommunications />
				<Next7DaysAgendaCard companyId={undefined} userId={userId} title="Next 7 Days" />
			</Column1>
			<Column2>
				<SimpleCard title={'Search Companies'}>
					<CompanySearchForm />
				</SimpleCard>
				<OutstandingMeetingsTable />
				<OutstandingVisitActionsTable />
				<OutstandingOpportunitiesTable />
			</Column2>
			<Column3>
				<BrowserHistory />
				<ErrorsList />
			</Column3>
		</Layout3Columns>
	</PageSection>
);

const mapStateToProps = (state: any) => ({
	userId: state.user.userId
});

export default connect(mapStateToProps)(HomePage);
