import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HashLoader } from 'react-spinners';
import { createSelector } from 'reselect';

class SyncingOrErrorIndicator extends React.PureComponent {
    static propTypes = {
        isLoading: PropTypes.bool,
        isSyncing: PropTypes.bool,
        isError: PropTypes.string
    };

    renderIsLoading = online => {
        return (
            <div className="d-inline-block mx-2">
                {online ? (
                    <HashLoader color={'#123abc'} loading size={10} />
                ) : (
                    <i className="fa fa-wifi text-danger" title="Offline may be old information" />
                )}
            </div>
        );
    };

    renderIsSyncing = online => {
        return (
            <div className="d-inline-block mx-2">
                {online ? (
                    <HashLoader color={'#123abc'} loading size={10} />
                ) : (
                    <i className="fa fa-hashtag text-primary" title="Content will be saved when online" />
                )}
            </div>
        );
    };

    renderIsError = error => {
        return <i className="fa fa-exclamation-triangle mr-2 text-danger" title={error} />;
    };

    render() {
        const { isSyncing, isLoading, isError, online } = this.props;

        return isLoading
            ? this.renderIsLoading(online)
            : isSyncing
            ? this.renderIsSyncing(online)
            : isError
            ? this.renderIsError(isError)
            : '';
    }
}

const makeMapStateToProps = () => {
    const getState = createSelector(
        [state => state.offline.online],
        online => ({
            online
        })
    );
    return (state, props) => getState(state, props);
};

const mapDispatchToProps = dispatch => ({});

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(SyncingOrErrorIndicator);
