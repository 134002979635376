import { Dispatch } from 'redux';
import { IAction, ENDPOINTS } from '../constants';
import { ICommunication } from '../../../reducers/Api/Communications';
import {handleErrorWithToastUnlessUnauthorised,  makePostRequest} from '../apiHelpers';

export interface ICommunicationFilter {
	status?: string[];
	assigneeId?: number;
	startDate?: Date;
	endDate?: Date;
	companyId?: number;
}

interface IResponse {
	data: ICommunication[]
}

const type = ENDPOINTS.API.COMMUNICATIONS_FILTER;

export const apiCommunicationFilter = (filter: ICommunicationFilter) => async (
	dispatch: Dispatch<IAction>,
	getState: () => any
) => {
		if (!getState().offline.online) {
			return false;
		}

		makePostRequest(['communication', 'filter'], {}, filter)
			.then(({data}:IResponse) => {
				dispatch({
					type,
					data,
					filter,
				});
				return true;
			})
			.catch((error:any)=> handleErrorWithToastUnlessUnauthorised(dispatch, getState, error, type, "Communications"));
};
