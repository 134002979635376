import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getChoiceListValue } from '../LabelText';
import EditFieldDisplayValue from '../EditField/EditFieldDisplayValue';
import { Formik } from 'formik';
import FormikSelectChoicelist from '../FormkControls/FormikSelectChoicelist';
import SmallSaveCancelButtons from './SmallSaveCancelButtons';

class SelectInlineChoicelist extends PureComponent {
    state = { isEditing: false };

    submitForm = values => {
        const { save, propertyName } = this.props;
        this.setState({ isEditing: false });
        save(propertyName, values.value);
    };

    renderEditContents = () => {
        const {
            choiceListName,
            title: oTitle,
            value,
            propertyName,
            mapChoiceValues,
            multiple,
            emptyOption,
            options,
            required
        } = this.props;

        const title = oTitle || propertyName;
        const initialValue = (value || '').toString();

        return (
            <Formik
                initialValues={{ value: initialValue }}
                validate={values => (required && !values.value ? { value: 'Required' } : {})}
                onSubmit={this.submitForm}
                render={formikProps => {
                    const { values, handleSubmit, handleReset } = formikProps;
                    formikProps.formName = title;
                    return (
                        <form onSubmit={handleSubmit}>
                            <FormikSelectChoicelist
                                emptyOption={emptyOption}
                                choiceList={choiceListName}
                                fieldName="value"
                                title={title}
                                isMulti={multiple}
                                options={options}
                                mapChoiceValues={mapChoiceValues}
                                {...formikProps}
                            >
                                <SmallSaveCancelButtons
                                    showSave={initialValue.toString() !== values.value}
                                    showCancel={true}
                                    onCancel={event => {
                                        this.setState({ isEditing: false });
                                        handleReset(event);
                                    }}
                                />
                            </FormikSelectChoicelist>
                        </form>
                    );
                }}
            />
        );
    };

    renderNotEditing = (formId, isEdit) => {
        const { isSyncing, isError, value, title, propertyName, multiple, primary, stack, fontawesome } = this.props;
        return (
            <EditFieldDisplayValue
                onClick={() => this.setState({ isEditing: true })}
                stack={stack}
                isSyncing={isSyncing}
                isError={isError}
                fontawesome={fontawesome || 'fa-pencil'}
                value={
                    multiple
                        ? value
                              .split(',')
                              .filter(x => x)
                              .map(value => this.getValue(value))
                              .join(', ')
                        : this.getValue(value)
                }
                title={title || propertyName}
                primary={primary}
                show={isEdit}
            />
        );
    };

    getValue = value => {
        const { resources, choiceListName, mapChoiceValues, options } = this.props;

        let option;
        if (options) {
            option = options.find(option => option.key === value);
        }

        return options
            ? option
                ? option.value
                : ''
            : getChoiceListValue(resources, choiceListName, value, mapChoiceValues);
    };

    render() {
        const { objectId, propertyName } = this.props;
        const { isEditing } = this.state;

        const formId = `${objectId}-${propertyName}`;
        return isEditing ? this.renderEditContents() : this.renderNotEditing(formId, isEditing);
    }
}

const mapStateToProps = state => ({
    resources: state.resources
});

export default connect(mapStateToProps)(SelectInlineChoicelist);
