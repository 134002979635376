import React, { useContext } from 'react';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { useDemosByCompanyById } from '../../Demos/Selectors/useDemosByCompanyId';

const CompanyDemosContext = React.createContext({
    companyId: undefined,
    demos: [],
    isLoading: false
});

const CompanyDemosProvider = ({ companyId, children }) => {
    const isLoading = useIsLoading([ENDPOINTS.API.COMPANY]);
    const demos = useDemosByCompanyById(companyId);
    return (
        <CompanyDemosContext.Provider value={{ companyId, demos, isLoading }}>{children}</CompanyDemosContext.Provider>
    );
};

export const useCompanyDemosContext = () => {
    const state = useContext(CompanyDemosContext);
    if (state === undefined) {
        throw new Error(`useCompanyDemosContext must be used inside CompanyDemosProvider`);
    }
    return state;
};

export default CompanyDemosProvider;
