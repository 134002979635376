import React from 'react';
import AgreementHeaderTableCard from './AgreementHeaderTableCard';
import { connect } from 'react-redux';
import { agreementsQuery } from '../../../actions/Api/AgreementHeader/apiAgreementFilter';
import RedirectNotLoggedIn from '../../app/RedirectNotLoggedIn';

export const filterAgreements = user => agreements =>
{
   return agreements.filter(
        a =>
            a.agreementType === 'Agreement' &&
            a.status === 'Declined'
    );
}
   
const DeclinedAgreementsTable = ({ user }) => (
    <>
        <RedirectNotLoggedIn
            onLoggedInDispatchArray={[
                agreementsQuery()
                    .filterAgreementType('Agreement')
                    .filterStatus('Declined')
                    .shouldFetchDetails(false)
                    .shouldFetchPersons(false)
                    .shouldFetchCompanies(true)
                    .run()
            ]}
        />
        <AgreementHeaderTableCard
            filter={filterAgreements(user)}
            title="Declined Agreements"
            noItemsText="There are no agreements to show"
        />
    </>
);

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(DeclinedAgreementsTable);
