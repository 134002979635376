import { IEvent } from '../../../reducers/Api/Events';
import { Dispatch } from 'redux';
import { IAction, ENDPOINTS } from '../constants';
import {handleErrorWithToastUnlessUnauthorised, makePostRequest} from '../apiHelpers';

export interface IEventFilter {
	assigneeId?: number;
	startDate?: Date;
	endDate?: Date;
	status?: string[];
}

interface IResponse {
	data: IEvent[]
}

const type = ENDPOINTS.API.EVENT_FILTER;

export const apiEventFilter = (filter: IEventFilter) => async (dispatch: Dispatch<IAction>, getState: () => any) => {

		if (!getState().offline.online) {
			return false;
		}

		makePostRequest(['event', 'filter'], {}, filter)
			.then(({data}: IResponse) =>{
				dispatch({
					type,
					data,
				});
				return true;
			})
			.catch((error:any)=> handleErrorWithToastUnlessUnauthorised(dispatch, getState, error, type, "Events"));
};
