import * as yup from 'yup';

const editAgreementAddendumDetailFormValidation = yup.object().shape({
    discountUnits: yup.string().required('A Discount unit is required'),
    manufacturerDiscount: yup
        .number()
        .when('discountUnits', (discountUnits, schema) => {
            if (discountUnits === 'Pounds') {
                return schema.test({
                    test: manufacturerDiscount => (manufacturerDiscount ? manufacturerDiscount <= 10000 : true),
                    message: 'Manufacturer discount cannot be more than one thousand'
                });
            }
            if (discountUnits === 'Percent') {
                return schema.test({
                    test: manufacturerDiscount => (manufacturerDiscount ? manufacturerDiscount <= 100 : true),
                    message: 'Manufacturer discount cannot be more than one hundred'
                });
            }
        })
        .min(0, 'Manufacturer discount cannot be less than zero'),
    dealerDiscount: yup
        .number()
        .nullable()
        .when('discountUnits', (discountUnits, schema) => {
            if (discountUnits === 'Pounds') {
                return schema.test({
                    test: dealerDiscount => (dealerDiscount ? dealerDiscount <= 10000 : true),
                    message: 'Dealer discount cannot be more than one thousand'
                });
            }
            if (discountUnits === 'Percent') {
                return schema.test({
                    test: dealerDiscount => (dealerDiscount ? dealerDiscount <= 100 : true),
                    message: 'Dealer discount cannot be more than one hundred'
                });
            }
        })
        .min(0, 'Dealer discount cannot be less than zero')
});

export default editAgreementAddendumDetailFormValidation;
