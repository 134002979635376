import React, { useContext } from 'react';
import ActionButtons from '../../common/ActionButtons/ActionButtons';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';
import AgreementPdfLinkButton from './AgreementPdfLinkActionButton';
import CloneAgreementButton from './CloneAgreementButton';
import FinalizeAgreementButton from './FinalizeAgreementButton';
import EmailAgreementButton from './EmailAgreementButton';
import DeleteAgreementButton from './DeleteAgreement';
import CancelAgreementButton from './CancelAgreement';
import AddendumRemoveProductButton from './AddendumRemoveProductButton';
import AddendumRemoveTemplateDetailButton from './AddendumRemoveTemplateDetailButton';
import AddendumApplyMinimumDiscountToProductButton from './AddendumApplyMinimumDiscountToProductButton';
import AddendumApplyMaximumDiscountToProductButton from './AddendumApplyMaximumDiscountToProductButton';
import AddendumAddProductButton from './AddendumAddProductButton';
import ApproveDeclineBuittonsInModal from './ApproveDeclineButtonsInModal';
import TemplateToDraftButton from './TemplateToDraftButton';

export default function AgreementDetailsActionButtons() {
    const {
        agreementHeaderId,
        agreementHeader,
        agreementHeader: { status },
        agreementState: {
            canApproveWholeAgreement,
            canClone,
            canDelete,
            canCancel,
            canDownloadPdf,
            canAmend,
            canEmailAgreement,
            canEditAddendum,
            isTemplate
        }
    } = useContext(AgreementHeaderContext);
    const buttons = [];
    if (canApproveWholeAgreement) {
        buttons.push({ key: 'ApproveDeclineBuittonsInModal', component: <ApproveDeclineBuittonsInModal /> });
    }

    if (canDelete) {
        buttons.push({
            key: 'DeleteButton',
            component: <DeleteAgreementButton agreementHeaderId={agreementHeaderId} />
        });
    }

    if (canCancel) {
        buttons.push({
            key: 'CancelButton',
            component: <CancelAgreementButton agreementHeaderId={agreementHeaderId} />
        });
    }

    if (canClone && status !== 'Declined') {
        buttons.push({
            key: 'CloneAgreementButton',
            component: <CloneAgreementButton agreementHeader={agreementHeader} />
        });
    }

    if (
        agreementHeader.status === 'Live' ||
        agreementHeader.status === 'Expired' ||
        agreementHeader.status === 'AwaitingApproval' ||
        agreementHeader.status === 'Cancelled'
    ) {
    } else {
        buttons.push({
            key: 'FinalizeAgreementButton',
            component: <FinalizeAgreementButton agreementHeaderId={agreementHeaderId} />
        });
    }

    if (isTemplate && canAmend) {
        buttons.push({
            key: 'TemplateToDraftButton',
            component: <TemplateToDraftButton agreementHeaderId={agreementHeaderId} />
        });
    }

    if (canEmailAgreement) {
        buttons.push({
            key: 'EmailAgreementButton',
            component: <EmailAgreementButton agreementHeaderId={agreementHeaderId} />
        });
    }

    if (canDownloadPdf) {
        buttons.push({
            key: 'AgreementPdfLinkButton',
            component: <AgreementPdfLinkButton agreementHeaderId={agreementHeaderId} />
        });
    }

    if (canEditAddendum) {
        buttons.push({
            key: 'AddendumAddProductButton',
            component: <AddendumAddProductButton agreementHeaderId={agreementHeaderId} />
        });
        buttons.push({
            key: 'AddendumRemoveProductButton',
            component: <AddendumRemoveProductButton agreementHeaderId={agreementHeaderId} />
        });
        buttons.push({
            key: 'AddendumRemoveTemplateDetailButton',
            component: <AddendumRemoveTemplateDetailButton agreementHeaderId={agreementHeaderId} />
        });
        buttons.push({
            key: 'AddendumApplyMinimumDiscountToProductButton',
            component: <AddendumApplyMinimumDiscountToProductButton agreementHeaderId={agreementHeaderId} />
        });
        buttons.push({
            key: 'AddendumApplyMaximumDiscountToProductButton',
            component: <AddendumApplyMaximumDiscountToProductButton agreementHeaderId={agreementHeaderId} />
        });
    }

    return buttons.length ? (
        <ActionButtons key={'actionButtonsGroup'}>
            {buttons.map(({ key, component }) => (
                <React.Fragment key={key}>{component}</React.Fragment>
            ))}
        </ActionButtons>
    ) : (
        <></>
    );
}
