import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';
import ConfirmButton from '../../common/Buttons/ConfirmButton';
import { apiApplyAddendum } from '../../../actions/Api/AgreementHeader/apiApplyAddendum';
import { useDispatch } from 'react-redux';

const AgreementHeaderPendingAddendumDetails = ({ addendumAgreement, applyAddendum }) => {
    const {
        agreementHeader,
        agreementHeader: { addendumStatus }
    } = useContext(AgreementHeaderContext);
    const dispatch = useDispatch();
    if (addendumStatus !== 'Pending') return null;

    const popoverId = `applyaddendum${agreementHeader.id}`;

    return (
        <Alert color="danger">
            <div className="w-100 d-flex">
                <span className="flex-grow-1">
                    <b>Addendum Pending: </b>
                    <Link
                        className="nav-link m-0 p-0"
                        style={{ color: 'blue' }}
                        to={`/dashboard/agreement/${agreementHeader.addendumId}`}
                    >
                        {addendumAgreement.name}
                    </Link>
                </span>
                <ConfirmButton
                    popoverId={popoverId}
                    title="Apply Addendum"
                    isWorking={false}
                    isWorkingTitle="Applying Addendum"
                    onConfirm={() => dispatch(apiApplyAddendum(agreementHeader.addendumId, [agreementHeader]))}
                >
                    {({ toggle }) => (
                        <Button id={popoverId} onClick={toggle}>
                            Apply
                        </Button>
                    )}
                </ConfirmButton>
            </div>
        </Alert>
    );
};

export default AgreementHeaderPendingAddendumDetails;
