import React, { useContext } from 'react';
import VisitActionsTableCard from '../../VisitActions/Tables/VisitActionsTableCard';
import visitAction from '../visitAction';
import { MeetingVisitActionsContext } from '../../Meeting/Provider/MeetingVisitActionsProvider';

const MeetingVisitActionsTable = () => {
    const { visitActions } = useContext(MeetingVisitActionsContext);

    return (
        <VisitActionsTableCard
            title="Visit Actions"
            rows={visitActions}
            breakpoint={860}
            columns={[visitAction.startDate, visitAction.activityRegarding, visitAction.assignee, visitAction.status]}
        />
    );
};

export default MeetingVisitActionsTable;
