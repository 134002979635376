import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const makeOpportunitiesByCompanyByIdSelector = () =>
    createSelector(
        state => state.opportunities,
        (_, companyId) => companyId,
        (opportunities, companyId) => opportunities.filter(opportunity => opportunity.companyId === companyId)
    );

export const useOpportunitiesByCompanyById = companyId => {
    const selectOpportunities = useMemo(makeOpportunitiesByCompanyByIdSelector, []);
    return useSelector(state => selectOpportunities(state, companyId));
};
