import _ from 'lodash';

const map = {
    [process.env.REACT_APP_LOCAL_HOSTNAME]: process.env.REACT_APP_LOCAL_ENDPOINT,
    [process.env.REACT_APP_DEV_HOSTNAME]: process.env.REACT_APP_DEV_ENDPOINT,
    [process.env.REACT_APP_UAT_HOSTNAME]: process.env.REACT_APP_UAT_ENDPOINT,
    [process.env.REACT_APP_LIVE_HOSTNAME]: process.env.REACT_APP_LIVE_ENDPOINT
};

const currentHostname = _.get(window, 'location.hostname', 'INVALID');
const apiEndpoint = map[currentHostname];

if (!apiEndpoint) {
    throw new Error(`Unable to match config for hostname: "${currentHostname}"`);
}

export default apiEndpoint;
