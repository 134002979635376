import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ENDPOINTS } from '../../../actions/Api/constants';

export const MeetingVisitActionsContext = React.createContext({
    companyId: undefined,
    visitActions: [],
    isLoading: false
});

const MeetingVisitActionsProvider = ({ companyId, isLoading, visitActions, children }) => {
    return (
        <MeetingVisitActionsContext.Provider value={{ companyId, visitActions, isLoading }}>
            {children}
        </MeetingVisitActionsContext.Provider>
    );
};

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => _.get(state.loadingStates, `${ENDPOINTS.API.COMPANY}.isLoading`, false),
            (state, { meetingId }) => state.visitActions.filter(visitAction => visitAction.meetingId === meetingId)
        ],
        (isLoading, visitActions) => ({
            isLoading,
            visitActions
        })
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(MeetingVisitActionsProvider);
