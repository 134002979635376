import axios from 'axios';
import { isLoading } from '../../Loading/isLoading';
import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../constants';
import { makeHeaders, makePath, makeUrl } from '../apiHelpers';

export const apiResource = () => async (dispatch, getState) => {
    const type = ENDPOINTS.API.RESOURCE;

    isLoading(dispatch, type, true, {});
    try {
        const result = await axios({
            method: 'GET',
            url: makeUrl(getState(), makePath(['resources']), {}), //TODO://make the adding path params a function
            headers: makeHeaders()
        });

        isLoading(dispatch, type, false, {});
        dispatch({ type, data: result.data });
        return result || true;
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        toastr.error(type, `Response ${error.message}`);
        return false;
    }
};
