import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import _ from 'lodash';

const initialState = [];

const agreementAddendumDetails = (state = initialState, { type, data }) => {
    switch (type) {
        case ENDPOINTS.API.ADDENDUM_DETAIL_DELETE:
        case ENDPOINTS.API.ADDENDUM_DETAIL_UPDATE:
            const updated = _.get(data, 'agreementAddendumDetails', []);
            return [...updated, ...state.filter(detail => !updated.some(updated => updated.id === detail.id))];
        case ENDPOINTS.API.AGREEMENT_FILTER:
            const addendumDetails = _.get(data, 'agreementAddendumDetails');
            if (!addendumDetails) return state;
            return _.get(data, 'agreementAddendumDetails');
        case RESET_REDUCER:
            return initialState;
        default:
            return state;
    }
};

export default agreementAddendumDetails;
