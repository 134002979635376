import { offlinePost } from '../apiHelpers';
import { ENDPOINTS } from '../constants';

export const apiMeetingPost = meeting => async (dispatch, getState) => {
    dispatch(
        offlinePost(
            ENDPOINTS.API.MEETING_POST,
            ENDPOINTS.API.MEETING_POST_COMMIT,
            ENDPOINTS.API.MEETING_POST_ROLLBACK,
            meeting,
            getUnmodified(meeting, getState),
            getPostData(meeting, getState),
            meeting.meetingId,
            ['meeting', 'update'],
            {}
        )
    );
};

const getUnmodified = (meeting, getState) => {
    let unmodified = getState().meetings.find(x => meeting.meetingId === x.meetingId);
    if (!unmodified) {
        unmodified = { ...meeting };
    }
    return unmodified;
};

const getPostData = (meeting, getState) => {
    const data = { ...meeting };

    if (data.meetingId < 0) {
        data.meetingId = null;
    }

    if (!data.endDate || data.endDate === '0001-01-01T00:00:00') {
        data.endDate = data.startDate;
    }
    return data;
};
