import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactiveForm from '../../common/ReactiveForm';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import _ from 'lodash';
import { apiAgreementHeaderUpdate } from '../../../actions/Api/AgreementHeader/apiAgreementHeaderUpdate';
import editAgreementHeaderFormValidation from './editAgreementHeaderFormValidation';
import editAgreementHeaderFormConfig from './editAgreementHeaderFormConfig';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { Alert } from 'reactstrap';
import { apiCreateDraftAgreement } from '../../../actions/Api/AgreementHeader/apiCreateDraftAgreement';
import { apiChangeTemplate } from '../../../actions/Api/AgreementHeader/apiChangeTemplate';
import { Button } from 'reactstrap';
import ConfirmButton from '../../common/Buttons/ConfirmButton';
import { getLoadingState } from '../../../reducers/Rest/loadingStates';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { safeDispatchPush } from '../../../reducers/util';
import { push } from 'connected-react-router';
import { agreementHeaderStates } from '../agreementHeaderStates';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import { useTemplateContext } from '../../Template/Provider/TemplateProvider';

function useCompaniesChoices(companyId, companies) {
    return useMemo(
        () =>
            companyId
                ? companies
                      .filter(x => x.companyId === companyId)
                      .map(company => ({
                          key: company.companyId,
                          value: company.name
                      }))
                : [],
        [companyId, companies]
    );
}

function useTemplateChoices(agreementHeaders) {
    return useMemo(
        () =>
            agreementHeaders
                .filter(
                    agreementHeader =>
                        agreementHeader.agreementType === 'Template' &&
                        ['Live', 'Approved'].indexOf(agreementHeader.status) > -1
                )
                .map(agreementHeader => ({
                    key: agreementHeader.id,
                    value: agreementHeader.name,
                    termsType: agreementHeader.termsType
                })),
        [agreementHeaders]
    );
}

function useCompanyPeopleChoices(companyId, people) {
    return useMemo(
        () =>
            companyId
                ? people
                      .filter(x => x.companyId === companyId)
                      .map(x => ({
                          key: x.personId,
                          value: x.firstName + ' ' + x.lastName
                      }))
                : [],
        [companyId, people]
    );
}

function saveForm(form, save, createAgreement, onSaveOrCancel, hideSaveButton) {
    hideSaveButton(true);
    const header = { ...form, id: form.id < 0 ? undefined : form.id };

    if (!header.addendumUpdatesStartDateEnabled) {
        header.addendumUpdatesStartDate = undefined;
    }
    if (!header.addendumUpdatesEndDateEnabled) {
        header.addendumUpdatesEndDate = undefined;
    }

    if (form)
        if (form.id < 0 && form.agreementType === 'Agreement') {
            createAgreement(header, 'Success', 'Agreement Created', onSaveOrCancel, openNewAgreementOnResult(form.id));
        } else {
            save(header, 'Success', 'Agreement Updated', openNewAgreementOnResult(form.id));
        }

    onSaveOrCancel();
}

export const openNewAgreementOnResult = id => result => dispatch => {
    const newAgreementHeaderId = _.get(result, 'data.agreementHeaders[0].id');
    if (id >= 0 || !newAgreementHeaderId) return;
    safeDispatchPush(dispatch, push, ['dashboard', 'agreement', newAgreementHeaderId]);
};

const filterTemplateChoices = (templateChoices, termsType) =>
    templateChoices.filter(template => template.termsType === termsType);

const EditAgreementHeaderForm = ({
    resources,
    companies,
    people,
    changeAgreementTemplate,
    save,
    agreementHeader,
    createAgreement,
    onSaveOrCancel = () => {},
    agreementHeaders,
    loadingStates,
    user,
    disableTemplateField
}) => {
    const isTemplateResetting = getLoadingState(loadingStates, ENDPOINTS.API.SWITCH_AGREEMENT_TEMPLATE).isLoading;
    const isLoading = getLoadingState(loadingStates, ENDPOINTS.API.AGREEMENT_CREATE_TEMPLATE).isLoading;
    const [hideSaveButton, setHideSaveButton] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const { templates } = useTemplateContext();
    const companyId = _.get(agreementHeader, 'companyId');

    useEffect(() => {
        const yearDate = moment(agreementHeader.startDate, 'YYYY-MM-DD HH:mm').format('YYYY');
        const endYearDate = moment(`${yearDate}/12/31`).format();
        const tempId = _.chain(companies)
            .find(company => company.companyId === companyId)
            .get('ucn')
            .value();
        setInitialValues({
            ...agreementHeader,
            endDate: agreementHeader.endDate ? agreementHeader.endDate : endYearDate,
            hyundaiAgreementId: tempId
        });
    }, [agreementHeader, companies, companyId]);

    useEffect(() => setHideSaveButton(false), [agreementHeader]);
    const config = editAgreementHeaderFormConfig[_.get(agreementHeader, 'agreementType', 'Agreement')];
    const companyChoices = useCompaniesChoices(companyId, companies);
    const companyPeople = useCompanyPeopleChoices(companyId, people);
    const templateChoices = useTemplateChoices(agreementHeaders);
    if (companyId && companyPeople.length === 0 && !config.hidePerson({ values: agreementHeader })) {
        return (
            <Alert color="danger">
                There are no people in this company, please add a contact before creating an agreement
            </Alert>
        );
    }

    agreementHeader.canAmend = _.get(
        agreementHeaderStates,
        `${agreementHeader.agreementType}.${agreementHeader.status}.canAmend`,
        false
    );
    agreementHeader.addendumCanUpdateStartDate = false;
    agreementHeader.addendumCanUpdateEndDate = false;
    agreementHeader.addendumUpdatesStartDateEnabled = !_.isEmpty(agreementHeader.addendumUpdatesStartDate);
    agreementHeader.addendumUpdatesEndDateEnabled = !_.isEmpty(agreementHeader.addendumUpdatesEndDate);

    const formName = 'EditAgreementHeaderForm';

    return (
        <>
            <ReactiveForm
                initialValues={initialValues}
                resources={resources}
                formName={formName}
                hideCancelButton={agreementHeader.id >= 0}
                hideMetaData
                saveButtonContent="Save"
                handleSubmit={form => saveForm(form, save, createAgreement, onSaveOrCancel, setHideSaveButton)}
                validationSchema={editAgreementHeaderFormValidation(agreementHeader.agreementType, config)}
                cancel={onSaveOrCancel}
                hideSaveButton={!agreementHeader.canAmend || hideSaveButton || isLoading}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Choicelist
                        columnDef={agreementHeaderColumnDefs.companyId}
                        hide={config.hideCompany}
                        disabled={true}
                        options={companyChoices}
                    />
                    <ReactiveForm.Choicelist
                        columnDef={agreementHeaderColumnDefs.personId}
                        hide={config.hidePerson}
                        testDisabled={config.isDisabled}
                        placeholder="Person"
                        options={companyPeople}
                    />
                    <ReactiveForm.Text
                        hide={config.hideName}
                        columnDef={agreementHeaderColumnDefs.name}
                        testDisabled={config.isDisabled}
                    />
                    <ReactiveForm.Choicelist
                        columnDef={agreementHeaderColumnDefs.addendumTemplate}
                        hide={() =>
                            !(
                                _.get(user, 'canCreateTemplate', 'N') === 'Y' &&
                                agreementHeader.agreementType === 'Addendum'
                            )
                        }
                        options={
                            !templates
                                ? []
                                : templates.map(template => ({
                                      value: template.name,
                                      key: template.id
                                  }))
                        }
                        testDisabled={() => disableTemplateField}
                    />
                    <ReactiveForm.Text
                        columnDef={agreementHeaderColumnDefs.hyundaiAgreementId}
                        hide={config.hideHyundaiAgreementId}
                        disabled={true}
                        testDisabled={config.isDisabled}
                    />
                    <ReactiveForm.Choicelist
                        columnDef={agreementHeaderColumnDefs.termsType}
                        hide={config.hideTermsType}
                        testDisabled={config.isDisabled}
                        onFormValueChanged={(values, fieldName, value, setFieldValue) => {
                            const choices = filterTemplateChoices(templateChoices, value);
                            setFieldValue('parentId', choices.length === 1 ? value[0].key : null, true);
                        }}
                    />
                    <ReactiveForm.DisplayAgreementHistory hideLabel={true} inline={false} />
                    <ReactiveForm.Choicelist
                        columnDef={agreementHeaderColumnDefs.templateId}
                        hide={config.hideTemplates}
                        testDisabled={config.templateChoiceIsDisabled}
                        options={({ values }) => filterTemplateChoices(templateChoices, values.termsType)}
                    />

                    <ReactiveForm.Other hide={config.hideTemplateReset}>
                        {({ values }) => (
                            <div className="w-100 d-flex flex-row-reverse pb-2">
                                <ConfirmButton
                                    popoverId="reset_template"
                                    title="Reset to Template"
                                    notes="WARNING! Changing the template of the agreement will delete the changes made the current agreement details."
                                    isWorking={isTemplateResetting}
                                    isWorkingTitle="Resetting Template"
                                    onConfirm={() =>
                                        changeAgreementTemplate(
                                            values.id,
                                            values.parentId,
                                            'Details Updated',
                                            'Details updated from template.'
                                        )
                                    }
                                >
                                    {({ toggle }) => (
                                        <Button
                                            id="reset_template"
                                            className="float-right"
                                            color="info"
                                            onClick={toggle}
                                        >
                                            Reset to Template
                                        </Button>
                                    )}
                                </ConfirmButton>
                            </div>
                        )}
                    </ReactiveForm.Other>

                    <ReactiveForm.Choicelist
                        columnDef={agreementHeaderColumnDefs.agreementApprovalTeam}
                        hide={config.hideApprovalTeam}
                        disabled={true}
                    />
                    <ReactiveForm.DisplayInColumns columnClasses={['col-12 col-sm-6 text-left']}>
                        <ReactiveForm.Date
                            columnDef={agreementHeaderColumnDefs.startDate}
                            hide={config.hideStartDate}
                            testDisabled={config.isDisabled}
                        />
                        <ReactiveForm.Date
                            columnDef={agreementHeaderColumnDefs.endDate}
                            hide={config.hideEndDate}
                            testDisabled={config.isDisabled}
                            disabled={false}
                        />
                    </ReactiveForm.DisplayInColumns>
                    <ReactiveForm.DisplayInColumns
                        columnClasses={['col-12 col-sm-6 text-left']}
                        hide={config.hideAddendumDatesSection}
                    >
                        <ReactiveForm.Checkbox
                            columnDef={{
                                ...basicColumnDef(
                                    'Update Start Date on Apply Addendum',
                                    'addendumUpdatesStartDateEnabled'
                                ),
                                type: 'checkbox'
                            }}
                            hide={config.hideAddendumUpdatesStartDate}
                            testDisabled={config.isDisabled}
                        />
                        <ReactiveForm.Date
                            columnDef={agreementHeaderColumnDefs.addendumUpdatesStartDate}
                            hide={config.editAddendumUpdatesStartDateDisabled}
                        />
                        <ReactiveForm.Text
                            columnDef={{
                                ...agreementHeaderColumnDefs.addendumUpdatesStartDate,
                                field: 'blankText'
                            }}
                            disabled={true}
                            hide={formikProps => !config.editAddendumUpdatesStartDateDisabled(formikProps)}
                        />
                        <ReactiveForm.Checkbox
                            columnDef={{
                                ...basicColumnDef('Update End Date on Apply Addendum', 'addendumUpdatesEndDateEnabled'),
                                type: 'checkbox'
                            }}
                            hide={config.hideAddendumUpdatesEndDate}
                            testDisabled={config.isDisabled}
                        />
                        <ReactiveForm.Date
                            columnDef={agreementHeaderColumnDefs.addendumUpdatesEndDate}
                            hide={config.editAddendumUpdatesEndDateDisabled}
                        />
                        <ReactiveForm.Text
                            columnDef={{
                                ...agreementHeaderColumnDefs.addendumUpdatesEndDate,
                                field: 'blankText'
                            }}
                            disabled={true}
                            hide={formikProps => !config.editAddendumUpdatesEndDateDisabled(formikProps)}
                        />
                    </ReactiveForm.DisplayInColumns>

                    <ReactiveForm.Text
                        columnDef={agreementHeaderColumnDefs.expectedVolume}
                        hide={config.hideExpectedVolume}
                        testDisabled={config.isDisabled}
                    />

                    <ReactiveForm.Text
                        columnDef={agreementHeaderColumnDefs.notes}
                        hide={config.hideNotes}
                        testDisabled={config.isDisabled}
                    />
                </ReactiveForm.Section>
            </ReactiveForm>
            <div className="grid-js-end grid-js-bottom mb-2" style={{ float: 'right', paddingRight: 15 }}>
                {isLoading ? <ShowLoadingSpinner isLoading size={20} /> : null}
            </div>
        </>
    );
};

const mapStateToProps = ({ resources, user, companies, people, agreementHeaders, loadingStates }) => ({
    resources,
    user,
    companies,
    people,
    agreementHeaders,
    loadingStates
});

const mapDispatchToProps = dispatch => ({
    save: (agreement, successTitle, successMessage, onResult) =>
        dispatch(apiAgreementHeaderUpdate(agreement, successTitle, successMessage, onResult)),
    createAgreement: (agreement, successTitle, successMessage, callback, onResult) => {
        dispatch(apiCreateDraftAgreement(agreement, successTitle, successMessage, callback, onResult));
    },
    changeAgreementTemplate: (id, template, successTitle, successMessage) =>
        dispatch(apiChangeTemplate(id, template, successTitle, successMessage))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAgreementHeaderForm);
