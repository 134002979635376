import React, { useContext } from 'react';
import Layout3Columns, { Column1, Column2, Column3 } from '../../Dashboard/Components/Layout3Columns';
import { Alert } from 'reactstrap';
import PageSection from '../../common/PageSection';
import AgreementDetailsActionButtons from '../Buttons/AgreementDetailsActionButtons';
import { AgreementHeaderContext } from '../../AgreementHeader/Provider/AgreementHeaderProvider';
import AgreementCompanyDetails from './AgreementCompanyDetails';
import AgreementPersonDetails from './AgreementPersonDetails';
import AgreementEvidence from './AgreementEvidence';
import AgreementDetailsCard from '../../AgreementDetails/AgreementDetailsTable/AgreementDetailsCard';
import EditAgreementHeaderFormCard from '../Forms/EditAgreementHeaderFormCard';
import AgreementsInPromotionTable from '../Tables/AgreementsInPromotionTable';
import AddendumChanges from '../../AgreementAddendumDetails/Components/AddendumChanges';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import { ENDPOINTS } from '../../../actions/Api/constants';
import AgreementsCreatedByAddendumTable from '../Tables/AgreementsCreatedByAddendumTable';
import AppliedToAgreementsTable from '../Tables/AppliedToAgreementsTable';
import PendingAddendumTable from '../Tables/PendingAddendumTable';
import AgreementHistory from '../../AgreementStatusChanges/Components/AgreementHistory';
import AddendumAgreementSearchFormAndTable from '../Search/AddendumAgreementSearchFormAndTable';
import AgreementTemplateFIle from './AgreementTemplateFIle';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { useHistory } from 'react-router';

const AgreementDetailsPageContent = React.memo(function AgreementDetailsPageContent() {
    const { goBack } = useHistory();
    const {
        agreementHeader,
        agreementState: { title }
    } = useContext(AgreementHeaderContext);

    const isLoading = useIsLoading([ENDPOINTS.API.AGREEMENT_FILTER]);

    return (
        <PageSection onBackClicked={goBack} title={title}>
            {agreementHeader !== undefined ? (
                <Layout3Columns>
                    <Column1>
                        <AgreementCompanyDetails />
                        <AgreementPersonDetails />
                    </Column1>
                    <Column2>
                        <EditAgreementHeaderFormCard />
                        <AddendumAgreementSearchFormAndTable />
                        <AgreementsInPromotionTable />
                        <PendingAddendumTable />
                        <AppliedToAgreementsTable />
                        <AgreementsCreatedByAddendumTable />
                        <AgreementDetailsCard />
                    </Column2>
                    <Column3>
                        <AgreementDetailsActionButtons />
                        <AddendumChanges />
                        <AgreementTemplateFIle />
                        <AgreementEvidence />
                        <AgreementHistory />
                    </Column3>
                </Layout3Columns>
            ) : (
                <>
                    {isLoading ? (
                        <>
                            <br />
                            <ShowLoadingSpinner isLoading={isLoading} />
                        </>
                    ) : (
                        <Alert color="danger" className="mt-4">
                            <strong>Sorry, there was an error trying to view the agreement, please try again.</strong>
                        </Alert>
                    )}
                </>
            )}
        </PageSection>
    );
});

export default AgreementDetailsPageContent;
