import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';
import { toastr } from 'react-redux-toastr';

export const apiCreatePromotionAgreementForCompany = (promotionId, companyId, callback, onError) => async dispatch => {
    const type = ENDPOINTS.API.CREATE_PROMOTION_AGREEMENT_FOR_COMPANY;

    dispatch(
        apiGetPost(
            type,
            ['agreement', 'promotion', 'apply'],
            {},
            { promotionId, companyId },
            undefined,
            true,
            (dispatch, type, response) => {
                onError && onError();
                toastr.error('Error Creating Promotion', response.message);
            },
            undefined,
            'Success',
            'Promotion Created',
            callback
        )
    );
};
