import React from 'react';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { CancelAgreementModal } from '../../common/Modal/ConfirmCancelAgreementModal';

export default function CancelAgreementButton({ agreementHeaderId }) {
    const agreementHeaderIds = [agreementHeaderId];
    return (
        <CancelAgreementModal agreementHeaderIds={agreementHeaderIds}>
            {({ triggerModal }) => (
                <ActionButton
                    buttonText={'Cancel Agreement'}
                    fontawesomeIcon="fa fa-minus"
                    bootstrapColor="secondary"
                    onClickAction={() => {
                        triggerModal();
                    }}
                />
            )}
        </CancelAgreementModal>
    );
}
