import React from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';

import { setIncludeMeetingNotesInEmail } from '../../../actions/emailSettingsActions';

const IncludeMeetingNotesCheckbox = ({
    meeting: { meetingId },
    includeNotesInEmailComms,
    setIncludeMeetingNotesInEmail
}) => (
    <div className="custom-control custom-checkbox">
        <Input
            type="checkbox"
            className="custom-control-input"
            id="includeNotesInVisitReportEmail"
            // checked={includeNotesInEmailComms.includes(communicationId)}
            checked={includeNotesInEmailComms.includes(meetingId)}
            onChange={({ target: { checked } }) => {
                setIncludeMeetingNotesInEmail(meetingId, checked);
            }}
        />
        <label className="custom-control-label" htmlFor="includeNotesInVisitReportEmail">
            Include meeting notes in visit report email
        </label>
    </div>
);

const mapStateToProps = state => ({
    includeNotesInEmailComms: state.emailSettings.includeNotesInEmailComms
});

const mapDispatchToProps = dispatch => ({
    setIncludeMeetingNotesInEmail: (commId, checked) => dispatch(setIncludeMeetingNotesInEmail(commId, checked))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IncludeMeetingNotesCheckbox);
