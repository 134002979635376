import {
    basicColumnDef,
    idColumnDef,
    choiceListColumnSettings,
    dateColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';

const eventDef = {
    id: idColumnDef('id', 'eventId'),
    eventType: {
        ...basicColumnDef('Event Type', 'eventType'),
        ...choiceListColumnSettings('communicationEventType')
    },
    startDate: {
        ...dateColumnDef('Start Date', 'startDate')
    },
    endDate: {
        ...dateColumnDef('End Date', 'endDate'),
        type: 'date'
    },
    body: {
        ...basicColumnDef('Notes', 'notes'),
        type: 'textarea'
    }
};

export default eventDef;
