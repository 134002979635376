import React from 'react';
import { SubSectionTitle } from '../SubSection/SubSectionTitle';
import BaseCard from './BaseCard';

const SimpleCard = props => {
    const { buttons, children, title, className, isLoading } = props;
    return (
        <BaseCard
            title={
                title ? <SubSectionTitle key="card title" title={title} buttons={buttons} isLoading={isLoading} /> : ''
            }
            className={className}
            children={children}
        />
    );
};

export default SimpleCard;
