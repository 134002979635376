import React from 'react';
import { AddTableRowButton } from '../../common/Buttons/TableButtons';
import { useCompanyOpportunitiesContext } from '../Provider/CompanyOpportunitiesProvider';

const AddOpportunityToCompanyButton = () => {
    const { addOpportunity, companyId } = useCompanyOpportunitiesContext();
    return <AddTableRowButton onClick={() => addOpportunity({ companyId })} />;
};

export default AddOpportunityToCompanyButton;
