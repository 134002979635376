import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const makeHasAgreementDetailsSelector = () =>
    createSelector(
        state => state.agreementDetails,
        (_, agreementHeaderId) => agreementHeaderId,
        (agreementDetails, agreementHeaderId) =>
            agreementDetails.some(
                agreementDetail =>
                    agreementHeaderId === agreementDetail.agreementHeaderId && agreementDetail.deleted !== 1
            )
    );

export const useHasAgreementDetails = agreementHeaderId => {
    const selector = useMemo(makeHasAgreementDetailsSelector, []);
    return useSelector(state => selector(state, agreementHeaderId));
};
