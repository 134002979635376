import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const makeCommunicationSelector = () =>
    createSelector(
        state => state.communications,
        (_, communicationId) => communicationId,
        (communications, communicationId) =>
            communications.find(communication => communication.communicationId === communicationId)
    );

export const useCommunicationById = communicationId => {
    const selectCommunication = useMemo(makeCommunicationSelector, []);
    return useSelector(state => selectCommunication(state, communicationId));
};
