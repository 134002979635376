import React from 'react';
import ReactiveForm from '../../common/ReactiveForm';
import { templateColumnDefs } from '../template';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import _ from 'lodash';
import { apiTemplateUpdate } from '../../../actions/Api/Template/apiTemplateUpdate';
import { apiTemplatePut } from '../../../actions/Api/Template/apiTemplatePut';

const validationSchema = yup.object().shape({
    type: yup.string().required('A Type is required'),
    confirmDelete: yup.string().when('deleted', {
        is: deleted => deleted,
        then: yup
            .string()
            .required('Confirm Delete is required')
            .oneOf(['delete'], 'Please confirm by typing word delete'),
        otherwise: yup.string().notRequired()
    })
});

export const TemplateForm = ({ template, toggle }) => {
    const dispatch = useDispatch();

    const handleSubmit = form => {
        if (form.id) {
            dispatch(apiTemplateUpdate({ ...form, name: template.name, link: template.link }));
        } else {
            form.template.templateType = form.type;
            form.template.active = form.active;
            form.template.deleted = form.deleted;
            dispatch(apiTemplatePut(form.template));
        }
        toggle();
    };

    const initialValues = template
        ? { id: template.id, type: template.type, active: template.active, deleted: template.deleted }
        : {};

    return (
        <ReactiveForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            formName={'TEMPLATE_UPLOAD'}
            handleSubmit={form => handleSubmit(form)}
            cancel={toggle}
            hideMetaData={true}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Choicelist
                    columnDef={templateColumnDefs.type}
                    options={[
                        { key: 'addendumTemplate', value: 'Addendum Template', ordinal: 0 }
                        // { key: 'agreementTemplate', value: 'Agreement Template', ordinal: 1 }
                    ]}
                />
                <ReactiveForm.Checkbox columnDef={templateColumnDefs.active} />
                <ReactiveForm.Checkbox
                    hide={() => !_.get(template, 'id', false)}
                    columnDef={templateColumnDefs.deleted}
                />
                <ReactiveForm.Text
                    placeholder={'Confirm by typing word delete'}
                    hide={({ values: { deleted } }) => !deleted}
                    columnDef={templateColumnDefs.confirmDelete}
                />
            </ReactiveForm.Section>
            <ReactiveForm.Section>
                <ReactiveForm.Dropzone
                    templateForm={true}
                    isTemplate={true}
                    hide={() => _.get(template, 'id', false)}
                    columnDef={templateColumnDefs.dropZone}
                    title="Drag template document here, or click to browse."
                />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
};
