import React, { useContext } from 'react';
import CompanyPromotionsAlertsCard from './CompanyPromotionsAlertsCard';
import SimpleCard from '../../common/Card/SimpleCard';
import { CompanyPeopleContext } from '../Provider/CompanyPeopleProvider';
import { Alert } from 'reactstrap';
import CompanyAddPersonButton from './CompanyAddPersonButton';
import { useUnusedPromotionsByCompanyId } from '../../AgreementHeader/Selectors/useUnusedPromotionsByCompanyId';

const CompanyPromotionsAlerts = () => {
    const { people, companyId } = useContext(CompanyPeopleContext);

    const unusedPromotionAgreements = useUnusedPromotionsByCompanyId(companyId);

    return unusedPromotionAgreements.length ? (
        <SimpleCard title="Promotional Agreements Available">
            <>
                {!people.length ? (
                    <Alert color={'danger'}>
                        <div>Company needs at least 1 person to create a promotion</div>
                        <div className="d-flex justify-content-end mt-3">
                            <CompanyAddPersonButton companyId={companyId} />
                        </div>
                    </Alert>
                ) : null}
                {unusedPromotionAgreements.map(promotion => (
                    <CompanyPromotionsAlertsCard
                        key={promotion.id}
                        promotion={promotion}
                        companyId={companyId}
                        disabled={!people.length}
                    />
                ))}
            </>
        </SimpleCard>
    ) : null;
};

export default CompanyPromotionsAlerts;
