import React, { useContext, useMemo } from 'react';
import { AddTableRowButton } from '../../common/Buttons/TableButtons';
import { AgreementDetailsTableContext } from '../AgreementDetailsTable/AgreementDetailsTableProvider';

const ButtonAddAllDetailsForFamily = ({ products, productFamilyId }) => {
    const productsWithNoDetails = useMemo(
        () =>
            products
                .filter(
                    product =>
                        !product.product.deleted &&
                        (!product.details.length || product.details[product.details.length - 1].deleted)
                )
                .map(x => x.product),
        [products]
    );

    const {
        agreementState: { canAmend },
        agreementHeaderId,
        addProductsToAgreementDetails
    } = useContext(AgreementDetailsTableContext);

    const visibility = !productsWithNoDetails.length || !canAmend ? 'invisible' : 'visible';

    return (
        <AddTableRowButton
            visibility={visibility}
            onClick={() => {
                addProductsToAgreementDetails(agreementHeaderId, productFamilyId, productsWithNoDetails);
            }}
        />
    );
};

export default ButtonAddAllDetailsForFamily;
