import React from 'react';
import { SubSectionTitle } from '../SubSection/SubSectionTitle';
import BaseCard from './BaseCard';
import useToggleOpen from '../Hooks/useToggleOpen';

const SimpleCardCollapsable = ({
    buttons,
    children,
    title,
    className,
    isLoading,
    collapseName,
    collapsed,
    setCollapsed
}) => {
    const { toggle, isOpen } = useToggleOpen();
    return (
        <BaseCard
            title={
                title ? (
                    <SubSectionTitle
                        key="card title"
                        title={title}
                        buttons={isOpen ? null : buttons}
                        isLoading={isLoading}
                        collapseName={collapseName}
                        collapsed={isOpen}
                        toggleCollapsed={toggle}
                    />
                ) : (
                    ''
                )
            }
            className={className}
            children={isOpen ? null : children}
        />
    );
};

export default SimpleCardCollapsable;
