import React from 'react';
import AgreementHeaderTableCard from './AgreementHeaderTableCard';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import { agreementsQuery } from '../../../actions/Api/AgreementHeader/apiAgreementFilter';
import RedirectNotLoggedIn from '../../app/RedirectNotLoggedIn';

export const filterPromotions = agreementHeaders => {
    return agreementHeaders.filter(agreementHeader => {
        return agreementHeader.agreementType === 'Promotion';
    });
};

const AgreementPromotionsTable = ({ showFilter }) => {
    return (
        <>
            <RedirectNotLoggedIn
                change={1}
                onLoggedInDispatchArray={[
                    agreementsQuery()
                        .filterAgreementType('Promotion')
                        .shouldFetchDetails(false)
                        .shouldFetchPersons(false)
                        .shouldFetchCompanies(false)
                        .run()
                ]}
            />

            <AgreementHeaderTableCard
                filter={filterPromotions}
                tableIsNotInCard={true}
                title="Promotions"
                noItemsText="There are no promotions to show"
                showFilter={showFilter}
                columns={[
                    agreementHeaderColumnDefs.name,
                    agreementHeaderColumnDefs.startDate,
                    agreementHeaderColumnDefs.endDate,
                    agreementHeaderColumnDefs.termsType,
                    agreementHeaderColumnDefs.status
                ]}
            />
        </>
    );
};

export default AgreementPromotionsTable;
