import React, { useState } from 'react';
import { useDebounce } from '../Hooks/useDebounce';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';

const SimpleTextFilter = ({ placeholder, children }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 100);

    return children({
        searchBox: (
            <InputGroup className="w-100 mb-2" style={{ gridColumn: '1 / span 2' }}>
                <Input
                    value={searchTerm}
                    placeholder={placeholder}
                    onChange={event => setSearchTerm(event.target.value)}
                />
                <InputGroupAddon addonType="append">
                    <Button onClick={() => setSearchTerm('')}>
                        <i className={`fa lg d-inline" ${debouncedSearchTerm ? 'fa-times' : 'fa-search'}`} />
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        ),
        searchTerm: debouncedSearchTerm.toLowerCase()
    });
};

export default SimpleTextFilter;
