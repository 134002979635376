import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { AppModalHeader } from "../../common/AppModalHeader";
import EditAgreementDetailForm from "../Forms/EditAgreementDetailForm";
import useModalForm from "../../common/Hooks/useModalForm";

const EditAgreementDetailModal = ({ children }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
                <AppModalHeader toggle={toggle}>
                    {formValue.arrayOfProductsToAdd && formValue.arrayOfProductsToAdd.length
                        ? "Add Products"
                        : formValue.agreementDetailId
                        ? "Edit Agreement Detail"
                        : "Add Agreement Detail"}
                </AppModalHeader>
                <ModalBody>
                    <EditAgreementDetailForm toggle={toggle} {...formValue} schema="agreement" />
                </ModalBody>
            </Modal>
            {children({
                editAgreementDetail: (
                    agreementDetailId,
                    agreementHeaderId,
                    productFamilyId,
                    productId,
                    selectProduct = false,
                    allowDeletedProducts = false,
                    allowDeleteProductFamilies = false
                ) => {
                    openModal({
                        agreementDetailId,
                        agreementHeaderId,
                        productFamilyId,
                        productId,
                        selectProduct,
                        allowDeletedProducts,
                        allowDeleteProductFamilies,
                    });
                },
                addProductsToAgreementDetails: (
                    agreementHeaderId,
                    productFamilyId,
                    arrayOfProductsToAdd,
                    selectProduct = false
                ) => {
                    openModal({
                        agreementHeaderId,
                        productFamilyId,
                        arrayOfProductsToAdd,
                        selectProduct,
                    });
                },
                toggle,
            })}
        </>
    );
};

export default EditAgreementDetailModal;
