import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { openMeeting } from '../../../actions/Navigation/Meeting/meetingSelected';
import { viewCompany } from '../../../actions/Navigation/viewCompany';
import MeetingsTableCard from './MeetingsTableCard';
import { getUserFromState } from '../../../selectors/Api/User/userSelector';
import { meetingsSelector } from '../../../reducers/Api/Meeting/selectors';
import meeting from '../meeting';
import _ from 'lodash';

import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { ENDPOINTS } from '../../../actions/Api/constants';

const OutstandingMeetingsTable = ({ meetings, editRow, openCompany, companyId }) => {
    const isLoading = useIsLoading([ENDPOINTS.API.MEETING_FILTER]);

    return (
        <MeetingsTableCard
            rows={meetings}
            companyId={companyId}
            columns={[meeting.startDate, meeting.startTime, meeting.companyName, meeting.status]}
            openCompany={openCompany}
            editRow={editRow}
            buttons={[]}
            isLoading={isLoading}
            fields={['meetingDate', 'subject', 'activityRegarding']}
            title={'Outstanding Meetings'}
            breakpoint={860}
            initialSortColumn={'start'}
            initialSortOrder={'asc'}
        />
    );
};

const makeMapStateToProps = () => {
    const getState = createSelector(
        [meetingsSelector, getUserFromState],
        (meetings, { userId }) => ({
            meetings: _.orderBy(
                meetings.filter(
                    x =>
                        x.assignee &&
                        x.assignee.id === userId &&
                        ['Pending', 'Overdue'].indexOf(x.status) > -1 &&
                        new Date(x.startDate) < new Date()
                ),
                'startDate'
            )
        })
    );
    return (state, props) => getState(state, props);
};

const mapDispatchToProps = dispatch => ({
    editRow: meeting => dispatch(openMeeting(meeting.meetingId, meeting.descriptor)),
    openCompany: (companyId, descriptor) => dispatch(viewCompany(companyId, descriptor))
});

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(OutstandingMeetingsTable);
