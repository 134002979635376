import * as Yup from 'yup';
import moment from 'moment';

const editAgreementHeaderFormValidation = (agreementType, config) => {
    const typeString = agreementType.toLowerCase();

    let yup = Yup.object().shape({
        companyId: config.hideCompany() ? undefined : Yup.string().required('A company must be selected'),
        personId: config.hidePerson() ? undefined : Yup.string().required('A person must be selected'),
        name: config.hideName()
            ? undefined
            : Yup.string()
                  .required('You must name this ' + typeString)
                  .min(1, 'You must name this ' + typeString),
        hyundaiAgreementId: config.hideHyundaiAgreementId()
            ? undefined
            : Yup.string().required('The hyundai agreement Id is required'),
        termsType: config.hideTermsType() ? undefined : Yup.string().required('You must select a terms type'),
        parentId: config.hideTemplates() ? undefined : Yup.string().required('You must select a template'),
        agreementApprovalTeam: config.hideApprovalTeam()
            ? undefined
            : Yup.string().required('You must select the approval team'),
        startDate: config.hideStartDate() ? undefined : Yup.string().required('A start date is required'),
        endDate: config.hideEndDate() ? undefined : Yup.string().required('An end date is required'),
        expectedVolume: config.hideExpectedVolume() ? undefined : Yup.string().required('Expected volume is required')
    });

    if (!config.hideStartDate() && !config.hideStartDate()) {
        yup = yup.test('dateValidation', function(value) {
            const { startDate, endDate } = value;
            return (
                moment(startDate).isBefore(moment(endDate)) ||
                this.createError({
                    path: 'endDate',
                    message: 'The end date must be after the start date'
                })
            );
        });
    }

    // yup = yup.test('addendumDateValidation', function(value) {
    //     const { addendumUpdatesStartDate, addendumUpdatesEndDate } = value;
    //
    //     return (
    //         !addendumUpdatesStartDate ||
    //         !addendumUpdatesEndDate ||
    //         moment(addendumUpdatesStartDate.isBefore(moment(addendumUpdatesEndDate))) ||
    //         this.createError({
    //             path: 'addendumUpdatesEndDate',
    //             message: 'The after applying an addendum the start date must be before the end date '
    //         })
    //     );
    // });

    return yup;
};

export default editAgreementHeaderFormValidation;
