import React from 'react';
import { ActionButtonGroup } from '../../common/ActionButtons/ActionButtons';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { apiDownloadAgreementTemplate } from '../../../actions/Api/AgreementHeader/apiDownloadAgreementTemplate';
import { ListGroupItem } from 'reactstrap';

const ShowAgreementTemplateFile = ({ templateFile, downloadTemplate, children }) => {
    return (
        <ActionButtonGroup title={'Agreement Template'}>
            {templateFile ? (
                <ActionButton
                    buttonText={`${templateFile}`}
                    fontawesomeIcon="fa-download"
                    bootstrapColor="white"
                    onClickAction={() => apiDownloadAgreementTemplate(templateFile)}
                    key={'templateFile'}
                />
            ) : (
                <ActionButton
                    tag="button"
                    type="button"
                    buttonText={'No template file has yet been uploaded for this template.'}
                    bootstrapColor="white"
                    fontawesomeIcon="fa-warning"
                    disabled={true}
                />
            )}
            {children ? (
                <ListGroupItem tag="div" type="div" className="list-group-item-action hover-pointer">
                    {children}
                </ListGroupItem>
            ) : null}
        </ActionButtonGroup>
    );
};

export default ShowAgreementTemplateFile;
