import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import EditCommunicationForm from '../Forms/EditCommunicationForm';
import useModalForm from '../../common/Hooks/useModalForm';
import { createFakeId } from '../../../reducers/util';

const EditCommunicationModal = ({ children, className }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();

    return (
        <>
            {isOpen ? (
                <Modal isOpen={isOpen} toggle={toggle} className={className}>
                    <AppModalHeader toggle={toggle}>
                        {`${formValue.communicationId < 0 ? 'Create' : 'Edit'} Communication`}
                    </AppModalHeader>
                    <ModalBody>
                        <EditCommunicationForm
                            onCancel={toggle}
                            communicationId={formValue.communicationId}
                            companyId={formValue.companyId}
                            schema="communication"
                        />
                    </ModalBody>
                </Modal>
            ) : null}
            {children({
                editCommunication: event => openModal(event),
                createCommunication: companyId => openModal({ communicationId: createFakeId(), companyId })
            })}
        </>
    );
};

export default EditCommunicationModal;
