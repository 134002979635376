import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { REST_TIME_FORMAT } from '../../../actions/Api/apiHelpers';
import { apiVisitActionPost } from '../../../actions/Api/VisitAction/apiVisitActionPost';
import ReactiveForm from '../../common/ReactiveForm';
import visitAction from '../visitAction';
import validation from './EditVisitActionForm.validation';

const FORM_NAME = 'editVisitActionForm';

class EditVisitActionForm extends React.Component {
    handleSubmit = formValues => {
        const { communicationAssignee } = this.props.resources.choiceList;

        const assignee = communicationAssignee.find(x => x.key === formValues.assigneeId);

        const visitAction = {
            ...formValues,
            assignee: {
                id: formValues.assigneeId,
                descriptor: assignee ? assignee.value : ''
            }
        };
        this.props.save(visitAction, this.props.onCancel);
    };

    render() {
        const {
            user: { userId },
            visitActionId,
            parentCommunicationId,
            companyId,
            visitActions,
            companies,
            resources,
            onCancel
        } = this.props;

        const company = companies.find(x => x.companyId === companyId);
        const communication = visitActions.find(x => x.visitActionId === visitActionId);

        const initialValues =
            visitActionId > 0
                ? _.cloneDeep(communication)
                : {
                      status: 'Pending',
                      action: 'VisitReport',
                      visitActionId,
                      activityRegarding: '',
                      subject: '',
                      assigneeId: userId.toString(),
                      meetingId: parentCommunicationId,
                      calendarOption: 'default',
                      eventType: 'VisitReport',
                      startDate: moment()
                          .hour(17)
                          .minute(30)
                          .seconds(0)
                          .format(REST_TIME_FORMAT),
                      endDate: moment()
                          .hour(9)
                          .minute(0)
                          .seconds(0)
                          .format(REST_TIME_FORMAT),
                      note: '',
                      descriptor: '',
                      companyId,
                      company: {
                          id: companyId,
                          descriptor: company ? company.descriptor : ''
                      },
                      createdBy: userId,
                      createdDate: undefined,
                      updatedBy: userId,
                      updatedDate: undefined
                  };

        initialValues.assigneeId = initialValues.assigneeId.toString();
        //initialValues.subject = initialValues.subject || '';
        initialValues.companyDescriptor = initialValues.company.descriptor;

        return (
            <ReactiveForm
                initialValues={initialValues}
                cancel={onCancel}
                resources={resources}
                formName={FORM_NAME}
                validationSchema={validation}
                handleSubmit={this.handleSubmit}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Text columnDef={visitAction.companyDescriptor} disabled />
                    <ReactiveForm.Date columnDef={visitAction.startDate} />
                    <ReactiveForm.Choicelist columnDef={visitAction.activityRegarding} />
                    <ReactiveForm.Choicelist columnDef={visitAction.status} />
                    <ReactiveForm.Choicelist columnDef={visitAction.action} disabled />
                    <ReactiveForm.Choicelist columnDef={visitAction.assignee} />
                    <ReactiveForm.Text columnDef={visitAction.note} type="textarea" />
                </ReactiveForm.Section>
            </ReactiveForm>
        );
    }
}

const mapStateToProps = state => ({
    visitActions: state.visitActions,
    companies: state.companies,
    resources: state.resources,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    save: (visitAction, cancel) => {
        dispatch(apiVisitActionPost(visitAction));
        cancel();
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditVisitActionForm);
