import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import { viewCompany } from '../../../actions/Navigation/viewCompany';
import opportunity from '../opportunity';
import EditOpportunityModal from '../Modal/EditOpportuntiyModal';

class OpportunitiesTableCardNew extends React.Component {
    static defaultProps = {
        breakpoint: 500,
        itemsPerPage: 5,
        noItemsText: 'There are no opportunities to show',
        columns: [opportunity.dueDate, opportunity.type, opportunity.stage, opportunity.likelihood, opportunity.model]
    };

    render() {
        const {
            isLoading,
            rows,
            openCompany,
            tableIsNotInCard = false,
            cardButtons = [],
            editRow,
            title,
            columns,
            ...rest
        } = this.props;

        const tableDefinition = new TableDefinition(columns);
        return (
            <EditOpportunityModal>
                {({ editOpportunity }) => (
                    <ChoiceListFilteredTableNoDashboardData
                        id={title}
                        tableDefinition={tableDefinition}
                        rows={rows}
                        columnDefsId={opportunity.id}
                        tableDefinitionColumns={tableDefinition.columns}
                        onRowClick={values => editOpportunity(values)}
                        actions={{
                            openCompany
                        }}
                        isLoading={isLoading}
                        tableIsNotInCard={tableIsNotInCard}
                        cardButtons={cardButtons}
                        title={title}
                        {...rest}
                    />
                )}
            </EditOpportunityModal>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    openCompany: (companyId, descriptor) => dispatch(viewCompany(companyId, descriptor))
});

export default connect(
    undefined,
    mapDispatchToProps
)(withRouter(OpportunitiesTableCardNew));
