import React from 'react';
import AddressTable from '../../Address/Tables/AddressTable';
import CompanyProvider from '../../Company/Provider/CompanyProvider';
import CompanyTitle from '../../Company/Components/CompanyTitle';
import PageSection from '../../common/PageSection';
import SimpleCard from '../../common/Card/SimpleCard';
import { useHistory, useParams } from 'react-router';

export default function AddressesPage() {
    const { goBack } = useHistory();
    const { companyId } = useParams();

    const intCompanyId = parseInt(companyId, 10);

    return (
        <CompanyProvider companyId={intCompanyId}>
            <PageSection title={<CompanyTitle />} onBackClicked={goBack}>
                <SimpleCard title="Addresses">
                    <AddressTable breakpoint={600} />
                </SimpleCard>
            </PageSection>
        </CompanyProvider>
    );
}
