import React from 'react';
import IncludeMeetingNotesCheckbox from './IncludeMeetingNotesCheckbox';
import SimpleCard from '../../common/Card/SimpleCard';
import MeetingNotesForm from '../Forms/MeetingNotesForm';
import { useMeetingContext } from '../../Meeting/Provider/MeetingProvider';

function MeetingNotes() {
    const { meeting } = useMeetingContext();

    return (
        <SimpleCard title="Meeting Notes">
            <MeetingNotesForm meeting={meeting} />
            <IncludeMeetingNotesCheckbox meeting={meeting} />
        </SimpleCard>
    );
}

export default MeetingNotes;
