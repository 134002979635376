import { useMemo } from 'react';

function setDefaultFieldValues(initialValues = {}, columnDefAndDefaultValues, defaultValues) {
    return columnDefAndDefaultValues.reduce(
        (acc, { field, convert }) => {
            const existingValue = initialValues[field];

            acc[field] = existingValue ? existingValue : defaultValues[field] || '';

            if (convert === 'toString') {
                acc[field] = acc[field].toString();
            }

            if (convert === 'toBool') {
                acc[field] = acc[field] ? acc[field] : false;
            }

            if (convert === 'toArray') {
                acc[field] = acc[field] ? acc[field] : [];
            }
            return acc;
        },
        { ...initialValues }
    );
}

const useInitialValues = (formValue, columnDefAndDefaultValues, defaultValues) => {
    return useMemo(() => setDefaultFieldValues(formValue, columnDefAndDefaultValues, defaultValues), [
        formValue,
        columnDefAndDefaultValues,
        defaultValues
    ]);
};

export default useInitialValues;
