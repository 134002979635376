import React from 'react';
import SimpleCard from '../../common/Card/SimpleCard';
import FormikSelectChoicelist from '../../common/FormkControls/FormikSelectChoicelist';
import FormikText from '../../common/FormkControls/FormikText';
import { useMeetingContext } from '../../Meeting/Provider/MeetingProvider';

function AppointmentFeedbackForm({ formikProps }) {
    //TODO: Should this be meeting context
    const { meeting } = useMeetingContext();

    return (
        <SimpleCard title="Appointment Feedback">
            <FormikSelectChoicelist
                emptyOption="Select a rating..."
                disabled={meeting.status !== 'Pending'}
                fieldName="rating"
                choiceList="communicationAppointmentRating"
                {...formikProps}
            />
            <FormikText fieldName="feedback" {...formikProps} type="textarea" disabled={meeting.status !== 'Pending'} />
        </SimpleCard>
    );
}

export default AppointmentFeedbackForm;
