import { makeGetRequest } from '../apiHelpers';

export const apiDownloadAgreementTemplate = async template => {
    const templateId = template;

    const { data: downloadUrl } = await makeGetRequest(['agreement', 'template', 'download'], { templateId }); //todo: not sure on this one, but templateId = template; is pointless?

    const downloadLink = document.createElement('a');
    downloadLink.href = downloadUrl;
    downloadLink.download = template.fileName;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};
