import React from 'react';
import Dropzone from 'react-dropzone';
import connect from 'react-redux/es/connect/connect';

class ReactiveFormDropzone extends React.Component {
    state = { files: [] };

    onDrop = (files, rejectedFiles, agreementHeaderId, templateForm) => {
        const { setFieldValue, field } = this.props;
        setFieldValue(field, '', true);

        files.forEach(file => {
            const fileName = file.name;

            const reader = new FileReader();

            reader.onload = () => {
                const fileAsBinaryString = reader.result;
                this.uploadEvidence(fileAsBinaryString, file, agreementHeaderId, fileName, templateForm);
            };
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');

            reader.readAsDataURL(file);
        });

        this.setState({
            files: [...files]
        });
    };

    uploadEvidence = (fileAsBinaryString, file, agreementHeaderId, fileName, templateForm) => {
        const { setFieldValue, field, s3bucket, imageWidth, imageHeight, handleSubmit } = this.props;
        const base64 = fileAsBinaryString.split(';base64,').pop();
        const evidence = {
            file: fileAsBinaryString,
            name: fileName,
            type: file.type,
            bucket: s3bucket,
            imageWidth,
            imageHeight,
            ContentsAsBase64: base64,
            AgreementHeaderId: agreementHeaderId,
            MimeType: file.type,
            FileName: fileName
        };

        setFieldValue(field, evidence, true);

        if (!templateForm) handleSubmit();
    };

    renderFiles = text => (
        <div>
            {this.state.files.length === 0 && text}
            <ul>
                {this.state.files.map(f => (
                    <li key={f.name}>
                        {f.name} - {f.size} bytes
                    </li>
                ))}
            </ul>
        </div>
    );

    render() {
        const { values, field, accept, multiple = true, hideLabel, templateForm } = this.props;

        return (
            <>
                {!hideLabel ? (
                    <div className="mt-3 mb-4 ml-2">
                        {' '}
                        {values[field]
                            ? values[field].split('; ').map(url => (
                                  <a key={url} href={url}>
                                      {url}
                                  </a>
                              ))
                            : null}
                    </div>
                ) : (
                    <></>
                )}
                <Dropzone
                    accept={accept}
                    className={`w-100 ${
                        !hideLabel ? 'mt-4' : ''
                    } mb-4 border border-primary hover-pointer bg-light p-2`}
                    style={{ minHeight: '100px' }}
                    multiple={multiple}
                    onDrop={(accepted, rejected) => {
                        this.onDrop(accepted, rejected, values.agreementHeaderId, templateForm);
                    }}
                >
                    {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
                        if (isDragActive) {
                            return 'This file is authorized';
                        }
                        if (isDragReject) {
                            return 'This file is not authorized';
                        }
                        return acceptedFiles.length || rejectedFiles.length
                            ? this.renderFiles(
                                  `Accepted ${acceptedFiles.length}, rejected ${rejectedFiles.length} files`
                              )
                            : this.renderFiles(this.props.title);
                    }}
                </Dropzone>
            </>
        );
    }
}

const mapStateToProps = state => ({
    resources: state.resources
});

export default connect(mapStateToProps)(ReactiveFormDropzone);
