import React, { useMemo } from 'react';
import ColumnTypeDate from '../Table/ColumnTypeDate';
import moment from 'moment';
import CaptionBadge from './CaptionBadge';
import ColumnTypeFormatWebsiteLink from '../Table/ColumnTypeFormatWebsiteLink';
import { PreventClickThrough, PreventClickThroughAllowDefault } from '../Table/PreventClickThrough';
import _ from 'lodash';
import { TestTemplateRowButton } from '../Buttons/TableButtons';
import { TestTemplateModal } from '../../Template/Modal/TestTemplateModal';

export const makeTableColumns = (columnDefinitions, columns) =>
    columns.reduce((arr, column) => {
        if (typeof column === 'string') {
            const col = columnDefinitions[column];
            //Is this a bit crazy code? I think I may have to work out what it does when I see it
            (!col && !console.warn(`Column: ${column} is not predefined for Communications Table`)) || arr.push(col);
        } else {
            arr.push(column);
        }
        return arr;
    }, []);

export const makeTableSearchFields = (defs, columns) =>
    columns.reduce((arr, column) => {
        if (defs[column]) {
            arr.push(defs[column]);
        }
        return arr;
    }, []);

export const makeTableFilters = (filterDefinitions, filters) =>
    filters.reduce((arr, filter) => {
        if (typeof filter === 'string') {
            const col = filterDefinitions[filter];
            !col || arr.push(col);
        } else {
            arr.push(filter);
        }
        return arr;
    }, []);

//export const makeFilters = filters => filters.map((filter, index) => ({ ...filter, index }));
//Choicelist can be an array of choicelist names, or a single choicelist name as a string
export const filterSpec = (label, field, choiceList, unselected) => ({
    label,
    field,
    choiceList,
    unselected,
    index: 0
});
/*
export const filterSpecRange = (label, field, choiceList, rangeUnit, unselected) => ({
    label,
    field,
    choiceList,
    rangeUnit,
    unselected,
    index: 0
});
*/
export const idColumnDef = (title, field) => ({
    ...basicColumnDef(title, field),
    mapping: (resources, values) => values[field]
});

export const basicColumnDef = (title, field, options = {}) => {
    const { displayEmptyAs = undefined } = options;
    const result = {
        title,
        field,
        sort: true
    };
    if (displayEmptyAs) {
        result.mapping = (resources, values, field, action) => (values[field] ? values[field] : displayEmptyAs);
    }
    return result;
};

/*
export const textAreaColumnDef = (title, field) => ({
    title,
    field,
    type: 'textarea'
});
*/

export const currencyColumnDef = (title, field) => ({
    ...basicColumnDef(title, field),
    prefix: '£',
    type: 'number',
    valueToSortOnMapping: (resources, values) => values[field],
    mapToDisplayedValue: (resources, values) => <span>£{values[field]}</span>
});
export const websiteColumnDef = (title, field) => ({
    ...basicColumnDef(title, field),
    valueToSortOnMapping: (resources, values) => values[field],
    mapToDisplayedValue: (resources, values) => <ColumnTypeFormatWebsiteLink values={{ webSite: values[field] }} />
});

export const emailColumnDef = (title, field) => ({
    ...basicColumnDef(title, field),
    valueToSortOnMapping: (resources, values) => values[field],
    mapToDisplayedValue: (resources, values) => (
        <a href={`mailto:${values[field]}`} onClick={PreventClickThrough}>
            {values[field]}
        </a>
    )
});

export const phoneColumnDef = (title, field) => ({
    ...basicColumnDef(title, field),
    valueToSortOnMapping: (resources, values) => values[field],
    mapToDisplayedValue: (resources, values) => (
        <a href={`tel:${values[field]}`} onClick={PreventClickThrough}>
            <span>
                <i className="fa fa-phone" /> {values[field]}
            </span>
        </a>
    )
});

export const simpleCheckboxColumnDef = (title, field, mapFrom) => ({
    ...basicColumnDef(title, field),
    preventClickThrough: true,
    mapToDisplayedValue: (resources, values, field, actions) => {
        return (
            <div className="m-1" onClick={PreventClickThrough}>
                {values[mapFrom] ? (
                    <i className="fa fa-check-circle" aria-hidden="true" style={{ color: 'green' }} />
                ) : (
                    <i className="fa fa-minus-circle" aria-hidden="true" style={{ color: 'red' }} />
                )}
            </div>
        );
    }
});

export const testTemplateButtonColumnDef = (title, field) => ({
    ...basicColumnDef(title, field),
    preventClickThrough: true,
    mapToDisplayedValue: (resources, values) => {
        const popoverId = 'testTemplate';
        return (
            <TestTemplateModal>
                {({ testTemplate }) => <TestTemplateRowButton id={popoverId} onClick={() => testTemplate(values)} />}
            </TestTemplateModal>
        );
    }
});

export const checkboxColumnDef = (title, field) => ({
    ...basicColumnDef(title, field),
    preventClickThrough: true,
    mapToDisplayedValue: (resources, values, field, actions) => {
        return (
            <div className="m-1" onClick={PreventClickThrough}>
                <input
                    id={title}
                    onClick={PreventClickThroughAllowDefault}
                    checked={values.selected || false}
                    className="d-block text-center"
                    type="checkbox"
                    onChange={() => {
                        actions.updateSingleRow(values.id);
                    }}
                />
            </div>
        );
    }
});

export const dateColumnDef = (title, field, showSync = false, options = {}) => {
    const { displayEmptyAs = undefined } = options;
    return {
        ...basicColumnDef(title, field),
        mapping: (resources, values) => moment(values[field]).format(process.env.REACT_APP_DATE_FORMAT),
        valueToSortOnMapping: (resources, values) => values[field],
        mapToDisplayedValue: (resources, values) => (
            <ColumnTypeDate values={values} field={field} showSync={showSync} displayEmptyAs={displayEmptyAs} />
        ),
        type: 'date'
    };
};

export const timeColumnDef = (title, field, showSync = false) => ({
    ...basicColumnDef(title, field),
    mapping: (_resources, values) => moment(values[field]).format(process.env.REACT_APP_TIME_FORMAT)
});

export const dateTimeColumnDef = (title, field, showSync = false, options = {}) => {
    const { displayEmptyAs = undefined } = options;
    return {
        ...basicColumnDef(title, field),
        mapping: (resources, values) => moment(values[field]).format(process.env.REACT_APP_DATETIME_FORMAT),
        valueToSortOnMapping: (resources, values) => values[field],
        mapToDisplayedValue: (resources, values) => (
            <ColumnTypeDate
                showTime={true}
                values={values}
                field={field}
                showSync={showSync}
                displayEmptyAs={displayEmptyAs}
            />
        ),
        type: 'date'
    };
};

export const choiceListColumnDef = (title, field, choiceListName, options = {}) => {
    const result = {
        ...basicColumnDef(title, field),
        ...choiceListColumnSettings(choiceListName, field, options.multipleChoice),
        isMulti: !!options.multipleChoice
    };

    const { renderAsBasicText = false, displayEmptyAs = '' } = options;
    if (renderAsBasicText) {
        result.mapToDisplayedValue = (resources, values, field) => {
            const key = _.get(values, field, undefined);
            if (!key) return displayEmptyAs;

            const choiceList = _.get(resources, `choiceList[${choiceListName}]`, []);
            if (!choiceList) return key;

            const choiceListMatch = choiceList.find(x => x.key === key.toString());
            if (!choiceListMatch) return key;

            return choiceListMatch.value;
        };
    }

    return result;
};

export const choiceListColumnSettings = (choiceList, defaultField, multipleChoice) => ({
    right: true,
    choiceList: choiceList,
    type: 'captionBadge',
    mapToDisplayedValue: (resources, values, field) =>
        multipleChoice ? (
            (values[field || defaultField] || '')
                .split(',')
                .filter(x => !!x)
                .map(value => (
                    <CaptionBadge
                        key={value}
                        choiceList={choiceList}
                        className="ml-1"
                        caption={value}
                        resources={resources}
                    />
                ))
        ) : (
            <CaptionBadge
                key={values[field || defaultField]}
                choiceList={choiceList}
                caption={values[field || defaultField]}
                resources={resources}
            />
        )
});

export const useMemoTableDefinition = columns => useMemo(() => new TableDefinition(columns), [columns]);

export class TableDefinition {
    constructor(columns) {
        this.columns = columns;

        this.filters = columns
            .filter(x => x !== undefined && x.choiceList)
            .map(({ field, title, choiceList, rangeUnit, unselected }) => ({
                field,
                label: title,
                choiceList,
                index: 0,
                rangeUnit,
                unselected
            }));

        this.searchFields = columns
            .filter(x => x !== undefined && x.searchable)
            .map(columnDef => ({
                path: columnDef.path || columnDef.field,
                name: columnDef.title,
                mapping: columnDef.mapping
            }));
    }
}

export const mapColumnDefToChoiceList = columnDef =>
    columnDef
        ? {
              fieldName: columnDef.field,
              title: columnDef.title,
              emptyOption: columnDef.emptyOption,
              choiceList: columnDef.choiceList,
              isMulti: columnDef.isMulti
          }
        : undefined;

export const mapColumnDefToCheckbox = columnDef =>
    columnDef
        ? {
              fieldName: columnDef.field,
              title: columnDef.title
          }
        : undefined;

export const mapColumnDefToDate = columnDef =>
    columnDef
        ? {
              fieldName: columnDef.field,
              title: columnDef.title
          }
        : undefined;

export const mapColumnDefToText = columnDef =>
    columnDef
        ? {
              fieldName: columnDef.field,
              title: columnDef.title,
              type: columnDef.type,
              prefix: columnDef.prefix
          }
        : undefined;
