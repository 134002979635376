import {
    basicColumnDef,
    checkboxColumnDef,
    choiceListColumnDef,
    dateColumnDef,
    idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';
import ColumnTypeFormatCompanyName from '../common/Table/ColumnTypeFormatCompanyName';
import React from 'react';
import AgreementPdfLinkActionButton from './Buttons/AgreementPdfLinkActionButton';
import { PreventClickThroughAllowDefault } from '../common/Table/PreventClickThrough';
import AgreementHeaderProvider from './Provider/AgreementHeaderProvider';
import { ApplyAddendumButton } from '../common/Buttons/TableButtons';
import AddendumRemoveAgreementButton from './Buttons/AddendumRemoveAgreementButton';

const agreementHeaderColumnDefs = {
    id: idColumnDef('id', 'id'),
    parentId: basicColumnDef('Parent Id', 'parentId'),
    templateId: choiceListColumnDef('Agreement Template', 'parentId', 'AgreementTemplate'),
    addendumTemplate: choiceListColumnDef(
        'Addendum Template (optional)',
        'addendumTemplateFileId',
        'addendumTemplates'
    ),
    parentType: {
        ...basicColumnDef('Terms Type', 'parentType'),
        searchable: true
    },
    agreementType: {
        ...choiceListColumnDef('Agreement Type', 'agreementType', 'AgreementType')
    },
    personId: basicColumnDef('Person', 'personId'),
    companyId: basicColumnDef('Company', 'companyId'),
    companyName: {
        ...basicColumnDef('Company Name', 'companyName'),
        mapping: (resources, values) => (values && values.company ? values.company.descriptor : ''),
        mapToDisplayedValue: (resources, values, field, actions) => (
            <ColumnTypeFormatCompanyName actions={actions} values={values} />
        ),
        valueToSortOnMapping: (resources, values) => (values && values.company ? values.company.descriptor : ''),
        searchable: true
    },
    hyundaiAgreementId: {
        ...basicColumnDef('Hyundai Agreement Id', 'hyundaiAgreementId'),
        searchable: true
    },
    documentTemplate: {
        ...choiceListColumnDef('Document Template', 'documentTemplate', 'AgreementHeaderDocumentTemplate')
    },
    status: {
        ...choiceListColumnDef('Status', 'status', 'AgreementHeaderStatus')
    },
    startDate: {
        ...dateColumnDef('Start Date', 'startDate')
    },
    endDate: {
        ...dateColumnDef('End Date', 'endDate')
    },
    termsType: {
        ...choiceListColumnDef('Terms Type', 'termsType', 'AgreementTermsType')
    },
    notes: {
        ...basicColumnDef('Notes', 'notes'),
        searchable: true,
        type: 'textarea'
    },
    expectedVolume: {
        ...basicColumnDef('Expected Volume', 'expectedVolume'),
        searchable: true,
        type: 'number'
    },
    agreementApprovalTeam: {
        ...choiceListColumnDef('Agreement Approval Team', 'agreementApprovalTeam', 'AgreementApprovalTeam')
    },
    name: {
        ...basicColumnDef('Name', 'name'),
        searchable: true
    },
    addendumId: idColumnDef('Addendum Id', 'addendumId'),
    addendumStatus: {
        ...choiceListColumnDef('Addendum Status', 'addendumStatus', 'AddendumStatus')
    },
    addendumUpdatesStartDate: {
        ...dateColumnDef('Addendum Updates Start Date', 'addendumUpdatesStartDate')
    },
    addendumUpdatesEndDate: {
        ...dateColumnDef('Addendum Updates End Date', 'addendumUpdatesEndDate')
    },
    createdDate: {
        ...dateColumnDef('Created', 'createdDate')
    },
    updatedDate: {
        ...dateColumnDef('Updated', 'updatedDate')
    },
    areDetailsModified: {
        ...basicColumnDef('Details Modified', 'areDetailsModified')
    },
    selected: columnCaption => ({
        ...checkboxColumnDef(columnCaption, 'selected')
    }),
    evidence: {
        ...basicColumnDef('Upload Evidence Documents', 'evidence'),
        searchable: false,
        sort: false,
        s3bucket: 'hyundai-agreement-documents-dev',
        multiple: false,
        accept:
            'image/png, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint',
        hideLabel: true
    },
    template: {
        ...basicColumnDef('Upload Template', 'evidence'),
        searchable: false,
        sort: false,
        s3bucket: 'hyundai-agreement-documents-dev',
        multiple: false,
        accept: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        hideLabel: true
    },
    pdfLink: {
        ...basicColumnDef('PDF', 'pdfLink'),
        hideLabel: true,
        mapping: (resources, values) => (values && values.company ? values.company.descriptor : ''),
        mapToDisplayedValue: (resources, values, field, actions) => {
            return values.agreementType === 'Agreement' && values.status === 'Live' ? (
                <div className="d-flex align-items-center" style={{ height: '30px', marginTop: '0.5rem' }}>
                    <span className="text-center mx-auto" onClick={PreventClickThroughAllowDefault}>
                        <AgreementHeaderProvider agreementHeaderId={values.id}>
                            <AgreementPdfLinkActionButton hideText={true} />
                        </AgreementHeaderProvider>
                    </span>
                </div>
            ) : null;
        }
    },
    applyAddendum: {
        ...basicColumnDef('Apply Addendum', 'applyAddendum'),
        mapToDisplayedValue: (resources, values, field, actions) => {
            return (
                <div className="text-center mx-auto" onClick={PreventClickThroughAllowDefault}>
                    <ApplyAddendumButton
                        onClick={() => {
                            actions.applyAddendum(values.addendumId, [values]);
                        }}
                    />
                </div>
            );
        }
    },
    removeAgreementFromAddendum: {
        ...choiceListColumnDef('Remove Agreement', 'removeAgreementFromAddendum'),
        mapping: (resources, values) => (values && values.company ? values.company.descriptor : ''),
        mapToDisplayedValue: (resources, values, field, actions) => {
            return (
                <div style={{ height: '30px', marginTop: '0.5rem' }}>
                    <span className="text-right mx-auto" onClick={PreventClickThroughAllowDefault}>
                        <AddendumRemoveAgreementButton addendumId={values.addendumId} agreementHeaderId={values.id} />
                    </span>
                </div>
            );
        }
    }
};

export default agreementHeaderColumnDefs;
