import { ENDPOINTS } from '../constants';
import { getAxiosGetRequest } from '../apiHelpers';

export const apiCompanyOffline = companyId => async (dispatch, getState) => {
    const type = ENDPOINTS.API.COMPANY_OFFLINE;

    if (!companyId) {
        return false;
    }

    if (getState().offline.outbox.find(x => x.type === type && x.data.companyId === companyId)) {
        return true;
    }
    const axiosRequest = getAxiosGetRequest(['company', companyId]);

    dispatch({
        type,
        data: { companyId, syncing: true },
        meta: {
            offline: {
                effect: axiosRequest,
                commit: { type: ENDPOINTS.API.COMPANY_OFFLINE_COMMIT, meta: { companyId } },
                rollback: { type: ENDPOINTS.API.COMPANY_OFFLINE_ROLLBACK, meta: { companyId } }
            }
        }
    });

    return true;
};
