import React, { useMemo, useState } from 'react';
import eventTypes from '../eventTypes';
import MapArray from '../../common/MapArray';
import { Badge } from 'reactstrap';

const Filter = ({ filter, toggleItem, events }) => {
    if (events.length === 0) {
        return <span className="ml-2 badge badge-light">No events</span>;
    }

    return (
        <MapArray
            from={filter}
            mapKey={filter => filter.eventTypeKey}
            data={events}
            map={(item, key, data) => ({ ...item, events: data })}
        >
            <FilterItem toggleItem={toggleItem} />
        </MapArray>
    );
};

const FilterItem = ({ eventTypeKey, visible, toggleItem, events }) => {
    const eventType = eventTypes[eventTypeKey];
    const count = events.filter(event => event.type.name === eventType.name).length;

    return (
        count > 0 && (
            <Badge
                className={`background-hover my-1 mr-2 py-2 px-2 ${
                    visible ? eventType.className : 'list-group-item-secondary'
                }`}
                onClick={() => toggleItem(eventTypeKey)}
            >
                <span className="py-2">
                    {eventType.icon('mr-2')}
                    {visible ? eventType.name : <s>{eventType.name}</s>}
                    <span className="ml-2 badge badge-light">{count}</span>
                </span>
            </Badge>
        )
    );
};

const CalendarEventsFilter = ({ events, children }) => {
    const [filter, setFilter] = useState(
        Object.keys(eventTypes).map(eventTypeKey => ({ eventTypeKey, visible: true }))
    );

    const filteredEvents = useMemo(
        () => {
            const visibleNames = filter.filter(x => x.visible).map(x => eventTypes[x.eventTypeKey].name);
            return events.filter(event => visibleNames.indexOf(event.type.name) > -1);
        },
        [filter, events]
    );

    const toggleItem = toggledKey => {
        setFilter(
            filter.map(({ eventTypeKey, visible }) => ({
                eventTypeKey,
                visible: toggledKey === eventTypeKey ? !visible : visible
            }))
        );
    };

    return children({ filteredEvents, filter: <Filter toggleItem={toggleItem} filter={filter} events={events} /> });
};

export default CalendarEventsFilter;
