import { RESET_REDUCER } from '../../../actions/resetReducers';
import { ENDPOINTS } from '../../../actions/Api/constants';
import _ from 'lodash';

const initialState = {
    addendumAgreements: [],
    totalResults: 0,
    hasMoreRows: false,
    keyword: '',
    page: 0
};

export function addendumAgreementSummaries(state = initialState, action) {
    switch (action.type) {
        case RESET_REDUCER:
            return initialState;
        case ENDPOINTS.API.ADDENDUM_AGREEMENT_SEARCH:
        case ENDPOINTS.API.AGREEMENT_FILTER_BY_PRODUCT:
            return { addendumAgreements: action.data.agreementHeaders };
        case ENDPOINTS.API.AGREEMENT_SET_ADDENDUM:
            return {
                addendumAgreements: _.chain(state)
                    .get('addendumAgreements')
                    .filter(x => x.id === _.get(action, 'data').id)
                    .value()
            };
        default:
            return state;
    }
}
