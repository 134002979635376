import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Formik } from 'formik';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import * as yup from 'yup';
import FormikText from '../../common/FormkControls/FormikText';
import FormikHtmlEditor from '../../common/FormkControls/FormikHtmlEditor';
import FormikErrors from '../../common/FormkControls/FormikErrors';
import PageSection from '../../common/PageSection/';
import { apiMeetingEmail } from '../../../actions/Api/Meeting/apiMeetingEmail';
import { getChoiceListValue } from '../../common/LabelText';
import { selectMeetingFromRouteProps } from '../../../reducers/Api/Meeting/selectors';
import Layout3Columns, { Column1, Column2 } from '../../Dashboard/Components/Layout3Columns';
import SimpleCard from '../../common/Card/SimpleCard';

const schema = yup.object().shape({
    from: yup
        .string()
        .required('From address cannot be empty.')
        .email('From address must be a single, valid email address.'),
    to: yup.string().test({
        test: val => {
            if (!val) return false;

            const emails = val.split(',').map(x => x.trim());
            const emailValidator = yup
                .string()
                .required()
                .email();

            return Promise.all(emails.map(e => emailValidator.isValid(e))).then(arr => arr.every(x => x));
        },
        message: 'To addresses must be one or more valid email addresses, separated by commas.'
    }),
    cc: yup.string().test({
        test: val => {
            if (!val || val === '') return true;

            const emails = val.split(',').map(x => x.trim());
            const emailValidator = yup
                .string()
                .required()
                .email();

            return Promise.all(emails.map(e => emailValidator.isValid(e))).then(arr => arr.every(x => x));
        },
        message: 'CC addresses must be one or more valid email addresses, separated by commas, or empty.'
    }),
    bcc: yup.string().test({
        test: val => {
            if (!val || val === '') return true;

            const emails = val.split(',').map(x => x.trim());
            const emailValidator = yup
                .string()
                .required()
                .email();

            return Promise.all(emails.map(e => emailValidator.isValid(e))).then(arr => arr.every(x => x));
        },
        message: 'BCC addresses must be one or more valid email addresses, separated by commas, or empty.'
    }),
    subject: yup.string().required('Subject cannot be empty.'),
    body: yup.string().required('Body cannot be empty.')
});

class SendVisitReportEmailPage extends React.Component {
    initialValues = null;

    handleSubmit(email) {
        const meetingId = this.props.match.params.meetingId;
        this.props.send(meetingId, email, () => {
            toastr.success('MAIL SENT SUCCESSFULLY', `Sent to ${email.to}`);
            this.props.history.goBack();
        });
    }

    getAction = action => getChoiceListValue(this.props.resources, 'visitActionActions', action);

    getEmailBody = (template, communication, person) => {
        const {
            user,
            emailSettings: { includeNotesInEmailComms },
            visitActions,
            resources
        } = this.props;
        const meetingId = this.props.match.params.meetingId;
        const visitActionActivityRegarding = _.get(resources, 'choiceList.visitActionActivityRegarding', []);

        let meetingActions = visitActions
            .filter(x => x.meetingId && x.meetingId.toString() === meetingId)
            .map(({ activityRegarding, note }) => ({
                note,
                activityRegarding: _.get(
                    visitActionActivityRegarding.find(x => x.key === activityRegarding),
                    'value',
                    activityRegarding
                ).replace('Visit Action - ', '')
            }))
            .map(va => `<li>${this.getAction(va.activityRegarding)} - ${va.note}</li>`)
            .join(' ');

        let meetingNotes = '';

        if (includeNotesInEmailComms.includes(communication.meetingId) && communication.engageNotes) {
            meetingNotes = `<p>${communication.engageNotes.split('\n').join('<br />')}</p>`;
        }

        let userJobTitle = user.jobTitle ? user.jobTitle : '';

        return template
            .replace(':contactFirstName', _.get(person, 'firstName', ''))
            .replace(
                ':meetingActions',
                meetingActions.length
                    ? `<b>Notes</b><br>${meetingNotes}<b>Actions</b><br><ul style="padding-left:0px">${meetingActions}</ul>`
                    : ''
            )
            .replace(':jobTitle', userJobTitle)
            .replace(':userName', user.descriptor)
            .replace(':userName', user.descriptor)
            .replace(':userFax', user.fax || '')
            .replace(':userMobile', user.mobile || '')
            .replace(':userEmail', user.userName)
            .replace(':userEmail', user.userName);
    };

    getInitialValues() {
        if (this.initialValues) return this.initialValues;

        const {
            meeting,
            person,
            resources: { emailTemplate },
            user
        } = this.props;

        const startDate = new Date(meeting.startDate);
        const meetingTime = `${startDate.toLocaleDateString()} at ${startDate.toLocaleTimeString()}`;
        const subject = emailTemplate[0].subject.replace(':meetingTime', meetingTime);

        this.initialValues = {
            from: user.email,
            to: _.get(person, ['businessEmail'], ''),
            cc: '',
            bcc: user.userName,
            subject,
            body: this.getEmailBody(emailTemplate[0].innerTemplate, meeting, person)
        };
        return this.initialValues;
    }

    render() {
        const initialValues = this.getInitialValues();
        return (
            <PageSection onBackClicked={this.props.history.goBack} title="Send Visit Report Summary Email">
                <Layout3Columns>
                    <Column1>
                        <></>
                    </Column1>
                    <Column2>
                        <SimpleCard title="Email">
                            <Formik
                                validationSchema={schema}
                                initialValues={initialValues}
                                onSubmit={this.handleSubmit.bind(this)}
                                render={formikProps => {
                                    formikProps.formName = 'emailForm';
                                    return (
                                        <form onSubmit={formikProps.handleSubmit}>
                                            <FormikText type="text" fieldName="from" {...formikProps} />
                                            <FormikText type="text" fieldName="to" {...formikProps} />
                                            <FormikText type="text" fieldName="cc" {...formikProps} />
                                            <FormikText type="text" fieldName="bcc" {...formikProps} />
                                            <FormikText type="text" fieldName="subject" {...formikProps} />
                                            <FormikHtmlEditor fieldName="body" {...formikProps} />
                                            <FormikErrors errors={formikProps.errors} />
                                            <Button
                                                style={{ marginTop: 20 }}
                                                block
                                                color="primary"
                                                type="submit"
                                                className="mb-5"
                                                disabled={!!Object.keys(formikProps.errors).length}
                                            >
                                                Send
                                            </Button>
                                        </form>
                                    );
                                }}
                            />
                        </SimpleCard>
                    </Column2>
                </Layout3Columns>
            </PageSection>
        );
    }
}

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => state.resources,
            selectMeetingFromRouteProps,
            state => state.people,
            state => state.user,
            state => state.emailSettings,
            state => state.visitActions
        ],
        (resources, meeting, people, user, emailSettings, visitActions) => {
            const personId = meeting.person.id;
            const person = people.find(person => person.personId === personId);
            return {
                resources,
                meeting,
                person,
                user,
                emailSettings,
                visitActions
            };
        }
    );
    return (state, props) => getState(state, props);
};

const mapDispatchToProps = dispatch => ({
    send: (meetingId, email, onSuccess) => dispatch(apiMeetingEmail(meetingId.toString(), email, onSuccess))
});

export default connect(makeMapStateToProps, mapDispatchToProps)(withRouter(SendVisitReportEmailPage));
