import useModalForm from '../Hooks/useModalForm';
import { useDispatch } from 'react-redux';
import ReactiveForm from '../../common/ReactiveForm';
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../AppModalHeader';
import { apiCancelAgreement } from '../../../actions/Api/AgreementHeader/apiAgreementCancel';

export const CancelAgreementModal = ({ agreementHeaderIds, children }) => {
    const dispatch = useDispatch();
    const { toggle, isOpen, openModal } = useModalForm();
    const reload = () => {
        window.location.reload();
        return false;
    };
    const initialValues = {};

    const handleSubmit = (form, dispatch) => {
        dispatch(
            apiCancelAgreement(
                agreementHeaderIds,
                'Success',
                'Agreement Cancelled',
                'You have successfully Cancelled this agreement.',
                reload
            )
        );
        toggle();
    };
    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
                <AppModalHeader toggle={toggle}>Cancel Agreement Confirmation</AppModalHeader>
                <ModalBody>
                    <ReactiveForm
                        initialValues={initialValues}
                        saveButtonContent="Save"
                        cancel={toggle}
                        handleSubmit={form => handleSubmit(form, dispatch)}
                    >
                        <ReactiveForm.Section title="">
                            <ReactiveForm.Warning
                                title={'Warning: '}
                                message="Cancelling will terminate this agreement and will have to be manually altered if wished to be returned to live. Should you wish to 'Cancel', please click the cancel button below or click 'Save' to confirm cancellation.'"
                            />
                        </ReactiveForm.Section>
                    </ReactiveForm>
                </ModalBody>
            </Modal>
            {children({
                triggerModal: () => openModal(),
                toggle
            })}
        </React.Fragment>
    );
};

export default CancelAgreementModal;
