import moment from 'moment';

export const onView = (view, props) => {
    const {
        match: {
            params: { year, month, day }
        }
    } = props;

    props.history.push(`/calendar/${view}/${year}/${month}/${day}`);
};

export const onNavigate = (date, view, props, setEventFilterDates) => {
    let start = moment(date)
        .startOf(view)
        .add(1, 'hour');
    const end = moment(date)
        .endOf(view)
        .add(1, 'hour');
    setEventFilterDates([start, end]);
    props.history.push(`/calendar/${view}/${start.year()}/${start.month() + 1}/${start.date()}`);
};

export const getDefaultDate = props => {
    const {
        match: {
            params: { year, month, day }
        }
    } = props;
    let defaultDate = moment().startOf('day');

    if (year && month && day) {
        defaultDate = moment([year, month - 1, day]).startOf('day');
    }
    return defaultDate;
};
