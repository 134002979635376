import React from 'react';
import _ from 'lodash';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import { ENDPOINTS } from '../../../actions/Api/constants';
import bandingsDef from '../bandingsDef';
import { useIsLoading } from '../../common/Hooks/useIsLoading';

const BandingsTableCard = ({ showFilter, rows, columns, noItemsText, breakpoint, itemsPerPage, editBanding }) => {
    const isLoading = useIsLoading([ENDPOINTS.API.COMPANY_BANDINGS_GET]);
    const rowsSorted = _.orderBy(
        rows.filter(x => x.updatedDate !== null),
        ['demoEndDate'],
        ['desc']
    );

    const tableDefinition = new TableDefinition(columns);
    return (
        <ChoiceListFilteredTableNoDashboardData
            id="Bandings"
            title="Bandings"
            noItemsText={noItemsText}
            tableIsNotInCard={true}
            showFilter={showFilter}
            itemsPerPage={itemsPerPage}
            breakpoint={breakpoint}
            cardButtons={[]}
            isLoading={isLoading}
            tableDefinition={tableDefinition}
            rows={rowsSorted}
            columnDefsId={bandingsDef.id}
            tableDefinitionColumns={tableDefinition.columns}
            onRowClick={banding => editBanding(banding)}
        />
    );
};

BandingsTableCard.defaultProps = {
    breakpoint: 500,
    itemsPerPage: 5,
    noItemsText: 'There are no bandings to show',
    columns: [
        bandingsDef.updatedDate,
        bandingsDef.descriptor,
        bandingsDef.carLcv,
        bandingsDef.minPricePoint,
        bandingsDef.maxPricePoint,
        bandingsDef.numberOfVehicles
    ]
};

export default BandingsTableCard;
