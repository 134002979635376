import { ENDPOINTS } from '../constants';
import { handleErrorWithToastUnlessUnauthorised, makePutRequest } from '../apiHelpers';
import { isLoading } from '../../Loading/isLoading';

const type = ENDPOINTS.API.TEMPLATE_PUT;

export const apiTemplatePut = file => async (dispatch, getState) => {
    isLoading(dispatch, type, true, {});
    makePutRequest(['template'], undefined, file)
        .then(({ data }) => {
            dispatch({ type, data });
            isLoading(dispatch, type, false, {});
        })
        .catch(error => handleErrorWithToastUnlessUnauthorised(dispatch, getState, error, type, 'Templates'));
};
