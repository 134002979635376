import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { isAnyLoading } from '../../../reducers/Rest/loadingStates';
import { apiAuthPasswordForgot } from '../../../actions/ApiAuth/Password/apiAuthPasswordForgot';
import { AppLogo } from '../../app/AppLogo';
import { Formik } from 'formik';
import FormikText from '../../common/FormkControls/FormikText';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';

class ForgottenPasswordForm extends React.Component {
    handleSubmit = form => {
        this.props.sendResetLink(form.email);
    };

    render() {
        const { loadingStates, className, resetPassword } = this.props;
        if (isAnyLoading(loadingStates)) {
            return <ShowLoadingSpinner isLoading color="#fff" />;
        }

        if (resetPassword.linkSent) {
            return (
                <h2 className="logo-font text-white shadow-2">Reset link has been sent to {resetPassword.sentTo}.</h2>
            );
        }

        const initialValues = {
            email: process.env.REACT_APP_DEFAULT_USERNAME || ''
        };

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={this.handleSubmit}
                render={formikProps => {
                    formikProps.formName = 'loginForm';
                    return (
                        <form className={className} onSubmit={formikProps.handleSubmit}>
                            <div className="w-100 text-center mb-4">
                                <h6 className="mb-0">Sign in to</h6>
                                <AppLogo />
                            </div>
                            <div className="mb-4">
                                <FormikText
                                    type="text"
                                    fieldName="email"
                                    placeholder="Email"
                                    {...formikProps}
                                    hideLabel
                                />
                                <div className="d-grid grid-cols-2 grid-column-gap-2">
                                    <div />
                                </div>
                            </div>
                            <Button type="submit" color="primary" className="w-100">
                                Send Reset Link
                            </Button>
                        </form>
                    );
                }}
            />
        );
    }
}

const mapStateToProps = state => ({
    loadingStates: state.loadingStates,
    resetPassword: state.resetPassword
});

const mapDispatchToProps = dispatch => ({
    sendResetLink: emailAddress => dispatch(apiAuthPasswordForgot(emailAddress))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgottenPasswordForm);
