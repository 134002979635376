import React from 'react';
import { Alert } from 'reactstrap';

const MoreResultsWarning = ({ show, maxResults, totalResults }) =>
    show ? (
        <Alert color="info">
            Showing top {maxResults} of {totalResults} results. Please refine your search.
        </Alert>
    ) : null;

export default MoreResultsWarning;
