import React, { useContext } from 'react';
import _ from 'lodash';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';
import CompanyDetails from '../../Company/Components/CompanyDetails';
import CompanyProvider from '../../Company/Provider/CompanyProvider';

const AgreementCompanyDetails = () => {
    const {
        agreementHeader,
        agreementState: { showCompanyDetails }
    } = useContext(AgreementHeaderContext);
    const companyId = _.get(agreementHeader, 'companyId');

    if (!companyId || !showCompanyDetails) {
        return null;
    }

    return (
        <CompanyProvider companyId={companyId} companyOnly={true}>
            <CompanyDetails />
        </CompanyProvider>
    );
};

export default AgreementCompanyDetails;
