import React, { useContext } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import CompanyProvider from '../../Company/Provider/CompanyProvider';
import MeetingVisitActionsProvider from './MeetingVisitActionsProvider';
import { apiMeetingPost } from '../../../actions/Api/Meeting/apiMeetingPost';
import { apiMeetingFilter } from '../../../actions/Api/Meeting/apiMeetingFilter';
import CancelMeetingModal from '../Modals/CancelMeetingModal';
import CompleteMeetingModal from '../Modals/CompleteMeetingModal';

const MeetingContext = React.createContext({
    profile: {},
    meeting: {}
});

class MeetingProvider extends React.Component {
    getStateAndHelpers({ meetingId, loadingStates, resources, meeting, visitActions, saveMeeting }) {
        return {
            meetingId,
            loadingStates,
            resources,
            meeting,
            saveMeeting,
            visitActions
        };
    }

    componentDidMount() {
        this.props.getMeeting(this.props.meetingId);
    }

    render() {
        const { children, meeting, meetingId } = this.props;
        return (
            <CompleteMeetingModal>
                {({ completeMeeting }) => (
                    <CancelMeetingModal>
                        {({ cancelMeeting }) => (
                            <MeetingContext.Provider
                                value={{ completeMeeting, cancelMeeting, ...this.getStateAndHelpers(this.props) }}
                            >
                                {meeting ? (
                                    <MeetingVisitActionsProvider meetingId={meetingId}>
                                        <CompanyProvider companyId={meeting.company.id}>{children}</CompanyProvider>
                                    </MeetingVisitActionsProvider>
                                ) : null}
                            </MeetingContext.Provider>
                        )}
                    </CancelMeetingModal>
                )}
            </CompleteMeetingModal>
        );
    }
}

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => state.loadingStates,
            state => state.resources,
            (state, { meetingId }) => state.meetings.find(meeting => meeting.meetingId === meetingId)
        ],
        (loadingStates, resources, meeting) => ({
            loadingStates,
            resources,
            meeting
        })
    );
    return (state, props) => getState(state, props);
};

const mapDispatchToProps = dispatch => ({
    saveMeeting: meeting => dispatch(apiMeetingPost(meeting)),
    getMeeting: meetingId => dispatch(apiMeetingFilter({ meetingId }))
});

export const useMeetingContext = () => {
    const state = useContext(MeetingContext);
    if (state === undefined) {
        throw new Error(`useMeetingContext must be used inside MeetingProvider`);
    }
    return state;
};

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(MeetingProvider);
