import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const makeAgreementStatusChangesSelector = () =>
    createSelector(
        state => state.agreementStatusChanges,
        (_, agreementHeaderId) => agreementHeaderId,
        (agreementStatusChanges, agreementHeaderId) =>
            agreementStatusChanges.filter(change => change.agreementHeaderId === agreementHeaderId)
    );

export const useAgreementStatusChanges = agreementHeaderId => {
    const selectChoiceList = useMemo(makeAgreementStatusChangesSelector, []);
    return useSelector(state => selectChoiceList(state, agreementHeaderId));
};
