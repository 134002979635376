import React from 'react';
import useToggleOpen from '../Hooks/useToggleOpen';
import { EditItemRowButton, TickTableRowButton } from '../Buttons/TableButtons';

const FormikSearchHolder = ({ description, collapsed, disabled, children }) => {
    const { toggle, isOpen } = useToggleOpen(!collapsed && !disabled);

    return (
        <div className="d-flex">
            <div className="flex-grow-1   ml-2">{isOpen ? children : <div>{description}</div>}</div>
            {disabled ? null : isOpen ? (
                <TickTableRowButton onClick={toggle} />
            ) : (
                <EditItemRowButton onClick={toggle} />
            )}
        </div>
    );
};

export default FormikSearchHolder;
