import { ENDPOINTS } from '../../../actions/Api/constants';
import _ from 'lodash';
import { RESET_REDUCER } from '../../../actions/resetReducers';

export function updateItemsInStateFromData(state, path, data) {
    const items = path === '' ? data : _.get(data, path, []);
    return [...state.filter(x => !items.some(d => d.id === x.id)), ...items];
}

const initialState = [];

const agreementDetails = (state = initialState, { type, data }) => {
    switch (type) {
        case ENDPOINTS.API.AGREEMENT_FILTER:
        case ENDPOINTS.API.AGREEMENT_GET:
        case ENDPOINTS.API.AGREEMENT_CREATE_DRAFT:
        case ENDPOINTS.API.AGREEMENT_APPROVE:
        case ENDPOINTS.API.AGREEMENT_DECLINE:
        case ENDPOINTS.API.PROMOTION_GET:
        case ENDPOINTS.API.SWITCH_AGREEMENT_TEMPLATE:
            return _.get(data, 'agreementDetails', state);
        case ENDPOINTS.API.AGREEMENT_DETAIL_POST:
            return updateItemsInStateFromData(state, '', data);
        case ENDPOINTS.API.AGREEMENT_DETAIL_APPROVE:
        case ENDPOINTS.API.AGREEMENT_DETAIL_DECLINE:
        case ENDPOINTS.API.AGREEMENT_DETAIL_DELETE:
            return updateItemsInStateFromData(state, 'agreementDetails', data);
        case RESET_REDUCER:
            return initialState;
        default:
            return state;
    }
};

export default agreementDetails;
