import _ from 'lodash';
import { useMemo } from 'react';

export const useRowsWithCompany = (rows, companies) =>
    useMemo(() => rows.map(row => ({ ...row, company: findCompanyForRow(row, companies) })), [rows, companies]);

export const useFindCompanyForRow = (row, companies) =>
    useMemo(() => companies.find(company => company.companyId === row.companyId), [row, companies]);

export const findCompanyForRow = (row, companies) => companies.find(company => company.companyId === row.companyId);

export function useCompanyName(companies, companyId) {
    return useMemo(() => {
        const company = companies.find(x => x.companyId === companyId);
        return company ? company.name : '';
    }, [companies, companyId]);
}

export const getCompany = (companies, companyId) => companies.find(company => company.companyId === companyId);

export const isPrimaryAddress = address => address.isPrimaryAddress;

export const isCompanySyncing = company => company.syncing;
export const isCompanyLoading = company => company.loading;

export const setCompanyLoading = (company, isLoading) => (company.loading = isLoading);

export const getPrimaryAddresses = company => {
    if (!_.has(company, 'addresses[0]')) {
        return [];
    }

    return _.uniqBy(company.addresses.filter(isPrimaryAddress), 'addressId');
};

export const getAddressById = (companies, companyId, addressId) => {
    const company = getCompany(companies, companyId);
    if (company.addresses) {
        return company.addresses.find(x => x.addressId === addressId);
    }
};

export const getPrimaryOrFirstAddress = company => {
    if (!_.has(company, 'addresses[0]')) {
        return;
    }
    const primaryAddress = company.addresses.find(isPrimaryAddress);
    return primaryAddress || company.addresses[0];
};

export const getPrimaryAddress = (companies, companyId) => {
    const company = getCompany(companies, companyId);
    if (!company) {
        return;
    }
    return getPrimaryOrFirstAddress(company);
};

export const EmptyCompany = {
    companyId: 0,
    businessPhone: '',
    profile: {
        fleetProfileId: 0,
        changeCycleMiles: '',
        changeCycleMilesLcv: '',
        changeCycleMonths: '',
        changeCycleMonthsLcv: '',
        co2Cap: 'string',
        companyId: 0,
        currentFunder: 'string',
        fleetSize: 0,
        numberOfBrand: 0,
        numberOfBrandLcv: 0,
        numberOfCars: 0,
        numberOfCommercials: 0,
        numberOfGrey: 0,
        otherManufacturers: '',
        otherManufacturerslcv: '',
        id: '',
        createdBy: 0,
        createdDate: undefined,
        updatedBy: 0,
        updatedDate: undefined,
        descriptor: '',
        hasSalarySacrifice: null
    },
    prospectType: '',
    soleTrader: true,
    registrationNumber: '',
    webSite: '',
    people: [],
    addresses: [],
    id: 'string',
    createdBy: 0,
    createdDate: undefined,
    updatedBy: 0,
    updatedDate: undefined,
    descriptor: ''
};
