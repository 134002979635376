import React, { useContext } from 'react';
import { AgreementHeaderSearchContext } from './AgreementHeaderSearchProvider';
import ReactiveForm from '../../common/ReactiveForm';
import useProductOptions from '../../AgreementDetails/Hooks/useProductOptions';
import useProductFamilyOptions from '../../AgreementDetails/Hooks/useProductFamilyOptions';
import { SaveCancelButton } from '../../common/SaveCancelButton';
import { Alert } from 'reactstrap';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';

function getInitialValues(searchOptions) {
    if (searchOptions) {
        return {
            companyName: {
                value: undefined,
                hide: true,
                disabled: true,
                collapsed: true
            },
            agreementType: {
                value: {},
                hide: true,
                disabled: true,
                collapsed: true
            },
            status: {
                value: {},
                hide: true,
                disabled: true,
                collapsed: true
            },
            termsType: {
                value: {},
                hide: true,
                disabled: true,
                collapsed: true
            },
            product: {
                value: {},
                hide: true,
                disabled: true,
                collapsed: true
            },
            excludeAgreementsWithAnAddendumId: {
                value: false,
                hide: true,
                disabled: true,
                collapsed: true
            },
            ...searchOptions
        };
    }
    return {};
}

const SearchAlert = ({ color = 'success', text }) => {
    return (
        <Alert color={color}>
            <div className="d-flex">
                <div className="flex-grow-1">{text}</div>
                <SaveCancelButton pullRight saveButtonContent="Search" hideCancelButton={true} />
            </div>
        </Alert>
    );
};

const AgreementSearchForm = ({ children, searchOptions }) => {
    const { newProduct, productFamily, search, isLoading, hasSearched, agreements, resources } = useContext(
        AgreementHeaderSearchContext
    );

    return children({
        agreementSearchForm: (
            <ReactiveForm
                initialValues={getInitialValues(searchOptions)}
                validationSchema={[]}
                resources={resources}
                formName={'Agreement Search'}
                cancel={() => {}}
                handleSubmit={form => search(form)}
                searchOptions={searchOptions}
                hideCancelButton={true}
                hideSaveButton={true}
                saveButtonContent={'Search'}
            >
                <ReactiveForm.Section
                    title="Company Name Filter"
                    hideSectionNumber={true}
                    show={({ values }) => !values.companyName.hide}
                >
                    <ReactiveForm.SearchText
                        field="companyName"
                        placeholder="Enter part or all of a company name"
                        filterDescription={value =>
                            value ? (
                                <span>
                                    Agreements will be returned for companies with names like{' '}
                                    <b className="text-uppercase">{value}</b>
                                </span>
                            ) : (
                                'Agreements will be returned for all companies'
                            )
                        }
                    />
                </ReactiveForm.Section>
                <ReactiveForm.Section
                    title="Agreement Type"
                    hideSectionNumber={true}
                    mt="mt-3"
                    show={({ values }) => !values.agreementType.hide}
                >
                    <ReactiveForm.SearchOptionsTableChoiceList
                        field="agreementType"
                        columnNames={['Type', 'Include', 'Exclude']}
                        choiceList="AgreementType"
                        nothingSelectedText="Agreements will be returned regardless of their type"
                        includedText={includedDescription => (
                            <span>
                                The following agreement types will be included <b>{includedDescription}</b>
                            </span>
                        )}
                        excludedText={excludedDescriptions => (
                            <span>
                                The following agreement types will be excluded <b>{excludedDescriptions}</b>
                            </span>
                        )}
                    />
                </ReactiveForm.Section>
                <ReactiveForm.Section
                    title="Agreement Status"
                    hideSectionNumber={true}
                    mt="mt-3"
                    show={({ values }) => !values.status.hide}
                >
                    <ReactiveForm.SearchOptionsTableChoiceList
                        field="status"
                        columnNames={['Status', 'Include', 'Exclude']}
                        choiceList="AgreementHeaderStatus"
                        nothingSelectedText="Agreements will be returned regardless of their status"
                        includedText={includedDescription => (
                            <span>
                                Agreements with the following status will be included <b>{includedDescription}</b>
                            </span>
                        )}
                        excludedText={excludedDescriptions => (
                            <span>
                                Agreements with the following status will be excluded <b>{excludedDescriptions}</b>
                            </span>
                        )}
                    />
                </ReactiveForm.Section>

                <ReactiveForm.Section
                    title="Terms Type"
                    hideSectionNumber={true}
                    mt="mt-3"
                    show={({ values }) => !values.status.hide}
                >
                    <ReactiveForm.SearchOptionsTableChoiceList
                        field="termsType"
                        columnNames={['Terms Type', 'Include', 'Exclude']}
                        choiceList="AgreementTermsType"
                        nothingSelectedText="Agreements will be returned regardless of their terms type"
                        includedText={includedDescription => (
                            <span>
                                Agreements with the following terms types will be included <b>{includedDescription}</b>
                            </span>
                        )}
                        excludedText={excludedDescriptions => (
                            <span>
                                Agreements with the following terms types will be excluded <b>{excludedDescriptions}</b>
                            </span>
                        )}
                    />
                </ReactiveForm.Section>

                <ReactiveForm.Section
                    title="Products"
                    hideSectionNumber={true}
                    mt="mt-3"
                    show={({ values }) => !values.product.hide}
                >
                    <ReactiveForm.SearchOptionsTableChoiceListSelectGrouped
                        field="product"
                        columnNames={['Product', 'Include', 'Exclude']}
                        choiceList="AgreementHeaderStatus"
                        options={{
                            group: {
                                options: useProductFamilyOptions(productFamily),
                                emptyOption: 'Select Product...',
                                title: 'Product'
                            },
                            item: {
                                options: useProductOptions(newProduct),
                                emptyOption: 'Select Product Family...',
                                title: 'Product Family'
                            },
                            addButtonText: 'Add Product',
                            noRowsText:
                                'Use the dropdowns above to select a product, from a product family. After adding a product you can choose if the search returns agreements that have this product or do not have it.'
                        }}
                        nothingSelectedText="Agreements will be returned regardless of the products they contain"
                        includedText={includedDescription => (
                            <span>
                                Agreements with the following products will be included <b>{includedDescription}</b>
                            </span>
                        )}
                        excludedText={excludedDescriptions => (
                            <span>
                                Agreements with the following products will be excluded <b>{excludedDescriptions}</b>
                            </span>
                        )}
                    />
                </ReactiveForm.Section>
                {/* <ReactiveForm.Section
                    title="Exclude Agreements With An AddendumId"
                    hideSectionNumber={true}
                    mt="mt-3"
                    show={({ values }) => !values.product.hide}
                >
                    <ReactiveForm.SearchSelect
                        field="excludeAgreementsWithAnAddendumId"
                        uncheckedText="All agreements will be returned"
                        checkedText="Agreements will not be returned if they have an addendum set"
                        label="excludeAgreementsWithAnAddendumId is on here for debug only, will be set to hidden for release"
                    />
                </ReactiveForm.Section> */}
                <ReactiveForm.Section>
                    <ReactiveForm.Other>
                        {() => (
                            <>
                                {!hasSearched ? (
                                    <SearchAlert
                                        color="success"
                                        text="Select your search criteria above and press 'Search'"
                                    />
                                ) : null}
                                {isLoading ? (
                                    <Alert>
                                        <ShowLoadingSpinner size={20} title={'Finding Agreements'} isLoading={true} />
                                    </Alert>
                                ) : null}
                                {hasSearched && !isLoading && !agreements.length ? (
                                    <SearchAlert color="warning" text="There are no agreements matching your search." />
                                ) : null}
                                {hasSearched && !isLoading && agreements.length ? (
                                    <SearchAlert color="success" text="Search again?" />
                                ) : null}
                            </>
                        )}
                    </ReactiveForm.Other>
                </ReactiveForm.Section>
            </ReactiveForm>
        ),
        isLoading,
        hasSearched,
        agreements
    });
};

export default AgreementSearchForm;
