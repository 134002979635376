import _ from 'lodash';

import { SET_TABLE_SORT_COLUMN } from '../actions/table_sort_order';
import { RESET_REDUCER } from '../actions/resetReducers';

const updateSortOrders = (state, table, column) => {
    let new_state = _.map(state, _.clone);
    let index = _.findIndex(new_state, x => x.table === table);
    if (index === -1) {
        new_state.push({ table, column, sort_asc: true });
    } else {
        let existing = new_state[index];
        if (existing.column === column) {
            existing.sort_asc = !existing.sort_asc;
        } else {
            existing.sort_asc = true;
            existing.column = column;
        }
    }
    return new_state;
};

const initialState = [{ table: 'TABLE_NAME_PERSON_LIST', column: 'firstName', sort_asc: true }];

export function tableSortOrders(state = initialState, action) {
    switch (action.type) {
        case SET_TABLE_SORT_COLUMN:
            return updateSortOrders(state, action.table, action.column);
        case RESET_REDUCER:
            return initialState;
        default:
            return state;
    }
}
