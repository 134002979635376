import React from 'react';
import { connect } from 'react-redux';
import ErrorAlert from './ErrorAlert';
import MapArray from '../common/MapArray';

class ErrorsList extends React.Component {
    render() {
        const { errors } = this.props;

        return process.env.NODE_ENV === 'development' ? (
            <MapArray from={errors}>
                <ErrorAlert />
            </MapArray>
        ) : (
            <div />
        );
    }
}

const mapStateToProps = state => ({
    errors: state.errors
});

const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorsList);
