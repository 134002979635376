export const SET_SHOW_COMMUNICATION_TYPE_ON_CALENDAR = 'SET_SHOW_COMMUNICATION_TYPE_ON_CALENDAR';
export const SET_SHOW_ON_AGENDA = 'SET_SHOW_ON_AGENDA';
export const SET_OPEN_IN_MODALS_OR_IN_PAGE = 'SET_OPEN_IN_MODALS_OR_IN_PAGE';
export const SET_CALENDAR_FILTER = 'SET_CALENDAR_FILTER';

export const setShowCommunicationTypeOnCalendar = (type, showOnCalendar) => dispatch =>
    dispatch({
        type: SET_SHOW_COMMUNICATION_TYPE_ON_CALENDAR,
        data: { type, showOnCalendar }
    });

export const setShowOnAgenda = (filter, type, show) => dispatch =>
    dispatch({
        type: SET_SHOW_ON_AGENDA,
        data: { filter, type, show }
    });

export const setCalendarFilter = (filterName, optionName, show) => dispatch =>
    dispatch({
        type: SET_CALENDAR_FILTER,
        data: { filterName, optionName, show }
    });

export const setOpenInModals = openInModals => dispatch =>
    dispatch({
        type: SET_OPEN_IN_MODALS_OR_IN_PAGE,
        data: openInModals
    });
