import { handleErrorWithToastUnlessUnauthorised, makePostRequest } from '../apiHelpers';
import { ENDPOINTS } from '../constants';
import { isLoading } from '../../Loading/isLoading';

const type = ENDPOINTS.API.TEMPLATE_TEST;

export const apiTemplateTest = data => async (dispatch, getState) => {
    isLoading(dispatch, type, true, {});
    makePostRequest(['template', 'test'], undefined, data)
        .then(({ data }) => {
            saveByteArray('Template Test', base64ToArrayBuffer(data));
            isLoading(dispatch, type, false, {});
        })
        .catch(error => handleErrorWithToastUnlessUnauthorised(dispatch, getState, error, type, 'Template Test'));
};

const base64ToArrayBuffer = base64 => {
    const binaryString = window.atob(base64);
    const binaryLength = binaryString.length;
    const byteArray = new Uint8Array(binaryLength);

    for (let i = 0; i < binaryLength; i++) {
        byteArray[i] = binaryString.charCodeAt(i);
    }

    return byteArray;
};

const saveByteArray = (fileName, byte) => {
    const blob = new Blob([byte], { type: 'application/pdf' });
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
};
