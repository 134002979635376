import React from 'react';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { connect } from 'react-redux';
import { apiAddendumDetailUpdate } from '../../../actions/Api/AgreementHeader/apiAddendumDetailUpdate';
import * as Yup from 'yup';
import ReactiveForm from '../../common/ReactiveForm';
import { Alert, Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import useModalForm from '../../common/Hooks/useModalForm';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import _ from 'lodash';
import agreementAddendumDetailsColumnDefs from '../../AgreementAddendumDetails/agreementAddendumDetailsColumnDefs';
import useProductOptions from '../../AgreementDetails/Hooks/useProductOptions';
import useProductFamilyOptions from '../../AgreementDetails/Hooks/useProductFamilyOptions';

export const selectFamliiyValidationSchema = Yup.object()
    .shape({
        productFamilyId: Yup.number()
            .required('You must select a product family')
            .min(1, 'You must select a product family'),
        applyToProductId: Yup.number()
            .required('You must select a product')
            .min(1, 'You must select a product'),
        discountUnits: Yup.string().required('A Discount unit is required')
    })
    .test('atleastOneDiscountAdded', function(value) {
        const manufacturerDiscountValue = _.get(value, 'manufacturerDiscount');
        const dealerDiscountValue = _.get(value, 'dealerDiscount');

        if (manufacturerDiscountValue === undefined && dealerDiscountValue === undefined) {
            return this.createError({
                // Formik will use the error "path" as the key in the errors; set this to whatever you want
                path: agreementAddendumDetailsColumnDefs.manufacturerDiscount.field,
                message: 'At least one of the discount fields must have a value'
            });
        }

        return true;
    });

const AddendumApplyMinimumDiscountToProductButton = ({
    agreementHeaderId,
    save,
    resources,
    newProduct,
    productFamily
}) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();

    const productOptions = useProductOptions(newProduct, true);
    const productFamilyOptions = useProductFamilyOptions(productFamily, true);

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle}>
                <AppModalHeader toggle={toggle}>Apply Minimum Discount to Product</AppModalHeader>
                {isOpen ? (
                    <ModalBody>
                        <Alert color={'primary'}>
                            The selected product will be updated in all addended agreements. If a manufacturer discount
                            is set then any associated agreements with a lower manufacturer discount will be increased
                            to match the provided discount. The same applies to the dealer discount.
                        </Alert>
                        <ReactiveForm
                            initialValues={formValue}
                            resources={resources}
                            cancel={toggle}
                            handleSubmit={form => {
                                save(
                                    {
                                        ...form,
                                        agreementHeaderId,
                                        addendumChangeType: 'ApplyMinimumDiscountToProduct'
                                    },
                                    'Success',
                                    'Minimum Discount Applied',
                                    toggle
                                );
                            }}
                            validationSchema={selectFamliiyValidationSchema}
                        >
                            <ReactiveForm.Section>
                                <ReactiveForm.Choicelist
                                    columnDef={basicColumnDef('Product Family', 'productFamilyId')}
                                    placeholder="Product Family"
                                    options={productFamilyOptions}
                                />
                                <ReactiveForm.Choicelist
                                    columnDef={agreementAddendumDetailsColumnDefs.applyToProductId}
                                    placeholder="Product Id"
                                    options={({ values }) => {
                                        return productOptions.filter(x => x.productFamilyId === values.productFamilyId);
                                    }}
                                />
                                <ReactiveForm.Text
                                    columnDef={agreementAddendumDetailsColumnDefs.dealerDiscount}
                                    placeholder="Minimum Dealer Discount"
                                />
                                <ReactiveForm.Text
                                    columnDef={agreementAddendumDetailsColumnDefs.manufacturerDiscount}
                                    placeholder="Minimum Manufacturer Discount"
                                />
                                <ReactiveForm.Choicelist
                                    columnDef={agreementAddendumDetailsColumnDefs.discountUnits}
                                    placeholder="Discount Unit"
                                />
                            </ReactiveForm.Section>
                        </ReactiveForm>
                    </ModalBody>
                ) : null}
            </Modal>
            <ActionButton
                buttonText={'Apply Minimum Discount To Product'}
                fontawesomeIcon="fa-edit"
                bootstrapColor="secondary"
                onClickAction={() => openModal({})}
            />
        </React.Fragment>
    );
};

const mapStateToProps = ({ newProduct, productFamily, resources }) => ({
    newProduct,
    productFamily,
    resources
});

const mapDispatchToProps = dispatch => ({
    save: (detail, successTitle, successMessage, callback) =>
        dispatch(apiAddendumDetailUpdate(detail, successTitle, successMessage, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddendumApplyMinimumDiscountToProductButton);
