import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const makeSelector = () =>
    createSelector(
        state => state.agreementHeaders,
        (_, agreementHeaderId) => agreementHeaderId,
        (agreementHeaders, agreementHeaderId) => agreementHeaders.find(e => e.id === agreementHeaderId)
    );

export const useAgreement = agreementHeaderId => {
    const selector = useMemo(makeSelector, []);
    return useSelector(state => selector(state, agreementHeaderId));
};
