import React from 'react';
import Tabs, { TabsButtons, TabsContent, TabsGroup, TabsTab } from '../../common/Tabs';
import BaseCard from '../../common/Card/BaseCard';
import UpdateFleetProfileEditForm from '../../FleetProfile/Forms/UpdateFleetProfileEditForm';
import CompanyBandingsTable from '../../Banding/Tables/CompanyBandingsTable';
import { useCompanyBandingsContext } from '../../Company/Provider/CompanyBandingsProvider';
import useToggleOpen from '../../common/Hooks/useToggleOpen';
import { AddTableRowButton, FilterTableRowButton } from '../../common/Buttons/TableButtons';

export default function MeetingTabs() {
    const { bandings, createBanding, companyId } = useCompanyBandingsContext();
    const { toggle: toggleBanding, isOpen: isBandingOpen } = useToggleOpen();
    return (
        <BaseCard>
            <Tabs>
                <TabsGroup>
                    <TabsTab>Profile</TabsTab>
                    <TabsContent>
                        <UpdateFleetProfileEditForm />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTab>Bandings ({bandings.length})</TabsTab>
                    <TabsButtons>
                        <FilterTableRowButton onClick={toggleBanding} />
                        <AddTableRowButton onClick={() => createBanding(companyId)} />
                    </TabsButtons>
                    <TabsContent id="bandings">
                        <CompanyBandingsTable showFilter={isBandingOpen} />
                    </TabsContent>
                </TabsGroup>
            </Tabs>
        </BaseCard>
    );
}
