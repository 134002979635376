import React, { useContext } from 'react';
import AgreementHeaderTableCard from './AgreementHeaderTableCard';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';

export const filterAddendumAgreements = agreementHeaderId => agreementHeaders => {
    return agreementHeaders.filter(
        agreementHeader =>
            agreementHeader.addendumId === agreementHeaderId && agreementHeader.addendumStatus === 'Applied'
    );
};

const title = 'Applied to Agreements';

const columns = [
    agreementHeaderColumnDefs.companyName,
    agreementHeaderColumnDefs.startDate,
    agreementHeaderColumnDefs.endDate,
    agreementHeaderColumnDefs.agreementType
];

const AppliedToAgreementsTable = ({ isLoading }) => {
    const {
        agreementHeaderId,
        agreementState: { displayAddendumAgreementsTable }
    } = useContext(AgreementHeaderContext);

    if (!displayAddendumAgreementsTable) {
        return null;
    }

    return (
        <AgreementHeaderTableCard
            filter={filterAddendumAgreements(agreementHeaderId)}
            isLoading={isLoading}
            title={title}
            noItemsText="There are no agreements to show"
            columns={columns}
        />
    );
};

export default AppliedToAgreementsTable;
