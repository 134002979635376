import React from 'react';
import LoginForm from '../Forms/LoginForm';

export default class LoginPage extends React.Component {
    render = () => (
        <div className="login-background d-flex no-block justify-content-center align-items-center">
            <LoginForm className="bg-white p-4" />
        </div>
    );
}
