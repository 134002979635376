import React from 'react';
import { AppLogo } from '../app/AppLogo';

const LoginHeader = () => (
    <div className="w-100 text-center mb-4">
        <h6 className="mb-0">Sign in to</h6>
        <AppLogo />
    </div>
);

export default LoginHeader;
