import _ from 'lodash';

export const getChoiceList = (resources, choiceListName, mapChoiceValues = x => x) =>
    _.get(resources, `choiceList.${choiceListName}`, []).map(({ key, value }) => ({
        key,
        value: mapChoiceValues(value)
    }));

export const getChoiceListValue = (resources, choiceListName, value, mapChoiceValues = x => x) => {
    let trimValue = value ? value.toString().substring(value[0] === ',' ? 1 : 0) : value;
    trimValue = value
        ? trimValue.substring(0, trimValue[trimValue.length - 1] === ',' ? trimValue.length - 1 : trimValue.length)
        : value;
    const choice = getChoiceList(resources, choiceListName).find(x => x.key === trimValue);
    return mapChoiceValues(choice ? choice.value : trimValue);
};
