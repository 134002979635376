import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import useToggleOpen from '../Hooks/useToggleOpen';

const CardDropDownButton = ({ items }) => {
    const { toggle, isOpen } = useToggleOpen(false);

    if (!items || items.length === 0) {
        return null;
    }

    return (
        <Dropdown inNavbar isOpen={isOpen} toggle={toggle}>
            <DropdownToggle
                tag="span"
                className="mx-1 simple-card-dimmed text-primary hover-pointer"
                data-toggle="dropdown"
                aria-expanded={isOpen}
            >
                <i className="fa d-inline fa-lg fa-ellipsis-h" />
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem header>Actions</DropdownItem>
                <DropdownItem divider />
                {items
                    .filter(item => !item.hidden)
                    .map(item => (
                        <DropdownItem key={item.text} onClick={() => item.onClick()}>
                            <i className={`fa d-inline ${item.fontAwesomeName} mr-3`} />
                            {item.text}
                        </DropdownItem>
                    ))}
            </DropdownMenu>
        </Dropdown>
    );
};

CardDropDownButton.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string.isRequired,
            fontAwesomeName: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
            hidden: PropTypes.bool
        })
    ).isRequired
};

export default CardDropDownButton;
