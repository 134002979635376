import * as yup from 'yup';
import eventDef from '../eventDef';
import _ from 'lodash';

const validation = yup
    .object()
    .shape({
        eventType: yup.string(),
        startDate: yup.date().required('Please enter a start date'),
        endDate: yup.date().required('Please select an end date'),
        body: yup.string()
    })
    .test('endDate_Before_startDate', function(value) {
        if (_.get(value, 'endDate') < _.get(value, 'startDate')) {
            return this.createError({
                path: eventDef.endDate.field,
                message: 'The end date must be after the start date'
            });
        }

        return true;
    });

export default validation;
