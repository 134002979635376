import { makeGetRequest } from '../apiHelpers';

export const apiDownloadAgreementEvidence = evidence => async (dispatch, getState) => {
    const evidenceId = evidence.id;
    const { data: downloadUrl } = await makeGetRequest(['AgreementEvidence', 'download'], { evidenceId });

    const downloadLink = document.createElement('a');
    downloadLink.href = downloadUrl;
    downloadLink.download = evidence.fileName;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};
