import { ENDPOINTS } from '../../../actions/Api/constants';
import _ from 'lodash';
import { RESET_REDUCER } from '../../../actions/resetReducers';

const initialState = [];

export default (state = initialState, { type, data }) => {
    switch (type) {
        case ENDPOINTS.API.COMPANY_DATA_GET:
            return data.fetchDemos
                ? [...state.filter(x => _.get(x, 'companyId') !== data.companyId), ...data.demos]
                : state;
        case RESET_REDUCER:
            return initialState;
        default:
            return state;
    }
};
