import React, { useReducer } from 'react';
import { connect } from 'react-redux';
import { agreementsQuery } from '../../../actions/Api/AgreementHeader/apiAgreementFilter';
import _ from 'lodash';

function getValue(searchObject, field, type) {
    const path = `${field}.value`;
    if (type === 'string') {
        return _.get(searchObject, path, []);
    }
    if (type === 'bool') {
        return _.get(searchObject, path, false);
    }
    if (type === 'includes') {
        const value = _.get(searchObject, path, {});
        return Object.entries(value)
            .filter(([key, value]) => !!value)
            .reduce(
                (acc, [key, value]) => {
                    acc[value].push(key);
                    return acc;
                },
                { included: [], excluded: [] }
            );
    }
}

export const AgreementHeaderSearchContext = React.createContext({
    addendumAgreementSummaries: {},
    newProduct: []
});

const useSearchReducerInitialState = {
    agreements: [],
    hasSearched: false,
    isLoading: false
};

const searchReducer = (state, action) => {
    switch (action.type) {
        case 'setIsSearching':
            return { ...state, isLoading: true, hasSearched: true };

        case 'setSearchResults':
            const agreements = _.get(action, 'data.agreementHeaders', []);
            return { ...state, agreements, isLoading: false };

        case 'removeItemsFromSearchResults':
            return {
                ...state,
                agreements: [...state.agreements.filter(s => !action.ids.includes(s.id))]
            };

        default:
            throw new Error('Unexpected action');
    }
};

const useSearchReducer = () => {
    const [state, dispatch] = useReducer(searchReducer, useSearchReducerInitialState);

    return {
        state,
        setIsSearching: isLoading => dispatch({ type: 'setIsSearching', isLoading }),
        setSearchResults: data => dispatch({ type: 'setSearchResults', data }),
        removeItemsFromSearchResults: ids => dispatch({ type: 'removeItemsFromSearchResults', ids })
    };
};

const AgreementHeaderSearchProvider = ({
    newProduct,
    title,
    columns,
    checkboxColumn = false,
    online,
    children,
    search,
    productFamily,
    resources
}) => {
    const {
        state: { isLoading, agreements, hasSearched },
        setIsSearching,
        setSearchResults,
        removeItemsFromSearchResults
    } = useSearchReducer();

    return (
        <AgreementHeaderSearchContext.Provider
            value={{
                newProduct,
                agreements,
                isLoading,
                hasSearched,
                online,
                search: searchObject => {
                    setIsSearching();
                    search(searchObject, data => {
                        setSearchResults(data);
                    });
                },
                removeItemsFromSearchResults,
                productFamily,
                resources,
                title,
                columns,
                checkboxColumn
            }}
        >
            {children}
        </AgreementHeaderSearchContext.Provider>
    );
};

const makeMapStateToProps = () => {
    return state => ({
        newProduct: state.newProduct,
        online: state.offline.online,
        productFamily: state.productFamily,
        resources: state.resources
    });
};

const mapDispatchToProps = dispatch => ({
    search: (searchObject, callback) => {
        const companyName = getValue(searchObject, 'companyName', 'string');
        const agreementType = getValue(searchObject, 'agreementType', 'includes');
        const excludeAgreementsWithAnAddendumId = getValue(searchObject, 'excludeAgreementsWithAnAddendumId', 'bool');
        const status = getValue(searchObject, 'status', 'includes');
        const product = getValue(searchObject, 'product', 'includes');
        const termsType = getValue(searchObject, 'termsType', 'includes');

        dispatch(
            agreementsQuery()
                .filterCompanyName(companyName.length ? companyName : undefined)
                .agreementTypeFilter(agreementType)
                .excludeAgreementsWithAnAddendumId(excludeAgreementsWithAnAddendumId)
                .statusFilter(status)
                .productFilter(product)
                .termsTypeFilter(termsType)
                .useSearchReducer()
                .setCallback(callback)
                .run()
        );
    }
});

export default connect(
    makeMapStateToProps,
    mapDispatchToProps
)(AgreementHeaderSearchProvider);
