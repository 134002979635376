import React from 'react';
import BigCalendar from 'react-big-calendar';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLoadingState } from '../../../reducers/Rest/loadingStates';
import { ENDPOINTS } from '../../../actions/Api/constants';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import moment from 'moment';
import CalendarEventWrapper from "../Components/CalendarEventWrapper";

const localizer = BigCalendar.momentLocalizer(moment);

const CommunicationCalendar: React.SFC<any> = props => {
	const {
		events,
		defaultView,
		eventStyleGetter,
		onNavigate,
		defaultDate,
		views,
		onView,
		loadingStates
	} = props;

	const isLoading = getLoadingState(loadingStates, ENDPOINTS.API.COMMUNICATIONS_FILTER).isLoading;

	return (
		<>
			<ShowLoadingSpinner isLoading={isLoading} title="Loading Calendar" />
			<BigCalendar
				localizer={localizer}
				selectable
				events={events}
				min={new Date(2017, 10, 0, 8, 0, 0)}
				max={new Date(2017, 10, 0, 18, 30, 0)}
				timeslots={1}
				defaultView={defaultView}
				eventPropGetter={eventStyleGetter}
				views={views}
				step={60}
				components={{
					event: CalendarEventWrapper,
				}}
				onSelectSlot={() => {}}
				onNavigate={onNavigate}
				onView={onView}
				showMultiDayTimes
				defaultDate={defaultDate}
				onSelectEvent={() => {}}
			/>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	user: state.user,
	offline: state.offline,
	loadingStates: state.loadingStates,
});

export default connect(
	mapStateToProps
)(withRouter(CommunicationCalendar));
