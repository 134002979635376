const navigationItems = {
    home: {},
    companies: {},
    calendar: {},
    agreements: {},
    agreementsManagement: {},
    changePassword: {},
    support: {},
    templates: {}
};

export const getNavigationItems = user => navigationItems;
