import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const makeBandingsByCompanyByIdSelector = () =>
    createSelector(
        state => state.bandings,
        (_, companyId) => companyId,
        (bandings, companyId) =>
            _.orderBy(bandings.filter(banding => banding.companyId === companyId), 'createdDate', 'desc')
    );

export const useBandingsByCompanyById = companyId => {
    const selectCommunications = useMemo(makeBandingsByCompanyByIdSelector, []);
    return useSelector(state => selectCommunications(state, companyId));
};
