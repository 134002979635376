import React, { useState } from 'react';
import FormikSearchHolder from './FormikSearchHolder';
import FormikSelectChoicelist from './FormikSelectChoicelist';
import { Alert, Button } from 'reactstrap';

export const FormikSearchOptionsTableCheckbox = ({ id, onChange, checked, label }) => {
    return (
        <div className="custom-control custom-checkbox">
            <input
                id={id}
                type="checkbox"
                className="custom-control-input form-check-input"
                onChange={onChange}
                checked={checked}
            />
            <label className="custom-control-label" htmlFor={id}>
                {label}
            </label>
        </div>
    );
};

const FormikSearchOptionsTableRow = ({ field, row, value, setValue }) => {
    return (
        <tr>
            <td>{row.value}</td>
            <td>
                <FormikSearchOptionsTableCheckbox
                    id={`includedOption${field}${row.value}`}
                    onChange={() => setValue(row.key, 'included')}
                    label=""
                    checked={value[row.key] === 'included'}
                />
            </td>
            <td>
                <FormikSearchOptionsTableCheckbox
                    id={`excludedOption${field}${row.value}`}
                    onChange={() => setValue(row.key, 'excluded')}
                    label=""
                    checked={value[row.key] === 'excluded'}
                />
            </td>
        </tr>
    );
};

export const FormikSearchOptionsTableSelectGrouped = ({
    formikProps,
    formikProps: { values, handleChange, setFieldValue },
    options: {
        group: { title: groupTitle, options: groups, emptyOption: emptyGroupOption },
        item: { title, options, emptyOption },
        addButtonText,
        noRowsText
    },
    field,
    nothingSelectedText,
    includedText,
    excludedText,
    columnNames,
    disabled
}) => {
    const [rows, setRows] = useState([]);

    const optionField = `option_${field}`;
    const groupField = `group_${field}`;
    const optionFieldValue = values[optionField];
    const groupFieldValue = values[groupField];
    const filteredOptions = options.filter(
        option => option.groupId === groupFieldValue && !rows.find(row => row.key === option.key)
    );

    return (
        <FormikSearchOptionsTable
            formikProps={formikProps}
            rows={rows}
            field={field}
            nothingSelectedText={nothingSelectedText}
            includedText={includedText}
            excludedText={excludedText}
            columnNames={columnNames}
            disabled={disabled}
            noRowsText={noRowsText}
            filter={
                <div className="">
                    <div className="mt-2 w-75">
                        <FormikSelectChoicelist
                            onFormValueChanged={(values, fieldName, value, setFieldValue) => {
                                setFieldValue(optionField, null, true);
                            }}
                            emptyOption={emptyGroupOption}
                            options={groups}
                            hideLabel={true}
                            title={groupTitle}
                            fieldName={`group_${field}`}
                            {...formikProps}
                        />
                    </div>
                    <div className="mt-2 w-75">
                        <FormikSelectChoicelist
                            className="flex-grow-1"
                            emptyOption={emptyOption}
                            options={filteredOptions}
                            hideLabel={true}
                            title={title}
                            fieldName={optionField}
                            {...formikProps}
                        />
                    </div>
                    <div className="d-flex flex-row-reverse mb-3 w-75">
                        <Button
                            className="-right"
                            disabled={!optionFieldValue}
                            onClick={() => {
                                setRows([...rows, options.find(option => option.key === optionFieldValue)]);
                                setFieldValue(optionField, null, true);
                            }}
                            title={addButtonText}
                        >
                            {addButtonText}
                        </Button>
                    </div>
                </div>
            }
        />
    );
};

const getSetValue = (setFieldValue, values, field, value) => (key, option) => {
    let newValue = values[field];
    newValue.value[key] = newValue.value[key] === option ? undefined : option;
    setFieldValue(field, newValue);
};

const clearType = (setFieldValue, field, values, type) => {
    const newValue = Object.entries(values).reduce((acc, [key, value]) => {
        if (value === type) {
            return acc;
        }
        acc[key] = value;
        return acc;
    }, {});

    setFieldValue(`${field}.value`, newValue);
};

const setType = (setFieldValue, field, rows, type) => {
    const newValue = rows.reduce((acc, row) => {
        acc[row.key] = type;
        return acc;
    }, {});

    setFieldValue(`${field}.value`, newValue);
};

const mapValuesOfTypeToDescriptions = (type, value, rows) =>
    Object.entries(value)
        .filter(([key, value]) => value === type)
        .map(([key, value]) => rows.find(row => row.key.toString() === key.toString()).value)
        .join(', ');

const SelectAllNoneButtons = ({ setFieldValue, field, rows, value, type }) => {
    const allChecked = Object.values(value).filter(x => x === type).length === rows.length;
    const id = `SelectAll${field}${type}`;
    return (
        <FormikSearchOptionsTableCheckbox
            id={id}
            onChange={() =>
                allChecked ? clearType(setFieldValue, field, value, type) : setType(setFieldValue, field, rows, type)
            }
            label="(Select All)"
            checked={allChecked}
        />
    );
};

const FormikSearchOptionsTable = ({
    formikProps: { values, handleChange, setFieldValue },
    rows,
    noRowsText,
    field,
    nothingSelectedText,
    includedText,
    excludedText,
    columnNames,
    filter
}) => {
    const { value, disabled, collapsed } = values[field];

    const setValue = getSetValue(setFieldValue, values, field, value);

    const includedDescriptions = mapValuesOfTypeToDescriptions('included', value, rows);
    const excludedDescriptions = mapValuesOfTypeToDescriptions('excluded', value, rows);

    return (
        <FormikSearchHolder
            description={
                <span>
                    {!includedDescriptions.length && !excludedDescriptions.length ? (
                        nothingSelectedText
                    ) : (
                        <>
                            {includedDescriptions.length ? includedText(includedDescriptions) : null}
                            {includedDescriptions.length && excludedDescriptions.length ? <br /> : null}
                            {excludedDescriptions.length ? excludedText(excludedDescriptions) : null}
                        </>
                    )}
                </span>
            }
            collapsed={collapsed}
            disabled={disabled}
        >
            <>
                {filter ? filter : null}
                {rows.length ? (
                    <table className="table table-sm w-75">
                        <thead>
                            <tr>
                                <th>{columnNames[0]}</th>
                                <th>{columnNames[1]}</th>
                                <th>{columnNames[2]}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td>
                                    <SelectAllNoneButtons
                                        setFieldValue={setFieldValue}
                                        type={'included'}
                                        field={field}
                                        rows={rows}
                                        value={value}
                                    />
                                </td>
                                <td>
                                    <SelectAllNoneButtons
                                        setFieldValue={setFieldValue}
                                        type={'excluded'}
                                        field={field}
                                        rows={rows}
                                        value={value}
                                    />
                                </td>
                            </tr>
                            {rows.map(row => (
                                <FormikSearchOptionsTableRow
                                    field={field}
                                    key={row.key}
                                    row={row}
                                    setValue={setValue}
                                    value={value}
                                />
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <Alert color="info" className="w-75">
                        {noRowsText}
                    </Alert>
                )}
            </>
        </FormikSearchHolder>
    );
};

export default FormikSearchOptionsTable;
