import React from 'react';
import OpportunitiesTableCard from './OpportunitiesTableCard';
import { useCompanyOpportunitiesContext } from '../../Company/Provider/CompanyOpportunitiesProvider';
import opportunity from '../opportunity';

export default ({ showFilter }) => {
    const { opportunities, isLoading } = useCompanyOpportunitiesContext();
    return (
        <OpportunitiesTableCard
            showFilter={showFilter}
            rows={opportunities}
            isLoading={isLoading}
            title="Opportunities"
            tableIsNotInCard={true}
            columns={[
                opportunity.model,
                opportunity.opportunitySize,
                opportunity.description,
                opportunity.stage,
                opportunity.wonLostReason,
                opportunity.wonLostOther,
                opportunity.dueDate,
                opportunity.closedDate
            ]}
        />
    );
};
