import React, { useContext } from 'react';
import { DeleteTableRowButton } from '../../common/Buttons/TableButtons';
import { connect } from 'react-redux';
import ConfirmButton from '../../common/Buttons/ConfirmButton';
import { AgreementHeaderContext } from '../../AgreementHeader/Provider/AgreementHeaderProvider';
import { apiAgreementDetailUpdateMultiple } from '../../../actions/Api/AgreementDetail/apiAgreementDetailUpdate';

const AgreementDetailDeleteButton = ({ detail, hideDeleteButton, deleteAddendumDetail }) => {
    const { isLive } = useContext(AgreementHeaderContext);

    if (isLive || !!detail.deleted) {
        return null;
    }

    return (
        <div style={{ float: 'right' }}>
            {!hideDeleteButton ? (
                <ConfirmButton
                    popoverId={`d${detail.id}`}
                    title="Delete Detail"
                    isWorking={false}
                    isWorkingTitle="Deleting Detail"
                    onConfirm={() => {
                        deleteAddendumDetail({ ...detail, deleted: 1 }, 'Success', 'Details Deleted');
                    }}
                >
                    {({ toggle }) => <DeleteTableRowButton id={`d${detail.id}`} onClick={toggle} />}
                </ConfirmButton>
            ) : null}
        </div>
    );
};
const mapStateToProps = state => ({
    agreementHeaders: state.agreementHeaders
});

const mapDispatchToProps = dispatch => ({
    deleteAddendumDetail: (addendumDetail, successTitle, successMessage) =>
        dispatch(apiAgreementDetailUpdateMultiple([addendumDetail], successTitle, successMessage))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgreementDetailDeleteButton);
