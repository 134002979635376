import useModalForm from "../Hooks/useModalForm";
import React from "react";
import {Modal, ModalBody} from "reactstrap";
import {AppModalHeader} from "../AppModalHeader";
import CcBccForm from "../../AgreementDetails/Forms/ccBccForm";

const CcBccModal = ({agreementHeaderIds, children}) => {
    const {toggle, isOpen, openModal} = useModalForm();
    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
                <AppModalHeader toggle={toggle}>
                    Email Agreement
                </AppModalHeader>
                <ModalBody>
                    <CcBccForm agreementHeaderIds={agreementHeaderIds} toggle={toggle}/>
                </ModalBody>
            </Modal>
            {children({
                triggerModal: () => openModal(),
                toggle
            })}
        </React.Fragment>
    )
}

export default CcBccModal;