import React from 'react';
import PropTypes from 'prop-types';

export default class MapArray extends React.Component {
    static propTypes = {
        from: PropTypes.array.isRequired,
        children: PropTypes.element.isRequired,
        map: PropTypes.func,
        mapKey: PropTypes.func,
        data: PropTypes.any
    };

    static defaultProps = {
        map: (item, key, data) => item,
        mapKey: (item, key) => key
    };

    shouldComponentUpdate(nextProps) {
        return nextProps.data !== this.props.data || nextProps.from !== this.props.from;
    }

    render() {
        const { from, children, map, mapKey, data } = this.props;
        const child = React.Children.only(children);

        const mapped = from.map((item, key) =>
            React.cloneElement(child, {
                key: mapKey(item, key),
                ...map(item, key, data)
            })
        );

        return mapped;
    }
}
