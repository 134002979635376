import React from 'react';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Modal, ModalBody } from 'reactstrap';
import useModalForm from '../../common/Hooks/useModalForm';
import { AppModalHeader } from '../../common/AppModalHeader';
import * as Yup from 'yup';
import ReactiveForm from '../../common/ReactiveForm';
import agreementDetailColumnDefs from '../../AgreementDetails/agreementDetailColumnDefs';
import { apiAddendumDetailUpdate } from '../../../actions/Api/AgreementHeader/apiAddendumDetailUpdate';
import useProductOptions from '../../AgreementDetails/Hooks/useProductOptions';
import useProductFamilyOptions from '../../AgreementDetails/Hooks/useProductFamilyOptions';

const validationSchema = Yup.object().shape({
    productFamilyName: Yup.number()
        .required('You must select a product family')
        .min(1, 'You must select a product family'),
    productId: Yup.number()
        .required('You must select a product')
        .min(1, 'You must select a product')
});

export default function AddendumRemoveProductButton({ agreementHeaderId }) {
    const { toggle, isOpen, formValue, openModal } = useModalForm();

    const dispatch = useDispatch();
    const newProduct = useSelector(state => state.newProduct);
    const productFamily = useSelector(state => state.productFamily);
    const resources = useSelector(state => state.resources);
    const productOptions = useProductOptions(newProduct, true);
    const productFamilyOptions = useProductFamilyOptions(productFamily, true);

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle}>
                <AppModalHeader toggle={toggle}>Remove Product</AppModalHeader>
                {isOpen ? (
                    <ModalBody>
                        <ReactiveForm
                            initialValues={formValue}
                            resources={resources}
                            formName="SelectProductForm"
                            cancel={toggle}
                            validationSchema={validationSchema}
                            handleSubmit={({ agreementHeaderId, productId }) => {
                                const detail = {
                                    agreementHeaderId,
                                    addendumChangeType: 'RemoveProduct',
                                    applyToProductId: productId
                                };
                                dispatch(apiAddendumDetailUpdate(detail, 'Success', 'Product Removed', toggle));
                            }}
                        >
                            <ReactiveForm.Section>
                                <Alert color={'primary'}>
                                    The selected product will be removed from all addended agreements
                                </Alert>

                                <ReactiveForm.Choicelist
                                    columnDef={agreementDetailColumnDefs.productFamilyName}
                                    options={productFamilyOptions}
                                />
                                <ReactiveForm.Choicelist
                                    columnDef={agreementDetailColumnDefs.productId}
                                    options={({ values }) =>
                                        productOptions.filter(x => x.productFamilyId === values.productFamilyName)
                                    }
                                />
                            </ReactiveForm.Section>
                        </ReactiveForm>
                    </ModalBody>
                ) : null}
            </Modal>
            <ActionButton
                buttonText={'Remove Product from Agreements'}
                fontawesomeIcon="fa-minus"
                bootstrapColor="secondary"
                onClickAction={() => openModal({ agreementHeaderId })}
            />
        </React.Fragment>
    );
}
