import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormGroup, Input } from 'reactstrap';
import { setTableSortColumn } from '../../../actions/table_sort_order';
import { FieldLabel } from '../DisplayField/FieldLabel';

const NOTHING_SELECTED = 'Sort By...';
//Provides a sort functionality for flip table when the screen is small.
class SortDropdown extends React.Component {
    filterChanged = ({ target: { value } }) =>
        this.props.setTableSortColumn(this.props.tableName, value.replace(/\s/g, ''));

    render = () => {
        const { headings, tableName, tableSortOrders } = this.props;
        const selectedValue = this.getSortColumnName(tableSortOrders, tableName);

        return (
            <FormGroup>
                <FieldLabel>Sort By</FieldLabel>
                <Input type="select" name="select" id="roleFilter" onChange={this.filterChanged} value={selectedValue}>
                    {selectedValue === NOTHING_SELECTED ? (
                        <option value={NOTHING_SELECTED}>{NOTHING_SELECTED}</option>
                    ) : null}
                    {headings.map(heading => (
                        <option value={heading.field} key={heading.field} label={heading.value} />
                    ))}
                </Input>
            </FormGroup>
        );
    };

    getSortColumnName = (sortRules, tableName) => {
        let index = _.findIndex(sortRules, x => x.table === tableName);
        if (index === -1) {
            return NOTHING_SELECTED;
        }
        return sortRules[index].column;
    };
}
const mapStateToProps = ({ tableSortOrders }) => ({ tableSortOrders });

const mapDispatchToProps = dispatch => ({
    setTableSortColumn: (table, column) => dispatch(setTableSortColumn(table, column))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SortDropdown);
