import React from 'react';
import _ from 'lodash';
import { useAgreementStatusChangesContext } from '../Provider/AgreementStatusChangesProvider';
import SimpleCard from '../../common/Card/SimpleCard';
import AgreementStatusChange from './AgreementStatusChange';
import { useChoiceList } from '../../common/Hooks/useChoiceList';
import agreementStatusChangeColumnDefs from '../agreementStatusChangeColumnDefs';

const AgreementHistory = () => {
    const { agreementStatusChanges } = useAgreementStatusChangesContext();

    const userChoiceList = useChoiceList(agreementStatusChangeColumnDefs.updatedBy.choiceList);
    const statusFromChoiceList = useChoiceList(agreementStatusChangeColumnDefs.statusFrom.choiceList);
    const statusToChoiceList = useChoiceList(agreementStatusChangeColumnDefs.statusTo.choiceList);

    if (agreementStatusChanges === undefined || agreementStatusChanges.length === 0) return null;

    return (
        <SimpleCard title="Agreement History">
            {_.orderBy(agreementStatusChanges, 'updatedDate', 'desc').map(agreementStatusChange => (
                <AgreementStatusChange
                    key={agreementStatusChange.id}
                    agreementStatusChange={agreementStatusChange}
                    userChoiceList={userChoiceList}
                    statusFromChoiceList={statusFromChoiceList}
                    statusToChoiceList={statusToChoiceList}
                />
            ))}
        </SimpleCard>
    );
};

export default AgreementHistory;
