export interface IAction {
    type: string;
    [propName: string]: any; // For now
}

export const ENDPOINTS = {
    API: {
        ADDRESS: 'ADDRESS',
        AGENDA_GET: 'API_AGENDA_GET',
        DEFAULT_TERMS: 'API_DEFAULT_TERMS',
        COMMUNICATION_GET: 'API_COMMUNICATION_GET',
        COMMUNICATION_POST: 'API_COMMUNICATION_POST',
        COMMUNICATION_POST_ROLLBACK: 'API_COMMUNICATION_POST_ROLLBACK',
        COMMUNICATION_POST_COMMIT: 'API_COMMUNICATION_POST_COMMIT',
        COMMUNICATIONS_FILTER: 'COMMUNICATIONS_FILTER',
        RESOURCE: 'RESOURCE',
        REGISTRATION_STATS_GET: 'REGISTRATION_STATS_GET',
        COMPANIES: 'COMPANIES',
        COMPANY: 'COMPANY',
        COMPANY_OFFLINE: 'COMPANY_OFFLINE',
        COMPANY_OFFLINE_COMMIT: 'COMPANY_OFFLINE_COMMIT',
        COMPANY_OFFLINE_ROLLBACK: 'COMPANY_OFFLINE_ROLLBACK',
        COMPANY_DATA_GET: 'COMPANY_DATA_GET',
        COMPANY_SEARCH: 'COMPANY_SEARCH',
        BANDING_POST: 'BANDING_POST',
        BANDING_POST_COMMIT: 'BANDING_POST_COMMIT',
        BANDING_POST_ROLLBACK: 'BANDING_POST_ROLLBACK',
        FLEET_PROFILE_POST: 'FLEET_PROFILE_POST',
        FLEET_PROFILE_POST_ITEM: 'FLEET_PROFILE_POST_ITEM',
        FLEET_PROFILE_POST_COMMIT: 'FLEET_PROFILE_COMMIT',
        FLEET_PROFILE_POST_ROLLBACK: 'FLEET_PROFILE_ROLLBACK',
        OPPORTUNITY_POST: 'OPPORTUNITY_POST',
        OPPORTUNITY_POST_COMMIT: 'OPPORTUNITY_POST_COMMIT',
        OPPORTUNITY_POST_ROLLBACK: 'OPPORTUNITY_POST_ROLLBACK',
        OPPORTUNITY_FILTER_COMMIT: 'OPPORTUNITY_FILTER_COMMIT',
        OPPORTUNITIES_BY_COMPANY_GET: 'OPPORTUNITIES_BY_COMPANY_GET',
        SUPPORT_REQUEST: 'SUPPORT_REQUEST',
        REPORTS: 'REPORTS',
        PERSON: 'API_PERSON',
        PERSON_POST: 'PERSON_POST',
        PERSON_POST_COMMIT: 'PERSON_POST_COMMIT',
        PERSON_POST_ROLLBACK: 'PERSON_POST_ROLLBACK',
        USER: 'API_USER',
        USERS: 'API_USERS',
        USERLOG_POST: 'API_USERLOG_POST',
        VISIT_ACTION_POST: 'API_VISIT_ACTION_POST',
        VISIT_ACTION_POST_COMMIT: 'API_VISIT_ACTION_POST_COMMIT',
        VISIT_ACTION_POST_ROLLBACK: 'API_VISIT_ACTION_POST_ROLLBACK',
        VISIT_ACTIONS_FILTER: 'API_VISIT_ACTIONS_FILTER',
        VISIT_ACTIONS_FILTER_COMMIT: 'API_VISIT_ACTIONS_FILTER_COMMIT',
        EVENT_FILTER: 'EVENT_FILTER',
        EVENT_POST: 'EVENT_POST',
        EVENT_POST_ROLLBACK: 'EVENT_POST_ROLLBACK',
        EVENT_POST_COMMIT: 'EVENT_POST_COMMIT',
        MEETING_FILTER: 'MEETING_FILTER',
        MEETING_GET: 'API_MEETING_GET',
        MEETING_POST: 'API_MEETING_POST',
        MEETING_POST_ROLLBACK: 'API_MEETING_POST_ROLLBACK',
        MEETING_POST_COMMIT: 'API_MEETING_POST_COMMIT',
        AGREEMENT_FILTER: 'AGREEMENT_FILTER',
        AGREEMENT_GET: 'AGREEMENT_GET',
        AGREEMENT_APPROVE: 'AGREEMENT_APPROVE',
        AGREEMENT_DECLINE: 'AGREEMENT_DECLINE',
        AGREEMENT_DELETE: 'AGREEMENT_DELETE',
        AGREEMENT_CANCEL: 'AGREEMENT_CANCEL',
        AGREEMENT_CREATE_DRAFT: 'AGREEMENT_CLONE',
        AGREEMENT_MAKE_TEMPLATE_EDITABLE: 'AGREEMENT_MAKE_TEMPLATE_EDITABLE',
        AGREEMENT_DETAIL_DELETE: 'AGREEMENT_DETAIL_DELETE',
        AGREEMENT_DETAIL_POST: 'AGREEMENT_DETAIL_POST',
        AGREEMENT_DETAIL_APPROVE: 'AGREEMENT_DETAIL_APPROVE',
        AGREEMENT_DETAIL_DECLINE: 'AGREEMENT_DETAIL_DECLINE',
        AGREEMENT_CREATE_TEMPLATE: 'AGREEMENT_CREATE_TEMPLATE',
        AGREEMENT_DOWNLOAD: 'AGREEMENT_DOWNLOAD',
        AGREEMENT_FILTER_BY_PRODUCT: 'AGREEMENT_FILTER_BY_PRODUCT',
        AGREEMENT_EVIDENCE_FILTER: 'AGREEMENT_EVIDENCE_FILTER',
        NOT_IN_REDUX: 'NOT_IN_REDUX',
        AGREEMENT_TEMPLATE_POST: 'AGREEMENT_TEMPLATE_POST',
        AGREEMENT_EVIDENCE_DOWNLOAD: 'AGREEMENT_EVIDENCE_DOWNLOAD',
        FINALISE_AGREEMENT: 'FINALISE_AGREEMENT',
        AGREEMENT_SET_ADDENDUM: 'AGREEMENT_SET_ADDENDUM',
        NEWPRODUCT_FILTER: 'NEWPRODUCT_FILTER',
        PRODUCT_FAMILY_FILTER: 'PRODUCT_FAMILY_FILTER',
        ADDENDUM_DETAIL_UPDATE: 'ADDENDUM_DETAIL_UPDATE',
        ADDENDUM_DETAIL_DELETE: 'ADDENDUM_DETAIL_DELETE',
        APPLY_ADDENDUM: 'APPLY_ADDENDUM',
        PROMOTION_GET: 'PROMOTION_GET',
        PRODUCTS_FILTER: 'PRODUCTS_FILTER',
        EMAIL_AGREEMENT_POST: 'EMAIL_AGREEMENT_POST',
        SWITCH_AGREEMENT_TEMPLATE: 'SWITCH_AGREEMENT_TEMPLATE',
        ADDENDUM_AGREEMENT_SEARCH: 'ADDENDUM_AGREEMENT_SEARCH',
        ADDENDUM_REMOVE_AGREEMENT: 'ADDENDUM_REMOVE_AGREEMENT',
        ADDENDUM_DETAIL_FILTER: 'ADDENDUM_DETAIL_FILTER',
        CREATE_PROMOTION_AGREEMENT_FOR_COMPANY: 'CREATE_PROMOTION_AGREEMENT_FOR_COMPANY',
        TEMPLATES_GET: 'TEMPLATES_GET',
        TEMPLATES_UPDATE: 'TEMPLATES_UPDATE',
        TEMPLATE_PUT: 'TEMPLATE_PUT',
        TEST_TEMPLATE_COMPANY_AGREEMENTS_FILTER: 'TEST_TEMPLATE_COMPANY_AGREEMENTS_FILTER',
        TEMPLATE_TEST: 'TEMPLATE_TEST'
    }
};
