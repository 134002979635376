import React from 'react';
import { MiniCalendarWeek } from './MiniCalendarWeek';
import moment from 'moment';

export const MiniCalendar = props => {
    const { month, highlightedDay, clickDay } = props;
    const startDay = moment()
        .startOf('week')
        .day();

    return (
        <table className="mini-calendar">
            <thead className="logo-font">
                <tr>
                    <DayTableHead day={startDay} />
                    <DayTableHead day={startDay + 1} />
                    <DayTableHead day={startDay + 2} />
                    <DayTableHead day={startDay + 3} />
                    <DayTableHead day={startDay + 4} />
                    <DayTableHead day={startDay + 5} />
                    <DayTableHead day={startDay + 6} />
                </tr>
            </thead>
            <tbody>
                {month.map((week, index) => (
                    <MiniCalendarWeek key={index} week={week} highlightedDay={highlightedDay} clickDay={clickDay} />
                ))}
            </tbody>
        </table>
    );
};
const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const DayTableHead = props => {
    const day = days[props.day % 7];
    return <th className={day === 'S' ? 'opacity-50' : ''}>{day}</th>;
};

export const generateFourWeeksCounts = () => {
    const weeks = [];
    let day = moment()
        //.startOf('month')
        .startOf('week');

    while (weeks.length < 4) {
        let week = [];
        for (let i = 0; i < 7; i++) {
            week.push({ day: day.clone(), count: 0 });
            day.add(1, 'day');
        }
        weeks.push(week);
    }
    return weeks;
};
