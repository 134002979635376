import React from 'react';
import ActionButton from '../../common/ActionButtons/ActionButton';
import CcBccModal from "../../common/Modal/ccBccModal";

export default function EmailAgreementButton({ agreementHeaderId }) {
    const agreementHeaderIds = [agreementHeaderId];

    return (
        <CcBccModal agreementHeaderIds={agreementHeaderIds}>
            {({ triggerModal }) => (
                <ActionButton
                    buttonText={'Email Agreement'}
                    fontawesomeIcon="fa-envelope-o"
                    bootstrapColor="secondary"
                    onClickAction={() => {
                        triggerModal();
                    }}
                />
            )}
        </CcBccModal>
    );
}
