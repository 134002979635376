import React, { useContext } from 'react';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';
import ShowAgreementTemplateFile from './ShowAgreementTemplateFile';
import UploadAgreementTemplate from './UploadAgreementTemplate';

const AgreementTemplateFile = () => {
    const {
        agreementHeaderId,
        agreementState: { isPromotion, isTemplate, templateFile }
    } = useContext(AgreementHeaderContext);

    return isPromotion || isTemplate ? (
        <ShowAgreementTemplateFile templateFile={templateFile}>
            <UploadAgreementTemplate agreementHeaderId={agreementHeaderId} />
        </ShowAgreementTemplateFile>
    ) : null;
};

export default AgreementTemplateFile;
