import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';
import { useAgreementByParentId } from '../Selectors/useAgreementsByParentId';

const AgreementLink = ({ addendumId, name }) => (
    <Link
        className="nav-link my-0 mx-2 p-0 d-inline-block"
        style={{ color: 'blue' }}
        to={`/dashboard/agreement/${addendumId}`}
    >
        {name}
    </Link>
);

const AgreementHeaderAppliedAddendumDetails = ({ addendumAgreement }) => {
    const {
        agreementHeader: { addendumId, addendumStatus, id }
    } = useContext(AgreementHeaderContext);

    const childAgreement = useAgreementByParentId(id);
    if (addendumStatus !== 'Applied' || !childAgreement) return null;
    return (
        <Alert>
            <div className="w-100">
                The addendum
                <AgreementLink addendumId={addendumId} name={addendumAgreement.name} />
                has been applied to this agreement.{' '}
                {childAgreement ? (
                    <>
                        Creating the new agreement{' '}
                        <AgreementLink addendumId={childAgreement.id} name={childAgreement.name} />
                    </>
                ) : null}
            </div>
        </Alert>
    );
};

export default AgreementHeaderAppliedAddendumDetails;
