import React from 'react';
import PropTypes from 'prop-types';

const SmallSaveCancelButtons = props => {
    const { showSave, showCancel, onCancel } = props;

    return (
        <div className="pull-right mt-2">
            {showSave && (
                <button className="btn btn-outline-secondary shadow-sm btn-sm " type="submit">
                    <i className={`text-primary d-inline fa fa-lg fa-check`} />
                </button>
            )}
            {showCancel && (
                <button
                    className="btn btn-outline-secondary ml-1 btn-sm "
                    type="button"
                    onClick={event => onCancel(event)}
                >
                    <i className={`text-danger d-inline fa fa-lg fa-times`} />
                </button>
            )}
        </div>
    );
};

SmallSaveCancelButtons.propTypes = {
    showSave: PropTypes.bool,
    showCancel: PropTypes.bool,
    onCancel: PropTypes.func
};
export default SmallSaveCancelButtons;
