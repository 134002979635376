import React from 'react';

export default ({ address: { postCode, address1 } }) => (
    <div className="mapouter">
        <div className="gmap_canvas">
            <iframe
                title="g map"
                width="100%"
                height="150px"
                style={{ border: 'none' }}
                id="gmap_canvas"
                src={`https://maps.google.com/maps?q=${postCode} ${address1}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
            />
        </div>
    </div>
);
