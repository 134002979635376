import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';

export const apiChangeTemplate = (
    agreementHeaderId,
    agreementTemplateId,
    successTitle,
    successMessage
) => async dispatch => {
    const type = ENDPOINTS.API.SWITCH_AGREEMENT_TEMPLATE;

    dispatch(
        apiGetPost(
            type,
            ['agreement', 'changetemplate', agreementHeaderId, agreementTemplateId],
            {},
            {},
            undefined,
            true,
            error => {
                toastr.error(type, `Response ${error.message}`);
            },
            undefined,
            successTitle,
            successMessage
        )
    );
};
