import {
    basicColumnDef,
    choiceListColumnDef,
    dateColumnDef,
    idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';
import React from 'react';
import ColumnTypeFormatCompanyName from '../common/Table/ColumnTypeFormatCompanyName';

const opportunity = {
    id: idColumnDef('id', 'opportunityId'),

    companyName: {
        ...basicColumnDef('Company Name', 'companyName'),
        mapping: (resources, values, field, action) => (values && values.company ? values.company.descriptor : ''),
        mapToDisplayedValue: (resources, values, field, actions) => (
            <ColumnTypeFormatCompanyName actions={actions} values={values} />
        ),
        valueToSortOnMapping: (resources, values) => (values && values.company ? values.company.descriptor : ''),
        searchable: true
    },
    description: {
        ...basicColumnDef('Description', 'description')
    },
    note: {
        ...basicColumnDef('Details', 'note'),
        type: 'textarea'
    },
    wonLostOther: {
        ...basicColumnDef('Lost Reason Other', 'wonLostOther'),
        type: 'textarea'
    },
    dueDate: {
        ...dateColumnDef('Due Date', 'dueDate', true, { displayEmptyAs: '-' })
    },
    closedDate: {
        ...dateColumnDef('Closed Date', 'closedDate', true, {
            displayEmptyAs: '-'
        })
    },
    model: {
        ...choiceListColumnDef('Model', 'model', 'opportunityModel', {
            multipleChoice: true
        }),
        emptyOption: 'Select a model...'
    },
    stage: {
        ...choiceListColumnDef('Stage', 'stage', 'opportunityStage'),
        emptyOption: 'Select a Stage...'
    },
    type: {
        ...choiceListColumnDef('type', 'type', 'opportunityType'),
        emptyOption: 'Select a type...'
    },
    opportunityYear: {
        ...choiceListColumnDef('Opportunity Year', 'year', 'opportunityYear'),
        emptyOption: 'Select a year...'
    },
    opportunitySize: {
        ...basicColumnDef('Opportunity Size (Vehicles)', 'opportunitySize'),
        type: 'number'
    },
    hyundaiOpportunitySize: {
        ...basicColumnDef('Hyundai Opportunity Size (Vehicles)', 'hyundaiOpportunitySize'),
        type: 'number'
    },
    updatedDate: {
        ...dateColumnDef('Updated', 'updatedDate')
    },
    openedDate: {
        ...dateColumnDef('Opened', 'openedDate')
    },
    /*likelihood: {
        ...choiceListColumnDef('Likelihood', 'likelihood', 'opportunityLikelihood'),
        emptyOption: 'How Likely is this Opportunity...'
    },*/
    likelihoodJustification: {
        ...basicColumnDef('Likelihood Justification', 'likelihoodJustification'),
        emptyOption: "Only mandatory if likelihood is 'Unknown'"
    },
    fuelType: {
        ...choiceListColumnDef('Fuel Type', 'fuelType', 'opportunityFuelType'),
        emptyOption: 'Select a fuel type...'
    },
    wonLostReason: {
        ...choiceListColumnDef('Won / Lost Reason', 'wonLostReason', 'opportunityWonLostReason'),
        emptyOption: 'Select the reason this opportunity was won or lost...'
    },
    product: {
        ...choiceListColumnDef('Product', 'product', 'opportunityProduct', {
            renderAsBasicText: true,
            displayEmptyAs: '-'
        }),
        emptyOption: 'Select the product...',
        right: false
    },
    competitorLostTo: {
        ...basicColumnDef('Competitor Lost To', 'competitorLostTo')
    },
    assignee: {
        ...choiceListColumnDef('Assignee', 'assigneeId', 'communicationAssignee'),
        emptyOption: 'Assign the opportunity...'
    },
    leasingCompany: {
        ...choiceListColumnDef('Leasing Company', 'leasingCompany', 'opportunityLeasingCompany'),
        emptyOption: 'Select the leasing company...'
    },
    leasingCompanyPerson: {
        ...choiceListColumnDef('Leasing Company Person', 'leasingCompanyPerson.id', 'opportunityLeasingCompanyPerson'),
        emptyOption: 'Select the contact from the leasing company...'
    },
    primaryContact: {
        ...basicColumnDef('Person', 'primaryContact.id')
    }
};

export default opportunity;
