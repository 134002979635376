import React, { useContext } from 'react';
import _ from 'lodash';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';
import CompanyProvider from '../../Company/Provider/CompanyProvider';
import PersonDetails from '../../Person/Components/PersonDetails';
import { usePerson } from '../../Person/Selectors/usePerson';

const AgreementPersonDetails = () => {
    const {
        agreementHeader,
        agreementState: { showPersonDetails }
    } = useContext(AgreementHeaderContext);

    const personId = _.get(agreementHeader, 'personId');
    const companyId = _.get(agreementHeader, 'companyId');

    const person = usePerson(personId);

    if (!person || !showPersonDetails) {
        return null;
    }
    return (
        <CompanyProvider companyId={companyId} companyOnly={true}>
            <PersonDetails title="Agreement Person" person={person} companyId={companyId} />
        </CompanyProvider>
    );
};

export default AgreementPersonDetails;
