import { combineReducers } from 'redux';
import { createForms } from 'react-redux-form';
import { connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { loadingStates } from '../reducers/Rest/loadingStates';
import { emailSettings } from '../reducers/Rest/emailSettings';
import { opportunities } from '../reducers/Api/Opportunities';
import { people } from '../reducers/Api/People';
import { companies } from '../reducers/Api/Company';
import { companySummaries } from '../reducers/Api/CompanySummaries';
import { tableSortOrders } from '../reducers/table_sort_order';
import { resources } from '../reducers/Api/Resources/index';
import { user } from '../reducers/Api/User/index';
import { errors } from '../reducers/Api/Errors/errors';
import { resetPassword } from '../reducers/Api/Auth/resetPassword';
import demos from '../reducers/Api/Demo';
//import { collapsed } from '../reducers/collapsed';
import { fleetProfiles } from '../reducers/Api/FleetProfiles/index';
import { settings } from '../reducers/Settings/Settings';
import { schema } from '../reducers/Schema/index';
import bandings from '../reducers/Api/Banding';
import { visitActions } from '../reducers/Api/VisitActions/visitActions';
import { address } from '../reducers/Api/Address/index';
import events from '../reducers/Api/Events';
import meetings from '../reducers/Api/Meeting';
import communicationsNew from '../reducers/Api/Communications';
import newProduct from '../reducers/Api/Agreements/newProduct';
import productFamily from '../reducers/Api/Agreements/productFamily';
import agreementDetails from '../reducers/Api/Agreements/agreementDetails';
import agreementHeaders from '../reducers/Api/Agreements/agreementHeaders';
import agreementAddendumDetails from '../reducers/Api/Agreements/agreementAddendumDetails';
import agreementStatusChanges from '../reducers/Api/Agreements/agreementStatusChanges';
import { addendumAgreementSummaries } from '../reducers/Api/Agreements/addendumAgreements';
import { templates, testTemplateData } from '../reducers/Api/Templates';

export default history =>
    combineReducers({
        router: connectRouter(history),
        toastr: toastrReducer,
        companies,
        emailSettings,
        demos,
        errors,
        fleetProfiles,
        resources,
        companySummaries,
        tableSortOrders,
        opportunities,
        communications: communicationsNew,
        people,
        schema,
        bandings,
        loadingStates,
        settings,
        user,
        resetPassword,
        visitActions,
        address,
        events,
        meetings,
        newProduct,
        productFamily,
        agreementDetails,
        agreementHeaders,
        addendumAgreementSummaries,
        agreementAddendumDetails,
        agreementStatusChanges,
        templates,
        testTemplateData,
        ...createForms({
            agreementForm: { notSet: true },
            editFieldForm: {
                value: '',
                title: ''
            },
            agreementTermForm: {}
        })
    });
