import React from 'react';
import openAgreementDocument from '../../../actions/Api/AgreementHeader/apiGetAgreementPdf';
import ActionButton from '../../common/ActionButtons/ActionButton';

export default function AgreementPdfLinkActionButton({ agreementHeaderId, hideText }) {
    return (
        <ActionButton
            buttonText="Download PDF"
            fontawesomeIcon="fa-download"
            bootstrapColor="primary"
            hideText={hideText}
            onClickAction={() => openAgreementDocument(agreementHeaderId)}
        />
    );
}
