import {handleErrorWithToastUnlessUnauthorised, makePostRequest} from '../apiHelpers';
import { ENDPOINTS, IAction } from '../constants';
import { Dispatch } from 'redux';
import {isLoading} from "../../Loading/isLoading";

export interface IOpportunityFilter {
	companyId?: number;
	assigneeId?: number;
	stages?: string[];
}

interface IResponse {
	data: any
}

const type = ENDPOINTS.API.OPPORTUNITY_FILTER_COMMIT;

export const apiOpportunityFilter = (filter: IOpportunityFilter, fetchCompanies: boolean) => async (
	dispatch: Dispatch<IAction>,
	getState: () => any
) => {
	if (!getState().offline.online) {
		return false;
	}
	isLoading(dispatch, type, true, {});
	makePostRequest(['opportunity', 'filter'], {fetchCompanies}, filter)
		.then(({data} :IResponse)=> {
			isLoading(dispatch, type, false, {});
			dispatch({ type, payload: { data: data, filter } });
			return true;
		})
		.catch((error:any)=> {
			isLoading(dispatch, type, false, {});
			handleErrorWithToastUnlessUnauthorised(dispatch, getState, error, type, "Opportunities");
		});
};
