import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

export const TemplateContext = React.createContext({});

export const TemplateConsumer = TemplateContext.Consumer;

const TemplateProvider = ({ children, templates }) => {
    return <TemplateContext.Provider value={{ templates }}>{children}</TemplateContext.Provider>;
};

export const useTemplateContext = () => {
    return useContext(TemplateContext);
};

const makeMapStateToProps = () => {
    const getState = createSelector([state => state.templates], templates => ({
        templates
    }));
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(TemplateProvider);
