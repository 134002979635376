import React from 'react';
import _ from 'lodash';

export const empty = value => _.isEmpty(value);

export const OptionsTableHeader = props => {
    if (!props.labels) {
        return null;
    }
    return (
        <div className="formik-table-row-header">
            <div style={{ paddingTop: '5%' }} className="div1 undefined text-white text-uppercase">
                {props.labels[0]}
            </div>
            <div style={{ paddingTop: '5%' }} className="div2 undefined text-white text-uppercase">
                <div className="formik-table-row-header-columns" />
                <div className="formik-table-row-header-columns">{props.labels[1]}</div>
                <div className="formik-table-row-header-columns">
                    {(Array.isArray(props.arg) ? true : !empty(props.value)) && (
                        <div className="formik-table-link">
                            <button type="button" className="btn btn-sm" onClick={props.onClick}>
                                {props.label1} All
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div style={{ paddingTop: '5%' }} className="div3 undefined text-white text-uppercase">
                <div className="formik-table-row-header-columns" />
                <div className="formik-table-row-header-columns">{props.labels[2]}</div>
                <div className="formik-table-row-header-columns">
                    {(Array.isArray(props.arg) ? true : !empty(props.value)) && (
                        <div className="formik-table-link">
                            <button type="button" className="btn btn-sm" onClick={props.onClick1}>
                                {props.label2} All
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
