import { isError } from '../../Loading/isError';
import { isLoading } from '../../Loading/isLoading';
import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../constants';
import { makePostRequest } from '../apiHelpers';

export const apiSupportRequest = data => async dispatch => {
    const type = ENDPOINTS.API.SUPPORT_REQUEST;
    isLoading(dispatch, type, true, {});
    try {
        //todo: const result =
        await makePostRequest(['support', 'request'], {}, data);
        return true;
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        toastr.error(type, `Response ${error.message}`);
        return false;
    }
};
