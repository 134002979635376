import React from 'react';
import PropTypes from 'prop-types';
import { FieldLabel } from '../DisplayField/FieldLabel';
import { FieldValue } from '../DisplayField/FieldValue';
import SyncingOrErrorIndicator from '../SyncingOrErrorIndicator';
import { DisplayField } from '../DisplayField/index';

const EditFieldDisplayValue = props => {
    const { onClick, isSyncing, isError, title, show, primary, fontawesome, value, stack } = props;
    return stack ? (
        <DisplayField
            className={show ? '' : 'hoverable hover-pointer'}
            onClick={() => (show ? null : onClick())}
            title={
                <span>
                    <SyncingOrErrorIndicator isSyncing={isSyncing} error={isError} />
                    {title}{' '}
                    <i
                        className={` gradient fa d-inline ${fontawesome} ml-2 grid-js-end hoverable hover-pointer hoverable-dimmed`}
                    />
                </span>
            }
            value={value}
        />
    ) : (
        <div
            className={`d-grid  ${show ? '' : 'hoverable hover-pointer'} mt-2`}
            style={{ gridTemplateColumns: 'auto auto 20px' }}
            onClick={() => (show ? null : onClick())}
        >
            <FieldLabel className="grid-cs-1">
                <SyncingOrErrorIndicator isSyncing={isSyncing} error={isError} />
                {title}
            </FieldLabel>
            <FieldValue primary={primary} className="text-right">
                {value}
            </FieldValue>
            <i
                className={` gradient fa d-inline ${fontawesome} ml-2 grid-js-end hoverable hover-pointer hoverable-dimmed`}
            />
        </div>
    );
};

EditFieldDisplayValue.propTypes = {
    onClick: PropTypes.func,
    show: PropTypes.bool,
    isSyncing: PropTypes.bool,
    isError: PropTypes.string,
    title: PropTypes.string,
    fontawesome: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.node])
};

export default EditFieldDisplayValue;
