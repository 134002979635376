import { Route, Switch } from 'react-router';
import React from 'react';
import PageWithNavigation from './PageWithNavigation';
import ChangePasswordPage from '../Authentication/Pages/ChangePasswordPage';
import HomePage from './Pages/HomePage';
import CompaniesPage from './Pages/CompaniesPage';
import AgreementsPage from './Pages/AgreementsPage';
import AgreementDetailsPage from './Pages/AgreementDetailsPage';
import CalendarPage from './Pages/CalendarPage';
import PeoplePage from './Pages/PeoplePage';
import AddressesPage from './Pages/AddressesPage';
import SendVisitReportEmailPage from './Pages/SendVisitReportEmailPage';
import MeetingPage from './Pages/MeetingPage';
import CompanyPage from './Pages/CompanyPage';
import VersionsPage from './Pages/VersionsPage';
import RequestSupportPage from '../Support/Pages/RequestSupportPage';
import Page404 from '../Authentication/Pages/Page404';
import { TemplatePage } from './Pages/TemplatePage';

const LoggedInRouter = ({ userId }) => {
    if (userId < 0) {
        return null;
    }

    return (
        <PageWithNavigation>
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/changepassword" component={ChangePasswordPage} />
                <Route exact path="/dashboard/home" component={HomePage} />
                <Route exact path="/dashboard/companies" component={CompaniesPage} />
                <Route exact path="/dashboard/agreements" component={AgreementsPage} />
                <Route exact path="/dashboard/agreement/:agreementHeaderId" component={AgreementDetailsPage} />
                <Route exact path="/dashboard/templates" component={TemplatePage} />
                <Route exact path="/calendar/:view/:year/:month/:day" component={CalendarPage} />
                <Route exact path="/company/:companyId/people" component={PeoplePage} />
                <Route exact path="/company/address/:companyId" component={AddressesPage} />
                <Route exact path="/communication/email/:meetingId" component={SendVisitReportEmailPage} />
                <Route path="/meeting/view/:communicationId/:descriptor" component={MeetingPage} />
                <Route path="/company/:companyId/:companyName" component={CompanyPage} />
                <Route exact path="/versions/" component={VersionsPage} />
                <Route exact path="/support/request/" component={RequestSupportPage} />
                <Route component={Page404} />
            </Switch>
        </PageWithNavigation>
    );
};

export default LoggedInRouter;
