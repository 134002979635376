import React, { useContext } from 'react';
import { AddTableRowButton } from '../../common/Buttons/TableButtons';
import { AgreementDetailsTableContext } from '../AgreementDetailsTable/AgreementDetailsTableProvider';

const ButtonAddProduct = ({ product, agreementDetail, isDeleted, lastDetailForProduct, isDeclined }) => {
    const {
        agreementState: { canAmend },
        agreementHeaderId,
        editAgreementDetail
    } = useContext(AgreementDetailsTableContext);

    const visibility =
        !lastDetailForProduct || !canAmend || !!product.deleted || !(isDeleted || isDeclined || !agreementDetail)
            ? 'invisible'
            : 'visible';

    return (
        <AddTableRowButton
            visibility={visibility}
            onClick={() => editAgreementDetail(null, agreementHeaderId, product.productFamilyId, product.id)}
        />
    );
};

export default ButtonAddProduct;
