import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router';
import visitAction from '../visitAction';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import EditVisitActionModal from '../Modals/EditVisitActionModal';

class VisitActionsTableCard extends React.Component {
    static defaultProps = {
        breakpoint: 500,
        itemsPerPage: 5,
        noItemsText: 'There are no visit actions to show',
        columns: [visitAction.startDate, visitAction.activityRegarding, visitAction.status]
    };

    renderButtons = createVisitAction => {
        const {
            buttons,
            cardButtons,
            companyId,
            visitAction,
            match: {
                params: { communicationId }
            }
        } = this.props;
        const items = cardButtons || [];

        if (buttons && buttons.includes('addVisitAction')) {
            items.push({
                text: 'Add VisitAction',
                fontAwesomeName: 'fa-plus',
                onClick: () =>
                    createVisitAction(companyId, (visitAction && visitAction.visitActionId) || communicationId)
            });
        }
        return items;
    };

    render() {
        const {
            loadingStates,
            title,
            rows,
            showFilter,
            companyId,
            visitActions,
            communication,
            editRow,
            user,
            isLoading,
            columns,
            ...rest
        } = this.props;

        const tableDefinition = new TableDefinition(columns);

        return (
            <EditVisitActionModal>
                {({ editVisitAction, createVisitAction }) => (
                    <ChoiceListFilteredTableNoDashboardData
                        id={title}
                        tableDefinition={tableDefinition}
                        rows={rows}
                        isLoading={isLoading}
                        showInCard={true}
                        cardButtons={this.renderButtons(createVisitAction)}
                        title={title}
                        columnDefsId={visitAction.id}
                        tableDefinitionColumns={tableDefinition.columns}
                        onRowClick={editVisitAction}
                        {...rest}
                    />
                )}
            </EditVisitActionModal>
        );
    }
}

const mapStateToProps = ({ loadingStates, visitActions, user }) => ({ loadingStates, visitActions, user });

export default connect(mapStateToProps)(withRouter(VisitActionsTableCard));
