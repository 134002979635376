import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import ReactiveForm from '../../common/ReactiveForm';
import { Alert } from 'reactstrap';
import validationSchema from './editAgreementDetailValidation';
import agreementDetailColumnDefs from '../agreementDetailColumnDefs';
import { apiAgreementDetailUpdateMultiple } from '../../../actions/Api/AgreementDetail/apiAgreementDetailUpdate';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import useInitialValues from '../../common/Hooks/useInitialValues';
import { AgreementHeaderContext } from '../../AgreementHeader/Provider/AgreementHeaderProvider';
import useProductFamilyOptions from '../Hooks/useProductFamilyOptions';
import useProductOptions from '../Hooks/useProductOptions';
import { DisplayField } from '../../common/DisplayField';
import { toastr } from 'react-redux-toastr';

const FORM_NAME = 'editAgreementDetailForm';

const onError = (setIsSubmitting, title) => (dispatch, type, error) => {
    toastr.error(title, `${error.message}`);
    setIsSubmitting(false);
};

function handleSubmit(form, setIsSubmitting, /*save,*/ saveMulti, toggle) {
    setIsSubmitting(true);

    if (form.arrayOfProductsToAdd && form.arrayOfProductsToAdd.length) {
        const products = form.arrayOfProductsToAdd.map((product) => ({
            ...form,
            productId: product.id,
        }));
        saveMulti(
            products,
            'Success',
            form.id ? 'Products Updated' : 'Products Added',
            toggle,
            onError(setIsSubmitting, form.id ? 'Error Updating Products' : 'Error Adding Products')
        );
    } else {
        saveMulti(
            [form],
            'Success',
            form.id ? 'Product Updated' : 'Product Added',
            toggle,
            onError(setIsSubmitting, form.id ? 'Error Updating Product' : 'Error Adding Product')
        );
    }
}

const EditAgreementDetailForm = ({
    agreementDetails,
    newProduct,
    productFamily,
    resources,
    agreementHeaderId,
    selectProduct,
    agreementDetailId,
    productId,
    isLoading,
    productFamilyId,
    //save,
    saveMulti,
    arrayOfProductsToAdd,
    toggle,
    allowDeletedProducts = false,
    allowDeleteProductFamilies = false,
}) => {
    const {
        agreementState: { isDraft },
    } = useContext(AgreementHeaderContext);

    const productOptions = useProductOptions(newProduct, allowDeletedProducts);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const productFamilyOptions = useProductFamilyOptions(productFamily, allowDeleteProductFamilies);

    const initialValues = useInitialValues(
        agreementDetails.find((d) => d.id === agreementDetailId),
        [
            { field: 'id' },
            { field: 'productFamilyId' },
            { field: 'productId' },
            { field: 'agreementHeaderId' },
            { field: 'discountUnits' },
            { field: 'manufacturerDiscount' },
            { field: 'dealerDiscount' },
            { field: 'arrayOfProductsToAdd', convert: 'toArray' },
        ],
        {
            id: agreementDetailId,
            productFamilyId,
            productId,
            agreementHeaderId,
            discountUnits: 'Percent',
            manufacturerDiscount: 0,
            dealerDiscount: 0,
            arrayOfProductsToAdd,
        }
    );

    initialValues.notes = '';

    return !isLoading ? (
        <>
            {!isDraft ? (
                <Alert color={'primary'}>
                    The product will be added to all addended agreements. If the agreement already has a rate applied to
                    this product, it will be replaced regardless of its current rate.
                </Alert>
            ) : (
                <></>
            )}

            <ReactiveForm
                initialValues={initialValues}
                resources={resources}
                formName={FORM_NAME}
                cancel={toggle}
                isSubmitting={isSubmitting}
                validationSchema={validationSchema}
                handleSubmit={(form) => handleSubmit(form, setIsSubmitting, saveMulti, toggle)}
            >
                <ReactiveForm.Section>
                    <ReactiveForm.Choicelist
                        columnDef={basicColumnDef('Product Family', 'productFamilyId')}
                        placeholder="Product Family"
                        options={productFamilyOptions}
                        disabled={!selectProduct}
                    />
                    <ReactiveForm.Other hide={({ values }) => !values.arrayOfProductsToAdd.length}>
                        {({ values }) => (
                            <DisplayField
                                title="products"
                                value={
                                    <div className="mt-3 mb-4 ml-2">
                                        {values.arrayOfProductsToAdd.map((x) => x.name).join(', ')}
                                    </div>
                                }
                            />
                        )}
                    </ReactiveForm.Other>
                    <ReactiveForm.Choicelist
                        columnDef={agreementDetailColumnDefs.productId}
                        placeholder="Product Id"
                        options={({ values }) => {
                            return productOptions.filter((x) => x.productFamilyId === values.productFamilyId);
                        }}
                        hide={({ values }) => values.arrayOfProductsToAdd.length}
                        disabled={!selectProduct}
                    />
                    <ReactiveForm.Text
                        columnDef={agreementDetailColumnDefs.manufacturerDiscount}
                        placeholder="Manufacturer Discount"
                    />
                    <ReactiveForm.Text
                        columnDef={agreementDetailColumnDefs.dealerDiscount}
                        placeholder="Dealer Discount"
                    />
                    <ReactiveForm.Choicelist
                        columnDef={agreementDetailColumnDefs.discountUnits}
                        placeholder="Discount Unit"
                    />
                    <ReactiveForm.Text
                        max="255"
                        columnDef={agreementDetailColumnDefs.notes}
                        placeholder="Notes"
                    ></ReactiveForm.Text>
                </ReactiveForm.Section>
            </ReactiveForm>
        </>
    ) : (
        <></>
    );
};

const mapStateToProps = ({ agreementDetails, newProduct, productFamily, resources }) => ({
    agreementDetails,
    newProduct,
    productFamily,
    resources,
});

const mapDispatchToProps = (dispatch) => ({
    saveMulti: (agreementDetails, successTitle, successMessage, callback, onError) => {
        dispatch(apiAgreementDetailUpdateMultiple(agreementDetails, successTitle, successMessage, callback, onError));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditAgreementDetailForm);
