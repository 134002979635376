import React, { memo } from 'react';

import { DashboardFieldColumnDef } from '../../Dashboard/Components/DashboardField';
import SimpleCard from '../../common/Card/SimpleCard';
import companyDef from '../companyDef';
import { useCompanyContext } from '../Provider/CompanyProvider';

const CompanyDetails = ({ hideCompanyName, hideCompanyUcn }) => {
    const { company = {} } = useCompanyContext();
    const isLoading = company.loading;

    return (
        <CompanyDetailsCard
            company={company}
            isLoading={isLoading}
            hideCompanyName={hideCompanyName}
            hideCompanyUcn={hideCompanyUcn}
        />
    );
};

export default CompanyDetails;

const CompanyDetailsCard = memo(({ company, isLoading, hideCompanyName, hideCompanyUcn }) => (
    <SimpleCard title="Company Information" isLoading={isLoading}>
        {!hideCompanyUcn && <DashboardFieldColumnDef item={company} columnDef={companyDef.ucn} />}
        {!hideCompanyName && <DashboardFieldColumnDef item={company} columnDef={companyDef.name} />}
        <DashboardFieldColumnDef item={company} columnDef={companyDef.businessPhone} />
        <DashboardFieldColumnDef item={company} columnDef={companyDef.webSite} />
        <DashboardFieldColumnDef item={company} columnDef={companyDef.completedMeetings} />
        <DashboardFieldColumnDef item={company} columnDef={{ showTime: false, ...companyDef.lastMeetingDate }} />
        <DashboardFieldColumnDef item={company} columnDef={companyDef.demosInLast12Months} />
        <DashboardFieldColumnDef item={company} columnDef={companyDef.totalDemos} />
    </SimpleCard>
));
