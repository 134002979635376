import _ from 'lodash';
import { agreementHeaderStates } from './agreementHeaderStates';
import { useMemo } from 'react';
import productAndDetailsDropdownFilters from './productAndDetailsDropdownFilters';

function userOnSameAgreementTeamOrIsAdmin(user, agreementHeader) {
    const usersTeam = _.get(user, 'agreementApprovalTeam');
    const agreementTeam = _.get(agreementHeader, 'agreementApprovalTeam');

    return (
        usersTeam === 'Admin' ||
        usersTeam === agreementTeam ||
        (usersTeam === 'BusinessCentre' && agreementTeam === 'Admin') ||
        (agreementTeam == null && _.get(user, 'canApproveAgreement', 'N') === 'Y')
    );
}

function useAgreementHeaderState(agreementHeader, user) {
    return useMemo(() => AgreementHeaderState(agreementHeader, user), [agreementHeader, user]);
}

function AgreementHeaderState(agreementHeader, user) {
    if (!agreementHeader) return {};
    const rules = {
        detailsFilter: {
            default: 'showAll',
            filters: {
                showAll: productAndDetailsDropdownFilters.showAll
            }
        },

        ..._.get(agreementHeaderStates, `${agreementHeader.agreementType}.${agreementHeader.status}`, {}),
        userCanApprove: _.get(user, 'canApproveAgreement', '') === 'Y',
        userCanDelete: _.get(user, 'canApproveAgreement', '') === 'Y',
        userOnSameAgreementTeamOrIsAdmin: userOnSameAgreementTeamOrIsAdmin(user, agreementHeader),
        isAddendum: agreementHeader.agreementType === 'Addendum',
        isAgreement: agreementHeader.agreementType === 'Agreement',
        isPromotion: agreementHeader.agreementType === 'Promotion',
        isTemplate: agreementHeader.agreementType === 'Template'
    };

    rules.canDecline = (rules.canDecline || false) && rules.userCanApprove && rules.userOnSameAgreementTeamOrIsAdmin;
    rules.canApprove = (rules.canApprove || false) && rules.userCanApprove && rules.userOnSameAgreementTeamOrIsAdmin;
    rules.canDelete = (rules.canDelete || false) && rules.userCanApprove && rules.userOnSameAgreementTeamOrIsAdmin;
    rules.canApproveWholeAgreement =
        (rules.canApproveWholeAgreement || false) && rules.userCanApprove && rules.userOnSameAgreementTeamOrIsAdmin;

    rules.canAmend = rules.userOnSameAgreementTeamOrIsAdmin;
    rules.canClone = (rules.canClone || false) && rules.userOnSameAgreementTeamOrIsAdmin;
    rules.isDraft = _.get(agreementHeader, 'status') === 'Draft';
    rules.templateFile = _.get(agreementHeader, 'templateFile');


    return rules;
}

export default useAgreementHeaderState;

export function isAuthorisedToApproveAgreements(user) {
    return _.get(user, 'canApproveAgreement', '') === 'Y';
}
