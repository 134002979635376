import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getChoiceListValue } from '../LabelText';
import { Formik } from 'formik';
import SmallSaveCancelButtons from './SmallSaveCancelButtons';
import FormikCheckbox from '../FormkControls/FormikCheckbox';
import { FieldLabel } from '../DisplayField/FieldLabel';
import { FieldValue } from '../DisplayField/FieldValue';

class EditCheckboxInline extends PureComponent {
    state = { isEditing: false };

    submitForm = values => {
        const { save, field } = this.props;
        const saveData = {
            [field]: values.value
        }
        save(saveData);
        this.setState({ isEditing: false });
    };

    renderEditContents = () => {
        const {
            title: oTitle,
            value,
            propertyName,
            required
        } = this.props;

        const title = oTitle || propertyName;
        const initialValue = value && value.toUpperCase() === 'Y' ? true : false;

        return (
            <Formik
                initialValues={{ value: initialValue }}
                validate={values => (required && !values.value ? { value: 'Required' } : {})}
                onSubmit={this.submitForm}
                render={formikProps => {
                    const { handleSubmit, handleReset } = formikProps;
                    formikProps.formName = title;

                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="pull-left mt-2">
                                <FormikCheckbox
                                    fieldName="value"
                                    title={title}
                                    {...formikProps}
                                />
                            </div>
                            <SmallSaveCancelButtons
                                showSave={true}
                                showCancel={true}
                                onCancel={event => {
                                    this.setState({ isEditing: false });
                                    handleReset(event);
                                }}
                            />
                        </form>
                    );
                }}
            />
        );
    };

    renderNotEditing = (formId, isEdit) => {
        const { title, value } = this.props;

        return !isEdit && (
            <div
                className={`d-grid  hoverable hover-pointer mt-2`}
                style={{ gridTemplateColumns: 'auto auto' }}
                onClick={() => this.setState({ isEditing: !isEdit })}
            >
                <FieldLabel className="grid-cs-1">
                    {title}
                </FieldLabel>
                <FieldValue className=" text-right" primary={false}>
                    {/* eslint-disable-next-line */}
                    {value == null || value == false || value === 'N' ? "No" : "Yes"}

                    <i
                        className={` gradient fa d-inline fa-pencil ml-3 grid-js-end hoverable hover-pointer hoverable-dimmed`}
                    />
                </FieldValue>
            </div>
        );
    };

    getValue = value => {
        const { resources, choiceListName, mapChoiceValues, options } = this.props;

        let option;
        if (options) {
            option = options.find(option => option.key === value);
        }

        return options
            ? option
                ? option.value
                : ''
            : getChoiceListValue(resources, choiceListName, value, mapChoiceValues);
    };

    render() {
        const { objectId, propertyName } = this.props;
        const { isEditing } = this.state;

        const formId = `${objectId}-${propertyName}`;
        return isEditing ? this.renderEditContents() : this.renderNotEditing(formId, isEditing);
    }
}

const mapStateToProps = state => ({
    resources: state.resources
});

export default connect(mapStateToProps)(EditCheckboxInline);
