import { ENDPOINTS } from '../constants';
import { toastr } from 'react-redux-toastr';
import { apiDelete } from '../apiHelpers';

export const apiAddendumDetailDelete = (id, successTitle, successMessage, callback) => async dispatch => {
    const type = ENDPOINTS.API.ADDENDUM_DETAIL_DELETE;

    dispatch(
        apiDelete(
            type,
            ['agreement', 'addendum', 'detail'],
            undefined,
            { id },
            undefined,
            true,
            error => {
                toastr.error(type, `Response ${error.message}`);
            },
            successTitle,
            successMessage,
            callback
        )
    );
};
