import * as yup from 'yup';
import agreementDetailColumnDefs from '../agreementDetailColumnDefs';
import _ from 'lodash';

const validationSchema = yup
    .object()
    .shape({
        // manufacturerDiscount: yup.string().required('Manufacturer discount price is required'),
        // dealerDiscount: yup.string().required('Dealer discount is required'),
        discountUnits: yup.string().required('A Discount unit is required'),
        manufacturerDiscount: yup
            .number()
            .when('discountUnits', (discountUnits, schema) => {
                if (discountUnits === 'Pounds') {
                    return schema.test({
                        test: manufacturerDiscount => (manufacturerDiscount ? manufacturerDiscount <= 10000 : true),
                        message: 'Manufacturer discount cannot be more than one thousand'
                    });
                }
                if (discountUnits === 'Percent') {
                    return schema.test({
                        test: manufacturerDiscount => (manufacturerDiscount ? manufacturerDiscount <= 100 : true),
                        message: 'Manufacturer discount cannot be more than one hundred'
                    });
                }
            })
            .min(0, 'Manufacturer discount cannot be less than zero'),
        dealerDiscount: yup
            .number()
            .when('discountUnits', (discountUnits, schema) => {
                if (discountUnits === 'Pounds') {
                    return schema.test({
                        test: dealerDiscount => (dealerDiscount ? dealerDiscount <= 10000 : true),
                        message: 'Dealer discount cannot be more than one thousand'
                    });
                }
                if (discountUnits === 'Percent') {
                    return schema.test({
                        test: dealerDiscount => (dealerDiscount ? dealerDiscount <= 100 : true),
                        message: 'Dealer discount cannot be more than one hundred'
                    });
                }
            })
            .min(0, 'Dealer discount cannot be less than zero')
    })
    .test('atleastOneDiscountAdded', function(value) {
        const manufacturerDiscountValue = _.get(value, 'manufacturerDiscount');
        const dealerDiscountValue = _.get(value, 'dealerDiscount');

        if (manufacturerDiscountValue === undefined && dealerDiscountValue === undefined) {
            return this.createError({
                // Formik will use the error "path" as the key in the errors; set this to whatever you want
                path: agreementDetailColumnDefs.manufacturerDiscount.field,
                message: 'At least one of the discount fields must have a value'
            });
        }

        return true;
    });

export default validationSchema;
