import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';
import { toastr } from 'react-redux-toastr';

export const apiAgreementMakeTemplateEditable = agreement => async (dispatch, getState) => {
    if (!getState().offline.online) {
        return false;
    }

    const type = ENDPOINTS.API.AGREEMENT_MAKE_TEMPLATE_EDITABLE;

    dispatch(
        apiGetPost(
            type,
            ['agreement', 'make-template-editable'],
            agreement,
            {},
            undefined,
            true,
            (dispatch, type, error) => {
                toastr.error('Update Template', `${error.message}`);
            },
            undefined,
            'Template Updated',
            'Template is now a Draft template and can be edited'
        )
    );
};
