import React from 'react';
import moment from 'moment';
import AgreementHeaderTableCard from './AgreementHeaderTableCard';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import { agreementsQuery } from '../../../actions/Api/AgreementHeader/apiAgreementFilter';
import { REST_TIME_FORMAT } from '../../../actions/Api/apiHelpers';
import RedirectNotLoggedIn from '../../app/RedirectNotLoggedIn';

const endDateBefore = moment().add(-6, 'weeks');
const endDateAfter = moment().add(6, 'weeks');

export const filterAgreementsLapsingSoon = agreementHeaders => {
    return agreementHeaders.filter(
        agreementHeader => agreementHeader.lapsingSoon === 1 || agreementHeader.lapsed === 1
    );
};

const AgreementsLapsingSoonTable = ({ showFilter }) => {
    return (
        <>
            <RedirectNotLoggedIn
                change={1}
                onLoggedInDispatchArray={[
                    agreementsQuery()
                        .filterAgreementType('Agreement')
                        .isBefore(endDateBefore.format(REST_TIME_FORMAT))
                        .isAfter(endDateAfter.format(REST_TIME_FORMAT))
                        .shouldFetchDetails(false)
                        .shouldFetchPersons(false)
                        .shouldFetchCompanies(true)
                        .run()
                ]}
            />
            <AgreementHeaderTableCard
                filter={filterAgreementsLapsingSoon}
                tableIsNotInCard={true}
                title="Agreements Lapsing Soon"
                noItemsText="There are no agreements to show"
                showFilter={showFilter}
                columns={[
                    agreementHeaderColumnDefs.companyName,
                    agreementHeaderColumnDefs.startDate,
                    agreementHeaderColumnDefs.endDate,
                    agreementHeaderColumnDefs.termsType,
                    agreementHeaderColumnDefs.agreementApprovalTeam,
                    agreementHeaderColumnDefs.status
                ]}
            />
        </>
    );
};

export default AgreementsLapsingSoonTable;
