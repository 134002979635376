import React, { useContext } from 'react';
import { useAgreementStatusChanges } from '../Selectors/useAgreementStatusChanges';

const AgreementStatusChangesContext = React.createContext({
    agreementHeaderId: undefined,
    agreementHeaders: []
});

const AgreementStatusChangesProvider = ({ agreementHeaderId, children }) => {
    const agreementStatusChanges = useAgreementStatusChanges(agreementHeaderId);

    return (
        <AgreementStatusChangesContext.Provider value={{ agreementHeaderId, agreementStatusChanges }}>
            {children}
        </AgreementStatusChangesContext.Provider>
    );
};

export const useAgreementStatusChangesContext = () => {
    const state = useContext(AgreementStatusChangesContext);
    if (state === undefined) {
        throw new Error(`useAgreementStatusChangesContext must be used inside AgreementStatusChangesProvider`);
    }
    return state;
};

export default AgreementStatusChangesProvider;
