import React from 'react';
import PageSectionContentTable from '../../common/PageSection/PageSectionContentTable';
import { useCompanyContext } from '../Provider/CompanyProvider';
import companyDef from '../companyDef';

const CompanyPageTitleContent = () => {
    const { company } = useCompanyContext();
    return (
        <PageSectionContentTable
            values={company}
            columnDefs={[
                companyDef.externalUcn,
                companyDef.accountManager,
                companyDef.status,
                companyDef.numberOfVisitsPerAnnum,
                companyDef.meetingBookingOwner
            ]}
        />
    );
};

export default CompanyPageTitleContent;
