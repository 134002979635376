import React from 'react';

const AddendumProductName = ({ familyName, productName }) => (
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        <b>
            {familyName} - {productName}
        </b>
    </div>
);

export default AddendumProductName;
