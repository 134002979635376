import React, { useContext } from 'react';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import AgreementSearchForm from './AgreementSearchForm';
import AgreementHeaderSearchProvider from './AgreementHeaderSearchProvider';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';
import AgreementSearchResults from './AgreementSearchResults';

const searchOptions = {
    companyName: {
        value: undefined,
        hide: false,
        disabled: false,
        collapsed: false
    },
    agreementType: {
        value: { Agreement: 'included' },
        hide: false,
        disabled: true,
        collapsed: false
    },
    status: {
        value: { Approved: 'included', Live: 'included' },
        hide: false,
        disabled: true,
        collapsed: true
    },
    termsType: {
        value: {},
        hide: false,
        disabled: false,
        collapsed: true
    },
    product: {
        value: {},
        hide: false,
        disabled: false,
        collapsed: true
    },
    excludeAgreementsWithAnAddendumId: {
        value: true,
        hide: false,
        disabled: true,
        collapsed: true
    }
};

const AddendumAgreementSearchFormAndTable = () => {
    const {
        agreementState: { displayAddendumAgreementsTable }
    } = useContext(AgreementHeaderContext);

    if (!displayAddendumAgreementsTable) {
        return null;
    }

    return (
        <AgreementHeaderSearchProvider
            title="Search Agreements"
            checkboxColumn={true}
            columns={[
                agreementHeaderColumnDefs.selected('Add To Addendum'),
                agreementHeaderColumnDefs.companyName,
                agreementHeaderColumnDefs.startDate,
                agreementHeaderColumnDefs.endDate,
                agreementHeaderColumnDefs.termsType,
                agreementHeaderColumnDefs.agreementType,
                agreementHeaderColumnDefs.agreementApprovalTeam,
                agreementHeaderColumnDefs.status
            ]}
        >
            <AgreementSearchForm searchOptions={searchOptions}>
                {({ agreementSearchForm }) => (
                    <AgreementSearchResults preventNavigate={true} agreementSearchForm={agreementSearchForm} />
                )}
            </AgreementSearchForm>
        </AgreementHeaderSearchProvider>
    );
};

export default AddendumAgreementSearchFormAndTable;
