import React from 'react';
import { Input } from 'reactstrap';
import FormikSearchHolder from './FormikSearchHolder';

const FormikSearchText = ({ formikProps: { values, handleChange }, field, placeholder, filterDescription }) => {
    const { value, disabled, collapsed } = values[field];

    return (
        <FormikSearchHolder description={filterDescription(value)} collapsed={collapsed} disabled={disabled}>
            <Input
                name={`${field}.value`}
                value={value ? value : ''} // is this ok to set it to empty stirng //index.js:2177 Warning: `value` prop on `input` should not be null. Consider using an empty string to clear the component or `undefined` for uncontrolled components.
                placeholder={placeholder}
                disabled={disabled}
                onChange={handleChange}
                className="py-4 w-75"
            />
        </FormikSearchHolder>
    );
};

export default FormikSearchText;
