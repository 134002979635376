import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import useAgreementHeaderState from '../agreementHeaderStatus';
import AgreementStatusChangesProvider from '../../AgreementStatusChanges/Provider/AgreementStatusChangesProvider';
import AgreementAddendumDetailsProvider from './AgreementAddendumDetailsProvider';
import { agreementsQuery } from '../../../actions/Api/AgreementHeader/apiAgreementFilter';
import AgreementEvidenceProvider, { useAgreementEvidenceContext } from './AgreementEvidenceProvider';
import RedirectNotLoggedIn from '../../app/RedirectNotLoggedIn';
import { useAgreement } from '../Selectors/useAgreement';

export const AgreementHeaderContext = React.createContext({
    agreementHeader: {},
    agreementHeaderId: -1
});

const InitialLoad = ({ agreementHeaderId }) => {
    const { setAgreementEvidence } = useAgreementEvidenceContext();

    return (
        <RedirectNotLoggedIn
            change={agreementHeaderId}
            onLoggedInDispatchArray={[
                agreementsQuery()
                    .filterAgreementHeaderId(agreementHeaderId)
                    .shouldFetchParentHeader(true)
                    .shouldFetchAddendumHeader(true)
                    .shouldFetchAddendedAgreements(true)
                    .shouldFetchPendingAddendum(true)
                    .shouldFetchAgreementsCreatedByAddendum(true)
                    .shouldFetchAgreementEvidence(true)
                    .shouldFetchAgreementStatusChanges(true)
                    .addPathToUserLog(`/dashboard/agreement/${agreementHeaderId}`)
                    .setCallback(data => {
                        setAgreementEvidence(data);
                    })
                    .run()
            ]}
        />
    );
};

const AgreementHeaderProvider = ({ agreementHeaderId, children }) => {
    const agreementHeader = useAgreement(agreementHeaderId);
    const user = useSelector(state => state.user);
    const agreementState = useAgreementHeaderState(agreementHeader, user);

    return (
        <>
            <AgreementEvidenceProvider>
                <AgreementAddendumDetailsProvider
                    agreementHeaderId={agreementHeaderId}
                    isAddendum={agreementState.isAddendum}
                >
                    <AgreementStatusChangesProvider agreementHeaderId={agreementHeaderId}>
                        <InitialLoad agreementHeaderId={agreementHeaderId} />
                        <AgreementHeaderContext.Provider
                            value={{
                                agreementState,
                                agreementHeader,
                                agreementHeaderId: _.get(agreementHeader, 'id', -1)
                            }}
                        >
                            {children}
                        </AgreementHeaderContext.Provider>
                    </AgreementStatusChangesProvider>
                </AgreementAddendumDetailsProvider>
            </AgreementEvidenceProvider>
        </>
    );
};

export default AgreementHeaderProvider;
