import React, { useContext } from 'react';
import ActionButton from '../../common/ActionButtons/ActionButton';
import FinalizeAgreementModal from '../Modals/FinalizeAgreementModal';
import { AgreementAddendumChangesContext } from '../Provider/AgreementAddendumDetailsProvider';
import { useHasAgreementDetails } from '../Selectors/useHasAgreementDetails';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';

export default function FinalizeAgreementButton({ agreementHeaderId }) {
    const {
        agreementState: { isPromotion, templateFile, canApprove },
        agreementHeader: { status }
    } = useContext(AgreementHeaderContext);
    const mustHaveTemplateFile = isPromotion && !templateFile;
    const isLoading = useIsLoading([ENDPOINTS.API.FINALISE_AGREEMENT]);
    const isDisabled = useIsLoading([ENDPOINTS.API.AGREEMENT_FILTER]) || mustHaveTemplateFile;
    const hasAgreementDetails = useHasAgreementDetails(agreementHeaderId);
    const { addendumDetails } = useContext(AgreementAddendumChangesContext);

    return (
        <FinalizeAgreementModal>
            {({ finalizeAgreement }) => (
                <ActionButton
                    disabled={(!hasAgreementDetails && !addendumDetails.length) || isDisabled}
                    buttonText={
                        status === 'Declined' && canApprove
                            ? `Resend For Approval ${mustHaveTemplateFile ? ' (Template Needed)' : ''}`
                            : `Finalise${mustHaveTemplateFile ? ' (Template Needed)' : ''}`
                    }
                    fontawesomeIcon="fa-handshake-o"
                    bootstrapColor="secondary"
                    onClickAction={() => finalizeAgreement(agreementHeaderId)}
                    loading={isLoading}
                />
            )}
        </FinalizeAgreementModal>
    );
}
