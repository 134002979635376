import React from 'react';
import AgreementHeaderProvider from '../../AgreementHeader/Provider/AgreementHeaderProvider';
import AgreementDetailsPageContent from '../../AgreementHeader/Components/AgreementDetailsPageContent';
import { useParams } from 'react-router';
import RedirectNotLoggedIn from '../RedirectNotLoggedIn';
import { apiTemplateGet } from '../../../actions/Api/Template/apiTemplateGet';
import TemplateProvider from '../../Template/Provider/TemplateProvider';

const AgreementDetailsPage = () => {
    const { agreementHeaderId: stringAgreementHeaderId } = useParams();

    const agreementHeaderId = parseInt(stringAgreementHeaderId, 10);
    return (
        <AgreementHeaderProvider agreementHeaderId={agreementHeaderId}>
            <TemplateProvider>
                <RedirectNotLoggedIn onLoggedInDispatchArray={[apiTemplateGet()]} />
                <AgreementDetailsPageContent />
            </TemplateProvider>
        </AgreementHeaderProvider>
    );
};

export default AgreementDetailsPage;
