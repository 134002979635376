import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const GetElapsed = strDate => {
    const now = moment();
    if (strDate) {
        const date = moment(strDate);

        let difference = now.diff(date, 'seconds');

        if (difference < 60) {
            return `${difference} seconds ago`;
        }

        difference = now.diff(date, 'minutes');
        if (difference < 60) {
            return `${difference} minutes ago`;
        }

        difference = now.diff(date, 'hours');
        if (difference < 24) {
            return `${difference} hours ago`;
        }

        difference = now.diff(date, 'days');
        if (difference < 24) {
            return `${difference} days ago`;
        }

        difference = now.diff(date, 'weeks');
        if (difference < 10) {
            return `${difference} weeks ago`;
        }

        difference = now.diff(date, 'months');
        if (difference < 12) {
            return `${difference} months ago`;
        }
        difference = now.diff(date, 'years');

        return `${difference} years ago`;
    }
};

const AuditSummary = props => {
    const { createdDate, updatedDate, createdBy, updatedBy, choiceList, className } = props;

    const strCreatedBy = createdBy.toString();
    const strUpdatedBy = updatedBy.toString();

    const createdByObj = choiceList ? choiceList.userAuthors.find(x => x.key === strCreatedBy) : '';
    const updatedByObj = choiceList ? choiceList.userAuthors.find(x => x.key === strUpdatedBy) : '';

    return (
        <div className={`w-100 ${className}`}>
            {createdDate ? (
                <div>
                    <small className="text-muted">
                        Created {GetElapsed(createdDate)}
                        {createdByObj ? ` by ${createdByObj.value}` : ''}
                    </small>
                </div>
            ) : null}
            {updatedDate ? (
                <div>
                    <small className="text-muted">
                        Updated {GetElapsed(updatedDate)}
                        {updatedByObj ? ` by ${updatedByObj.value}` : ''}
                    </small>
                </div>
            ) : null}
        </div>
    );
};

AuditSummary.propTypes = {
    createdDate: PropTypes.string,
    updatedDate: PropTypes.string,
    createdBy: PropTypes.number,
    updatedBy: PropTypes.number,
    choiceList: PropTypes.shape({
        userAuthors: PropTypes.array.isRequired
    }).isRequired
};

AuditSummary.defaultProps = {
    createdBy: '',
    updatedBy: ''
};

export default AuditSummary;
