import React, { useContext } from 'react';
import { ENDPOINTS } from '../../../actions/Api/constants';
import EditBandingModal from '../../Banding/Modals/EditBandingModal';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { useBandingsByCompanyById } from '../../Banding/Selectors/useBandingsByCompanyId';

const CompanyBandingsContext = React.createContext({
    companyId: undefined,
    bandings: [],
    isLoading: false
});

const CompanyBandingsProvider = ({ companyId, children }) => {
    const isLoading = useIsLoading([ENDPOINTS.API.COMPANY]);
    const bandings = useBandingsByCompanyById(companyId);
    return (
        <EditBandingModal>
            {({ createBanding, editBanding }) => (
                <CompanyBandingsContext.Provider value={{ companyId, bandings, isLoading, createBanding, editBanding }}>
                    {children}
                </CompanyBandingsContext.Provider>
            )}
        </EditBandingModal>
    );
};

export const useCompanyBandingsContext = () => {
    const state = useContext(CompanyBandingsContext);
    if (state === undefined) {
        throw new Error(`useCompanyBandingsContext must be used inside CompanyBandingsProvider`);
    }
    return state;
};

export default CompanyBandingsProvider;
