import { ENDPOINTS } from '../../../actions/Api/constants';
import _ from 'lodash';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import { EmptyCompany, setCompanyLoading } from './company';

const updateCompanies = (state, data) => {
    if (!data || !Object.entries(data).length) {
        return state;
    }
    const newState = _.clone(state);
    const index = _.findIndex(newState, x => x.companyId === data.companyId);

    if (index > -1) {
        newState[index] = data;
    } else {
        newState.push(data);
    }
    return newState;
};

const commitFetch = (state, action) => {
    const company = action.payload.data;
    return [company, ...state.filter(x => company.companyId !== x.companyId)];
};

const setCompanySyncing = (state, action) => {
    let companyIndex = _.findIndex(state, x => x.companyId === action.data.companyId);
    if (companyIndex < 0) {
        const company = _.cloneDeep(EmptyCompany);
        company.companyId = action.data.companyId;
        company.descriptor = '...';
        state.push(company);
        companyIndex = state.length - 1;
    }

    const newState = _.clone(state);
    setCompanyLoading(newState[companyIndex], true);
    return newState;
};

const initialState = [];

export function companies(state = initialState, action) {
    switch (action.type) {
        case RESET_REDUCER:
            return initialState;

        case ENDPOINTS.API.COMPANY_OFFLINE:
            return setCompanySyncing(state, action);

        case ENDPOINTS.API.COMPANY_OFFLINE_COMMIT:
            return commitFetch(state, action);

        case ENDPOINTS.API.OPPORTUNITY_FILTER_COMMIT:
            const {
                payload: { data }
            } = action;

            const dataCompanies = _.get(data, 'companies');

            if (dataCompanies) {
                return [
                    ...dataCompanies,
                    ...state.filter(x => !dataCompanies.some(dataCompany => dataCompany.companyId === x.companyId))
                ];
            }
            return state;
        case ENDPOINTS.API.COMPANY_DATA_GET:
            return action.data.companies.length ? updateCompanies(state, action.data.companies[0]) : state;
        case ENDPOINTS.API.COMPANY:
            //case ENDPOINTS.API.COMPANY_POST:
            return updateCompanies(state, action.data);
        case ENDPOINTS.API.COMPANIES:
            return [...action.data, ...state.filter(s => !action.data.some(a => a.companyId === s.companyId))];
        /*case ENDPOINTS.API.COMPANY_POST_ROLLBACK:
            return updateCompanies(state, {
                ...action.data.unmodified,
                syncing: false,
                error: action.payload.message
            });*/
        case ENDPOINTS.API.ADDENDUM_AGREEMENT_SEARCH:
        case ENDPOINTS.API.AGREEMENT_FILTER:
        case ENDPOINTS.API.AGREEMENT_GET:
        case ENDPOINTS.API.ADDENDUM_GET:
        case ENDPOINTS.API.PROMOTION_GET:
        case ENDPOINTS.API.CREATE_PROMOTION_AGREEMENT_FOR_COMPANY:
            const companies = _.get(action, 'data.companies', []);
            return [...companies, ...state.filter(s => !companies.some(a => a.companyId === s.companyId))];
        case ENDPOINTS.API.ADDRESS_POST:
            let company = state.find(x => x.companyId === action.companyId);
            let oldPrimaryAddress = company.addresses.find(x => x.isPrimaryAddress);
            oldPrimaryAddress.isPrimaryAddress = false;
            company.addresses.push(action.updatedAddress);

            return [...state.filter(x => x.companyId !== action.companyId), { ...company }];

        default:
            return state;
    }
}
