import React from 'react';
import moment from 'moment';

export const FormatDate = ({ date, showTime, fromNow, displayEmptyAs }) => {
    if (!date) {
        return displayEmptyAs ? displayEmptyAs : <span />;
    }

    const momentDate = moment(date);

    if (!momentDate.isValid() || momentDate.isBefore(moment().add(-10, 'years'))) {
        return displayEmptyAs ? displayEmptyAs : <span />;
    }

    return (
        <span>
            {showTime
                ? momentDate.format(`${process.env.REACT_APP_DATETIME_FORMAT}`)
                : momentDate.format(process.env.REACT_APP_DATE_FORMAT)}
            {fromNow && ` (${momentDate.fromNow()})`}
        </span>
    );
};
