import React from 'react';
import { useCompanyBandingsContext } from '../../Company/Provider/CompanyBandingsProvider';
import BandingsTableCard from './BandingsTableCard';

function CompanyBandingsTable({ showFilter }) {
    const { bandings, isLoading, editBanding } = useCompanyBandingsContext();
    return (
        <BandingsTableCard
            showFilter={showFilter}
            editBanding={editBanding}
            rows={bandings}
            isLoading={isLoading}
            tableIsNotInCard={true}
        />
    );
}

export default CompanyBandingsTable;
