import React from 'react';
import PersonEditModal from '../../Person/Modal/PersonEditModal';
import { Button } from 'reactstrap';

const CompanyAddPersonButton = ({ companyId }) => (
    <PersonEditModal>
        {({ createPerson }) => (
            <Button buttonText="Add Person" onClick={() => createPerson(companyId)}>
                Add Person
            </Button>
        )}
    </PersonEditModal>
);

export default CompanyAddPersonButton;
