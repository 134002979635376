import React from 'react';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import AgreementHeaderTableCard from './AgreementHeaderTableCard';

export const filterAgreementTemplates = agreementHeaders => {
    return agreementHeaders.filter(agreementHeader => {
        return agreementHeader.agreementType === 'Template';
    });
};

const AgreementTemplatesTable = ({ showFilter }) => (
    <>
        {/*
            // TEMPLATES ARE DOWNLOADED ON LOGIN.
            // If not seeing them, something has overwritten them
        <RedirectOnChangedAndNotLoggedIn
            change={1}
            onLoggedInDispatchArray={[
                agreementsQuery()
                    .filterAgreementType('Template')
                    .shouldFetchDetails(false)
                    .shouldFetchPersons(false)
                    .shouldFetchCompanies(false)
                    .run()
            ]}
        />*/}
        <AgreementHeaderTableCard
            filter={filterAgreementTemplates}
            title="Agreement Templates"
            tableIsNotInCard={true}
            noItemsText="There are no agreement templates to show"
            showFilter={showFilter}
            columns={[
                agreementHeaderColumnDefs.name,
                agreementHeaderColumnDefs.termsType,
                agreementHeaderColumnDefs.status
            ]}
        />
    </>
);

export default AgreementTemplatesTable;
