import { Route, Switch } from 'react-router';
import LoginPage from '../Authentication/Pages/LoginPage';
import ForgottenPasswordPage from '../Authentication/Pages/ForgottenPasswordPage';
import SetupAccountPage from '../Authentication/Pages/SetupAccountPage';
import React from 'react';
import ChangePasswordPage from '../Authentication/Pages/ChangePasswordPage';

const LoggedOutRouter = ({ userId }) => {
    if (userId > -1) {
        return null;
    }

    return (
        <Switch>
            <Route exact path="/changepassword/:token" component={ChangePasswordPage} />
            <Route exact path="/resetpassword/:token" component={ChangePasswordPage} />
            <Route exact path="/forgotpassword/" component={ForgottenPasswordPage} />
            <Route exact path="/setupaccount/:resetToken" component={SetupAccountPage} />
            <Route exact path="*" component={LoginPage} />
        </Switch>
    );
};

export default LoggedOutRouter;
