import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import useModalForm from '../../common/Hooks/useModalForm';
import StatusChangeForm from '../Forms/StatusChangeForm';

let title;
let agreementType;

const StatusChangeModal = ({ children }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();

    return (
        <React.Fragment>
            {children({
                updateAgreementStatus: (id, type) => {
                    title = `${type} Agreement`;
                    agreementType = type;
                    openModal(id, type);
                },
                toggle
            })}
            <Modal isOpen={isOpen} toggle={toggle} className="modal-sm">
                <AppModalHeader toggle={toggle}>{title}</AppModalHeader>
                {isOpen ? (
                    <ModalBody>
                        <StatusChangeForm
                            isModal={true}
                            statusChangeType={agreementType}
                            notes={formValue}
                            toggle={toggle}
                        />
                    </ModalBody>
                ) : null}
            </Modal>
        </React.Fragment>
    );
};

export default StatusChangeModal;
