import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { getLoadingState } from '../../../reducers/Rest/loadingStates';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import communication from '../communication';
import EditCommunicationModal from '../Modals/EditCommunicationModal';

class CommunicationsTableCard extends React.Component {
    static defaultProps = {
        breakpoint: 500,
        itemsPerPage: 5,
        noItemsText: 'There are no communications to show',
        columns: [
            communication.startDate,
            communication.activityRegarding,
            communication.assigneeId,
            communication.status,
            communication.note
        ]
    };

    renderButtons = createCommunication => {
        const { buttons, cardButtons, companyId } = this.props;
        const items = cardButtons || [];

        if (companyId) {
            if (buttons && buttons.includes('addCommunication')) {
                items.push({
                    text: 'Add Communication',
                    fontAwesomeName: 'fa-plus',
                    onClick: () => createCommunication(companyId)
                });
            }
        }

        return items;
    };

    render() {
        const { loadingStates, rows, openCompany, editRow, title, columns, ...rest } = this.props;
        const isLoading = getLoadingState(loadingStates, ENDPOINTS.API.VISIT_ACTIONS_FILTER_COMMIT).isLoading;
        const tableDefinition = new TableDefinition(columns);

        return (
            <EditCommunicationModal>
                {({ editCommunication, createCommunication }) => (
                    <ChoiceListFilteredTableNoDashboardData
                        id={title}
                        tableDefinition={tableDefinition}
                        rows={rows}
                        isLoading={isLoading}
                        showInCard={true}
                        cardButtons={this.renderButtons(createCommunication)}
                        title={title}
                        columnDefsId={communication.id}
                        tableDefinitionColumns={tableDefinition.columns}
                        onRowClick={editCommunication}
                        actions={{
                            openCompany
                        }}
                        {...rest}
                    />
                )}
            </EditCommunicationModal>
        );
    }
}

const mapStateToProps = ({ loadingStates }) => ({ loadingStates });

export default connect(mapStateToProps)(CommunicationsTableCard);
