import {
    basicColumnDef,
    choiceListColumnDef,
    dateColumnDef,
    idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';

const agreementAddendumDetailsColumnDefs = {
    id: idColumnDef('id', 'id'),
    agreementHeaderId: idColumnDef('Agreement Header Id', 'agreementHeaderId'),
    applyToProductId: idColumnDef('Apply to product Id', 'applyToProductId'),
    templateDetailId: idColumnDef('Template detail Id', 'templateDetailId'),
    AddendumChangeType: {
        ...choiceListColumnDef('Addendum Change Type', 'addendumChangeType', 'AddendumChangeType')
    },
    manufacturerDiscount: {
        ...basicColumnDef('Manufacturer Discount', 'manufacturerDiscount'),
        searchable: false
    },
    dealerDiscount: {
        ...basicColumnDef('Dealer Discount', 'dealerDiscount'),
        searchable: false
    },
    discountUnits: {
        ...choiceListColumnDef('Discount Unit', 'discountUnits', 'DiscountUnits')
    },
    createdDate: {
        ...dateColumnDef('Created', 'createdDate')
    },
    updatedDate: {
        ...dateColumnDef('Updated', 'updatedDate')
    }
};

export default agreementAddendumDetailsColumnDefs;
