import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { apiFleetProfileEdit } from '../../../actions/Api/FleetProfile/apiFleetProfileEdit';
import SelectInlineChoicelist from '../../common/InlineEdit/SelectInlineChoicelist';
import EditTextField from '../../common/EditField/EditTextField';
import EditDateField from '../../common/EditField/EditDateField';
import EditFieldDisplayValue from '../../common/EditField/EditFieldDisplayValue';
import { isPropertyHasError, isPropertySyncing } from '../../../reducers/Api/helper';
import { DisplayField } from '../../common/DisplayField/index';
import CaptionBadge from '../../common/FilteredTable/CaptionBadge';
import EditCheckboxInline from '../../common/InlineEdit/EditCheckboxInline';

class FleetProfileItem extends React.Component {
    save = updatedValues => {
        const { data: profile, save, schema } = this.props;
        const updatedFleetProfile = _.cloneDeep(profile);

        Object.keys(updatedValues).forEach(x => {
            updatedFleetProfile[x] = updatedValues[x];
        });

        schema.fleetProfile.forEach(section => {
            section.fields
                .filter(field => field.type === 'sum')
                .forEach(sumField => {
                    let newSum = 0;
                    sumField.fields.forEach(x => {
                        newSum += updatedFleetProfile[x];
                    });
                    updatedFleetProfile[sumField.field] = newSum;
                });
        });

        //convert boolean hasSalarySacrifice back to a 'Y' or 'N' string. Y === true, N === false
        updatedFleetProfile.hasSalarySacrifice = updatedFleetProfile.hasSalarySacrifice ? 'Y' : 'N';

        save(updatedFleetProfile);
    };

    saveNew = (field, value, type) => {
        const { data: profile, save } = this.props;
        const updatedFleetProfile = _.cloneDeep(profile);

        const originalValue = profile[field];

        if (type === 'number') {
            value = parseInt(value, 10);
        }

        if (originalValue !== value) {
            updatedFleetProfile[field] = value;
            if (field === 'numberOfCars' || field === 'numberOfCommercials') {
                updatedFleetProfile.fleetSize =
                    updatedFleetProfile.numberOfCars + updatedFleetProfile.numberOfCommercials;
            }
            save(updatedFleetProfile);
        }
    };

    choiceListLabelMapping = name => {
        switch (this.props.choiceListLabelMapping) {
            case 'trimMonths':
                return value => (value.split(' ').pop() === 'Months' ? value.substring(0, value.length - 6) : value);
            default:
                return x => x;
        }
    };

    renderItem = () => {
        const { label, field, type, readonly, multiple, choiceList, resources, data: profile } = this.props;

        let value = _.get(profile, field, '');
        value = value || value === 0 ? value : '';

        if (readonly) {
            const displayValue =
                type === 'choiceList'
                    ? value
                        .toString()
                        .split(',')
                        .filter(x => x.length > 0)
                        .map(x => <CaptionBadge key={x} resources={resources} choiceList={choiceList} caption={x} />)
                    : value;

            return (
                <EditFieldDisplayValue
                    title={label}
                    id={profile.fleetProfileId}
                    field={field}
                    value={displayValue}
                    type={type === 'sum' ? 'number' : 'text'}
                    show
                    isSyncing={isPropertySyncing(profile, field)}
                    isError={isPropertyHasError(profile, field)}
                />
            );
        }

        switch (type) {
            case 'sum':
            case 'percent':
                return (
                    <EditFieldDisplayValue
                        title={label}
                        id={profile.fleetProfileId}
                        field={field}
                        value={value}
                        type={type === 'sum' ? 'number' : 'text'}
                        show
                        isSyncing={isPropertySyncing(profile, field)}
                        isError={isPropertyHasError(profile, field)}
                    />
                );

            case 'text':
            case 'number':
                return (
                    <EditTextField
                        title={label}
                        id={profile.fleetProfileId}
                        field={field}
                        value={value}
                        type={type}
                        isSyncing={isPropertySyncing(profile, field)}
                        isError={isPropertyHasError(profile, field)}
                        onChanged={this.save}
                    />
                );

            case 'date':
                return (
                    <EditDateField
                        title={label}
                        id={profile.fleetProfileId}
                        field={field}
                        value={value}
                        type={type}
                        isSyncing={isPropertySyncing(profile, field)}
                        isError={isPropertyHasError(profile, field)}
                        onChanged={this.save}
                    />
                );

            case 'choiceList':
                return (
                    <SelectInlineChoicelist
                        choiceListName={choiceList}
                        value={value}
                        multiple={multiple}
                        objectId={profile.fleetProfileId}
                        propertyName={field}
                        title={label}
                        isSyncing={isPropertySyncing(profile, field)}
                        isError={isPropertyHasError(profile, field)}
                        save={this.saveNew}
                        mapChoiceValues={this.choiceListLabelMapping()}
                    />
                );
            case 'checkbox':
                return (
                    <EditCheckboxInline
                        value={value}
                        id={profile.fleetProfileId}
                        field={field}
                        save={this.save}
                        title={label}
                    />)

            default:
                return <DisplayField key={label} title={label} value={value} />;
        }
    };

    render = () => <li className="list-group-item">{this.renderItem()}</li>;
}

const mapStateToProps = state => ({
    schema: state.schema,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (profile, cancel) => dispatch(apiFleetProfileEdit(profile))
});

export default connect(mapStateToProps, mapDispatchToProps)(FleetProfileItem);
