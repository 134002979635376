import React from 'react';
import moment from 'moment/moment';

export const MiniCalendarDay = props => {
    const {
        day: { day, count },
        highlightedDay,
        clickDay
    } = props;
    return (
        <td onClick={() => clickDay(day)}>
            <span className="mini-calendar-day">{day.date()}</span>
            <span
                className={`mini-calendar-count ${dayClassFromCommCount(count)} ${
                    day.isSame(moment(), 'day')
                        ? 'mini-calendar-now'
                        : day.isBefore(highlightedDay)
                            ? 'mini-calendar-old'
                            : ''
                }`}
            />
        </td>
    );
};

const dayClassFromCommCount = count => {
    if (count === 0) {
        return 'cal-xs';
    } else if (count < 4) {
        return 'cal-sm';
    } else if (count < 10) {
        return 'cal-md';
    } else {
        return 'cal-lg';
    }
};
