import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, FormGroup } from 'reactstrap';
import FormikLabel from './FormikLabel';
import { PreventClickThrough } from '../Table/PreventClickThrough';
import { empty } from './FormikTableHelpers';

const FormikFormGroup = props => {
    const {
        formName,
        fieldName,
        children,
        title,
        hideLabel,
        type,
        className,
        labelClassName,
        collapsible,
        defaultCollapsed = true,
        values
    } = props;
    const [collapse, setCollapse] = useState(!defaultCollapsed);

    return (
        <FormGroup
            className={type === 'hidden' ? 'm-0 ' + className : '' + className}
            onClick={() => setCollapse(!collapse)}
        >
            {collapsible ? (
                <span className="formik-table-row-options-title">
                    <i style={{ marginRight: 5 }} className={`fa fa-sm fa-chevron-${collapse ? 'down' : 'up'}`} />
                </span>
            ) : null}

            {hideLabel ? null : (
                <>
                    <FormikLabel
                        fieldName={fieldName}
                        formName={formName}
                        title={title}
                        labelClassName={labelClassName}
                    />
                    {collapsible && !collapse && (
                        <i className="formik-table-row-options-title" style={{ textTransform: 'lowercase' }}>
                            {!empty(values[fieldName])
                                ? ` - filtering on ${fieldName}: ${values[fieldName]}`
                                : ' - no filters selected'}
                        </i>
                    )}
                </>
            )}
            {collapsible ? (
                <Collapse isOpen={collapse}>
                    <div onClick={PreventClickThrough}>{children}</div>
                </Collapse>
            ) : (
                children
            )}
        </FormGroup>
    );
};

FormikFormGroup.propTypes = {
    formName: PropTypes.string,
    fieldName: PropTypes.string.isRequired
};

export default FormikFormGroup;
