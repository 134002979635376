import React from 'react';
import ReactiveForm from '../../common/ReactiveForm';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import { connect } from 'react-redux';
import { apiCreateTemplateFile } from '../../../actions/Api/AgreementHeader/apiCreateAgreementTemplate';

const UploadAgreementTemplate = ({ resources, uploadTemplate, agreementHeaderId }) => {
    const handleSubmit = form => {
        uploadTemplate(form.evidence);
    };

    const initialValues = {
        agreementHeaderId: agreementHeaderId
    };

    return (
        <ReactiveForm
            initialValues={initialValues}
            resources={resources}
            formName={'TEMPLATE_UPLOAD'}
            handleSubmit={handleSubmit}
            hideCancelButton={true}
            hideSaveButton={true}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Dropzone
                    isTemplate={true}
                    hideLabel={true}
                    columnDef={agreementHeaderColumnDefs.template}
                    title="Drag template document here, or click to browse."
                />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
};

const mapDispatchToProps = dispatch => ({
    uploadTemplate: template => dispatch(apiCreateTemplateFile(template))
});

export default connect(
    null,
    mapDispatchToProps
)(UploadAgreementTemplate);
