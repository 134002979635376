import React from 'react';
import { DisplayField } from '../../common/DisplayField/index';

export const SidebarDisplayField = ({ title, value, onClick }) => (
    <li className="nav-item sidebar-nav-item border-bottom " onClick={onClick}>
        <div className="py-3 px-4 text-primary">
            <DisplayField title={title} value={value} />
        </div>
    </li>
);

export default SidebarDisplayField;
