import React from 'react';
import { useDispatch } from 'react-redux';
import meeting from '../meeting';
import { useCompanyMeetingsContext } from '../../Company/Provider/CompanyMeetingsProvider';
import MeetingsTableCard from './MeetingsTableCard';
import { openMeeting } from '../../../actions/Navigation/Meeting/meetingSelected';

export default function CompanyMeetingsTable() {
    let { companyId, meetings, isLoading } = useCompanyMeetingsContext();
    const dispatch = useDispatch();
    meetings.map(x => (x.assigneeId = x.assignee.id));
    meetings.map(x => (x.personId = x.person.id));
    return (
        <MeetingsTableCard
            rows={meetings.filter(meeting => meeting.action === 'Meeting')}
            companyId={companyId}
            editRow={meeting => dispatch(openMeeting(meeting.meetingId, meeting.descriptor))}
            buttons={[]}
            isLoading={isLoading}
            columns={[
                meeting.startDate,
                meeting.startTime,
                meeting.activityRegarding,
                meeting.assigneeId,
                meeting.status
            ]}
            title={'Meetings'}
            breakpoint={860}
            initialSortColumn={'start'}
            initialSortOrder={'asc'}
        />
    );
}
