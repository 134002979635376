import React from 'react';
import PropTypes from 'prop-types';
import { SaveCancelButton } from '../SaveCancelButton';
import FormikErrors from '../FormkControls/FormikErrors';
import AuditSummary from '../CommonForms/AuditSummary';
import { DebugSection } from '../DebugSection';

const FormikForm = props => {
    const { cancel, formikProps, resources, debugSections, children, hideMetaData } = props;

    return (
        <form onSubmit={formikProps.handleSubmit} className="mb-5">
            <div className="row">
                {children}
                <div className="col-12">
                    <SaveCancelButton pullRight onCancel={cancel} />
                </div>
                <div className="col-12">
                    <FormikErrors {...formikProps} />
                </div>
                {formikProps.values.createdDate && !hideMetaData && (
                    <div className="col-12">
                        <AuditSummary
                            createdDate={formikProps.values.createdDate}
                            updatedDate={formikProps.values.updatedDate}
                            createdBy={formikProps.values.createdBy}
                            updatedBy={formikProps.values.updatedBy}
                            choiceList={resources.choiceList}
                        />
                    </div>
                )}
                <DebugSection sections={debugSections} />
            </div>
        </form>
    );
};

FormikForm.propTypes = {
    formikProps: PropTypes.object,
    cancel: PropTypes.func,
    resources: PropTypes.object,
    debugSections: PropTypes.array
};

export default FormikForm;
