import React from 'react';
import connect from 'react-redux/es/connect/connect';
import FilteredRows from './FilteredRows';
import TextFilter from './TextFilter';

function ChoiceListAndTextFilteredRows({ resources, children, rows, searchFields, showFilter, filters }) {
    return (
        <TextFilter rows={rows} searchFields={searchFields}>
            {({ searchBox, textFilteredRows }) => (
                <FilteredRows
                    showFilter={showFilter}
                    filterOptions={filters}
                    resources={resources}
                    rows={textFilteredRows}
                    searchBox={searchBox}
                >
                    {({ filter, filteredRows }) => children({ filter, filteredRows })}
                </FilteredRows>
            )}
        </TextFilter>
    );
}

const mapStateToProps = ({ resources }) => ({ resources });

export default connect(mapStateToProps)(ChoiceListAndTextFilteredRows);
