import React from 'react';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { useDispatch, useSelector } from 'react-redux';
import { apiAddendumDetailUpdate } from '../../../actions/Api/AgreementHeader/apiAddendumDetailUpdate';
import ReactiveForm from '../../common/ReactiveForm';
import { Alert, Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import useModalForm from '../../common/Hooks/useModalForm';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import agreementAddendumDetailsColumnDefs from '../../AgreementAddendumDetails/agreementAddendumDetailsColumnDefs';
import useProductOptions from '../../AgreementDetails/Hooks/useProductOptions';
import useProductFamilyOptions from '../../AgreementDetails/Hooks/useProductFamilyOptions';
import { selectFamliiyValidationSchema } from './AddendumApplyMinimumDiscountToProductButton';

const AddendumApplyMaximumDiscountToProductButton = ({ agreementHeaderId }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();
    const dispatch = useDispatch();
    const newProduct = useSelector(state => state.newProduct);
    const productFamily = useSelector(state => state.productFamily);
    const resources = useSelector(state => state.resources);

    const productOptions = useProductOptions(newProduct, true);
    const productFamilyOptions = useProductFamilyOptions(productFamily, true);

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle}>
                <AppModalHeader toggle={toggle}>Apply Maximum Discount to Product</AppModalHeader>
                {isOpen ? (
                    <ModalBody>
                        <Alert color={'primary'}>
                            The selected product will be updated in all addended agreements. If a manufacturer discount
                            is set then any associated agreements with a higher manufacturer discount will be decreased
                            to match the provided discount. The same applies to the dealer discount
                        </Alert>
                        <ReactiveForm
                            initialValues={formValue}
                            resources={resources}
                            cancel={toggle}
                            handleSubmit={form =>
                                dispatch(
                                    apiAddendumDetailUpdate(
                                        {
                                            ...form,
                                            agreementHeaderId,
                                            addendumChangeType: 'ApplyMaximumDiscountToProduct'
                                        },
                                        'Success',
                                        'Maximum Discount Applied',
                                        toggle
                                    )
                                )
                            }
                            validationSchema={selectFamliiyValidationSchema}
                        >
                            <ReactiveForm.Section>
                                <ReactiveForm.Choicelist
                                    columnDef={basicColumnDef('Product Family', 'productFamilyId')}
                                    placeholder="Product Family"
                                    options={productFamilyOptions}
                                />
                                <ReactiveForm.Choicelist
                                    columnDef={agreementAddendumDetailsColumnDefs.applyToProductId}
                                    placeholder="Product Id"
                                    options={({ values }) => {
                                        return productOptions.filter(x => x.productFamilyId === values.productFamilyId);
                                    }}
                                />
                                <ReactiveForm.Text
                                    columnDef={agreementAddendumDetailsColumnDefs.dealerDiscount}
                                    placeholder="Minimum Dealer Discount"
                                />
                                <ReactiveForm.Text
                                    columnDef={agreementAddendumDetailsColumnDefs.manufacturerDiscount}
                                    placeholder="Minimum Manufacturer Discount"
                                />
                                <ReactiveForm.Choicelist
                                    columnDef={agreementAddendumDetailsColumnDefs.discountUnits}
                                    placeholder="Discount Unit"
                                />
                            </ReactiveForm.Section>
                        </ReactiveForm>
                    </ModalBody>
                ) : null}
            </Modal>
            <ActionButton
                buttonText={'Apply Maximum Discount To Product'}
                fontawesomeIcon="fa-edit"
                bootstrapColor="secondary"
                onClickAction={() => openModal({})}
            />
        </React.Fragment>
    );
};

export default AddendumApplyMaximumDiscountToProductButton;
