import React from 'react';
import { PageSectionTitle } from './PageSectionTitle';
import { Card, CardBody } from 'reactstrap';
import RedirectNotLoggedIn from '../../app/RedirectNotLoggedIn';

class PageSection extends React.Component {
    render = () => {
        const { title, children, onBackClicked, titleContent, titleExtra } = this.props;
        return (
            <div className="px-3 pb-5">
                {title || titleContent ? (
                    <Card className={`mt-4 page-title-background pt-2 pb-2 mb-3 shadow-sm simple-card`}>
                        <RedirectNotLoggedIn />
                        <CardBody className="py-0">
                            <table>
                                <tbody>
                                    <tr>
                                        {onBackClicked ? (
                                            <td className="align-top">
                                                <button
                                                    className="navbar-btn align-text-top"
                                                    type="button"
                                                    onClick={() => onBackClicked()}
                                                >
                                                    <i className="fa fa-arrow-left" />
                                                </button>
                                            </td>
                                        ) : null}
                                        <td className="align-middle pl-2">
                                            <PageSectionTitle title={title} />
                                            {titleExtra}
                                        </td>
                                    </tr>
                                    {titleContent ? (
                                        <tr>
                                            <td />
                                            <td className="pl-2">{titleContent}</td>
                                        </tr>
                                    ) : null}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                ) : null}
                <div className="py-0">{children}</div>
            </div>
        );
    };
}

export default PageSection;
