import React from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import {
    apiAuthPasswordChange,
    apiAuthPasswordChangeLoggedIn
} from '../../../actions/ApiAuth/Password/apiAuthPasswordReset';
import ReactiveForm from '../../common/ReactiveForm';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import SimpleCard from '../../common/Card/SimpleCard';
import validation from '../../common/PasswordValidation';
import { cookieGetToken } from '../../../reducers/cookies';

const UpdatePasswordForm = ({ token, user, resources, sendChangePassword }) => {
    const handleSubmit = ({ emailAddress, token, password, currentPassword }) => {
        sendChangePassword(user, emailAddress, token, password, currentPassword);
    };

    const initialValues = {
        emailAddress: user ? user.email : '',
        token: token || cookieGetToken(),
        currentPassword: token || '',
        password: '',
        confirmPassword: ''
    };

    return (
        <SimpleCard>
            <ReactiveForm
                initialValues={initialValues}
                resources={resources}
                formName={'CHANGE_PASSWORD_FORM'}
                validationSchema={validation}
                handleSubmit={handleSubmit}
                hideCancelButton={true}
                saveButtonContent={'Submit'}
                hideDebug={true}
            >
                <ReactiveForm.Section>
                    <div className="p-2">
                        <div className="w-100 text-center">
                            <h6>Complete the form to change your password.</h6>
                        </div>
                        <hr />
                    </div>
                    <ReactiveForm.Text
                        placeholder={'Email Address'}
                        columnDef={{ ...basicColumnDef('Email Address', 'emailAddress') }}
                        disabled={!token && user && user.email}
                    />
                    {!token ? (
                        <ReactiveForm.Text
                            type={'password'}
                            placeholder={'Current Password'}
                            columnDef={{ ...basicColumnDef('Current Password', 'currentPassword') }}
                        />
                    ) : (
                        <></>
                    )}
                    <div className="py-2">
                        <Alert>
                            <strong>Your new password must meet the following requirements:</strong>
                            <ul style={{ listStylePosition: 'inside', margin: '5px', padding: '4px' }}>
                                <li>At least 8 characters</li>
                                <li>At least one uppercase character</li>
                                <li>At least one lowercase character</li>
                                <li>At least one number</li>
                                <li>At least one special character</li>
                            </ul>
                        </Alert>
                    </div>
                    <ReactiveForm.Text
                        type={'password'}
                        placeholder={'New Password'}
                        columnDef={{ ...basicColumnDef('New Password', 'password') }}
                    />
                    <ReactiveForm.Text
                        type={'password'}
                        placeholder={'Confirm New Password'}
                        columnDef={{ ...basicColumnDef('Confirm New Password', 'confirmPassword') }}
                    />
                </ReactiveForm.Section>
            </ReactiveForm>
        </SimpleCard>
    );
};

const mapStateToProps = (state, props) => ({
    user: state.user,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    sendChangePassword: (user, emailAddress, token, password, currentPassword) =>
        user.userId !== -1
            ? dispatch(apiAuthPasswordChangeLoggedIn(emailAddress, token, password, currentPassword))
            : dispatch(apiAuthPasswordChange(emailAddress, token, password, currentPassword))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdatePasswordForm);
