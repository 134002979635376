import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';

const CalendarFilter = ({ filter }) => {
    return (
        <div className="mb-4">
            <ListGroup>
                <ListGroupItem active tag="h5">
                    Filter
                </ListGroupItem>
                <ListGroupItem>{filter}</ListGroupItem>
            </ListGroup>
        </div>
    );
};

export default CalendarFilter;
