import React, { Fragment, Component } from 'react';
import { Popover, PopoverBody } from 'reactstrap';

export default class IsPrimaryPerson extends Component {
    state = {
        popoverOpen: false
    };

    render() {
        return (
            <Fragment>
                <div
                    id={this.props.id}
                    style={{
                        width: 20,
                        height: 20,
                        marginRight: 10
                    }}
                    className="float-left d-block"
                    onMouseOver={() => this.setState({ popoverOpen: true })}
                    onMouseOut={() => this.setState({ popoverOpen: false })}
                >
                    <div className="position-relative">
                        <i className={`position-absolute mt-2 ml-2 fa d-inline fa-xl mr-3 fa-check`} />
                    </div>
                </div>
                <Popover key="2" placement="top" isOpen={this.state.popoverOpen} target={this.props.id}>
                    <PopoverBody>This is the primary person for the company.</PopoverBody>
                </Popover>
            </Fragment>
        );
    }
}
