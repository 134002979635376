import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import EditMeetingForm from '../Forms/EditMeetingForm';
import { AppModalHeader } from '../../common/AppModalHeader';
import useModalForm from '../../common/Hooks/useModalForm';
import { createFakeId } from '../../../reducers/util';

const EditMeetingModal = ({ children, className }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();

    return (
        <>
            {isOpen ? (
                <Modal isOpen={isOpen} toggle={toggle} className={className}>
                    <AppModalHeader toggle={toggle}>
                        {`${formValue.meetingId < 0 ? 'Create' : 'Edit'} Meeting`}
                    </AppModalHeader>
                    <ModalBody>
                        <EditMeetingForm
                            onCancel={toggle}
                            meetingId={formValue.meetingId}
                            companyId={formValue.companyId}
                            person={formValue.person}
                            schema="meeting"
                        />
                    </ModalBody>
                </Modal>
            ) : null}
            {children({
                editMeeting: meeting => openModal(meeting),
                createMeeting: companyId => openModal({ meetingId: createFakeId(), companyId })
            })}
        </>
    );
};

export default EditMeetingModal;
