import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import { getFieldsNotUpdated, setPropertiesSyncing } from '../helper';
import moment from 'moment';
import { addColorToVisitAction, addColorToVisitActions } from './VisitAction';
import _ from 'lodash';

const defaultState = [];

const propertiesToIgnoreForErrors = [
    'person',
    'company',
    'visitActionId',
    'descriptor',
    'assignee',
    'updatedDate',
    'start',
    'end',
    'assigneeName',
    'calendarOption'
];

export const getVisitActionUpdateErrors = (local, remote) => {
    return getFieldsNotUpdated(local, remote, propertiesToIgnoreForErrors);
};

const updateVisitActionPost = (state, { data }) => {
    const original = state.find(x => data.visitActionId === x.visitActionId);
    if (original) {
        setPropertiesSyncing(original, data);
    }
    addColorToVisitAction(data);
    return [data, ...state.filter(oldComm => data.visitActionId !== oldComm.visitActionId)];
};

const postCommit = (state, action) => {
    const {
        meta: { modified },
        payload: { data: remote }
    } = action;

    addColorToVisitActions([remote]);

    remote.assigneeId = _.get(remote, 'assignee.id', '').toString();
    return [
        remote,
        ...state.filter(x => modified.visitActionId !== x.visitActionId && remote.visitActionId !== x.visitActionId)
    ];
};

function filterToMatchFunction({ assigneeId, companyId, status, meetingId, endDate, startDate, ...rest }) {
    const start = startDate === undefined ? undefined : moment(startDate);
    const end = endDate === undefined ? undefined : moment(endDate);

    return visitAction => {
        return (
            (!assigneeId || visitAction.assignee.id === assigneeId) &&
            (!companyId || visitAction.company.id === companyId) &&
            ((!start && !end) ||
                ((!start || start.isBefore(moment(visitAction.startDate))) &&
                    (!end || end.isAfter(moment(visitAction.startDate))))) &&
            (!status || status.indexOf(visitAction.status) > -1) &&
            (!meetingId || visitAction.meetingId === meetingId)
        );
    };
}

export const filterState = (state, filter, newData) => {
    const matchesState = filterToMatchFunction(filter);
    return state.filter(x => !newData.some(newComm => x.visitActionId === newComm.visitActionId) && !matchesState(x));
};

export const visitActions = (state = defaultState, action) => {
    switch (action.type) {
        case RESET_REDUCER:
            return defaultState;
        case ENDPOINTS.API.VISIT_ACTIONS_FILTER_COMMIT:
            const {
                payload: { data, filter }
            } = action;
            addColorToVisitActions(data);
            return [
                ...data.map(x => ({
                    ...x,
                    assigneeId: _.get(x, 'assignee.id', '').toString()
                })),
                ...filterState(state, filter, data)
            ];
        case ENDPOINTS.API.VISIT_ACTION_POST_ROLLBACK:
            const rollbackTo = {
                ...action.meta.unmodified,
                syncing: false,
                error: action.payload.message
            };
            return [rollbackTo, ...state.filter(oldComm => rollbackTo.visitActionId !== oldComm.visitActionId)];
        case ENDPOINTS.API.VISIT_ACTION_POST:
            return updateVisitActionPost(state, action);
        case ENDPOINTS.API.VISIT_ACTION_POST_COMMIT:
            return postCommit(state, action);
        default:
            return state;
    }
};
