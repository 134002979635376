import React from 'react';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import meeting from '../meeting';
import EditMeetingModal from '../Modals/EditMeetingModal';
import { useIsLoading } from '../../common/Hooks/useIsLoading';

const renderButtons = (createMeeting, buttons, cardButtons, companyId) => {
    const items = cardButtons || [];

    if (companyId && buttons && buttons.includes('addMeeting')) {
        items.push({
            text: 'Add Meeting',
            fontAwesomeName: 'fa-plus',
            onClick: () => createMeeting(companyId)
        });
    }

    return items;
};

const MeetingTableCard = ({
    loadingStates,
    rows,
    openCompany,
    editRow,
    title,
    columns,
    buttons,
    cardButtons,
    companyId,
    ...rest
}) => {
    const isLoading = useIsLoading([ENDPOINTS.API.MEETING_FILTER]);
    const tableDefinition = new TableDefinition(columns);

    return (
        <EditMeetingModal>
            {({ createMeeting, editMeeting }) => (
                <ChoiceListFilteredTableNoDashboardData
                    id={title}
                    tableDefinition={tableDefinition}
                    rows={rows}
                    isLoading={isLoading}
                    showInCard={true}
                    cardButtons={renderButtons(createMeeting, buttons, cardButtons, companyId)}
                    title={title}
                    columnDefsId={meeting.id}
                    tableDefinitionColumns={tableDefinition.columns}
                    onRowClick={meeting => (editRow ? editRow(meeting) : editMeeting(meeting))}
                    actions={{
                        openCompany
                    }}
                    {...rest}
                />
            )}
        </EditMeetingModal>
    );
};

MeetingTableCard.defaultProps = {
    breakpoint: 500,
    itemsPerPage: 5,
    noItemsText: 'There are no meetings to show',
    columns: [meeting.startDate, meeting.activityRegarding, meeting.assigneeId, meeting.status, meeting.body]
};

export default MeetingTableCard;
