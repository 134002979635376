import { ENDPOINTS } from '../constants';
import { handleErrorWithToastUnlessUnauthorised, makeGetRequest } from '../apiHelpers';

export const companyDataQuery = (
    query = {
        fetchCompanies: true
    }
) => ({
    forCompanyId: companyId => companyDataQuery({ ...query, companyId }),
    shouldFetchDemos: fetchDemos => companyDataQuery({ ...query, fetchDemos }),
    shouldFetchBandings: fetchBandings => companyDataQuery({ ...query, fetchBandings }),
    shouldFetchOpportunities: fetchAgreements => companyDataQuery({ ...query, fetchAgreements }),
    shouldFetchAgreements: fetchOpportunities => companyDataQuery({ ...query, fetchOpportunities }),
    setCallback: onResultCallback => companyDataQuery({ ...query, onResultCallback }),
    run: () => apiCompanyData(query)
});

const type = ENDPOINTS.API.COMPANY_DATA_GET;

const apiCompanyData = filter => async (dispatch, getState) => {
    const { onResultCallback, ...urlParams } = filter;

    if (!urlParams.companyId || !getState().offline.online) {
        return false;
    }

    makeGetRequest(['company', 'data'], urlParams)
        .then(({ data }) => {
            dispatch({
                type,
                data
            });

            if (data && onResultCallback) {
                onResultCallback(data);
            }

            return true;
        })
        .catch(error => handleErrorWithToastUnlessUnauthorised(dispatch, getState, error, type, 'Communications'));
};
