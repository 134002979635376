import React from 'react';
import { connect } from 'react-redux';
import { apiPersonEdit } from '../../../actions/Api/Person/apiPersonEdit';
import ReactiveForm from '../../common/ReactiveForm';
import person from '../person';
import { createFakeId } from '../../../reducers/util';
import validation from './person.validation';

const emptyForm = {
    companyId: -1,
    createdBy: 0,
    updatedBy: 0,
    createdDate: '0001-01-01T00:00:00',
    updatedDate: '0001-01-01T00:00:00',
    id: '',
    personId: -1,
    privacyNotice: false,
    salutation: '',
    title: '',
    firstName: '',
    lastName: '',
    businessEmail: '',
    businessPhone: '',
    mobilePhone: '',
    ddiPhone: '',
    gdprEmail: '',
    gdprDirectMail: '',
    gdprTelephone: '',
    gdprSms: '',
    isPrimaryPerson: false
};

const PersonForm = ({ save, cancel, people, personId, companyId, resources }) => {
    let initialValues = people.find(person => person.personId === personId);

    if (!initialValues && personId < 0) {
        initialValues = {
            ...emptyForm,
            companyId,
            personId: createFakeId()
        };
    }

    return (
        <ReactiveForm
            initialValues={initialValues}
            validationSchema={validation}
            resources={resources}
            handleSubmit={values => save(values, cancel)}
            cancel={cancel}
        >
            <ReactiveForm.Section title="Personal Information">
                <ReactiveForm.Choicelist columnDef={person.salutation} />
                <ReactiveForm.Text columnDef={person.firstName} />
                <ReactiveForm.Text columnDef={person.lastName} />
                <ReactiveForm.Text columnDef={person.title} />
            </ReactiveForm.Section>
            <ReactiveForm.Section title="Contact Details">
                <ReactiveForm.Text columnDef={person.businessEmail} />
                <ReactiveForm.Text columnDef={person.businessPhone} />
                <ReactiveForm.Text columnDef={person.mobilePhone} />
                <ReactiveForm.Text columnDef={person.ddiPhone} />
            </ReactiveForm.Section>
            <ReactiveForm.Section title="GDPR">
                <ReactiveForm.Choicelist columnDef={person.gdprEmail} />
                <ReactiveForm.Choicelist columnDef={person.gdprDirectMail} />
                <ReactiveForm.Choicelist columnDef={person.gdprTelephone} />
                <ReactiveForm.Choicelist columnDef={person.gdprSms} />
                <ReactiveForm.Checkbox columnDef={person.isPrimaryPerson} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
};

const mapStateToProps = state => ({
    people: state.people,
    loadingStates: state.loadingStates,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (person, cancel) => {
        dispatch(apiPersonEdit(person));
        cancel();
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PersonForm);
