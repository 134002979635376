import React, { useContext } from 'react';
import { isPropertyHasError, isPropertySyncing } from '../../../reducers/Api/helper';
import SelectInlineChoicelist from '../../common/InlineEdit/SelectInlineChoicelist';
import PersonDetails from '../../Person/Components/PersonDetails';
import { CompanyPeopleContext } from '../../Company/Provider/CompanyPeopleProvider';
import { useMeetingContext } from '../Provider/MeetingProvider';

const findContact = (people, meeting) => people.find(x => x.personId === meeting.personId);

const mapPeopleToSelectOptions = people =>
    people.map(x => ({ key: x.personId.toString(), value: `${x.firstName} ${x.lastName}` }));

const MeetingAndForm = () => {
    const { meeting, saveMeeting } = useMeetingContext();
    const { isLoading, companyId, people } = useContext(CompanyPeopleContext);
    const contact = findContact(people, meeting);

    return contact ? (
        <PersonDetails
            key={contact.personId}
            title={'Contact'}
            person={contact}
            companyId={companyId}
            isLoading={isLoading}
        >
            <SelectInlineChoicelist
                options={mapPeopleToSelectOptions(people)}
                value={meeting.personId ? meeting.personId.toString() : ''}
                objectId={meeting.meetingId}
                propertyName={'personId'}
                stack
                required
                isSyncing={isPropertySyncing(meeting, 'personId')}
                isError={isPropertyHasError(meeting, 'personId')}
                save={(a, newPersonId, c) => {
                    const { personId, person, ...rest } = meeting;
                    saveMeeting({ personId: parseInt(newPersonId, 10), person: { id: newPersonId }, ...rest });
                }}
            />
        </PersonDetails>
    ) : null;
};

export default MeetingAndForm;
