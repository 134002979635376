import React, { useContext } from 'react';
import { EditItemRowButton } from '../../common/Buttons/TableButtons';
import { connect } from 'react-redux';
import { AgreementHeaderContext } from '../../AgreementHeader/Provider/AgreementHeaderProvider';

const AgreementDetailEditButton = ({ detail, products, hideEditButton, editAgreementDetail }) => {
    const { isLive } = useContext(AgreementHeaderContext);

    if (isLive || !!detail.deleted) {
        return null;
    }

    return (
        <div style={{ float: 'right' }}>
            {!hideEditButton ? (
                <EditItemRowButton
                    onClick={() => {
                        const product = products.find(x => x.id === detail.productId);
                        editAgreementDetail(
                            detail.id,
                            detail.agreementHeaderId,
                            product.productFamilyId,
                            detail.productId,
                            false,
                            true
                        );
                    }}
                />
            ) : null}
        </div>
    );
};
const mapStateToProps = state => ({
    products: state.newProduct,
    agreementHeaders: state.agreementHeaders
});

export default connect(mapStateToProps)(AgreementDetailEditButton);
