import React, { useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import { DeleteTableRowButton } from '../../common/Buttons/TableButtons';
import { AgreementHeaderContext } from '../../AgreementHeader/Provider/AgreementHeaderProvider';
import { apiAgreementDetailDelete } from '../../../actions/Api/AgreementDetail/apiAgreementDetailDelete';
import ConfirmButton from '../../common/Buttons/ConfirmButton';

const ButtonDeleteDetailsInProductFamily = ({ deleteDetails, products, productFamiliyId, toggle }) => {
    const {
        agreementState: { canAmend }
    } = useContext(AgreementHeaderContext);

    const deletableDetails = useMemo(
        () =>
            products
                .map(product => product.details)
                .flat()
                .filter(({ deleted }) => deleted === null)
                .map(({ id }) => id),
        [products]
    );

    const popoverId = `dfam${productFamiliyId}`;

    const visibility = !canAmend || !deletableDetails.length ? 'invisible' : 'visible';

    return (
        <ConfirmButton
            popoverId={popoverId}
            title="Delete Products"
            isWorking={false}
            isWorkingTitle="Deleting Products"
            onConfirm={() => deleteDetails(deletableDetails, 'Success', 'Agreement Details Deleted', toggle)}
        >
            {({ toggle }) => <DeleteTableRowButton visibility={visibility} id={popoverId} onClick={toggle} />}
        </ConfirmButton>
    );
};

const mapDispatchToProps = dispatch => ({
    deleteDetails: (ids, successTitle, successMessage, callback) =>
        dispatch(apiAgreementDetailDelete(ids, successTitle, successMessage, callback))
});

export default connect(
    undefined,
    mapDispatchToProps
)(ButtonDeleteDetailsInProductFamily);
