import React from 'react';

class Page404 extends React.Component {
    render = () => (
        <div className="login-background d-flex no-block justify-content-center align-items-center">
            <h2 className="logo-font text-white shadow-2">404 - Page not found.</h2>
        </div>
    );
}

export default Page404;
