import React from 'react';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import ActionButtons from '../../common/ActionButtons/ActionButtons';
import { safeDispatchPush } from '../../../reducers/util';
import { useCompanyContext } from '../../Company/Provider/CompanyProvider';
import { useMeetingContext } from '../../Meeting/Provider/MeetingProvider';
import { useCompanyOpportunitiesContext } from '../../Company/Provider/CompanyOpportunitiesProvider';
import ActionButton from '../../common/ActionButtons/ActionButton';
import EditVisitActionModal from '../../VisitActions/Modals/EditVisitActionModal';
import EditMeetingModal from '../Modals/EditMeetingModal';

function ViewMeetingPageButtons({ sendEmail }) {
    const dispatch = useDispatch();
    const resources = useSelector(state => state.resources);
    const { meeting, cancelMeeting, completeMeeting } = useMeetingContext();
    const { company } = useCompanyContext();
    const { addOpportunity } = useCompanyOpportunitiesContext();
    //const actions = [];
    const templatesLoaded = !!_.get(resources, 'emailTemplate', []).length;

    return (
        <EditMeetingModal>
            {({ createMeeting, editMeeting }) => (
                <EditVisitActionModal>
                    {({ createVisitAction }) => (
                        <ActionButtons>
                            <ActionButton
                                buttonText="Add Opportunity"
                                fontawesomeIcon="fa-plus"
                                bootstrapColor="secondary"
                                onClickAction={() => addOpportunity({ companyId: company.companyId })}
                            />

                            <ActionButton
                                buttonText="Add Visit Action"
                                fontawesomeIcon="fa-plus"
                                bootstrapColor="secondary"
                                onClickAction={() => createVisitAction(company.companyId, meeting.meetingId)}
                            />
                            <ActionButton
                                buttonText="Add Meeting"
                                fontawesomeIcon="fa-plus"
                                bootstrapColor="secondary"
                                onClickAction={() => createMeeting(company.companyId)}
                            />
                            {meeting.status === 'Pending' || meeting.status === 'Overdue' ? (
                                <ActionButton
                                    buttonText="Edit meeting"
                                    fontawesomeIcon="fa-edit"
                                    bootstrapColor="success"
                                    onClickAction={() => editMeeting(meeting)}
                                />
                            ) : null}
                            {meeting.status === 'Pending' || meeting.status === 'Overdue' ? (
                                <ActionButton
                                    buttonText="Complete meeting"
                                    fontawesomeIcon="fa-edit"
                                    bootstrapColor="success"
                                    onClickAction={() => completeMeeting(meeting)}
                                />
                            ) : null}

                            <ActionButton
                                buttonText="Create visit report email"
                                fontawesomeIcon="fa-envelope"
                                bootstrapColor="success"
                                onClickAction={() =>
                                    safeDispatchPush(dispatch, push, ['communication', 'email', meeting.meetingId])
                                }
                                loading={!templatesLoaded}
                            />
                            {meeting.status === 'Pending' ? (
                                <ActionButton
                                    buttonText="Cancel Appointment"
                                    fontawesomeIcon="fa-ban"
                                    bootstrapColor="secondary"
                                    onClickAction={() => cancelMeeting(meeting)}
                                />
                            ) : null}
                        </ActionButtons>
                    )}
                </EditVisitActionModal>
            )}
        </EditMeetingModal>
    );

    // addActionButton(
    //     actions,
    //     meeting.status === 'Pending' && user.userId === meeting.assignee.id,
    //     'Send invite to my calendar',
    //     'fa-calendar',
    //     'success',
    //     () => dispatch(apiCalendarInvite(meeting.meetingId, url)
    // );
}

export default ViewMeetingPageButtons;
