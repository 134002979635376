import React, { useContext } from 'react';
import { AgreementHeaderSearchContext } from './AgreementHeaderSearchProvider';
import _ from 'lodash';
import AgreementHeaderTableCard from '../Tables/AgreementHeaderTableCard';
import SimpleCardCollapsable from '../../common/Card/SimpleCardCollapsable';

const ITEMS_PER_PAGE = 5;

const AgreementSearchResults = ({ agreementSearchForm }) => {
    const {
        isLoading,
        hasSearched,
        agreements,
        title,
        columns,
        checkboxColumn,
        removeItemsFromSearchResults
    } = useContext(AgreementHeaderSearchContext);

    return (
        <SimpleCardCollapsable
            collapseName={'Search Agreements'}
            title={`${title} (${Array.isArray(agreements) ? _.size(agreements) : 0})`}
            isLoading={isLoading}
            buttons={[]}
        >
            {agreementSearchForm}
            <br />
            <br />
            {!isLoading && hasSearched && agreements.length ? (
                <AgreementHeaderTableCard
                    rows={agreements || []}
                    isLoading={isLoading}
                    itemsPerPage={ITEMS_PER_PAGE}
                    tableIsNotInCard={true}
                    checkboxColumn={checkboxColumn}
                    checkButtonLabel={count =>
                        count === 0 ? 'Add' : `Add ${count} Agreement${count === 1 ? '' : 's'}`
                    }
                    submitFunctionType={'Add'}
                    columns={columns}
                    removeItemsFromSearchResults={removeItemsFromSearchResults}
                />
            ) : null}
        </SimpleCardCollapsable>
    );
};

export default AgreementSearchResults;
