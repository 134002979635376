import {
    basicColumnDef,
    choiceListColumnDef,
    simpleCheckboxColumnDef,
    testTemplateButtonColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';

export const templateColumnDefs = {
    id: {
        ...basicColumnDef('Id', 'id'),
        mapping: (_, values) => values.id
    },
    dropZone: {
        ...basicColumnDef('Upload Template', 'template'),
        searchable: false,
        sort: false,
        multiple: false,
        accept: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        hideLabel: true
    },
    name: {
        ...basicColumnDef('Name', 'name')
    },
    type: {
        ...choiceListColumnDef('Type', 'type', 'agreementTemplateTypes')
    },
    active: {
        ...basicColumnDef('Active', 'active')
    },
    deleted: {
        ...basicColumnDef('Delete', 'deleted')
    },
    confirmDelete: {
        ...basicColumnDef('Confirm Delete', 'confirmDelete')
    },
    activeCheckbox: columnCaption => ({
        ...simpleCheckboxColumnDef(columnCaption, 'activeCheckbox', 'active')
    }),
    testButton: columnCaption => ({
        ...testTemplateButtonColumnDef(columnCaption, 'testTemplate')
    })
};
