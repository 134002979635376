import React from 'react';
import { FieldLabel } from '../DisplayField/FieldLabel';
import FilterOption from './FilterOption';

export const TableFilterInTableRow = ({ field, label, options, update }) => (
    <tr key={field}>
        <td className="nowrap">
            <FieldLabel className="mr-3">{label}:</FieldLabel>
        </td>
        <td className="w-100">
            {options.map(option => (
                <FilterOption key={`${field}_${option.optionName}`} {...option} field={field} onClick={update} />
            ))}
        </td>
    </tr>
);
