import React from 'react';
import PropTypes from 'prop-types';
import SyncingOrErrorIndicator from '../../common/SyncingOrErrorIndicator';

const PersonDescriptor = props => {
    const { syncing, error, salutation, firstName, lastName } = props;
    return (
        <span>
            <SyncingOrErrorIndicator error={error} isSyncing={syncing} />
            {salutation} {firstName} {lastName}
        </span>
    );
};

PersonDescriptor.propTypes = {
    syncing: PropTypes.bool,
    // error: PropTypes.string.isRequired,
    salutation: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired
};

export default PersonDescriptor;
