import productAndDetailsDropdownFilters from './productAndDetailsDropdownFilters';

export const agreementHeaderStates = {
    Addendum: {
        AddendumPending: {
            title: '',
            canAmend: false,
            canApprove: false,
            canDecline: false,
            canClone: false
        },
        Approved: {
            title: 'Addendum',
            displayAddendumAgreementsTable: true,
            canEditAddendum: true,
            showAddendumAgreementSearchForm: true
        },
        AwaitingApproval: {
            title: 'Addendum Awaiting Approval',
            canDecline: true,
            canApprove: true,
            displayAddendumAgreementsTable: true
        },
        Declined: {
            title: 'Declined Addendum',
            canAmend: true
            //agreementDetailsFormTitle: 'Amend Detail',
        },
        Deleted: {
            title: 'Deleted Addendum',
            displayAddendumAgreementsTable: true
        },
        Draft: {
            title: 'Draft Addendum',
            canAmend: true,
            canEditAddendum: true
            //agreementDetailsFormTitle: 'Amend Detail',
        },
        Expired: {
            title: 'Expired Addendum',
            displayAddendumAgreementsTable: true
        },
        Live: {
            title: 'Live Addendum',
            displayAddendumAgreementsTable: true,
            showAddendumAgreementSearchForm: true
        }
    },
    Agreement: {
        AddendumPending: {
            title: '',
            canAmend: false,
            canApprove: false,
            canDecline: false,
            canClone: false,
            canDelete: false,
            showCompanyDetails: true,
            showPersonDetails: true,
            showEvidence: true,
            showAgreementsAddendumDetails: true
        },
        Approved: {
            title: 'Approved Agreement',
            showCompanyDetails: true,
            showPersonDetails: true,
            canClone: true,
            canDelete: true,
            canDownloadPdf: true,
            showEvidence: true,
            canAmend: false,
            detailsFilter: {
                default: 'showApprovedRowsOnly',
                filters: productAndDetailsDropdownFilters
            },
            showAgreementsAddendumDetails: true,
            canEmailAgreement: true
        },
        AwaitingApproval: {
            title: 'Agreement Awaiting Approval',
            canApproveWholeAgreement: true,
            canDecline: true,
            canApprove: true,
            canDelete: true,
            showCompanyDetails: true,
            showPersonDetails: true,
            showEvidence: true,
            canAmend: true,
            detailsFilter: {
                default: 'showRequireApprovalRowsOnly',
                filters: productAndDetailsDropdownFilters
            }
        },
        Declined: {
            title: 'Declined Agreement',
            canDecline: true,
            canApprove: true,
            canClone: true,
            canDelete: true,
            //agreementDetailsFormTitle: 'Amend Detail',
            canAddEvidence: true,
            canAmend: true,
            showCompanyDetails: true,
            showPersonDetails: true,
            showEvidence: true,
            displayChangeTemplateControl: true
        },
        Deleted: {
            title: 'Deleted Agreement',
            showEvidence: true
        },
        Draft: {
            title: 'Draft Agreement',
            //agreementDetailsFormTitle: 'Amend Detail',
            canAddEvidence: true,
            canAmend: true,
            canDelete: true,
            showCompanyDetails: true,
            showPersonDetails: true,
            showEvidence: true,
            displayChangeTemplateControl: true
        },
        Expired: {
            title: 'Expired Agreement',
            showCompanyDetails: true,
            showPersonDetails: true,
            canDownloadPdf: true,
            canDelete: true,
            canClone: true,
            showEvidence: true,
            showAgreementsAddendumDetails: true
        },
        Cancelled: {
            title: 'Cancelled Agreement',
            showCompanyDetails: true,
            showPersonDetails: true,
            canDownloadPdf: true,
            canDelete: true,
            showEvidence: true,
            showAgreementsAddendumDetails: true
        },
        Live: {
            title: 'Live Agreement',
            showCompanyDetails: true,
            canClone: true,
            canDownloadPdf: true,
            canEmailAgreement: true,
            canDelete: true,
            canCancel: true,
            showEvidence: true,
            showPersonDetails: true,
            detailsFilter: {
                default: 'showApprovedRowsOnly',
                filters: productAndDetailsDropdownFilters
            },
            showAgreementsAddendumDetails: true
        }
    },
    Promotion: {
        AddendumPending: {
            title: '',
            canAmend: false,
            canApprove: false,
            canDecline: false,
            canClone: false
        },
        Approved: {
            title: 'Approved Promotion',
            canEmailAgreement: true
        },
        AwaitingApproval: {
            title: 'Promotion Awaiting Approval',
            canDecline: true,
            canApprove: true
        },
        Declined: {
            title: 'Declined Promotion',
            canAmend: true
            //agreementDetailsFormTitle: 'Amend Detail',
        },
        Deleted: {
            title: 'Deleted Promotion'
        },
        Draft: {
            title: 'Draft Promotion',
            canAmend: true
            //agreementDetailsFormTitle: 'Amend Detail',
        },
        Expired: {
            title: 'Expired Promotion'
        },
        Live: {
            title: 'Live Promotion'
        }
    },
    Template: {
        AddendumPending: {
            title: '',
            canAmend: false,
            canApprove: false,
            canDecline: false,
            canClone: false,
            canAddEvidence: true
        },
        Approved: {
            title: 'Approved Template',
            canAddEvidence: false
        },
        AwaitingApproval: {
            title: 'Template Awaiting Approval',
            canAddEvidence: false,
            canDecline: true,
            canApprove: true
        },
        Declined: {
            title: 'Declined Template',
            canAmend: true,
            canAddEvidence: true
            //agreementDetailsFormTitle: 'Amend Detail',
        },
        Deleted: {
            title: 'Deleted Template',
            canAddEvidence: false
        },
        Draft: {
            title: 'Draft Template',
            canAmend: true,
            canAddEvidence: true
            //agreementDetailsFormTitle: 'Amend Detail',
        },
        Expired: {
            title: 'Expired Template',
            canAddEvidence: false
        },
        Live: {
            title: 'Live Template',
            canAddEvidence: false
        }
    }
};
