import React, {useState} from "react";
import ReactiveForm from "../../common/ReactiveForm";
import {
    basicColumnDef,
    choiceListColumnDef,
} from "../../common/FilteredTable/tableAndFilterHelpers";
import {apiEmailAgreementPost} from "../../../actions/Api/AgreementHeader/apiEmailAgreementPost";
import {useDispatch} from "react-redux";
import * as yup from 'yup';

export const isNullOrEmpty = string => !!string ? string === "" : true

const ccBccDef = {
    cc: {
        ...choiceListColumnDef('Cc List', 'cc', 'cc', {multipleChoice: true})
    },
    bCc: {
        ...choiceListColumnDef('BCc List', 'bCc', 'bCc', {multipleChoice: true}),
    },
    addCc: {
        ...basicColumnDef('Add Cc', 'addCc'),
        join: 'cc'
    },
    addBCc: {
        ...basicColumnDef('Add BCc', 'addBCc'),
        join: 'bCc'
    }
}

const CcBccForm = ({agreementHeaderIds, toggle}) => {
    const dispatch = useDispatch();
    const [cc, setCc] = useState([]);
    const [bCc, setBCc] = useState([]);

    const initialValues = {
        cc: cc.map(({value}) => value).join(','),
        bCc: bCc.map(({value}) => value).join(',')
    }

    const validationSchema = yup.object().shape(
        {
            addCc: yup.string().email('Please enter a valid email address.'),
            addBCc: yup.string().email('Please enter a valid email address.')
        }
    )

    const handleSubmit = (form, dispatch) => {
        const {cc, bCc} = form;
        const ccList = isNullOrEmpty(cc) ? [] : cc.split(',');
        const bCcList = isNullOrEmpty(bCc) ? [] : bCc.split(',');
        dispatch(apiEmailAgreementPost(agreementHeaderIds, 'Success', 'Email Sent', ccList, bCcList));
        toggle();
    }

    return (
        <ReactiveForm
            initialValues={
                initialValues
            }
            cancel={toggle}
            handleSubmit={form => handleSubmit(form, dispatch)}
            saveButtonContent={"Send"}
        >
            <ReactiveForm.Section title="">
                <ReactiveForm.Choicelist columnDef={ccBccDef.cc} options={cc} isMulti/>
                <ReactiveForm.TextWithAddon columnDef={ccBccDef.addCc} onAddonCLick={setCc} addonData={cc} validation={validationSchema}/>
            </ReactiveForm.Section>
            <ReactiveForm.Section title="">
                <ReactiveForm.Choicelist columnDef={ccBccDef.bCc} options={bCc} isMulti/>
                <ReactiveForm.TextWithAddon columnDef={ccBccDef.addBCc} onAddonCLick={setBCc} addonData={bCc}  validation={validationSchema}/>
            </ReactiveForm.Section>
        </ReactiveForm>
    )
}

export default CcBccForm;


