import React from 'react';
import Tabs, { TabsButtons, TabsContent, TabsGroup, TabsTab } from '../../common/Tabs';
import BaseCard from '../../common/Card/BaseCard';
import AgreementPromotionsTable from '../Tables/AgreementPromotionsTable';
import AgreementTemplatesTable from '../Tables/AgreementTemplatesTable';
import AddendaTable from '../Tables/AddendaTable';
import { FilterTableRowButton } from '../../common/Buttons/TableButtons';
import useToggleOpen from '../../common/Hooks/useToggleOpen';
import { isAuthorisedToApproveAgreements } from '../agreementHeaderStatus';
import { useSelector } from 'react-redux';
import {
    NewAddendumTableButton,
    NewPromotionTableButton,
    NewTemplateTableButton
} from '../Buttons/createTypesOfAgreementButtons';
import AgreementsLapsingSoonTable from '../Tables/AgreementsLapsingSoonTable';
import DraftAgreementsTable from '../Tables/DraftAgreementsTable';
import AgreementsEmailPendingTable from '../Tables/AgreementsEmailPendingTable';
import AgreementsPendingApprovalTable from '../Tables/AgreementsPendingApprovalTable';
import { endpointsWhichMayBeLoadingHeaders } from '../Hooks/useAgreementHeadersIsLoading';
import CreateAgreementModal from '../Modals/CreateAgreementModal';
import { useIsLoading } from '../../common/Hooks/useIsLoading';

const AgreementTabs = () => {
    const { toggle, isOpen } = useToggleOpen();

    const loading = useIsLoading(endpointsWhichMayBeLoadingHeaders);
    const user = useSelector(state => state.user);
    const canApprove = isAuthorisedToApproveAgreements(user);
    return (
        <CreateAgreementModal agreementType="Template" user={user}>
            {({ createTemplate, createPromotion, createAddendum }) => (
                <BaseCard>
                    <Tabs defaultGroup={0}>
                        <TabsGroup>
                            <TabsTab>Draft Agreements</TabsTab>
                            <TabsButtons isLoading={loading}>
                                <FilterTableRowButton onClick={toggle} />
                            </TabsButtons>
                            <TabsContent>
                                <DraftAgreementsTable showFilter={isOpen} />
                            </TabsContent>
                        </TabsGroup>
                        <TabsGroup>
                            <TabsTab>Lapsing Soon</TabsTab>
                            <TabsButtons isLoading={loading}>
                                <FilterTableRowButton onClick={toggle} />
                            </TabsButtons>
                            <TabsContent>
                                <AgreementsLapsingSoonTable showFilter={isOpen} />
                            </TabsContent>
                        </TabsGroup>
                        <TabsGroup>
                            <TabsTab>Pending Approval</TabsTab>
                            <TabsButtons isLoading={loading}>
                                <FilterTableRowButton onClick={toggle} />
                            </TabsButtons>
                            <TabsContent>
                                <AgreementsPendingApprovalTable showFilter={isOpen} />
                            </TabsContent>
                        </TabsGroup>
                        <TabsGroup>
                            <TabsTab>Emails Pending</TabsTab>
                            <TabsButtons isLoading={loading}>
                                <FilterTableRowButton onClick={toggle} />
                            </TabsButtons>
                            <TabsContent>
                                <AgreementsEmailPendingTable showFilter={isOpen} />
                            </TabsContent>
                        </TabsGroup>
                        {canApprove ? (
                            <TabsGroup>
                                <TabsTab>Templates</TabsTab>
                                <TabsButtons isLoading={loading}>
                                    <NewTemplateTableButton onClick={createTemplate} />
                                    <FilterTableRowButton onClick={toggle} />
                                </TabsButtons>
                                <TabsContent>
                                    <AgreementTemplatesTable showFilter={isOpen} />
                                </TabsContent>
                            </TabsGroup>
                        ) : null}
                        {canApprove ? (
                            <TabsGroup>
                                <TabsTab>Promotions</TabsTab>
                                <TabsButtons isLoading={loading}>
                                    <NewPromotionTableButton onClick={createPromotion} />
                                    <FilterTableRowButton onClick={toggle} />
                                </TabsButtons>
                                <TabsContent>
                                    <AgreementPromotionsTable showFilter={isOpen} />
                                </TabsContent>
                            </TabsGroup>
                        ) : null}

                        {canApprove ? (
                            <TabsGroup>
                                <TabsTab>Addendum</TabsTab>
                                <TabsButtons isLoading={loading}>
                                    <NewAddendumTableButton onClick={createAddendum} />
                                    <FilterTableRowButton onClick={toggle} />
                                </TabsButtons>
                                <TabsContent>
                                    <AddendaTable showFilter={isOpen} />
                                </TabsContent>
                            </TabsGroup>
                        ) : null}
                    </Tabs>
                </BaseCard>
            )}
        </CreateAgreementModal>
    );
};

export default AgreementTabs;
