import React from 'react';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import AgreementSearchForm from './AgreementSearchForm';
import AgreementHeaderSearchProvider from './AgreementHeaderSearchProvider';
import AgreementSearchResults from './AgreementSearchResults';

const searchOptions = {
    companyName: {
        value: undefined,
        hide: false,
        disabled: false,
        collapsed: false
    },
    agreementType: {
        value: { Agreement: 'included' },
        hide: false,
        disabled: false,
        collapsed: false
    },
    status: {
        value: { Approved: 'included' },
        hide: false,
        disabled: false,
        collapsed: true
    },
    termsType: {
        value: {},
        hide: false,
        disabled: false,
        collapsed: true
    },
    product: {
        value: {},
        hide: false,
        disabled: false,
        collapsed: true
    },
    excludeAgreementsWithAnAddendumId: {
        value: false,
        hide: false,
        disabled: false,
        collapsed: true
    }
};

const AgreementSearchFormAndTable = () => {
    return (
        <AgreementHeaderSearchProvider
            title="Search Agreements"
            columns={[
                agreementHeaderColumnDefs.name,
                agreementHeaderColumnDefs.companyName,
                agreementHeaderColumnDefs.startDate,
                agreementHeaderColumnDefs.endDate,
                agreementHeaderColumnDefs.termsType,
                agreementHeaderColumnDefs.agreementType,
                agreementHeaderColumnDefs.agreementApprovalTeam,
                agreementHeaderColumnDefs.status
            ]}
        >
            <AgreementSearchForm searchOptions={searchOptions}>
                {({ agreementSearchForm }) => <AgreementSearchResults agreementSearchForm={agreementSearchForm} />}
            </AgreementSearchForm>
        </AgreementHeaderSearchProvider>
    );
};

export default AgreementSearchFormAndTable;
