import moment from 'moment';

export const overrideIcon = (category, action, activityRegarding, icon) => {
    switch (category) {
        case 'Appointment':
            switch (action) {
                case 'Meeting':
                    return 'fa-comments';
                case 'PhoneOut':
                    return 'fa-phone';
                case 'ToDo':
                    switch (activityRegarding) {
                        case 'LBDMProspecting':
                            return 'fa-phone';
                        case 'LBDMInboundEnquiry':
                            return 'fa-inbox';
                        case 'LBDMNetworkingEvent':
                            return 'fa-users';
                        case 'LBDMCanvassing':
                            return 'fa-clipboard';
                        case 'LBDMVacation':
                            return 'fa-plane';
                        case 'LBDMOtherDealerTask':
                            return 'fa-list';
                        case 'LBDMOpportunityReview':
                            return 'fa-book';
                        case 'LBDMTradeEvent':
                            return 'fa-pied-piper';
                        case 'LBDMTraining':
                            return 'fa-graduation-cap';
                        default:
                            return 'fa-clipboard';
                    }
                default:
                    return icon;
            }
        default:
            return icon;
    }
};

export const calendarOptions = {
    faceToFace: {
        color: 3,
        name: 'Face to Face meeting',
        icon: 'fa-link',
        showOnCalendar: true,
        order: 3
    },
    telephone: {
        color: 7,
        name: 'Telephone meeting',
        icon: 'fa-phone',
        showOnCalendar: true,
        order: 7
    },
    contactOut: {
        color: 4,
        name: 'Scheduled Contact',
        icon: 'fa-phone',
        showOnCalendar: true,
        order: 4
    },
    visitReportAction: {
        color: 5,
        name: 'Visit Report Action',
        icon: 'fa-clock-o',
        showOnCalendar: true,
        order: 5
    },
    default: {
        color: 6,
        name: 'Default',
        icon: 'fa-minus',
        showOnCalendar: false,
        order: 6
    },
    holiday: {
        color: 1,
        name: 'Holiday',
        icon: 'fa-plane',
        showOnCalendar: true,
        order: 1
    },
    adminDay: {
        color: 2,
        name: 'Admin Day',
        icon: 'fa-pencil',
        showOnCalendar: true,
        order: 2
    },
    event: {
        color: 6,
        name: 'Event',
        icon: 'fa-glass',
        showOnCalendar: true,
        order: 6
    }
};

export const endOfSoon = () => moment().add(35, 'days');
export const recentPast = () => moment().add(-35, 'days');
export const longPast = () => moment().add(-365, 'days');
