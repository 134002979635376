import { createSelector } from 'reselect';
import { getChoiceList } from '../LabelText';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const makeChoiceListSelector = () =>
    createSelector(
        state => state.resources,
        (_, { options, choiceList, mapChoiceValues }) => ({ options, choiceList, mapChoiceValues }),
        (resources, { options, choiceList, mapChoiceValues }) =>
            options || getChoiceList(resources, choiceList, mapChoiceValues)
    );

export const useChoiceList = (choiceList, mapChoiceValues, options) => {
    const selectChoiceList = useMemo(makeChoiceListSelector, []);
    return useSelector(state => selectChoiceList(state, { options, choiceList, mapChoiceValues }));
};
