import React from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import { setOpenInModals } from '../../../actions/settings';
import SidebarDisplayField from './SidebarDisplayField';
import { Link } from 'react-router-dom';
import appSettings from '../../../config/appSettings';
import { currentVersion } from '../Pages/VersionsPage';

const SidebarUserMenum = ({ onLinkOpen, openInModals, setOpenInModals, user }) => {
    const version = currentVersion();
    return (
        <div className="sidebar-background-image">
            <ul className="nav flex-column">
                {//TODO: Move this to its own file
                Object.entries(appSettings.sidebarUserMenuItems).map(([key, value]) => {
                    switch (key) {
                        case 'currentVersion':
                            //return <SidebarDisplayField title={value.title} value={version && `${version.version} - ${version.title}`}/>;
                            return (
                                <Link key={key} className="nav-link m-0 p-0" to={'/versions/'}>
                                    <SidebarDisplayField
                                        title={value.title}
                                        value={version && `${version.version} - ${version.title}`}
                                        onClick={() => onLinkOpen && onLinkOpen()}
                                    />
                                </Link>
                            );
                        case 'role':
                            return (
                                <SidebarDisplayField
                                    key={key}
                                    title={value.title}
                                    value={user.roles && user.roles.join(', ')}
                                />
                            );
                        default:
                            console.error(`sideBarUserMenu does not support ${key}`);
                            return null;
                    }
                })}

                {process.env.NODE_ENV === 'development' ? (
                    <li className="nav-item sidebar-nav-item border-bottom">
                        <div className="py-3 px-4 text-danger">
                            <Input
                                id="modalCheckbox"
                                type="checkbox"
                                checked={openInModals}
                                onChange={({ target: { checked } }) => setOpenInModals(checked)}
                            />
                            <label htmlFor="modalCheckbox">modals?</label>
                        </div>
                    </li>
                ) : null}
            </ul>
        </div>
    );
};

const mapStateToProps = state => ({
    openInModals: state.settings.openInModals,
    user: state.user
});

export default connect(
    mapStateToProps,
    { setOpenInModals }
)(SidebarUserMenum);
