import React from 'react';
import { connect } from 'react-redux';
import { AddTableRowButton } from '../../common/Buttons/TableButtons';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { isAuthorisedToApproveAgreements } from '../agreementHeaderStatus';

const mapStateToProps = ({ user }) => ({ user });

export const NewPromotionTableButton = connect(mapStateToProps)(({ user, onClick }) =>
    isAuthorisedToApproveAgreements(user) ? <AddTableRowButton onClick={onClick} /> : null
);

export const NewPromotionActionButton = connect(mapStateToProps)(({ user, onClick }) =>
    isAuthorisedToApproveAgreements(user) ? (
        <ActionButton
            buttonText="Create Promotion"
            fontawesomeIcon="fa-plus"
            bootstrapColor="secondary"
            onClickAction={onClick}
        />
    ) : null
);

export const NewTemplateTableButton = connect(mapStateToProps)(({ user, onClick }) =>
    isAuthorisedToApproveAgreements(user) ? <AddTableRowButton onClick={onClick} /> : null
);

export const NewTemplateActionButton = connect(mapStateToProps)(({ user, onClick }) =>
    isAuthorisedToApproveAgreements(user) ? (
        <ActionButton
            buttonText="Create Template"
            fontawesomeIcon="fa-plus"
            bootstrapColor="secondary"
            onClickAction={onClick}
        />
    ) : null
);

export const NewAddendumTableButton = connect(mapStateToProps)(({ user, onClick }) =>
    isAuthorisedToApproveAgreements(user) ? <AddTableRowButton onClick={onClick} /> : null
);

export const NewAddendumActionButton = connect(mapStateToProps)(({ user, onClick }) =>
    isAuthorisedToApproveAgreements(user) ? (
        <ActionButton
            buttonText="Create Addendum"
            fontawesomeIcon="fa-plus"
            bootstrapColor="secondary"
            onClickAction={onClick}
        />
    ) : null
);
