import React, { useContext } from 'react';
import AgreementDetailsTableProvider from './AgreementDetailsTableProvider';
import AgreementDetailsTable from './AgreementDetailsTable';
import { AgreementHeaderContext } from '../../AgreementHeader/Provider/AgreementHeaderProvider';
import { getLoadingState } from '../../../reducers/Rest/loadingStates';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useSelector } from 'react-redux';

const AgreementDetailsCard = () => {
    const {
        agreementState: { isAddendum }
    } = useContext(AgreementHeaderContext);

    const loadingStates = useSelector(state => state.loadingStates);

    const isLoading = getLoadingState(loadingStates, ENDPOINTS.API.AGREEMENT_FILTER).isLoading;
    if (isAddendum || isLoading) {
        return null;
    }
    return (
        <AgreementDetailsTableProvider>
            <div className="card simple-card shadow-sm">
                <AgreementDetailsTable />
            </div>
        </AgreementDetailsTableProvider>
    );
};

export default AgreementDetailsCard;
