import { ENDPOINTS } from '../constants';
import { handleErrorWithToastUnlessUnauthorised, makePostRequest } from '../apiHelpers';
import { isLoading } from '../../Loading/isLoading';

export const agreementsQuery = (
    query = {
        type: ENDPOINTS.API.AGREEMENT_FILTER,
        fetchCompanies: true,
        fetchDetails: true,
        fetchPersons: true,
        fetchParentHeader: false,
        fetchAddendumHeader: false,
        agreementTypeFilter: { included: [], excluded: [] },
        addPathToUserLog: '',
        termsTypeFilter: { included: [], excluded: [] },
        statusFilter: { included: [], excluded: [] },
        productFilter: { included: [], excluded: [] }
    }
) => ({
    filterAgreementHeaderId: agreementHeaderId => agreementsQuery({ ...query, agreementHeaderId }),
    filterAgreementHeaderIds: agreementHeaderIds => agreementsQuery({ ...query, agreementHeaderIds }),
    filterTermsType: termsType => agreementsQuery({ ...query, termsType }),
    filterStatus: status => agreementsQuery({ ...query, status }),
    agreementTypeFilter: agreementTypeFilter => agreementsQuery({ ...query, agreementTypeFilter }),
    termsTypeFilter: termsTypeFilter => agreementsQuery({ ...query, termsTypeFilter }),
    statusFilter: statusFilter => agreementsQuery({ ...query, statusFilter }),
    productFilter: productFilter => agreementsQuery({ ...query, productFilter }),
    filterAddendumStatus: addendumStatus => agreementsQuery({ ...query, addendumStatus }),
    filterAgreementType: agreementType => agreementsQuery({ ...query, agreementType }),
    filterCompanyId: companyId => agreementsQuery({ ...query, companyId }),
    filterAddendumId: addendumId => agreementsQuery({ ...query, addendumId }),
    filterTeam: team => agreementsQuery({ ...query, team }),
    filterParentId: parentId => agreementsQuery({ ...query, parentId }),
    filterProductId: productId => agreementsQuery({ ...query, productId }),
    isBefore: endDateBefore => agreementsQuery({ ...query, endDateBefore }),
    isAfter: endDateAfter => agreementsQuery({ ...query, endDateAfter }),
    shouldFetchCompanies: fetchCompanies => agreementsQuery({ ...query, fetchCompanies }),
    shouldFetchDetails: fetchDetails => agreementsQuery({ ...query, fetchDetails }),
    shouldFetchPersons: fetchPersons => agreementsQuery({ ...query, fetchPersons }),
    shouldFetchParentHeader: fetchParentHeader => agreementsQuery({ ...query, fetchParentHeader }),
    shouldFetchAddendumHeader: fetchAddendumHeader => agreementsQuery({ ...query, fetchAddendumHeader }),
    shouldFetchAddendedAgreements: fetchAddendedHeaders => agreementsQuery({ ...query, fetchAddendedHeaders }),
    shouldFetchPendingAddendum: fetchPendingAddendum => agreementsQuery({ ...query, fetchPendingAddendum }),
    excludeAgreementsWithAnAddendumId: excludeAgreementsWithAnAddendumId =>
        agreementsQuery({ ...query, excludeAgreementsWithAnAddendumId }),
    shouldFetchAgreementsCreatedByAddendum: fetchAgreementsCreatedByAddendum =>
        agreementsQuery({ ...query, fetchAgreementsCreatedByAddendum }),
    shouldFetchAgreementEvidence: fetchAgreementEvidence => agreementsQuery({ ...query, fetchAgreementEvidence }),
    shouldFetchAgreementStatusChanges: fetchAgreementStatusChanges =>
        agreementsQuery({ ...query, fetchAgreementStatusChanges }),
    addPathToUserLog: addPathToUserLog => agreementsQuery({ ...query, addPathToUserLog }),
    filterCompanyName: companyName => agreementsQuery({ ...query, companyName }),
    useSearchReducer: () =>
        agreementsQuery({
            ...query,
            type: ENDPOINTS.API.ADDENDUM_AGREEMENT_SEARCH
        }),
    setCallback: onResultCallback => agreementsQuery({ ...query, onResultCallback }),
    run: () => apiAgreementFilter(query)
});

const apiAgreementFilter = filter => async (dispatch, getState) => {
    const {
        type,
        fetchCompanies,
        fetchDetails,
        fetchPersons,
        fetchParentHeader,
        fetchAddendumHeader,
        fetchAddendedHeaders,
        fetchPendingAddendum,
        fetchAgreementsCreatedByAddendum,
        fetchAgreementEvidence,
        fetchAgreementStatusChanges,
        addPathToUserLog,
        onResultCallback,
        ...data
    } = filter;

    if (!getState().offline.online) {
        return false;
    }

    //if (type === ENDPOINTS.API.ADDENDUM_AGREEMENT_SEARCH) {
    isLoading(dispatch, type, true, {});
    //}

    makePostRequest(
        ['agreement', 'filter'],
        {
            fetchCompanies,
            fetchDetails,
            fetchPersons,
            fetchParentHeader,
            fetchAddendumHeader,
            fetchAddendedHeaders,
            fetchPendingAddendum,
            fetchAgreementsCreatedByAddendum,
            fetchAgreementEvidence,
            fetchAgreementStatusChanges,
            addPathToUserLog
        },
        data
    )
        .then(result => {
            dispatch({ type, data: result.data, filter });
            isLoading(dispatch, type, false, {});
            if (result.data && onResultCallback) {
                onResultCallback(result.data);
            }

            return true;
        })
        .catch(error => {
            isLoading(dispatch, type, false, {});
            handleErrorWithToastUnlessUnauthorised(dispatch, getState, error, type, 'Agreements');
        });
};
