import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import _ from 'lodash';
import { choiceListsConfig, choiceListAdditions } from '../../../config/choiceLists';

const defaultState = {
    resources: {
        choiceList: {
            activityregarding: [],
            status: []
        }
    }
};

const initialState = JSON.parse(localStorage.getItem('reduxPersist:resources')) || defaultState;

export function resources(state = initialState, action) {
    switch (action.type) {
        case RESET_REDUCER:
            return initialState;

        case ENDPOINTS.API.RESOURCE:
            const config = choiceListsConfig();
            action.data.choiceList = action.data.choiceList.reduce((acc, item) => {
                const itemColors = config[item.name];
                acc[item.name] = _.orderBy(
                    item.choices
                        .filter(({ value }) => !_.get(itemColors, `${value}.remove`, false))
                        .map(({ caption: value, value: key, ordinal }) => ({
                            key,
                            value: value,
                            ordinal,
                            ..._.get(itemColors, `${key}`, {})
                        })),
                    ['ordinal', 'key']
                );
                return acc;
            }, {});

            choiceListAdditions().forEach(addition => {
                const updateTarget = _.get(action.data.choiceList, addition.choiceListName, []);
                updateTarget.push(addition);
                action.data.choiceList[addition.choiceListName] = _.orderBy(updateTarget, ['ordinal']);
            });

            if (process.env.NODE_ENV === 'development') {
                Object.entries(config).forEach(([key, config]) => {
                    if (!action.data.choiceList[key]) {
                        console.warn(`Section: ${key} exists in choiceList.js but is not returned from Service`);
                    } else {
                        const list = action.data.choiceList[key];
                        Object.entries(config).forEach(([choiceKey]) => {
                            if (list.findIndex(x => x.key === choiceKey) === -1) {
                                console.warn(
                                    `Section: ${key}, Choice:${choiceKey} exists in choiceList.js but is not returned from Service`
                                );
                            }
                        });
                    }
                });
            }

            return action.data;

        default:
            return state;
    }
}
