import React from 'react';
import { Card, CardBody } from 'reactstrap';

const BaseCard = props => {
    const { title, className, children } = props;
    return (
        <Card className={`mb-4 shadow-sm simple-card ${className}`}>
            {title ? (
                <CardBody data-testid="title" className="pb-0">
                    {title}
                </CardBody>
            ) : null}
            <CardBody className="pt-0" data-test-id="card-body">
                {children}
            </CardBody>
        </Card>
    );
};

export default BaseCard;
