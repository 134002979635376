import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import LoggedOutRouter from './LoggedOutRouter';
import LoggedInRouter from './LoggedInRouter';
import LogOutTimer from './LogOutTimer';
import { connect } from 'react-redux';

const Example = ({ userId }) => {
    return [
        <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="bottom-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            key="toastr"
        />,
        <LogOutTimer key="LogOutTimer" />,
        <section key="index-section">
            <LoggedOutRouter userId={userId} />
            <LoggedInRouter userId={userId} />
        </section>
    ];
};

const mapStateToProps = ({ user: { userId } }) => ({
    userId
});

export default connect(mapStateToProps)(Example);
