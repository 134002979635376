import { ENDPOINTS } from '../../../actions/Api/constants';
import _ from 'lodash';
import { setPropertiesSyncing } from '../helper';
import bandingsDef from '../../../containers/Banding/bandingsDef';
import { RESET_REDUCER } from '../../../actions/resetReducers';

const idField = bandingsDef.id.field;

function updateStateBeforeSendingToServer(state, { data }) {
    const original = state.find(x => data[idField] === x[idField]);
    if (original) {
        setPropertiesSyncing(original, data);
    }
    data.syncing = true;
    return [data, ...state.filter(x => x[idField] !== data[idField])];
}

function postCommit(state, action) {
    const {
        meta: { modified },
        payload: { data: remote }
    } = action;
    return [remote, ...state.filter(x => modified[idField] !== x[idField] && x[idField] !== remote[idField])];
}

const initialState = [];

// Is only ever called with a companyId
const bandings = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case ENDPOINTS.API.COMPANY_DATA_GET:
            return data.fetchBandings
                ? [...state.filter(x => _.get(x, 'companyId') !== data.companyId), ...data.bandings]
                : state;
        case ENDPOINTS.API.BANDING_POST:
            return updateStateBeforeSendingToServer(state, action);
        case ENDPOINTS.API.BANDING_POST_COMMIT:
            return postCommit(state, action);
        case RESET_REDUCER:
            return initialState;
        default:
            return state;
    }
};

export default bandings;
