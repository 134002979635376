import cookie from 'react-cookies';

export const COOKIE_TOKEN = 'COOKIE_TOKEN';
export const COOKIE_EXPIRES = 'COOKIE_EXPIRES';
export const COOKIE_USERID = 'COOKIE_USERID';

export const isCookieLoggedIn = () => {
    const allCookies = cookie.loadAll();

    return (
        allCookies[COOKIE_TOKEN] &&
        allCookies[COOKIE_EXPIRES] &&
        new Date(allCookies[COOKIE_EXPIRES]).getTime() > Date.now()
    );
};

export const cookieGetToken = () => cookie.load(COOKIE_TOKEN);
export const cookieGetUserId = id => cookie.load(COOKIE_USERID);
export const cookieGetExpires = id => cookie.load(COOKIE_EXPIRES);

export const cookieSetToken = token => {
    cookie.save(COOKIE_TOKEN, token, { path: '/' });
};
export const cookieSetExpires = expires => {
    cookie.save(COOKIE_EXPIRES, expires, { path: '/' });
};
export const cookieSetUserId = id => {
    cookie.save(COOKIE_USERID, id, { path: '/' });
};

export const cookieClear = () => {
    cookie.remove(COOKIE_TOKEN, { path: '/' });
    cookie.remove(COOKIE_EXPIRES, { path: '/' });
    cookie.remove(COOKIE_USERID, { path: '/' });
};
