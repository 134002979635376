export const choiceListAdditions = () => [];

export const choiceListsConfig = () => ({
    visitActionStatus: {
        Pending: { color: ['info', 'white'] },
        Complete: { color: ['primary', 'white'] }
    },
    communicationStatus: {
        InProgress: { color: ['warning', 'white'] },
        Cancelled: { color: ['danger', 'white'] },
        Complete: { color: ['primary', 'white'] },
        Pending: { color: ['info', 'white'] },
        Postponed: { color: ['secondary', 'white'] }
    }
});
