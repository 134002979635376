import React from 'react';
import { connect } from 'react-redux';
import ReactiveForm from '../../common/ReactiveForm';
import validation from './banding.validation';
import bandingsDef from '../bandingsDef';
import { apiBandingPost } from '../../../actions/Api/Banding/apiBandingPost';

const FORM_NAME = 'EditBandingForm';

const EditBandingForm = ({ save, onCancel, user, banding, banding: { bandingId }, bandings, resources }) => {
    let initialValues = {};

    if (bandingId < 0) {
        initialValues = {
            descriptor: '',
            carLcv: '',
            numberOfVehicles: '',
            manufacturers: '',
            segment: '',
            manufacturerPolicy: '',
            reason: '',
            fundingMethod: '',
            decisionBasedOn: '',
            minPricePoint: '',
            maxPricePoint: '',
            co2Cap: '',
            fuelType: '',
            changeCycleMonths: '',
            changeCycleMiles: '',
            benchmarkManufacturer: '',
            benchmarkModel: '',
            benchmarkPrice: '',
            hyundaiSalesOpportunity: '',
            anecdotalNotes: '',
            ...banding
        };
    } else {
        initialValues = bandings.find(banding => banding.bandingId === bandingId);
    }

    return (
        <ReactiveForm
            initialValues={initialValues}
            cancel={onCancel}
            resources={resources}
            formName={FORM_NAME}
            validationSchema={validation}
            handleSubmit={values => save(values, onCancel)}
        >
            <ReactiveForm.Section title="">
                <ReactiveForm.Text columnDef={bandingsDef.descriptor} />
                <ReactiveForm.Choicelist columnDef={bandingsDef.carLcv} />
                <ReactiveForm.Text columnDef={bandingsDef.numberOfVehicles} />
                {/* <ReactiveForm.Choicelist columnDef={bandingsDef.manufacturerPolicy} /> 
               <ReactiveForm.Choicelist columnDef={bandingsDef.segment} /> */}
                <ReactiveForm.Text columnDef={bandingsDef.manufacturers} />
                <ReactiveForm.Text columnDef={bandingsDef.minPricePoint} />
                <ReactiveForm.Text columnDef={bandingsDef.maxPricePoint} />
                {/* <ReactiveForm.Choicelist columnDef={bandingsDef.reason} />
                <ReactiveForm.Choicelist columnDef={bandingsDef.fundingMethod} />
                <ReactiveForm.Choicelist columnDef={bandingsDef.decisionBasedOn} />
                <ReactiveForm.Text columnDef={bandingsDef.co2Cap} />
                <ReactiveForm.Choicelist columnDef={bandingsDef.fuelType} /> 
                <ReactiveForm.Text columnDef={bandingsDef.changeCycleMonths} />
                <ReactiveForm.Text columnDef={bandingsDef.changeCycleMiles} />
                <ReactiveForm.Choicelist columnDef={bandingsDef.benchmarkManufacturer} />
                <ReactiveForm.Text columnDef={bandingsDef.benchmarkModel} />
                <ReactiveForm.Text columnDef={bandingsDef.benchmarkPrice} /> */}
                <ReactiveForm.Choicelist columnDef={bandingsDef.hyundaiSalesOpportunity} />
                <ReactiveForm.Text columnDef={bandingsDef.anecdotalNotes} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
};

const mapStateToProps = state => ({
    bandings: state.bandings,
    resources: state.resources,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    save: (banding, cancel) => {
        dispatch(apiBandingPost(banding));
        cancel();
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBandingForm);
