import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { ApproveTableRowButton } from '../../common/Buttons/TableButtons';
import { AgreementHeaderContext } from '../../AgreementHeader/Provider/AgreementHeaderProvider';
import { apiAgreementDetailApprove } from '../../../actions/Api/AgreementDetail/apiAgreementDetailApprove';

const ButtonApproveDetail = ({ agreementDetail, isDeleted }) => {
    const dispatch = useDispatch();
    const {
        agreementState: { canApprove, isDraft }
    } = useContext(AgreementHeaderContext);
    if (isDraft) {
        return null;
    }

    const rowCanBeDeclined =
        _.get(agreementDetail, 'Deleted', null) === null && _.get(agreementDetail, 'status') === 'Modified';
    const visibility = !rowCanBeDeclined || !canApprove || isDeleted ? 'invisible' : 'visible';

    return (
        <ApproveTableRowButton
            visibility={visibility}
            onClick={() =>
                dispatch(apiAgreementDetailApprove([agreementDetail.id], 'Success', 'Agreement Detail Approved'))
            }
        />
    );
};

export default ButtonApproveDetail;
