import React from 'react';
import { connect } from 'react-redux';
import AuditSummary from '../../common/CommonForms/AuditSummary';
import { Alert } from 'reactstrap';
import MapArray from '../../common/MapArray';
import FleetProfileSection from './FleetProfileSection';
import { useCompanyContext } from '../../Company/Provider/CompanyProvider';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';

const UpdateFleetProfileEditForm = ({ profileView, schema, choiceList, hideMetaData }) => {
    const { profile, isLoading } = useCompanyContext();

    if (isLoading) {
        return <ShowLoadingSpinner />;
    }

    if (!profile) {
        return (
            <Alert color="danger">
                <b>No Fleet Profile found.</b>
                <span className="ml-3">Please contact support.</span>
            </Alert>
        );
    }

    const from = profileView ? schema[`${profileView}FleetProfile`] || schema.fleetProfile : schema.fleetProfile;

    const classes = ['d-grid', 'grid-column-gap-1'];
    let allowFullWidth = false;

    if (from.length === 3) {
        allowFullWidth = true;
        classes.push('grid-cols-3-center-panel');
    } else {
        classes.push('grid-cols-2-center-panel');
    }

    return (
        <React.Fragment>
            <div className={classes.join(' ')}>
                <MapArray
                    from={from}
                    map={(section, index, data) => ({ ...section, index, data, allowFullWidth })}
                    data={profile}
                >
                    <FleetProfileSection />
                </MapArray>
            </div>
            {!hideMetaData && (
                <AuditSummary
                    choiceList={choiceList}
                    createdBy={profile.createdBy}
                    updatedDate={profile.updatedDate}
                    updatedBy={profile.updatedBy}
                    createdDate={profile.createdDate}
                />
            )}
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    choiceList: state.resources.choiceList,
    schema: state.schema
});

export default connect(mapStateToProps)(UpdateFleetProfileEditForm);
