import * as yup from 'yup';

const validation = yup.object().shape({
    currentPassword: yup.string().required('Current Password is required'),
    password: yup
        .string()
        // .min(8, 'New Password must be at least 8 characters')
        // .matches(new RegExp('(?=.*[a-z])'), 'New Password must contain at least one lowercase character')
        // .matches(new RegExp('(?=.*[A-Z])'), 'New Password must contain at least one uppercase character')
        // .matches(new RegExp('(?=.*[0-9])'), 'New Password must contain at least one number')
        // .matches(new RegExp('(?=.[!@#%&])'), 'New Password must contain at least one special character')
        .min(8, 'New Password does not meet requirements')
        .matches(new RegExp('(?=.*[a-z])'), 'New Password does not meet requirements')
        .matches(new RegExp('(?=.*[A-Z])'), 'New Password does not meet requirements')
        .matches(new RegExp('(?=.*[0-9])'), 'New Password does not meet requirements')
        .matches(new RegExp('(?=.[!@#%&])'), 'New Password does not meet requirements'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password')], 'Confirmation Password does not match')
        .required('Confirmation Password does not match')
});

export default validation;
