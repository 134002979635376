import { SET_INCLUDE_MEETING_NOTES_IN_EMAIL } from '../../actions/emailSettingsActions';
import { RESET_REDUCER } from '../../actions/resetReducers';

const defaultState = {
    includeNotesInEmailComms: []
};

export const emailSettings = (state = defaultState, { type, data }) => {
    switch (type) {
        case SET_INCLUDE_MEETING_NOTES_IN_EMAIL:
            const { communicationId, includeNotesInVisitReportEmail } = data;
            let includeNotesInEmailComms = state.includeNotesInEmailComms;
            if (!includeNotesInVisitReportEmail) {
                includeNotesInEmailComms = includeNotesInEmailComms.filter(x => x !== communicationId);
            } else {
                includeNotesInEmailComms = uniq([...includeNotesInEmailComms, communicationId]);
            }
            return { ...state, includeNotesInEmailComms };

        case RESET_REDUCER:
            return defaultState;

        default:
            return state;
    }
};

const uniq = arr => [...new Set(arr)];
