import React from 'react';
import TinyMCE from 'react-tinymce';

export default ({ handleChange, initialValues, fieldName, config }) => (
    <TinyMCE
        content={initialValues[fieldName]}
        config={config || defaultConfig}
        onChange={e => {
            e.target.value = e.target.getContent();
            e.target.id = fieldName;
            handleChange(e);
        }}
    />
);

const defaultConfig = {
    plugins: 'link image',
    height: 350,
    toolbar: 'undo redo | bold underline italic | alignleft aligncenter alignright',
    branding: false,
    elementPath: false,
    statusbar: false,
    menu: {}
};
