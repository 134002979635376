import React from 'react';
import { connect } from 'react-redux';
import { agreementsQuery } from '../../../actions/Api/AgreementHeader/apiAgreementFilter';
import AgreementHeaderTableCard from './AgreementHeaderTableCard';
import RedirectNotLoggedIn from '../../app/RedirectNotLoggedIn';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';

export const useEmailsPendingFilter = user => agreementHeaders => {
    return agreementHeaders.filter(
        agreementHeader =>
            agreementHeader.agreementType === 'Agreement' &&
            !agreementHeader.emailSent &&
            agreementHeader.status === 'Live' &&
            ((user.canApproveAgreement === 'Y' &&
                agreementHeader.agreementApprovalTeam === user.agreementApprovalTeam) ||
                agreementHeader.createdBy === user.userId)
    );
};

const AgreementsEmailPendingTable = ({ showFilter, user }) => {
    const filter = useEmailsPendingFilter(user);
    return (
        <>
            <RedirectNotLoggedIn
                onLoggedInDispatchArray={[
                    agreementsQuery()
                        .filterAgreementType('Agreement')
                        .filterStatus('Live')
                        .shouldFetchDetails(false)
                        .shouldFetchPersons(false)
                        .shouldFetchCompanies(true)
                        .run()
                ]}
            />
            <AgreementHeaderTableCard
                filter={filter}
                tableIsNotInCard={true}
                title="Emails Pending"
                noItemsText="There are no emails to show"
                showFilter={showFilter}
                columns={[
                    agreementHeaderColumnDefs.selected('Send email'),
                    agreementHeaderColumnDefs.companyName,
                    agreementHeaderColumnDefs.createdDate,
                    agreementHeaderColumnDefs.startDate,
                    agreementHeaderColumnDefs.endDate,
                    agreementHeaderColumnDefs.termsType,
                    agreementHeaderColumnDefs.agreementApprovalTeam,
                    agreementHeaderColumnDefs.status
                ]}
                checkboxColumn={true}
                checkButtonLabel={count =>
                    count === 0 ? 'Email' : `Email ${count} ${count === 1 ? 'Agreements' : 'Agreements'}`
                }
                submitFunctionType={'Email'}
            />
        </>
    );
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(AgreementsEmailPendingTable);
