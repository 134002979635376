import { ENDPOINTS } from '../constants';
import { handleErrorWithToastUnlessUnauthorised, makePostRequest } from '../apiHelpers';
import { IMeeting } from '../../../reducers/Api/Meeting';
import { isLoading } from '../../Loading/isLoading';

export interface IMeetingFilter {
    meetingId?: number;
    companyId?: number;
    assigneeId?: number;
    personId?: number;
    startDate?: Date;
    endDate?: Date;
    status?: string[];
}
interface IResponse {
    data: IMeeting[];
}

const type = ENDPOINTS.API.MEETING_FILTER;

export const apiMeetingFilter = (filter: IMeetingFilter, fetchCompanies: boolean = true) => async (
    dispatch: (action: any) => void,
    getState: () => any
) => {
    if (!getState().offline.online) {
        return false;
    }
    isLoading(dispatch, type, true, {});
    makePostRequest(['meeting', 'filter'], {}, filter)
        .then(({ data }: IResponse) => {
            isLoading(dispatch, type, false, {});
            dispatch({
                type,
                data,
                filter
            });
            return true;
        })
        .catch((error: any) => {
            isLoading(dispatch, type, false, {});
            handleErrorWithToastUnlessUnauthorised(dispatch, getState, error, type, 'Meetings');
        });
};
