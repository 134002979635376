import {
    basicColumnDef,
    choiceListColumnDef,
    currencyColumnDef,
    dateColumnDef,
    idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';

const bandingsDef = {
    id: idColumnDef('id', 'bandingId'),
    updatedDate: {
        ...dateColumnDef('Last Updated', 'updatedDate')
    },
    carLcv: {
        ...choiceListColumnDef('Cars/Lcv', 'carLcv', 'bandingCarLCV')
    },
    descriptor: {
        ...basicColumnDef('Band Name', 'descriptor'),
        searchable: true
    },
    numberOfVehicles: {
        ...basicColumnDef('Number of Vehicles', 'numberOfVehicles'),
        type: 'number'
    },
    manufacturers: {
        ...choiceListColumnDef('Manufacturers', 'manufacturers', 'bandingManufacturers')
    },
    segment: {
        ...choiceListColumnDef('Segment', 'segment', 'bandingSegment', { multipleChoice: true })
    },
    manufacturerPolicy: {
        ...choiceListColumnDef('Manufacturer Policy', 'manufacturerPolicy', 'bandingManufacturerPolicy')
    },
    reason: {
        ...choiceListColumnDef('Reason', 'reason', 'bandingReason', { multipleChoice: true })
    },
    fundingMethod: {
        ...choiceListColumnDef('Funding Method', 'fundingMethod', 'bandingFundingMethod', { multipleChoice: true })
    },
    decisionBasedOn: {
        ...choiceListColumnDef('Purchase Decision Based On', 'decisionBasedOn', 'bandingDecisionBasedOn', {
            multipleChoice: true
        })
    },
    minPricePoint: {
        ...currencyColumnDef('Min Price Point', 'minPricePoint')
    },
    changeCycleMonths: {
        ...basicColumnDef('Change Cycle Months', 'changeCycleMonths')
    },
    changeCycleMiles: {
        ...basicColumnDef('Change Cycle Miles', 'changeCycleMiles')
    },
    fuelType: {
        ...choiceListColumnDef('Fuel Type', 'fuelType', 'bandingFuelType', { multipleChoice: true })
    },
    co2Cap: {
        ...basicColumnDef('CO² Cap', 'co2Cap'),
        type: 'number'
    },
    benchmarkManufacturer: {
        ...choiceListColumnDef('Benchmark Manufacturer', 'benchmarkManufacturer', 'bandingBenchmarkManufacturer', {
            multipleChoice: true
        })
    },
    benchmarkModel: {
        ...basicColumnDef('Benchmark Model', 'benchmarkModel')
    },
    benchmarkPrice: {
        ...currencyColumnDef('Benchmark Price', 'benchmarkPrice')
    },
    hyundaiSalesOpportunity: {
        ...choiceListColumnDef('Hyundai Sales Opportunity', 'hyundaiSalesOpportunity', 'bandingSalesOpportunity')
    },
    anecdotalNotes: {
        ...basicColumnDef('Anecdotal Notes', 'anecdotalNotes'),
        type: 'textarea'
    },
    maxPricePoint: {
        ...currencyColumnDef('Max Price Point', 'maxPricePoint')
    }
};

export default bandingsDef;
