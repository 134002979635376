import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import OpportunityForm from '../Forms/OpportunityForm';
import { AppModalHeader } from '../../common/AppModalHeader';
import useModalForm from '../../common/Hooks/useModalForm';
import { createFakeId } from '../../../reducers/util';

const EditOpportunityModal = ({ children }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();

    return (
        <>
            {isOpen ? (
                <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
                    <AppModalHeader toggle={toggle}>
                        {formValue.opportunityId < 0 ? 'Create Opportunity' : 'Edit Opportunity'}
                    </AppModalHeader>
                    <ModalBody>
                        <OpportunityForm
                            onCancel={toggle}
                            companyId={formValue.companyId}
                            opportunityId={formValue.opportunityId}
                        />
                    </ModalBody>
                </Modal>
            ) : null}
            {children({
                editOpportunity: ({ companyId, opportunityId }) =>
                    openModal({ companyId, opportunityId: opportunityId || createFakeId() }),
                addOpportunity: ({ companyId }) => openModal({ companyId, opportunityId: createFakeId() })
            })}
        </>
    );
};

export default EditOpportunityModal;
