import React, { useContext } from 'react';
import { ENDPOINTS } from '../../../actions/Api/constants';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { useCommunicationsByCompanyById } from '../../Communication/Selectors/useCommunicationsByCompanyId';

const CompanyCommunicationsContext = React.createContext({
    companyId: undefined,
    communications: [],
    isLoading: false
});

const CompanyCommunicationsProvider = ({ companyId, children }) => {
    const isLoading = useIsLoading([ENDPOINTS.API.COMPANY]);
    const communications = useCommunicationsByCompanyById(companyId);
    return (
        <CompanyCommunicationsContext.Provider value={{ companyId, communications, isLoading }}>
            {children}
        </CompanyCommunicationsContext.Provider>
    );
};

export const useCompanyCommunicationsContext = () => {
    const state = useContext(CompanyCommunicationsContext);
    if (state === undefined) {
        throw new Error(`useCompanyCommunicationsContext must be used inside CompanyCommunicationsProvider`);
    }
    return state;
};

export default CompanyCommunicationsProvider;
