import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';

export const apiCreateTemplateFile = file => async dispatch => {
    const type = ENDPOINTS.API.AGREEMENT_TEMPLATE_POST;

    dispatch(
        apiGetPost(type, ['Agreement', 'template', 'upload'], file, {}, undefined, true, error => {
            toastr.error(type, `Response ${error.message}`);
        })
    );
};
