import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';

import { createFakeId } from '../../../reducers/util';
import useModalForm from '../../common/Hooks/useModalForm';
import EditBandingForm from '../Forms/EditBandingForm';

const EditBandingModal = ({ children, className }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className={className}>
                <AppModalHeader toggle={toggle}>
                    {formValue.bandingId < 0 ? 'Add Banding' : 'Edit Banding'}
                </AppModalHeader>
                <ModalBody>
                    <EditBandingForm onCancel={toggle} banding={formValue} />
                </ModalBody>
            </Modal>
            {children({
                editBanding: banding => openModal(banding),
                createBanding: (companyId, defaultValues) =>
                    openModal({ bandingId: createFakeId(), companyId, ...defaultValues })
            })}
        </>
    );
};

export default EditBandingModal;
