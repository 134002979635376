import React from 'react';
import useIsWindowWidthOver from '../../common/Hooks/useIsWindowWidthOver';

const showIfFound = (children, type, show = true) => {
    if (!show) {
        return null;
    }
    const tab = children.find(child => child.type.name === type);
    return tab ? React.cloneElement(tab, {}) : null;
};

export const Column1 = ({ children }) => children;
export const Column2 = ({ children }) => children;
export const Column2And3 = ({ children }) => children;
export const Column3 = ({ children }) => children;
export const Column3Top = ({ children }) => children;

const Layout3Columns = ({ children }) => {
    const has3columns = useIsWindowWidthOver(1440);

    return (
        <div className="d-grid page-grid">
            <div className="column-1">
                {showIfFound(children, Column3Top.name, !has3columns)}
                {showIfFound(children, Column1.name)}
                {showIfFound(children, Column3.name, !has3columns)}
            </div>
            {children.find(child => child.type.name === Column2And3.name) ? (
                <div className="column-2 grid-cs-2 grid-ce-last">{showIfFound(children, Column2And3)}</div>
            ) : null}
            <div className="column-2">{showIfFound(children, Column2.name)}</div>
            <div className="column-3">
                {showIfFound(children, Column3Top.name, has3columns)}
                {showIfFound(children, Column3.name, has3columns)}
            </div>
        </div>
    );
};

export default Layout3Columns;
