import React from 'react';
import { Popover, PopoverBody } from 'reactstrap';

export default class GdprPreference extends React.Component {
    state = {
        popoverOpen: false
    };

    render() {
        const { type, permitted } = this.props;
        return [
            <div
                key="1"
                id={`gdprpref_${type.fa}`}
                style={{
                    width: 20,
                    height: 20,
                    marginRight: 10
                }}
                className="float-left d-block"
                onMouseOver={() => this.setState({ popoverOpen: true })}
                onMouseOut={() => this.setState({ popoverOpen: false })}
            >
                <div className="position-relative">
                    <i className={`position-absolute mt-2 ml-2 fa d-inline fa-xl mr-3 ${type.fa}`} />
                    {permitted === true ? null : (
                        <i className="position-absolute text-danger fa d-inline fa-2x fa-ban mr-3" />
                    )}
                </div>
            </div>,
            <Popover key="2" placement="top" isOpen={this.state.popoverOpen} target={`gdprpref_${type.fa}`}>
                <PopoverBody>{`This person ${permitted ? 'can' : 'cannot'} be marketed to by ${
                    type.descriptor
                }.`}</PopoverBody>
            </Popover>
        ];
    }
}
