import { RESET_REDUCER } from '../../../actions/resetReducers';
import { API_LOGIN_ENDPOINTS } from '../../../actions/ApiAuth/ApiAuthEndpoints';
import { ENDPOINTS } from '../../../actions/Api/constants';

const defaultState = {
    descriptor: '',
    email: '',
    roles: [],
    userId: -1,
    userName: '',
    expires: null,
    token: '',
    mobile: '',
    fax: '',
    browsingHistory: [],
    eventCompanyId: -1,
    canApproveAgreement: '',
    canCreateTemplate: '',
    agreementApprovalTeam: ''
};

const initialState = JSON.parse(localStorage.getItem('reduxPersist:user')) || defaultState;
/*
export const isAlm = user => user && user.roles && user.roles.includes('cv_alm');

export const isDealer = user => user && user.roles && user.roles.includes('cv_dealer');

export const isDealerOrAlm = user => isDealer(user) || isAlm(user);
*/

export const filterAuthorised = (array, user) =>
    array.filter(item => (item.roles && item.roles.some(role => userHasRole(role, user))) || !item.roles);

export const userHasRole = (role, user) => user.roles.some(userRole => role === userRole);

export function user(state = initialState, action) {
    switch (action.type) {
        case RESET_REDUCER:
            return defaultState;
        case ENDPOINTS.API.USERLOG_POST:
            const { browsingHistory: s, ...rest } = state;
            return { browsingHistory: action.data, ...rest };
        case ENDPOINTS.API.AGREEMENT_FILTER:
            if (!action.data.browsingHistory) return state;

            return { ...state, browsingHistory: action.data.browsingHistory };
        case API_LOGIN_ENDPOINTS.API.API_AUTH_USER_LOGIN:
            const {
                expires,
                token,
                descriptor,
                email,
                roles,
                userId,
                userName,
                fax,
                mobile,
                browsingHistory,
                eventCompanyId,
                jobTitle,
                canApproveAgreement,
                canCreateTemplate,
                agreementApprovalTeam
            } = action.data;
            return {
                ...state,
                expires,
                token,
                descriptor,
                email,
                roles,
                userId,
                userName,
                fax,
                mobile,
                browsingHistory,
                eventCompanyId,
                jobTitle,
                canApproveAgreement,
                canCreateTemplate,
                agreementApprovalTeam
            };

        case ENDPOINTS.API.USER:
            return {
                ...state,
                descriptor: action.data.descriptor,
                email: action.data.email,
                roles: action.data.roles,
                userId: action.data.userId,
                userName: action.data.userName,
                fax: action.data.fax,
                mobile: action.data.mobile,
                browsingHistory: action.data.browsingHistory,
                canApproveAgreement: action.data.canApproveAgreement,
                canCreateTemplate: action.data.canCreateTemplate,
                agreementApprovalTeam: action.data.agreementApprovalTeam
            };

        default:
            return state;
    }
}
