import React from 'react';
import SelectInlineChoicelist from '../../common/InlineEdit/SelectInlineChoicelist';

const DashboardFieldChoiceList = props => {
    const {
        field,
        fieldProps: {
            title,
            value,
            meta: { isSyncing, isError, save, choiceListName }
        }
    } = props;

    return (
        <SelectInlineChoicelist
            choiceListName={choiceListName}
            isError={isError}
            isSyncing={isSyncing}
            multiple={false}
            objectId={value}
            propertyName={field}
            required
            save={(_, val) => save(val)}
            stack
            title={title}
            value={choiceListName === 'rfoRequest' && value === false ? 'false' : value || 'Not specified'}
        />
    );
};

export default DashboardFieldChoiceList;
