import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PersonForm from '../Forms/PersonForm';
import { AppModalHeader } from '../../common/AppModalHeader';
import { createFakeId } from '../../../reducers/util';
import useModalForm from '../../common/Hooks/useModalForm';

const PersonEditModal = ({ children, className }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className={`modal-lg ${className}`}>
                <AppModalHeader toggle={toggle}>{formValue.personId < 0 ? 'Add Person' : 'Edit Person'}</AppModalHeader>
                <ModalBody>
                    <PersonForm cancel={toggle} {...formValue} />
                </ModalBody>
            </Modal>
            {children({
                editPerson: person => openModal(person),
                createPerson: (companyId, ...defaultValues) =>
                    openModal({ personId: createFakeId(), companyId, ...defaultValues })
            })}
        </>
    );
};

export default PersonEditModal;
