import React from 'react';
import _ from 'lodash';
import { FieldLabel } from '../DisplayField/FieldLabel';

const Th = props => {
    const { tableId, sort, value, field, right, setTableSortColumn, sortField, sortOrder } = props;
    return (
        <td
            className={`border-top-0 ${right ? 'text-right' : ''}`}
            onClick={() => (sort ? setTableSortColumn(tableId, sortField || field) : undefined)}
        >
            <FieldLabel>
                {sort ? (
                    <i
                        className={`fa d-inline fa-lg fa-sort mr-2 ${
                            sortField === _.get(sortOrder, 'column', '')
                                ? `text-warning ${sortOrder.sort_asc ? 'fa-sort-desc' : 'fa-sort-asc'}`
                                : 'fa-sort'
                        }`}
                    />
                ) : null}
                {value}
            </FieldLabel>
        </td>
    );
};

export default Th;
