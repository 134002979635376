import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';
import { toastr } from 'react-redux-toastr';

export const apiApplyAddendum = (addendumId, agreementHeaderIds) => async dispatch => {
    const type = ENDPOINTS.API.APPLY_ADDENDUM;

    dispatch(
        apiGetPost(
            type,
            ['agreement', 'addendum', addendumId, 'apply'],
            agreementHeaderIds,
            {},
            undefined,
            true,
            error => {
                toastr.error(type, `${error.message}`);
            },
            undefined,
            undefined,
            undefined,
            data => {
                if (data.createdCount > 0) {
                    toastr.success('Addendum Applied', `Applied addendum to ${data.createdCount} agreements`);
                }
                if (data.failedCreatePdfCount > 0) {
                    toastr.error(
                        'Unable to generate agreement PDF',
                        `Failed to create PDFs for ${data.failedCreatePdfCount} agreements`
                    );
                }
            }
        )
    );
};
