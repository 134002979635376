import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { apiCommunicationPost } from '../../../actions/Api/Communication/apiCommunicationPost';
import { REST_TIME_FORMAT } from '../../../actions/Api/apiHelpers';
import ReactiveForm from '../../common/ReactiveForm';
import communication from '../communication';
import validation from '../communication.validation';
import _ from 'lodash';
import { useCompanyById } from '../../Company/Selectors/useCompanyById';
import { useCommunicationById } from '../Selectors/useCommunicationById';

const FORM_NAME = 'editCommunicationForm';
export const COMMUNICATION_CAPTION = [
    'FOMCompanyFollowUpCall',
    'ArrangeDemo',
    'BookMeeting',
    'ProvideProductInfo',
    'EventInvite'
];
const communicationActivityRegardingOptions = resources =>
    _.get(resources, 'choiceList.communicationActivityRegarding', []).filter(x =>
        COMMUNICATION_CAPTION.includes(x.key)
    );

function handleSubmit(save, onCancel, formValues) {
    const communication = {
        ...formValues,
        category: formValues.activityRegarding === 'FOMCompanyFollowUpCall' ? 'Task' : 'Appointment',
        person: { id: formValues.personId },
        assignee: { id: parseInt(formValues.assigneeId, 10) }
    };
    save(communication, onCancel);
}

function emptyForm({ userId, descriptor }, { companyId, descriptor: companyDescriptor }, communicationId) {
    const startDate = moment()
        .hour(9)
        .minute(0)
        .seconds(0)
        .milliseconds(0)
        .format(REST_TIME_FORMAT);

    return {
        communicationId,
        activityRegarding: '',
        action: '',
        category: '',
        subject: companyDescriptor,
        assigneeId: userId.toString(),
        assigneeName: descriptor,
        assignee: {
            id: userId,
            descriptor
        },
        status: '',
        note: '',
        companyId,
        company: {
            id: companyId,
            descriptor: companyDescriptor
        },
        startDate,
        endDate: startDate
    };
}

const EditCommunicationForm = ({ onCancel, user, resources, companyId, communicationId, save, people }) => {
    const company = useCompanyById(companyId) || { companyId, description: 'NA' };
    const existingCommunication = useCommunicationById(communicationId);

    let initialValues = _.cloneDeep(existingCommunication);

    if (!initialValues && communicationId < 0) {
        initialValues = emptyForm(user, company, communicationId);
        initialValues.communicationId = communicationId;
    }

    if (initialValues) {
        initialValues.assigneeId =
            initialValues.assignee && initialValues.assignee.id ? initialValues.assignee.id.toString() : null;
        initialValues.companyId =
            initialValues.company && initialValues.company.id ? initialValues.company.id.toString() : null;
        initialValues.status = initialValues.status || 'Pending';
        initialValues.action = initialValues.action || 'PhoneOut';
        initialValues.personId = _.get(initialValues, 'person.id');
    }

    if (initialValues.activityRegarding === 'FOMCompanyFollowUpCall') {
        initialValues.category = 'Task';
    } else {
        initialValues.category = 'Appointment';
    }

    const peopleChoices = people
        .filter(person => person.companyId.toString() === initialValues.companyId)
        .map(person => ({
            key: person.personId,
            value: [person.salutation, person.firstName, person.lastName].filter(x => !!x).join(' ')
        }));

    return (
        <ReactiveForm
            initialValues={initialValues}
            cancel={onCancel}
            resources={resources}
            formName={FORM_NAME}
            validationSchema={validation}
            handleSubmit={formValues => handleSubmit(save, onCancel, formValues)}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Date columnDef={communication.startDate} />
                <ReactiveForm.Choicelist columnDef={communication.personId} options={peopleChoices} />
                <ReactiveForm.Choicelist
                    columnDef={communication.activityRegarding}
                    options={communicationActivityRegardingOptions(resources)}
                />
                <ReactiveForm.Choicelist columnDef={communication.action} />
                <ReactiveForm.Text columnDef={communication.subject} />
                <ReactiveForm.Choicelist columnDef={communication.assigneeId} />
                <ReactiveForm.Choicelist columnDef={communication.status} />
                <ReactiveForm.Text columnDef={communication.note} />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
};

const mapStateToProps = state => ({
    user: state.user,
    people: state.people,
    resources: state.resources
});

const mapDispatchToProps = dispatch => ({
    save: (communication, cancel) => {
        dispatch(apiCommunicationPost(communication));
        if (cancel) {
            cancel();
        }
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCommunicationForm);
