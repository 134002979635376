import React, { useState } from 'react';
import { Alert, Button } from 'reactstrap';
import { apiCreatePromotionAgreementForCompany } from '../../../actions/Api/AgreementHeader/apiCreatePromotionAgreementForCompany';
import { useDispatch } from 'react-redux';

const CompanyPromotionsAlertsCard = ({ disabled, promotion, companyId }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <Alert color={'primary'} className={'m-0 mb-2 clearfix overflow-hidden'}>
                <div>{promotion.name}</div>
                <div className="d-flex justify-content-end mt-3">
                    <Button
                        onClick={() => {
                            setIsLoading(true);
                            dispatch(
                                apiCreatePromotionAgreementForCompany(
                                    promotion.id,
                                    companyId,
                                    () => setIsLoading(false),
                                    () => setIsLoading(false)
                                )
                            );
                        }}
                        className={'float-right'}
                        disabled={disabled || isLoading}
                    >
                        Create Agreement
                    </Button>
                </div>
            </Alert>
        </>
    );
};

export default CompanyPromotionsAlertsCard;
