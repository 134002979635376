import pipe from 'lodash/fp/pipe';
import filter from 'lodash/fp/filter';
import allPass from 'lodash/fp/allPass';
import sortBy from 'lodash/fp/sortBy';
import forEach from 'lodash/fp/forEach';
import moment from 'moment';
import { calendarOptions } from '../../calendarOptions';
import { getChoiceListValue } from '../../../containers/common/LabelText';

const isAssignedToUser = (visitAction, userId) => visitAction && visitAction.assignee && visitAction.assignee.id === userId;

export const isNotCancelled = visitAction => visitAction.status !== 'Cancelled';

const isPending = visitAction => visitAction.status === 'Pending'; //|| visitAction.status === 'Overdue';
const isOverdue = visitAction => new Date(visitAction.endDate).getTime() < Date.now();

export const filterAssignedToUser = (visitActions, userId) =>
    visitActions.filter(visitAction => isAssignedToUser(visitAction, userId)).filter(x => isNotCancelled(x));

export const outstandingVisitActions = pipe(
    filterAssignedToUser,
    filter(allPass([isPending, isOverdue])),
    sortBy('startDate')
);

export const addColorToVisitAction = visitAction => {
    visitAction.start = moment(visitAction.startDate)
        .utc()
        .valueOf();
    visitAction.end = moment(visitAction.endDate)
        .utc()
        .valueOf();
    visitAction.end = visitAction.end < visitAction.start ? visitAction.start + 60 * 60 * 1000 : visitAction.end;
    visitAction.assigneeName = visitAction.assignee.descriptor;
    visitAction.calendarOption = 'visitReportAction';
};

const getVisitActionTitle = ({ subject, action }, visitAction, choiceList) => {
    const actionName = getChoiceListValue({ choiceList }, 'visitActionActions', action);
    return actionName && subject
        ? `${actionName} - ${subject}`
        : actionName
        ? actionName
        : subject
        ? subject
        : 'UNKNOWN';
};

export const mapVisitActionToCalendarEvent = (visitAction, choiceList) => {
    const { visitActionId, start, end } = visitAction;
    let calendarOpt = calendarOptions.visitReportAction;
    const icon = calendarOpt.icon;
    const color = calendarOpt.color;

    return {
        id: `visitAction-${visitActionId}`,
        title: getVisitActionTitle(visitAction, choiceList),
        visitAction,
        icon,
        start: moment(start).toDate(),
        end: moment(end).toDate(),
        color
    };
};

export const eventStyleGetter = event => {
    const { icon, color } = event;
    const style = {
        icon
    };
    return {
        style: style,
        className: `border-0 rounded-0 background-hover background-color-${color}`
    };
};

export const addColorToVisitActions = pipe(forEach(addColorToVisitAction));
