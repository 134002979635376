import { getFieldsNotUpdated } from '../helper';

const propertiesToIgnoreForErrors = [
    'person',
    'company',
    'assignee',
    'updatedDate',
    'start',
    'end',
    'assigneeName',
    'calendarOption'
];

const isPersonInCompany = (person, companyId) => person.companyId === companyId;

export const filterPeopleInCompany = (people, companyId) =>
    people.filter(person => isPersonInCompany(person, companyId));

export const getPersonUpdateErrors = (local, remote) => getFieldsNotUpdated(local, remote, propertiesToIgnoreForErrors);
