import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import useModalForm from '../../common/Hooks/useModalForm';
import FinalizeAgreementForm from '../Forms/FinalizeAgreementForm';

const FinalizeAgreementModal = ({ children }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
                <AppModalHeader toggle={toggle}>Finalise Agreement</AppModalHeader>
                {isOpen ? (
                    <ModalBody>
                        <FinalizeAgreementForm isModal={true} agreementHeaderId={formValue} toggle={toggle} />
                    </ModalBody>
                ) : null}
            </Modal>
            {children({
                finalizeAgreement: id => {
                    openModal(id);
                },
                toggle
            })}
        </React.Fragment>
    );
};

export default FinalizeAgreementModal;
