import React from 'react';
import ReactiveForm from '../../common/ReactiveForm';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import { connect } from 'react-redux';
import { apiCreateAgreementEvidence } from '../../../actions/Api/AgreementEvidence/apiCreateAgreementEvidence';

const UploadAgreementEvidence = ({ resources, uploadEvidence, agreementHeaderId, onResultCallback }) => {
    const handleSubmit = form => {
        uploadEvidence(form.evidence, onResultCallback);
    };

    const initialValues = {
        agreementHeaderId: agreementHeaderId
    };

    return (
        <ReactiveForm
            initialValues={initialValues}
            resources={resources}
            formName={'FILE_UPLOAD'}
            handleSubmit={handleSubmit}
            hideCancelButton={true}
            hideSaveButton={true}
        >
            <ReactiveForm.Section>
                <ReactiveForm.Dropzone
                    hideLabel={true}
                    columnDef={agreementHeaderColumnDefs.evidence}
                    title="Drag evidence document here, or click to browse."
                />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
};

const mapDispatchToProps = dispatch => ({
    uploadEvidence: (evidence, onResultCallback) => dispatch(apiCreateAgreementEvidence(evidence, onResultCallback))
});

export default connect(
    null,
    mapDispatchToProps
)(UploadAgreementEvidence);
