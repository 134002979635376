import React from 'react';
import useModalForm from '../../common/Hooks/useModalForm';
import { AppModalHeader } from '../../common/AppModalHeader';
import { Modal, ModalBody } from 'reactstrap';
import TestTemplateForm from '../Forms/TestTemplateForm';
import { useDispatch } from 'react-redux';
import { RESET_TEMPLATE_REDUCER } from '../../../reducers/Api/Templates';

export const TestTemplateModal = ({ children }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();
    const dispatch = useDispatch();

    const resetReducerToggle = () => {
        dispatch({
            type: RESET_TEMPLATE_REDUCER,
            data: {}
        });
        toggle();
    };

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={resetReducerToggle} className={'modal-xl'}>
                <AppModalHeader toggle={resetReducerToggle}>"Test Template"</AppModalHeader>
                <ModalBody>
                    <TestTemplateForm toggle={resetReducerToggle} template={formValue} />
                </ModalBody>
            </Modal>
            {children({
                testTemplate: template => openModal(template)
            })}
        </React.Fragment>
    );
};
