import React from 'react';
import DemosTableCard from './DemosTableCard';
import { useCompanyDemosContext } from '../../Company/Provider/CompanyDemosProvider';

function CompanyDemosTable() {
    const { demos, isLoading } = useCompanyDemosContext();
    return <DemosTableCard rows={demos} isLoading={isLoading} tableIsNotInCard={true} />;
}

export default CompanyDemosTable;
