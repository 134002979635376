import React from 'react';
import PageSectionContentTable from '../../common/PageSection/PageSectionContentTable';
import { useMeetingContext } from '../../Meeting/Provider/MeetingProvider';
import meetingDef from '../../Meeting/meeting';

export default () => {
    const { meeting } = useMeetingContext();
    return (
        <PageSectionContentTable
            values={meeting}
            columnDefs={[
                meetingDef.status,
                meetingDef.meetingMode,
                meetingDef.startDate,
                meetingDef.activityRegarding,
                meetingDef.location
            ]}
        />
    );
};
