import React from 'react';
import PageSection from '../../common/PageSection';
import _ from 'lodash';
import { SubSectionTitle } from '../../common/SubSection/SubSectionTitle';

const versions = [
    {
        version: '1.0.0',
        title: 'Initial Release',
        date: '01/04/2019',
        changes: []
    }
];

export const currentVersion = () => _.orderBy(versions, ['version'], 'desc')[0];

const VersionsPage = ({ history }) => (
    <PageSection onBackClicked={history.goBack} title="Versions">
        {versions.map(({ version, date, title, changes }) => (
            <div key={version}>
                <SubSectionTitle title={`${version} - ${date} - ${title}`} />
                <ul>
                    {changes.map((change, index) => (
                        <li key={index}>{change}</li>
                    ))}
                </ul>
            </div>
        ))}
    </PageSection>
);

export default VersionsPage;
