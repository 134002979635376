import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const makeMeetingsByCompanyByIdSelector = () =>
    createSelector(
        state => state.meetings,
        (_, companyId) => companyId,
        (meetings, companyId) => meetings.filter(meeting => (meeting.company.id === companyId || meeting.companyId === companyId))
    );

export const useMeetingsByCompanyById = companyId => {
    const selectMeetings = useMemo(makeMeetingsByCompanyByIdSelector, []);
    return useSelector(state => selectMeetings(state, companyId));
};
