import * as yup from 'yup';

const mandatoryString = errorMessage =>
    yup
        .string()
        .min(1)
        .nullable()
        .required(errorMessage);

const validation = yup.object().shape({
    // Personal Information
    salutation: mandatoryString('Salutation is required'),
    title: mandatoryString('Job Title is required'),
    firstName: mandatoryString('First Name is required'),
    lastName: mandatoryString('Last Name is required'),
    // Contact Details
    businessEmail: yup
        .string()
        .email('Invalid email address')
        .required('Email is required'),
    businessPhone: yup.string().nullable(),
    mobilePhone: yup.string().nullable(),
    // GDPR
    gdprEmail: mandatoryString('Please set the email GDPR preference'),
    gdprDirectMail: mandatoryString('Please set the direct mail GDPR preference'),
    gdprTelephone: mandatoryString('Please set the telephone GDPR preference'),
    gdprSms: mandatoryString('Please set the SMS GDPR preference'),
    isPrimaryPerson: yup.boolean()
});

export default validation;
