import React from 'react';
import {connect} from 'react-redux';
import {Modal, ModalBody} from 'reactstrap';
import {AppModalHeader} from '../../common/AppModalHeader';
import useModalForm from '../../common/Hooks/useModalForm';
import {DeclineAllProductsInFamilyButton} from '../../common/Buttons/TableButtons';
import ReactiveForm from '../../common/ReactiveForm';
import {basicColumnDef} from '../../common/FilteredTable/tableAndFilterHelpers';
import {apiAgreementDetailDecline} from '../../../actions/Api/AgreementDetail/apiAgreementDetailDecline';

const activeDetailIdsFor = products => {
    return products.reduce((acc, prod) => {
        const activeDetails = prod.details.filter(d => d.deleted === null && d.status === 'Modified').map(x => x.id);
        return [...acc, ...activeDetails];
    }, []);
};

const DeclineAllAgreementDetailsForProductFamilyModal = ({resources, declineDetails, products, visibility}) => {
    const {toggle, isOpen, formValue, openModal} = useModalForm();

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
                <AppModalHeader toggle={toggle}>Decline Product</AppModalHeader>
                <ModalBody>
                    <ReactiveForm
                        initialValues={formValue}
                        resources={resources}
                        formName="DeclineAllAgreementDetailsForProductForm"
                        cancel={toggle}
                        handleSubmit={({notes}) => {
                            declineDetails(activeDetailIdsFor(products), notes, 'Success', 'Agreement Details Declined')
                            toggle();
                        }}
                    >
                        <ReactiveForm.Section>
                            <ReactiveForm.Text type="textarea" columnDef={basicColumnDef('Notes', 'notes')}/>
                        </ReactiveForm.Section>
                    </ReactiveForm>
                </ModalBody>
            </Modal>
            <DeclineAllProductsInFamilyButton visibility={visibility} onClick={() => openModal({notes: ''})}/>
        </>
    );
};

const mapStateToProps = ({resources}) => ({resources});

const mapDispatchToProps = dispatch => ({
    declineDetails: (ids, notes, successTitle, successMessage) =>
        dispatch(apiAgreementDetailDecline(ids, notes, successTitle, successMessage))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeclineAllAgreementDetailsForProductFamilyModal);
