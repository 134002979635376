import React, { useContext } from 'react';
import { ENDPOINTS } from '../../../actions/Api/constants';
import EditOpportunityModal from '../../Opportunity/Modal/EditOpportuntiyModal';
import { useOpportunitiesByCompanyById } from '../../Opportunity/Selectors/useOpportunitiesByCompanyId';
import { useIsLoading } from '../../common/Hooks/useIsLoading';

const CompanyOpportunitiesContext = React.createContext({
    companyId: undefined,
    opportunities: [],
    isLoading: false
});

const CompanyOpportunitiesProvider = ({ companyId, children }) => {
    const opportunities = useOpportunitiesByCompanyById(companyId);
    const isLoading = useIsLoading([ENDPOINTS.API.COMPANY]);

    return (
        <EditOpportunityModal>
            {({ addOpportunity }) => (
                <CompanyOpportunitiesContext.Provider value={{ addOpportunity, companyId, opportunities, isLoading }}>
                    {children}
                </CompanyOpportunitiesContext.Provider>
            )}
        </EditOpportunityModal>
    );
};

export const useCompanyOpportunitiesContext = () => {
    const state = useContext(CompanyOpportunitiesContext);
    if (state === undefined) {
        throw new Error(`useCompanyOpportunitiesContext must be used inside CompanyOpportunitiesContext`);
    }
    return state;
};

export default CompanyOpportunitiesProvider;
