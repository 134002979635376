import { useSelector } from 'react-redux';
import _ from 'lodash';
import { createSelector } from 'reselect';
import { useMemo } from 'react';

const makeIsLoadingSelector = () =>
    createSelector(
        state => state.loadingStates,
        (_, endpoints) => endpoints,
        (loadingStates, endpoints) =>
            endpoints.some(endpoint => _.get(loadingStates, endpoint, { isLoading: false }).isLoading)
    );

export const useIsLoading = (endpoints = []) => {
    const selectIsLoading = useMemo(makeIsLoadingSelector, []);
    return useSelector(state => selectIsLoading(state, endpoints));
};
