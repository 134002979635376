import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiGetVersionPromise } from '../../actions/Api/Version/version';
import { apiAuthUserLogout } from '../../actions/ApiAuth/apiAuthUserLogout';

const CacheBusterContext = React.createContext(undefined);

const checkInterval = 300000; //5 minutes

export const CacheBusterProvider = ({ children }) => {
    const dispatch = useDispatch();

    const [state, setState] = useState({ loading: true, isLatestVersion: false });
    const refreshCacheAndReload = () => {
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches
                .keys()
                .then(function(names) {
                    for (let name of names) caches.delete(name);
                })
                .catch(error => {
                    console.error('An error occurred: ', error);
                });
        }
        dispatch(apiAuthUserLogout(undefined, true));
    };

    const checkVersion = useCallback(() => {
        const currentVersion = process.env.REACT_APP_VERSION;
        apiGetVersionPromise()
            .then(result => {
                const latest = result && result.data;
                if (latest && latest === currentVersion) {
                    setState({ loading: false, isLatestVersion: true });
                } else {
                    setState({ loading: false, isLatestVersion: false });
                }
            })
            .catch(() => {
                setState({ loading: false, isLatestVersion: true });
            });
    }, []);

    useEffect(() => {
        checkVersion();
        const interval = setInterval(() => {
            checkVersion();
        }, [checkInterval]);
        return () => clearInterval(interval);
    }, [checkVersion]);

    return (
        <CacheBusterContext.Provider
            value={{
                ...state,
                refreshCacheAndReload
            }}
        >
            {children}
        </CacheBusterContext.Provider>
    );
};

export const useCacheBusterContext = () => {
    const state = useContext(CacheBusterContext);

    if (state === undefined) {
        throw new Error(`useCacheBusterContext must be used inside CacheBusterProvider`);
    }
    return state;
};

export default CacheBusterProvider;
