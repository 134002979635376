import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import appSettings from '../../config/appSettings';
import { apiAuthUserLogout } from '../../actions/ApiAuth/apiAuthUserLogout';
import { connect } from 'react-redux';

function isLogOutTime(timeout, lastInteraction) {
    const timeSinceLastInteraction = new Date() - lastInteraction;
    return timeSinceLastInteraction > timeout;
}

function onUnload(e) {
    if (process.env.REACT_APP_NO_OFFLINE === 'true') localStorage.clear();
}

function updateInteraction(setLastInteraction) {
    setLastInteraction(new Date());
}

const LogOutTimer = ({ userId, logout }) => {
    const [lastInteraction, setLastInteraction] = useState(new Date());

    useEffect(
        () => {
            const debouncedUpdateInteraction = _.debounce(() => updateInteraction(setLastInteraction), 1000, {
                leading: true
            });
            updateInteraction(setLastInteraction);
            document.addEventListener('click', debouncedUpdateInteraction);
            document.addEventListener('keydown', debouncedUpdateInteraction);
            return () => {
                document.removeEventListener('click', debouncedUpdateInteraction);
                document.removeEventListener('keydown', debouncedUpdateInteraction);
            };
        },
        [setLastInteraction]
    );

    const intervalRef = useRef();

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            if (userId > -1 && isLogOutTime(appSettings.timeout, lastInteraction)) {
                logout();
            }
        }, 30000);
        return () => clearInterval(intervalRef.current);
    });

    useEffect(() => window.addEventListener('beforeunload', onUnload), []);

    return null;
};

const mapStateToProps = ({ user: { userId }, page }) => ({
    userId,
    page
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(apiAuthUserLogout())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LogOutTimer);
