import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';
import FormikLabel from '../../common/FormkControls/FormikLabel';
import { useAgreement } from '../Selectors/useAgreement';

export default function AgreementParentLinks({ inline }) {
    const { agreementHeader } = useContext(AgreementHeaderContext);
    const parentAgreement = useAgreement(agreementHeader.parentId);

    return (
        <div className="form-group">
            {agreementHeader.addendumId === null && agreementHeader.parentId === null ? null : (
                <>
                    {parentAgreement ? (
                        <FormikLabel
                            title="Agreement History"
                            fieldName="AgreementHistory"
                            labelClassName={inline ? 'd-inline-block' : 'd-block mb-2'}
                        />
                    ) : null}

                    {parentAgreement ? (
                        <Link
                            className="nav-link m-0 mb-2 p-0 grid-js-end"
                            style={{ color: 'blue' }}
                            to={`/dashboard/agreement/${agreementHeader.parentId}`}
                        >
                            {agreementHeader.parentType === 'Template'
                                ? `${parentAgreement.name} - Created from Template`
                                : agreementHeader.parentType === 'Promotion'
                                ? `${parentAgreement.name} - On Promotion`
                                : agreementHeader.parentType === 'Addendum'
                                ? 'Addendum'
                                : `${parentAgreement.name} - Cloned from Agreement`}
                        </Link>
                    ) : null}
                </>
            )}
        </div>
    );
}
