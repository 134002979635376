import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import useModalForm from '../../common/Hooks/useModalForm';
import ReactiveForm from '../../common/ReactiveForm';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import * as Yup from 'yup';
import { apiAddendumDetailUpdate } from '../../../actions/Api/AgreementHeader/apiAddendumDetailUpdate';

const validationSchema = Yup.object().shape({
    templateDetailId: Yup.number()
        .required('You must enter the template detail Id')
        .min(1, 'The template detail Id must be a positive integer')
});

export default function AddendumRemoveTemplateDetailButton({ agreementHeaderId }) {
    const { toggle, isOpen, formValue } = useModalForm();
    const dispatch = useDispatch();
    const resources = useSelector(state => state.resources);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <AppModalHeader toggle={toggle}>Remove Product</AppModalHeader>
            {isOpen ? (
                <ModalBody>
                    <ReactiveForm
                        initialValues={formValue}
                        resources={resources}
                        formName="AddendumRemoveTemplateDetailForm"
                        cancel={toggle}
                        handleSubmit={({ templateDetailId }) => {
                            const detail = {
                                agreementHeaderId,
                                addendumChangeType: 'RemoveTemplateDetail',
                                templateDetailId
                            };
                            dispatch(apiAddendumDetailUpdate(detail, 'Success', 'Addendum Updated', toggle));
                        }}
                        validationSchema={validationSchema}
                    >
                        <ReactiveForm.Section>
                            {/* TODO replace this with a better selector */}
                            <ReactiveForm.Text
                                columnDef={{
                                    ...basicColumnDef('Template Detail Id', 'templateDetailId')
                                }}
                                type="number"
                            />
                        </ReactiveForm.Section>
                    </ReactiveForm>
                </ModalBody>
            ) : null}
        </Modal>
    );
}
