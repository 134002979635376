import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';

export const apiFinaliseAgreement = (
    AgreementHeaderId,
    notes,
    successTitle,
    successMessage,
    callback
) => async dispatch => {
    const type = ENDPOINTS.API.FINALISE_AGREEMENT;
    const data = {
        notes
    };

    dispatch(
        apiGetPost(
            type,
            ['agreement', 'finalise', AgreementHeaderId],
            data,
            {},
            undefined,
            true,
            (dispatch, type, error) => {
                toastr.error('Finalise Agreement', `${error.message}`);
            },
            undefined,
            successTitle,
            successMessage,
            callback
        )
    );
};
