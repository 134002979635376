import React, { useContext } from 'react';
import _ from 'lodash';
import { DeclineTableRowButton } from '../../common/Buttons/TableButtons';
import { AgreementHeaderContext } from '../../AgreementHeader/Provider/AgreementHeaderProvider';
import DeclineAgreementDetailModal from '../Modals/DeclineAgreementDetailModal';

const ButtonDeclineDetail = ({ agreementDetail, isDeleted }) => {
    const {
        agreementState: { canDecline, isDraft }
    } = useContext(AgreementHeaderContext);

    if (isDraft) {
        return null;
    }

    const rowCanBeDeclined =
        _.get(agreementDetail, 'Deleted', null) === null && _.get(agreementDetail, 'status') === 'Modified';

    const visibility = !rowCanBeDeclined || !canDecline || isDeleted ? 'invisible' : 'visible';

    return (
        <DeclineAgreementDetailModal>
            {({ declineAgreementDetail }) => (
                <DeclineTableRowButton
                    visibility={visibility}
                    onClick={() => declineAgreementDetail(agreementDetail.id)}
                />
            )}
        </DeclineAgreementDetailModal>
    );
};

export default ButtonDeclineDetail;
