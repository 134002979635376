import React from 'react';
import AgreementHeaderTableCard from './AgreementHeaderTableCard';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import { agreementsQuery } from '../../../actions/Api/AgreementHeader/apiAgreementFilter';
import RedirectNotLoggedIn from '../../app/RedirectNotLoggedIn';

export const filterAddendumAgreements = agreementHeaders => {
    return agreementHeaders.filter(agreementHeader => {
        return agreementHeader.agreementType === 'Addendum';
    });
};

const AddendaTable = ({ showFilter }) => {
    return (
        <>
            <RedirectNotLoggedIn
                change={1}
                onLoggedInDispatchArray={[
                    agreementsQuery()
                        .filterAgreementType('Addendum')
                        .shouldFetchDetails(false)
                        .shouldFetchPersons(false)
                        .shouldFetchCompanies(false)
                        .run()
                ]}
            />
            <AgreementHeaderTableCard
                filter={filterAddendumAgreements}
                title="Addenda"
                showFilter={showFilter}
                tableIsNotInCard={true}
                noItemsText="There are no addenda to show"
                columns={[agreementHeaderColumnDefs.name,agreementHeaderColumnDefs.createdDate, agreementHeaderColumnDefs.status]}
            />
        </>
    );
};

export default AddendaTable;
