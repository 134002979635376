import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DisplayField } from '../DisplayField/index';

const PageSectionContentTable = props => {
   const { columnDefs, values, resources } = props;
   return (
       <table>
           <tbody>
               <tr>
                   {columnDefs.map(({ title, field, mapping, mapToDisplayedValue }) => (
                       <td key={title} className="pr-4">
                           <DisplayField
                               title={title}
                               key={title}
                               value={
                                   (mapToDisplayedValue
                                       ? mapToDisplayedValue(resources, values, field)
                                       : mapping
                                       ? mapping(resources, values)
                                       : values[field]) || 'Unspecified'
                               }
                           />
                       </td>
                   ))}
               </tr>
           </tbody>
       </table>
   );
};

PageSectionContentTable.propTypes = {
   items: PropTypes.arrayOf(
       PropTypes.shape({
           title: PropTypes.node.isRequired,
           value: PropTypes.node
       })
   )
};
const mapStateToProps = state => ({
   resources: state.resources
});

export default connect(mapStateToProps)(PageSectionContentTable);