import { makeHeadersOffline, makePath, makeUrl } from '../apiHelpers';

export const API_MEETING_EMAIL = 'API_MEETING_EMAIL';

export interface IMeetingEmail {
	userId: number;
	to: string;
	cc: string;
	bcc: string;
	from: string;
	subject: string;
	body: string;
}

export const apiMeetingEmail = (meetingId: string, email: IMeetingEmail, onSuccess: () => void = () => {}) => async (
	dispatch: any,
	getState: () => any
) => {

	const { user } = getState();
	let to, cc, bcc, fromAddress;
	if (process.env.REACT_APP_EMAIL_OVERRIDE) {
		to = [process.env.REACT_APP_EMAIL_OVERRIDE];
		cc = [process.env.REACT_APP_EMAIL_OVERRIDE];
		bcc = [process.env.REACT_APP_EMAIL_OVERRIDE];
		fromAddress = 'it@roiltd.co.uk';
	} else {
		to = email.to.split(',').map(x => x.trim());
		cc = email.cc.split(',').map(x => x.trim());
		bcc = email.bcc.split(',').map(x => x.trim());
		fromAddress = email.from;
	}
	const data = {
		userId: user.userId,
		to,
		cc,
		bcc,
		fromAddress,
		fromName: user.descriptor,
		subject: email.subject,
		body: email.body,
	};
	const axiosRequest = {
		method: 'POST',
		url: makeUrl(getState(), makePath(['meeting', meetingId, 'email']), {}),
		headers: makeHeadersOffline(),
		data,
	};
	dispatch({
		type: API_MEETING_EMAIL,
		data: {}, // Reducers can ignore these, just want redux-offline to send/resend for me
		meta: {
			offline: {
				effect: axiosRequest,
			},
		},
	});
	if (onSuccess) onSuccess();
};
