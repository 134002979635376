import { ENDPOINTS } from '../../../actions/Api/constants';
import _ from 'lodash';
import { RESET_REDUCER } from '../../../actions/resetReducers';

const initialState = [];

const agreementStatusChanges = (state = initialState, { type, data }) => {
    switch (type) {
        case ENDPOINTS.API.AGREEMENT_TEMPLATE_POST:
        case ENDPOINTS.API.AGREEMENT_APPROVE:
        case ENDPOINTS.API.AGREEMENT_DECLINE:
        case ENDPOINTS.API.AGREEMENT_MAKE_TEMPLATE_EDITABLE:
        case ENDPOINTS.API.FINALISE_AGREEMENT:
        case ENDPOINTS.API.DELETE_AGREEMENT:
        case ENDPOINTS.API.SWITCH_AGREEMENT_TEMPLATE:
        case ENDPOINTS.API.AGREEMENT_DETAIL_DECLINE:
        case ENDPOINTS.API.AGREEMENT_FILTER:
            return _.get(data, 'agreementStatusChanges', []);
        case RESET_REDUCER:
            return initialState;
        default:
            return state;
    }
};

export default agreementStatusChanges;
