import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Button } from 'reactstrap';
import FormikText from '../../common/FormkControls/FormikText';
import { resetReducers } from '../../../actions/resetReducers';
import { Link } from 'react-router-dom';
import { apiUserLogin } from '../../../actions/ApiAuth/apiAuthUserLogin';
import { isAnyLoading } from '../../../reducers/Rest/loadingStates';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import LoginHeader from '../../app/LoginHeader';
import LoginFooter from '../../app/LoginFooter';

class LoginForm extends React.Component {
    state = {
        email: process.env.REACT_APP_DEFAULT_USERNAME || ''
    };

    componentDidMount() {
        this.props.logOut();
    }

    handleSubmit = ({ email, password }) => this.props.logIn(email, password);

    render() {
        const { loadingStates, className } = this.props;
        if (isAnyLoading(loadingStates)) {
            return <ShowLoadingSpinner isLoading color="#fff" />;
        }

        const initialValues = {
            email: this.state.email,
            password: process.env.REACT_APP_DEFAULT_PASSWORD || ''
        };

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={this.handleSubmit}
                render={formikProps => {
                    formikProps.formName = 'loginForm';
                    return (
                        <form className={className} onSubmit={formikProps.handleSubmit}>
                            <LoginHeader />
                            <div className="mb-4">
                                <FormikText
                                    type="text"
                                    fieldName="email"
                                    placeholder="Email"
                                    {...formikProps}
                                    hideLabel
                                    handleChange={e => {
                                        this.setState({ email: e.target.value });
                                        formikProps.handleChange(e);
                                    }}
                                />
                                <FormikText
                                    type="password"
                                    fieldName="password"
                                    placeholder="Password"
                                    {...formikProps}
                                    hideLabel
                                />
                                <div className="d-grid grid-cols-2 grid-column-gap-2">
                                    <div />
                                    <Link className="nav-link m-0 p-0 grid-js-end" to="/forgotpassword/">
                                        Forgotten Password
                                    </Link>
                                </div>
                            </div>
                            <Button
                                type="submit"
                                color="primary"
                                className="w-100"
                                disabled={!formikProps.values.email || !formikProps.values.password}
                            >
                                Login
                            </Button>
                            <LoginFooter />
                        </form>
                    );
                }}
            />
        );
    }
}

const mapStateToProps = state => ({
    loadingStates: state.loadingStates,
    login: state.login
});

const mapDispatchToProps = dispatch => ({
    logOut: () => dispatch(resetReducers()),
    logIn: (userName, password) => dispatch(apiUserLogin(userName, password))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm);
