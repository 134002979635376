import { useMemo } from 'react';

function useProductAndFamilyForDetail(newProduct, productFamily, detail) {
    return useMemo(
        () => {
            const product = newProduct.find(x => x.id === detail.productId);
            const family = product ? productFamily.find(family => product.productFamilyId === family.id) : undefined;
            return { family, product };
        },
        [newProduct, productFamily, detail]
    );
}

export default useProductAndFamilyForDetail;
