import {toastr} from 'react-redux-toastr';
import {ENDPOINTS} from '../constants';
import {apiGetPost} from '../apiHelpers';

export const apiEmailAgreementPost = (agreementHeaderIds, successTitle, successMessage, cc = [], bCc = []) => async dispatch => {
    const type = ENDPOINTS.API.EMAIL_AGREEMENT_POST;
    const data = {
        agreementHeaderIds,
        ccs: cc,
        bCcs: bCc
    };

    dispatch(
        apiGetPost(
            type,
            ['agreement', 'email'],
            data,
            {},
            undefined,
            true,
            error => {
                toastr.error(type, `Response ${error.message}`);
            },
            undefined,
            successTitle,
            successMessage
        )
    );
};
