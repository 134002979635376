import { useMemo } from 'react';

function AccumulateAverage() {
    this.count = 0;
    this.total = 0;

    this.addValue = function(value, count = 1) {
        if (!value) return;

        this.total += value;
        this.count += count;
    };

    this.average = function() {
        return this.total / this.count;
    };

    this.toString = function() {
        return isNaN(this.average()) ? ' ' : `${this.average().toFixed(1)}%`;
    };
}

const useAverages = products =>
    useMemo(
        () => {
            const averageDiscounts = products
                .map(product => product.details.find(d => d.deleted === null))
                .filter(activeDetails => !!activeDetails)
                .reduce(
                    (averageDiscounts, activeDetails) => {
                        averageDiscounts.manufacturer.addValue(activeDetails.manufacturerDiscount);
                        averageDiscounts.dealer.addValue(activeDetails.dealerDiscount);
                        averageDiscounts.total.addValue(
                            activeDetails.dealerDiscount + activeDetails.manufacturerDiscount
                        );
                        return averageDiscounts;
                    },
                    {
                        manufacturer: new AccumulateAverage(),
                        dealer: new AccumulateAverage(),
                        total: new AccumulateAverage()
                    }
                );

            return {
                manufacturerAverage: averageDiscounts.manufacturer,
                dealerAverage: averageDiscounts.dealer,
                totalAverage: averageDiscounts.total
            };
        },
        [products]
    );

export default useAverages;
