import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import _ from 'lodash';

const initialState = {
    companies: [],
    totalResults: 0,
    hasMoreRows: false,
    keyword: '',
    page: 0
};

function mapCompanyAccountManager(company) {
    return company.accountManager ? { ...company, accountManagerId: '' + company.accountManager.id } : company;
}

export function companySummaries(state = initialState, action) {
    switch (action.type) {
        case RESET_REDUCER:
            return initialState;
        case ENDPOINTS.API.COMPANY_SEARCH:
            let companies = _.get(action, 'data.companies');

            if (companies === null) return action.data;

            return { ...action.data, companies: companies.map(mapCompanyAccountManager) };
        default:
            return state;
    }
}
