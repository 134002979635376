import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../constants';
import { getAxiosPostOfflineRequest, REST_TIME_FORMAT } from '../apiHelpers';
import moment from 'moment';

export const apiFleetProfileEdit = (fleetProfileForm, originalFleetProfile) => async (dispatch, getState) => {
    const type = ENDPOINTS.API.FLEET_PROFILE_POST;

    const fleetProfile = { ...fleetProfileForm };

    try {
        const data = { ...fleetProfile };
        if (data.fleetProfileId < 0) {
            data.fleetProfileId = null;
        }

        data.updatedDate = moment().format(REST_TIME_FORMAT);

        const axiosRequest = getAxiosPostOfflineRequest(['fleetprofile'], {}, data);

        let unmodifiedFleetProfile = getState().fleetProfiles.find(
            x => x.fleetProfileId === fleetProfile.fleetProfileId
        );

        if (!unmodifiedFleetProfile) {
            unmodifiedFleetProfile = originalFleetProfile || fleetProfile;
        }

        unmodifiedFleetProfile.error = undefined;

        dispatch({
            type,
            data: { ...fleetProfile, syncing: true },
            entityId: `${type}-${fleetProfile.fleetProfileId}`, //Used in store.js to see if offline updates are targeting the same entity
            meta: {
                offline: {
                    effect: axiosRequest,
                    commit: { type: ENDPOINTS.API.FLEET_PROFILE_POST_COMMIT, meta: { fleetProfile } },
                    rollback: {
                        type: ENDPOINTS.API.FLEET_PROFILE_POST_ROLLBACK,
                        meta: { unmodifiedFleetProfile, fleetProfile }
                    }
                }
            }
        });
        return true;
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        toastr.error(type, `Response ${error.message}`);
        return false;
    }
};
