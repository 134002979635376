import React from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'reactstrap';
import { HashLoader } from 'react-spinners';

const ActionButton = props => {
    const {
        buttonText,
        fontawesomeIcon,
        bootstrapColor,
        onClickAction,
        hideText,
        disabled = false,
        loading = false
    } = props;

    const hasText = buttonText !== '' && !hideText;

    return (
        <ListGroupItem
            tag="button"
            type="button"
            color={bootstrapColor}
            action
            className="hover-pointer"
            onClick={() => onClickAction()}
            disabled={!!disabled}
        >
            {loading ? (
                <div className="mr-3" style={{ display: 'inline-block' }}>
                    <HashLoader color={'#6c757d'} size={10} />
                </div>
            ) : (
                <div style={{ width: '15px' }} className={`d-inline-block text-center ${hasText ? 'mr-3' : ''}`}>
                    <i className={`fa ${fontawesomeIcon}`} />
                </div>
            )}
            {!hideText ? buttonText : ''}
        </ListGroupItem>
    );
};

ActionButton.defaultProps = {
    buttonText: PropTypes.string.isRequired,
    fontawesomeIcon: PropTypes.string.isRequired,
    bootstrapColor: PropTypes.string.isRequired,
    onClickAction: PropTypes.func.isRequired
};

export default ActionButton;
