import React from 'react';

export const AppLogo = ({ className, style }) => (
    <svg
        version="1.1"
        baseProfile="tiny"
        className={className ? className : 'logo-img'}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={style}
        viewBox="0 0 249.4 68"
    >
        <g id="Layer_1">
            {process.env.REACT_APP_MODE === 'PORTAL' ? (
                <g>
                    <path
                        className="logo-whiteletter"
                        d="M94.8,46.4 M94.8,46.4c0-1,0-31.2,0-31.2h17.9c2.9,0,5.4,0.4,7.4,1.1c2,0.7,3.6,1.7,4.9,3
			c2.2,2.2,3.3,5.1,3.3,8.8v0.1c0,2.9-0.7,5.3-2.1,7.2c-1.4,1.9-3.3,3.4-5.7,4.4l9,13.2h-12.1l-7.6-11.4h-0.1h-4.5V53
			C105.3,53,94.8,53,94.8,46.4z M112.2,33.3c1.8,0,3.2-0.4,4.2-1.2c1-0.8,1.5-1.9,1.5-3.3v-0.1c0-1.5-0.5-2.6-1.5-3.4
			c-1-0.7-2.4-1.1-4.2-1.1h-6.8v9.1H112.2z"
                    />
                    <g>
                        <path
                            className="logo-whiteletter"
                            d="M15.8,46.4c0-0.7,0-31.2,0-31.2H32c2.4,0,4.5,0.3,6.5,0.9c1.9,0.6,3.6,1.5,5,2.6c1.4,1.1,2.4,2.5,3.2,4.2
				c0.7,1.7,1.1,3.5,1.1,5.6v0.1c0,2.2-0.4,4.2-1.2,5.9c-0.8,1.7-1.9,3.1-3.4,4.3c-1.4,1.2-3.2,2-5.2,2.6c-2,0.6-4.2,0.9-6.5,0.9
				h-5.2V53C26.3,53,15.8,53,15.8,46.4z M31.3,34c1.9,0,3.3-0.4,4.4-1.3c1.1-0.9,1.6-2.1,1.6-3.6V29c0-1.6-0.5-2.9-1.6-3.7
				c-1.1-0.9-2.5-1.3-4.5-1.3h-4.9v10H31.3z"
                        />
                    </g>
                    <g>
                        <g>
                            <g>
                                <path
                                    className="logo-whiteletter"
                                    d="M142.8,24.3h-11.3v-9.2h33.1v9.2h-11.3v28.6h-10.5V24.3z"
                                />
                                <path
                                    className="logo-whiteletter"
                                    d="M175.4,14.9h10.1l16.1,38h-11.2l-2.8-6.7H173l-2.7,6.7h-11L175.4,14.9z M184.6,38.1l-4.2-10.7
						l-4.3,10.7H184.6z"
                                />
                            </g>
                        </g>
                    </g>
                    <path
                        className="logo-whiteletter"
                        d="M204.8,15.2h10.5v28.7h18.3v9.2c0,0-10.9,0-19.9,0s-9-7.2-9-7.2L204.8,15.2z"
                    />
                    <g>
                        <path
                            className="logo-blueletter"
                            d="M58.6,42.1c-1.5-2.3-2.4-5.1-2.4-8c0-8.1,6.5-14.6,14.6-14.6c3,0,5.8,0.9,8.1,2.4l3.6-3.6
				c-3.3-2.4-7.3-3.9-11.7-3.9C60,14.5,51.2,23.2,51.2,34c0,4.3,1.4,8.4,3.8,11.6L58.6,42.1z"
                        />
                        <path
                            className="logo-blueletter"
                            d="M86.5,22.4L82.9,26c1.5,2.3,2.4,5.1,2.4,8c0,8.1-6.5,14.6-14.6,14.6c-3,0-5.8-0.9-8.1-2.4l-3.6,3.6
				c3.3,2.4,7.3,3.9,11.7,3.9c10.8,0,19.6-8.8,19.6-19.6C90.3,29.7,88.9,25.7,86.5,22.4z"
                        />
                    </g>
                </g>
            ) : process.env.REACT_APP_MODE === 'AFFINITY' ? (
                <g>
                    <g id="Layer_4" data-name="Layer 4">
                        <path
                            className="logo-whiteletter"
                            d="M96.23,15.1V7.25H70.38V23.66l0,0c0,5.51,0,10.41,0,10.5,0,5.49,8.92,5.51,8.92,5.51v-.13h0V28H94.61V20.55H79.33V15.1Z"
                        />
                        <path
                            className="logo-whiteletter"
                            d="M13.88,6.71h8.71L36.46,39.55H26.78L24.4,33.72H11.83L9.5,39.55H0Zm7.91,20-3.63-9.27-3.68,9.27Z"
                        />
                        <path
                            className="logo-whiteletter"
                            d="M220.92,27.47,208.64,7.25h10.2L225.49,19l6.7-11.77h10L229.92,27.33V39.57h-9Z"
                        />
                        <path
                            className="logo-whiteletter"
                            d="M124.53,6.88H133l13.47,17.27V6.88h8.91V39.55h-8l-14-17.92V39.55s-8.91,0-8.91-5.64v-27"
                        />
                        <path className="logo-whiteletter" d="M185.53,15h-9.78V7h28.59V15h-9.78V39.63h-9Z" />
                        <path
                            className="logo-whiteletter"
                            d="M65.43,15.1V7.25H39.57V23.66l0,0c0,5.51,0,10.41,0,10.5,0,5.49,8.93,5.51,8.93,5.51v-.13h0V28H63.81V20.55H48.53V15.1Z"
                        />
                        <path
                            className="logo-whiteletter"
                            d="M161.48,7h9.09V39.7s-9.06,0-9.06-5.52C161.51,34,161.48,7,161.48,7Z"
                        />
                        <g className="cls-2">
                            <image
                                width="92"
                                height="76"
                                transform="translate(63.58)"
                                href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFwAAABMCAYAAADgOdDDAAAACXBIWXMAAAsSAAALEgHS3X78AAAD/klEQVR4Xu3aQXPaVhTF8XPuE8KAnY6TdqNusoYPyejr5KNkD2uv2LSJpwZjkPTe6cIh02amsRfNhXbub8NGMOKvO09PDJSE4MdeOiD8uyK4swjuLII7i+DOIrizCO4sgjuL4M4iuLMI7iyCO4vgziK4swjuLII7i+DOIrizCO4sgjuL4M4iuLMI7iyCO4vgziK4swjuLII7i+DOIriz6qUDLgFJvnQMAOg/8N9rXto5nuJKQtu2XK1WXCwW3Gw2vL+/JxYLLL4cuwKA1Qq3t7dqmkar1UqLxULL5VKna3RpF+GSgrNtWwKwzWbD8XhsVVVZSskeHh6qlN6a2db6/oo3N89v2G6B0eigUm5Kzp/LmzdvhpxzGYahHI/H0jSNAJTlcikAF/FFLyJ427YGwO7u7qrpdFpJGlVVdXqtJY1ztrqqlHrAUikEgGymEVCGgTml0pE8DsPQkeyHYRhI9vv9fnj//v2A5/Dl+2fy413CGn6a6no2m00AXNd1Pe17TFIqV5mcsnBmhknOqi3RgEQAMEg5q5ixk+ypGB7NbJ+zHcbj9NT3/X42m+02m81T0zQdnqf8rBN29glv29Y+ffo0KqVcq65v2ekXG+GtpJ8guybLDcWbAswAjA1KpwWakgqYARwNeBS1lWwLlh3JP0qPz6r5G7vu3sx2796968895WedcJL88OEDP378WOWcr46l3JrxV8gaqPxMlRvQZpKuCUxE1QIMBc83VkKACsVOwBNoO6o8CtwC9rtZmeRShrqun1JKh/l8PpDkOW+kZw0OAOv1mvv9niQrVNUYsCmFGwFvRFybMAFZC0oUKeDrHlHPo04QiWANYVYIA0AKR8GmQB53XVdJ4nq9ftX28kc6e/D5fK7dbqec83As5QiUvaQtyIqyA6iRyApCIklQX6MRAEUJkohMaaCsF/Uk5S3APYBjXddDSknz+fxsk31y1uCS1LatjsfjUEo5oK7vy6AqpbQndC1oJFpCKSYWEgmn5eQrQkIBi0lmBSrZwB60Xc7lHpXdd113MLNhvV7rnMsJcAE3TTzvv9Nms6lHo9GkqqpZztU0paEupaSSEkcAypet4D8xM/UALGeZWc656lIa9sMwPPZ9/9Q0TbdcLjPOvEs564R/IQClaZru7u6uTKfTY13zgTQbhimv6o4AkFJ64WOABODQT1RVB5G5dF0//HUfjjPHBi5jwk/+9qT57WP8a337uI940vy+1/5Q9VrnXrO/dXHB/+/i93BnEdxZBHcWwZ1FcGcR3FkEdxbBnUVwZxHcWQR3FsGdRXBnEdxZBHcWwZ1FcGcR3FkEdxbBnUVwZxHcWQR3FsGdRXBnEdxZBHcWwZ1FcGcR3NmfRFUBbsg+OngAAAAASUVORK5CYII="
                            />
                        </g>
                        <polygon
                            className="logo-affinityIcon"
                            points="114.2 21.42 114.2 6.79 105.09 6.79 105.09 21.42 97.54 21.42 109.64 34.68 121.75 21.42 114.2 21.42"
                        />
                    </g>
                </g>
            ) : (
                <g>
                    <g>
                        <g>
                            <g>
                                <path
                                    className="logo-whiteletter"
                                    d="M85.4,34.1V34c0-11,8.6-19.6,20.3-19.6c6.6,0,11.3,2.1,15.3,5.5l-6.2,7.4c-2.7-2.3-5.4-3.6-9.1-3.6
						c-5.5,0-9.7,4.6-9.7,10.4v0.1c0,6.1,4.3,10.5,10.3,10.5c2.5,0,4.5-0.5,6-1.6v-4.6H105v-7.7h17.4v17c-4,3.3-9.6,5.8-16.4,5.8
						C94.2,53.7,85.4,45.6,85.4,34.1z"
                                />
                                <path
                                    className="logo-whiteletter"
                                    d="M166.7,34.1V34c0-11,8.6-19.6,20.3-19.6c6.6,0,11.3,2.1,15.3,5.5l-6.2,7.4c-2.7-2.3-5.4-3.6-9.1-3.6
						c-5.5,0-9.7,4.6-9.7,10.4v0.1c0,6.1,4.3,10.5,10.3,10.5c2.5,0,4.5-0.5,6-1.6v-4.6h-7.4v-7.7h17.4v17c-4,3.3-9.6,5.8-16.4,5.8
						C175.5,53.7,166.7,45.6,166.7,34.1z"
                                />
                            </g>
                        </g>
                    </g>
                    <path
                        className="logo-blueletter"
                        d="M151.2,15h-10.1L134,31.8c2.1-1.5,4.9-2.5,8.4-1.7c0.3,0.1,0.6,0.1,0.8,0.2l0.4-3.7l4.7,4.1l4.7,4.1
			l-5.4,3.1l-5.4,3.1l0.3-3.5c-2.4-0.7-9.4-2.1-12.7,4.2L125,53.1h11l2.7-6.7h14.6l2.8,6.7h11.2L151.2,15z"
                    />
                    <path
                        className="logo-whiteletter"
                        d="M18.2,53c-9.3,0-9.3-6.9-9.3-6.9l0-30.9h30.4V24H19.1v5.7h18.2V38H19.1v6h20.3V53C39.5,53,27.5,53,18.2,53
			z"
                    />
                    <path
                        className="logo-whiteletter"
                        d="M219.3,53c-9.3,0-9.3-6.9-9.3-6.9l0-30.9h30.4V24h-20.1v5.7h18.2V38h-18.2v6h20.3V53
			C240.6,53,228.7,53,219.3,53z"
                    />
                    <g>
                        <path
                            className="logo-whiteletter"
                            d="M44.6,15.1h9.8l15.6,20v-20h10.3V53h-9.2L54.9,32.2V53c0,0-10.3,0-10.3-6.5C44.6,46,44.6,15.1,44.6,15.1"
                        />
                    </g>
                </g>
            )}
        </g>
    </svg>
);
