import React, { useContext } from 'react';
import { CompanyPeopleContext } from '../../Company/Provider/CompanyPeopleProvider';
import PersonTable from './PersonTable';

const CompanyPeopleTable = () => {
    const { people, companyId } = useContext(CompanyPeopleContext);
    return <PersonTable title={'Contacts'} breakpoint={700} itemsPerPage={10} rows={people} companyId={companyId} />;
};

export default CompanyPeopleTable;
