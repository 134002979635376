import { useState } from 'react';
import useToggleOpen from './useToggleOpen';

const useModalForm = (initialIsOpen = false) => {
    const { toggle, isOpen, setIsOpen } = useToggleOpen(initialIsOpen);
    const [formValue, setFormValue] = useState({});

    return {
        toggle,
        isOpen,
        setIsOpen,
        formValue,
        openModal: value => {
            setFormValue(value);
            setIsOpen(true);
        }
    };
};

export default useModalForm;
