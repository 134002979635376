import React from 'react';
import { Button } from 'reactstrap';

const visibilitySetting = { makeInvisible: 0, makeDisabled: 1 };

const invisibilitySetting = visibilitySetting.makeDisabled;

export const DeleteTableRowButton = props => TableRowButton(props, 'Delete', 'trash', 'danger');
export const TestTemplateRowButton = props => TableRowButton(props, 'Test', 'indent', 'primary');
//export const CloneTableRowButton = props => TableRowButton(props, 'Clone', 'copy', 'primary');
//export const EditTableRowButton = props => TableRowButton(props, 'Edit', 'pencil', 'primary');
//export const ViewAllTableRowButton = props => TableRowButton(props, 'View All', 'th-list', 'primary');
export const ApproveTableRowButton = props => TableRowButton(props, 'Approve', 'thumbs-o-up', 'primary');
//export const AddendumTableRowButton = props => TableRowButton(props, 'Create Addendum', 'paperclip', 'primary');
export const TickTableRowButton = props => TableRowButton(props, 'Confirm', 'check', 'success');
export const CrossTableRowButton = props => TableRowButton(props, 'Cancel', 'times', 'danger');
//export const OpenAsPdfTableRowButton = props => TableRowButton(props, 'View PDF', 'file', 'primary');
//export const HistoryTableRowButton = props => TableRowButton(props, 'History', 'history', 'secondary');
//export const JsonTableRowButton = props => TableRowButton(props, 'View Data Dev', 'eye', 'secondary');
export const EditItemRowButton = props => TableRowButton(props, 'Edit', 'pencil', 'primary');
export const DeclineTableRowButton = props => TableRowButton(props, 'Decline', 'thumbs-o-down', 'danger');
export const AddTableRowButton = props => TableRowButton(props, 'Add', 'plus', 'primary');
export const ApplyAddendumButton = props => TableRowButton(props, 'Apply Addendum', 'check-circle-o', 'primary');
export const EmptyButton = props => TableRowButton({ ...props, empty: true }, '', 'pencil', 'primary');
export const AddendumRemoveAgreementButton = props =>
    TableRowButton(props, 'Remove Agreement from Addendum', 'trash', 'primary');

export const CollapseTableRowButton = props => (
    <i
        className={`fa d-inline fa-lg ${
            props.collapsed ? 'fa-chevron-down' : 'fa-chevron-up'
        } simple-card-dimmed text-primary hover-pointer`}
        onClick={() => props.onClick()}
    />
);

/*
{
    const { collapsed, ...rest } = props;
    return TableRowButton(
        { ...rest },
        collapsed ? 'Expand' : 'Hide',
        collapsed ? 'chevron-down' : 'chevron-up',
        'primary'
    );
};*/
export const FilterTableRowButton = props => TableRowButton(props, 'Filter', 'filter', 'primary');
//export const MoveUpTableRowButton = props => TableRowButton(props, 'Move Up', 'chevron-up', 'primary');
//export const MoveDownTableRowButton = props => TableRowButton(props, 'Move Down', 'chevron-down', 'primary');
//export const TryAgainButton = props => TableRowButton(props, 'Retry', 'refresh', 'primary');

export const ApproveAllProductsInFamilyButton = props => TableRowButton(props, 'Approve All', 'thumbs-o-up', 'primary');
export const DeclineAllProductsInFamilyButton = props =>
    TableRowButton(props, 'Decline All', 'thumbs-o-down', 'danger');

export const AddDetailToAllProductsInFamilyButton = props => TableRowButton(props, 'Add To All', 'plus', 'primary');

const TableRowButton = (props, title, icon, outline) => {
    let className = '';
    switch (invisibilitySetting) {
        case visibilitySetting.makeInvisible:
            className = props.visibility || 'visible';
            break;
        case visibilitySetting.makeDisabled:
            className = props.visibility ? (props.visibility === 'invisible' ? 'disabled' : '') : '';
            break;
        default:
            //nothing
            break;
    }

    const { onClick, empty, ...rest } = props;

    return (
        <Button
            title={empty ? undefined : props.visibility ? (props.visibility === 'visible' ? title : undefined) : title}
            className={`simple-card-dimmed btn-sm bg-white-not-hover btn-outline-${outline} ${props.className} ${
                empty ? 'invisible' : className
            } mx-1`}
            onClick={empty ? null : props.visibility ? (props.visibility === 'visible' ? onClick : null) : onClick}
            {...rest}
        >
            <i
                className={`fa d-inline fa-lg fa-${icon}${props.disabled ? ' text-muted' : ''}${
                    props.children ? ' mr-2' : ''
                }`}
            />
            {props.children}
        </Button>
    );
};
