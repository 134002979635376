import React from 'react';
import { connect } from 'react-redux';
import addressDef from '../addressDef';
import { getPrimaryAddresses } from '../../../reducers/Api/Company/company';
import { getLoadingState } from '../../../reducers/Rest/loadingStates';
import { ENDPOINTS } from '../../../actions/Api/constants';
import SimpleCard from '../../common/Card/SimpleCard';
import { viewCompanyAddress } from '../../../actions/Navigation/viewCompanyAddress';
import CardDropDownButton from '../../common/Buttons/CardDropDownButton';
import { useCompanyContext } from '../../Company/Provider/CompanyProvider';
import { ConnectedFieldDetails } from '../../common/Table/addColumnsToRow';

function CompanyPrimaryAddressesDetails({ onViewCompanyAddresses }) {
    const { companyId = { address: [] }, company, loadingStates } = useCompanyContext();

    const primaryAddresses = getPrimaryAddresses(company);

    const loadingState = getLoadingState(loadingStates, ENDPOINTS.API.COMPANY);

    const cardButtons = [];
    cardButtons.push({
        text: 'View All',
        fontAwesomeName: 'fa-th-list',
        onClick: () => onViewCompanyAddresses(companyId)
    });

    return loadingState.isError ? (
        <div>Error</div>
    ) : !primaryAddresses.length ? (
        <SimpleCard
            key={'NoAddress'}
            title={'No primary address associated with this company'}
            isLoading={false}
            buttons={[]}
        />
    ) : (
        primaryAddresses.map(address => (
            <SimpleCard
                key={address.addressId}
                title="Addresses"
                isLoading={loadingState.isLoading}
                buttons={<CardDropDownButton items={cardButtons} />}
            >
                <ConnectedFieldDetails
                    values={address}
                    idColumnDef={addressDef.id}
                    fields={[addressDef.map, addressDef.streetAddress, addressDef.city, addressDef.postCode]}
                />
            </SimpleCard>
        ))
    );
}

const mapDispatchToProps = dispatch => ({
    onViewCompanyAddresses: companyId => dispatch(viewCompanyAddress(companyId))
});

export default connect(null, mapDispatchToProps)(CompanyPrimaryAddressesDetails);
