import React, { useMemo, useState } from 'react';
import productAndDetailsDropdownFilters from '../../AgreementHeader/productAndDetailsDropdownFilters';
import { useSelector } from 'react-redux';
import { makeCreateSelectorDetailsForAgreementHeader } from '../Hooks/useFindDetailsBelongingToHeaderId';

function getOptions(productAndDetailsDropdownFilters) {
    return Object.entries(productAndDetailsDropdownFilters).map(([key, { value }]) => ({ key, value }));
}

function useFamiliesProductsDetails(searchTerm, products, productFamily, details, filter) {
    return useMemo(
        () =>
            productAndDetailsDropdownFilters[filter]
                .filter(
                    details,
                    products.filter(
                        product =>
                            product.name.toLowerCase().includes(searchTerm) ||
                            productFamily.find(
                                family =>
                                    family.id === product.productFamilyId &&
                                    family.name.toLowerCase().includes(searchTerm)
                            )
                    ),
                    productFamily,
                    searchTerm
                )
                .filter(x => x.products.length),
        [searchTerm, products, productFamily, details, filter]
    );
}

export default function ProductAndDetailsDropdownFilter({ searchTerm, detailsFilter, children, agreementHeaderId }) {
    const [filter, setFilter] = useState(detailsFilter.default);

    const productFamily = useSelector(state => state.productFamily);
    const products = useSelector(state => state.newProduct);

    const selectorDetailsForAgreementHeader = useMemo(makeCreateSelectorDetailsForAgreementHeader, []);
    const details = useSelector(state => selectorDetailsForAgreementHeader(state, agreementHeaderId));

    const options = getOptions(productAndDetailsDropdownFilters);

    const familiesProductsDetails = useFamiliesProductsDetails(searchTerm, products, productFamily, details, filter);

    return children({
        productRowsFilter: (
            <form className={'form-inline'}>
                <div className={'form-group mb-2'}>
                    <label htmlFor={'productExtraRowsOptions'} className={'mr-2'}>
                        Filter:
                    </label>
                    <select
                        className={'form-control mr-2'}
                        id={'productExtraRowsOptions'}
                        onChange={event => {
                            setFilter(event.target.value);
                        }}
                        value={filter}
                    >
                        {options.map(x => (
                            <option key={x.key} value={x.key} /*selected={x.key === filter}*/>
                                {x.value}
                            </option>
                        ))}
                    </select>
                </div>
            </form>
        ),
        familiesProductsDetails
    });
}
