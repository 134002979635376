import _ from 'lodash';
import { createSelector } from 'reselect';
import { ENDPOINTS } from '../../actions/Api/constants';

export const makeGetCompanySearchLoading = () =>
    createSelector(
        [getCompanySearchLoading],
        x => x
    );

const getCompanySearchLoading = (state, props) =>
    _.get(state.loadingStates, `${ENDPOINTS.API.COMPANY_SEARCH}.isLoading`, false);
export const getCompanyLoadingSelector = state =>
    _.get(state.loadingStates, `${ENDPOINTS.API.COMPANY_OPPORTUNITIES_GET}.isLoading`, false);
/*
export const isDisciplineReportLoadingSelector = state =>
    getLoading(state.loadingStates, [ENDPOINTS.API.REPORTS, ENDPOINTS.API.COMPANIES]);*/

export const isCommunicationsLoadingSelector = state =>
    getLoading(state.loadingStates, [ENDPOINTS.API.COMMUNICATION_GET]);

export const isOpportunitiesLoadingSelector = state =>
    getLoading(state.loadingStates, [ENDPOINTS.API.OPPORTUNITY_FILTER_COMMIT]);

/*
export const isDatabaseGrowthReportLoadingSelector = state => getLoading(state.loadingStates, [ENDPOINTS.API.REPORTS]);
*/

export const getLoading = (loadingStates, endpoints) =>
    endpoints.some(endpoint => getIsLoading(loadingStates, endpoint));

/*
export const isRetentionRateLoadingSelector = state => getLoading(state.loadingStates, [ENDPOINTS.API.REPORTS]);
*/

const getIsLoading = (loadingStates, endpoint) => _.get(loadingStates, `${endpoint}.isLoading`, false);
