import React from 'react';
import CommunicationsTableCard from './CommunicationsTableCard';
import communication from '../communication';
import { useCompanyCommunicationsContext } from '../../Company/Provider/CompanyCommunicationsProvider';

function CompanyCommunicationsTable() {
    const { companyId, communications } = useCompanyCommunicationsContext();
    return (
        <CommunicationsTableCard
            rows={communications}
            companyId={companyId}
            buttons={['addCommunication']}
            isLoading={false}
            columns={[
                communication.startDate,
                communication.activityRegarding,
                communication.assigneeId,
                communication.status,
                communication.note
            ]}
            title={'Communications'}
            breakpoint={860}
            initialSortColumn={'startDate'}
            initialSortOrder={'asc'}
        />
    );
}

export default CompanyCommunicationsTable;
