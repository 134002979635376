import React from 'react';
import AddendumProductName from './AddendumProductName';
import AgreementEditButton from '../../AgreementDetails/Buttons/AgreementDetailEditButton';
import AddendumDetailDeleteButton from '../Buttons/AddendumDetailDeleteButton';

export default function RemoveProductDetailChange({ detail, editAgreementAddendumDetail, readOnly }) {
    return (
        <div>
            {!readOnly && (
                <>
                    <AgreementEditButton
                        detail={detail}
                        hideEditButton={detail.addendumChangeType !== 'RemoveTemplateDetail'}
                        editAgreementAddendumDetail={editAgreementAddendumDetail}
                    />
                    <AddendumDetailDeleteButton detail={detail} />
                </>
            )}
            <AddendumProductName familyName={detail.family.name} productName={detail.product.name} />
            <ul style={{ paddingLeft: 25 }}>
                <li>Will be removed from agreements</li>
            </ul>
        </div>
    );
}
