import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getUserFromState } from '../../../selectors/Api/User/userSelector';
import { getOpportunitiesFromState } from '../../../selectors/Api/Opportunity/opportunitySelector';
import OpportunitiesTableCard from './OpportunitiesTableCard';
import opportunity from '../opportunity';
import pipe from 'lodash/fp/pipe';
import sortBy from 'lodash/fp/sortBy';
import filter from 'lodash/fp/filter';
import allPass from 'lodash/fp/allPass';
import moment from 'moment';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { ENDPOINTS } from '../../../actions/Api/constants';

const opportunityInProgress = oppo => ['Opportunity'].indexOf(oppo.stage) > -1;
const opportunityPastEndDate = oppo => moment(oppo.dueDate).isBefore(moment());

export const filterAssigneeOutstandingOpportunities = pipe(
    filter(allPass([opportunityInProgress, opportunityPastEndDate])),
    sortBy('CreatedDate')
);

const OutstandingOpportunitiesTable = ({ opportunities }) => {
    const isLoading = useIsLoading([ENDPOINTS.API.OPPORTUNITY_FILTER_COMMIT]);
    return (
        <OpportunitiesTableCard
            rows={opportunities}
            isLoading={isLoading}
            title="Outstanding Opportunities"
            columns={[
                opportunity.companyName,
                opportunity.model,
                opportunity.stage,
                opportunity.opportunitySize,
                opportunity.openedDate
            ]}
        />
    );
};

const makeMapStateToProps = () => {
    const getState = createSelector(
        [getOpportunitiesFromState, getUserFromState],
        (opportunities, user) => ({
            opportunities: filterAssigneeOutstandingOpportunities(opportunities /*, user.userId*/)
        })
    );
    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(OutstandingOpportunitiesTable);
