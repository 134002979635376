import React from 'react';
import { useCompanyContext } from '../Provider/CompanyProvider';

const CompanyTitle = () => {
    const {
        company: { descriptor }
    } = useCompanyContext();
    return <React.Fragment>{descriptor}</React.Fragment>; // Because <></> syntax makes webpack complain even though there's nothing wrong here
};

export default CompanyTitle;
