import React, { useContext, useMemo } from 'react';
import { connect } from 'react-redux';
import SecondaryColumn from './SecondaryColumn';
import Product from './Product';
import MapArray from '../../common/MapArray';
import { AgreementDetailsTableContext } from './AgreementDetailsTableProvider';
import useToggleOpen from '../../common/Hooks/useToggleOpen';
import {
    CollapseTableRowButton,
    ApproveAllProductsInFamilyButton,
    EmptyButton
} from '../../common/Buttons/TableButtons';
import DeclineAllAgreementDetailsForProductFamilyModal from '../Modals/DeclineAllAgreementDetailsForProductFamilyModal';
import { apiAgreementDetailApprove } from '../../../actions/Api/AgreementDetail/apiAgreementDetailApprove';
import _ from 'lodash';
import useAverages from '../Hooks/useAverages';
import ButtonAddAllDetailsForFamily from '../AgreementDetailsTableButtons/ButtonAddAllDetailsForFamily';
import ButtonDeleteDetailsInProductFamily from '../AgreementDetailsTableButtons/ButtonDeleteDetailsInProductFamily';

const isApprovable = detail => _.get(detail, 'Deleted', null) === null && _.get(detail, 'status') === 'Modified';

const activeDetailIdsFor = products => {
    return products.reduce((acc, prod) => {
        const activeDetails = prod.details.filter(d => d.deleted === null && d.status === 'Modified').map(x => x.id);
        return [...acc, ...activeDetails];
    }, []);
};

const ProductFamilyRow = ({ family: { id, name }, products, approveDetails }) => {
    const {
        agreementState: { canDecline, canApprove, isDraft },
        showActionsColumn
    } = useContext(AgreementDetailsTableContext);
    const { toggle, isOpen } = useToggleOpen(true);
    const { manufacturerAverage, dealerAverage, totalAverage } = useAverages(products);
    const hasApprovableDetails = useMemo(() => products.some(p => p.details.some(isApprovable)), [products]);

    return (
        <>
            <SecondaryColumn style={{ gridColumnStart: 1 }}>
                <CollapseTableRowButton collapsed={isOpen} onClick={toggle} />
            </SecondaryColumn>
            <SecondaryColumn style={{ gridColumnStart: 2, gridColumnEnd: 4 }}>
                <span>{name}</span>
            </SecondaryColumn>
            <SecondaryColumn style={{ textAlign: 'right' }}>{manufacturerAverage.toString()}</SecondaryColumn>
            <SecondaryColumn style={{ textAlign: 'right' }}>{dealerAverage.toString()}</SecondaryColumn>
            <SecondaryColumn style={{ textAlign: 'right' }}>{`${
                totalAverage.average() ? 'Average:' : ''
            } ${totalAverage.toString()}`}</SecondaryColumn>
            <SecondaryColumn style={{ gridColumnEnd: -1 }} className={showActionsColumn ? '' : 'd-none'}>
                <div style={{ textAlign: 'right' }}>
                    {!isDraft && (
                        <>
                            {' '}
                            <ApproveAllProductsInFamilyButton
                                visibility={!canApprove || !hasApprovableDetails ? 'invisible' : 'visible'}
                                onClick={() =>
                                    approveDetails(
                                        activeDetailIdsFor(products, 'Success', 'Agreement Details Approved')
                                    )
                                }
                            />
                            <DeclineAllAgreementDetailsForProductFamilyModal
                                visibility={!canDecline || !hasApprovableDetails ? 'invisible' : 'visible'}
                                products={products}
                            />
                        </>
                    )}
                    <ButtonAddAllDetailsForFamily productFamilyId={id} products={products} />
                    <EmptyButton visibility={'invisible'} />
                    <ButtonDeleteDetailsInProductFamily productFamiliyId={id} products={products} />
                </div>
            </SecondaryColumn>
            {isOpen ? (
                <MapArray from={products} mapKey={({ product }) => product.id}>
                    <Product />
                </MapArray>
            ) : null}
        </>
    );
};

const mapDispatchToProps = dispatch => ({
    approveDetails: (ids, successTitle, successMessage) =>
        dispatch(apiAgreementDetailApprove(ids, successTitle, successMessage))
});

export default connect(null, mapDispatchToProps)(ProductFamilyRow);
