import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { viewCompany } from '../../../actions/Navigation/viewCompany';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import companySummaryDef from '../companySummaryDef';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';

class CompaniesTable extends React.Component {
    static defaultProps = {
        breakpoint: 500,
        itemsPerPage: 5,
        noItemsText: 'There are no demos to show',
        columns: [
            companySummaryDef.descriptor,
            companySummaryDef.postcode,
            companySummaryDef.status,
            companySummaryDef.accountManager,
            companySummaryDef.numberOfCars
        ]
    };

    render() {
        const { rows, breakpoint, isLoading, itemsPerPage, openCompany, columns } = this.props;

        const tableDefinition = new TableDefinition(columns);

        return (
            <ChoiceListFilteredTableNoDashboardData
                id="CompanyTable"
                noItemsText="There are no companies to show"
                tableIsNotInCard={true}
                showFilter={true}
                itemsPerPage={itemsPerPage}
                breakpoint={breakpoint}
                cardButtons={[]}
                isLoading={isLoading}
                loadingText={'Loading Companies'}
                tableDefinition={tableDefinition}
                rows={rows}
                columnDefsId={companySummaryDef.id}
                tableDefinitionColumns={tableDefinition.columns}
                onRowClick={openCompany}
            />
        );
    }
}

const mapDispatchToProps = dispatch => ({
    openCompany: ({ companyId, descriptor }) => dispatch(viewCompany(companyId, descriptor))
});

export default connect(
    undefined,
    mapDispatchToProps
)(CompaniesTable);
