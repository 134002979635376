import React, { useContext } from 'react';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';
import AgreementHeaderAppliedAddendumDetails from './AgreementHeaderAppliedAddendumDetails';
import AgreementHeaderPendingAddendumDetails from './AgreementHeaderPendingAddendumDetails';
import { useAgreement } from '../Selectors/useAgreement';

const AgreementHeaderAddendumDetails = () => {
    const {
        agreementHeader: { addendumId },
        agreementState: { showAgreementsAddendumDetails }
    } = useContext(AgreementHeaderContext);

    const addendumAgreement = useAgreement(addendumId);

    if (!showAgreementsAddendumDetails || !addendumAgreement) {
        return null;
    }

    return (
        <>
            <AgreementHeaderPendingAddendumDetails addendumAgreement={addendumAgreement} />
            <AgreementHeaderAppliedAddendumDetails addendumAgreement={addendumAgreement} />
        </>
    );
};

export default AgreementHeaderAddendumDetails;
