import React, { useMemo, useState } from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDebounce } from '../Hooks/useDebounce';

function filterRows(rows, search, searchFields, resources) {
    return search && searchFields
        ? rows.filter(row =>
              searchFields.some(field => {
                  let value = _.get(row, field.path, '');

                  if (_.isFunction(value) || React.isValidElement(value)) {
                      value = _.get(row, `${field.path}-raw`, '');
                  }
                  return value && value.toLowerCase().includes(search);
              })
          )
        : rows;
}

const TextFilter = ({ children, rows, searchFields, resources }) => {
    const [search, setSearch] = useState('');

    const debouncedSearchTerm = useDebounce(search.toLowerCase(), 300);

    const textFilteredRows = useMemo(
        () => {
            return filterRows(rows, debouncedSearchTerm, searchFields, resources);
        },
        [rows, debouncedSearchTerm, searchFields, resources]
    );

    return children({
        searchBox:
            searchFields && searchFields.length ? (
                <InputGroup className="w-100 mb-2">
                    <Input
                        value={search}
                        placeholder={`Filter by ${searchFields.map(x => x.name).join(', ')}`}
                        onChange={event => setSearch(event.target.value)}
                    />
                    <InputGroupAddon addonType="append">
                        <Button onClick={() => setSearch('')}>
                            <i className={`fa lg d-inline" ${search ? 'fa-times' : 'fa-search'}`} />
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            ) : null,
        textFilteredRows
    });
};

TextFilter.propTypes = {
    rows: PropTypes.array.isRequired,
    searchFields: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        })
    )
};

TextFilter.defaultProps = {
    searchFields: []
};

export default TextFilter;
