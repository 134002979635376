import React, { useMemo, useContext } from 'react';
import connect from 'react-redux/es/connect/connect';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import { safeDispatchPush } from '../../../reducers/util';
import { push } from 'connected-react-router';
import { apiAgreementSetAddendum } from '../../../actions/Api/AgreementHeader/apiAgreementSetAddendum';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';
import { useRowsWithCompany } from '../../../reducers/Api/Company/company';
import { apiApplyAddendum } from '../../../actions/Api/AgreementHeader/apiApplyAddendum';
import useAgreementHeadersIsLoading from '../Hooks/useAgreementHeadersIsLoading';
import { apiEmailAgreementPost } from '../../../actions/Api/AgreementHeader/apiEmailAgreementPost';
import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../../../actions/Api/constants';

function getSubmitFunction(type, add, apply, email) {
    switch (type) {
        case 'Add':
            return add;
        case 'Apply':
            return apply;
        case 'Email':
            return email;
        default:
            return () => toastr.error('Function not implemented', 'Please contact support');
    }
}

function AgreementHeaderTableCard({
    isLoading,
    loadingStates,
    rows,
    tableIsNotInCard = false,
    cardButtons = [],
    editRow,
    defaultEditRow,
    showFilter,
    title,
    breakpoint = 500,
    itemsPerPage = 5,
    noItemsText = 'There are no agreements to show',
    columns = [
        agreementHeaderColumnDefs.companyName,
        agreementHeaderColumnDefs.startDate,
        agreementHeaderColumnDefs.endDate,
        agreementHeaderColumnDefs.termsType,
        agreementHeaderColumnDefs.agreementApprovalTeam,
        agreementHeaderColumnDefs.status
    ],
    agreementHeaders,
    filter,
    checkboxColumn,
    addToAddendum,
    sendEmails,
    companies,
    children,
    applyAddendum,
    checkButtonLabel,
    submitFunctionType,
    ...rest
}) {
    const { agreementHeader } = useContext(AgreementHeaderContext);
    const tableDefinition = new TableDefinition(columns);

    const tableRows = useMemo(() => (filter ? filter(agreementHeaders) : rows), [rows, filter, agreementHeaders]);

    const rowsWithCompanies = useRowsWithCompany(tableRows, companies);
    const loading = useAgreementHeadersIsLoading(loadingStates, isLoading, [
        ENDPOINTS.API.AGREEMENT_SET_ADDENDUM,
        ENDPOINTS.API.EMAIL_AGREEMENT_POST,
        ENDPOINTS.API.APPLY_ADDENDUM
    ]);

    //TODO: AgreementHeaderId should not be passed to this, a function should be
    return (
        <ChoiceListFilteredTableNoDashboardData
            id={title}
            tableDefinition={tableDefinition}
            showFilter={showFilter}
            noItemsText={noItemsText}
            rows={rowsWithCompanies}
            isLoading={loading}
            tableIsNotInCard={tableIsNotInCard}
            cardButtons={cardButtons}
            title={title}
            itemsPerPage={itemsPerPage}
            checkboxColumn={checkboxColumn}
            columnDefsId={agreementHeaderColumnDefs.id}
            tableDefinitionColumns={tableDefinition.columns}
            onRowClick={values => (editRow ? editRow(values) : defaultEditRow(values))}
            submitFunction={getSubmitFunction(submitFunctionType, addToAddendum, applyAddendum, sendEmails)}
            agreementHeaderId={agreementHeader.id}
            checkButtonLabel={checkButtonLabel}
            submitFunctionType={submitFunctionType}
            {...rest}
        >
            {children}
        </ChoiceListFilteredTableNoDashboardData>
    );
}

const mapStateToProps = ({ agreementHeaders, loadingStates, companies }) => ({
    agreementHeaders,
    loadingStates,
    companies
});

const mapDispatchToProps = (dispatch, props) => ({
    defaultEditRow: agreementHeader => safeDispatchPush(dispatch, push, ['dashboard', 'agreement', agreementHeader.id]),
    addToAddendum: (agreementHeaderId, agreementHeaderIds) => {
        props.removeItemsFromSearchResults(agreementHeaderIds);
        return dispatch(apiAgreementSetAddendum(agreementHeaderId, agreementHeaderIds));
    },
    sendEmails: (_, agreementHeaderIds) =>
        dispatch(apiEmailAgreementPost(agreementHeaderIds, 'Success', 'Emails Sent')),
    applyAddendum: (addendumId, agreementHeaderIds) => dispatch(apiApplyAddendum(addendumId, agreementHeaderIds))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AgreementHeaderTableCard);
