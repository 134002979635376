import { apiResource } from '../Api/Resource/apiResource';
import { retryErrors } from '../Api/RetryErrors/retryErrors';
import { agreementsQuery } from '../Api/AgreementHeader/apiAgreementFilter';
import { productsQuery } from '../Api/Products/apiProductsFilter';

export const apiUserLoggedIn = () => async dispatch => {
    dispatch(retryErrors());
    dispatch(apiResource());
    dispatch(
        agreementsQuery()
            .filterAgreementType('Template')
            .shouldFetchDetails(false)
            .shouldFetchPersons(false)
            .shouldFetchCompanies(false)
            .run()
    );
    dispatch(
        agreementsQuery()
            .filterAgreementType('Promotion')
            .filterStatus('Live')
            .shouldFetchDetails(false)
            .shouldFetchPersons(false)
            .shouldFetchCompanies(false)
            .run()
    );
    dispatch(productsQuery().run());
};
