import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const makeCommunicationsByCompanyByIdSelector = () =>
    createSelector(
        state => state.communications,
        (_, companyId) => companyId,
        (communications, companyId) => communications.filter(communication => (communication.company.id === companyId || communication.companyId === companyId))
    );

export const useCommunicationsByCompanyById = companyId => {
    const selectCommunications = useMemo(makeCommunicationsByCompanyByIdSelector, []);
    return useSelector(state => selectCommunications(state, companyId));
};
