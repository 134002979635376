import React, { useContext } from "react";
import HeaderRow from "./HeaderRow";
import ProductFamilyRow from "./ProductFamilyRow";
import { AgreementDetailsTableContext } from "./AgreementDetailsTableProvider";
import MapArray from "../../common/MapArray";
import _ from "lodash";

export default function AgreementDetailsTable() {
    const { showActionsColumn, familiesProductsDetails, searchBox, productRowsFilter } = useContext(
        AgreementDetailsTableContext
    );
    return (
        <div
            style={{
                gridTemplateColumns: showActionsColumn
                    ? "auto 1fr 1fr auto auto auto auto"
                    : "auto 1fr 1fr auto auto auto",
            }}
            className="d-grid"
        >
            <HeaderRow />
            <div style={{ gridColumnStart: 2, gridColumnEnd: 4 }}>{searchBox}</div>
            <div style={{ gridColumnStart: -3, gridColumnEnd: -1 }}>{productRowsFilter}</div>
            <MapArray
                from={_.sortBy(familiesProductsDetails, [(product) => product.family.sortOrder], ["asc"])}
                mapKey={(x) => x.family.name}
            >
                <ProductFamilyRow />
            </MapArray>
        </div>
    );
}
