import MapArray from '../MapArray';
import { TableFilterInTableRow } from './TableFilter';
import { DisplayField } from '../DisplayField';
import React from 'react';

export const RenderFilters = ({ rowCount, filteredRows, filters, showFilter, showCount, searchBox, updateFilter }) => {
    if (!showFilter) return null;
    return (
        <div className="border-bottom-1 w-100" key="filter">
            {searchBox && (
                <div className="row">
                    <div className="col-12">{searchBox}</div>
                </div>
            )}
            <table className="table table-borderless table-sm">
                <tbody>
                    <MapArray
                        from={filters}
                        map={(filter, index, { update }) => ({
                            ...filter.buildRenderData(filteredRows),
                            update
                        })}
                        mapKey={filter => filter.field}
                        data={{ update: updateFilter }}
                    >
                        <TableFilterInTableRow />
                    </MapArray>
                </tbody>
            </table>
            {showCount && <DisplayField title={'Count:'} value={rowCount} inline />}
        </div>
    );
};
