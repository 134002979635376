import React, { useContext } from 'react';
import SimpleCard from '../../common/Card/SimpleCard';
import { AgreementHeaderContext } from '../../AgreementHeader/Provider/AgreementHeaderProvider';
import { AgreementAddendumChangesContext } from '../../AgreementHeader/Provider/AgreementAddendumDetailsProvider';
import RemoveTemplateDetailChange from './RemoveTemplateDetailChange';
import ApplyMinimumDiscountToProductDetailChange from './ApplyMinimumDiscountToProductDetailChange';
import RemoveProductDetailChange from './RemoveProductDetailChange';
import { Alert } from 'reactstrap';
import AddendumProductDetailChange from './AddendumProductDetailChange';
import EditAgreementAddendumDetailModal from '../Modals/EditAgreementAddendumDetailModal';
import EditAgreementDetailModal from '../../AgreementDetails/Modals/EditAgreementDetailModal';
import { useNotDeletedAgreementDetailsByHeaderId } from '../../AgreementDetails/Selectors/useNotDeletedAgreementDetailsByHeaderId';

const AddendumChanges = () => {
    const {
        agreementState: { isAddendum, canAmend },
        agreementHeaderId
    } = useContext(AgreementHeaderContext);

    const { addendumDetails } = useContext(AgreementAddendumChangesContext);

    const details = useNotDeletedAgreementDetailsByHeaderId(agreementHeaderId);

    if (!isAddendum) {
        return null;
    }

    return (
        <EditAgreementAddendumDetailModal>
            {({ editAgreementAddendumDetail }) => (
                <EditAgreementDetailModal>
                    {({ editAgreementDetail }) => (
                        <SimpleCard title="Addendum Changes">
                            {!details.length && !addendumDetails.length ? (
                                <Alert color="danger">
                                    There are no changes on this addendum. Use the buttons above to add changes.
                                </Alert>
                            ) : null}
                            {addendumDetails.map(detail => (
                                <div key={detail.id} className="border-bottom py-3">
                                    {detail.addendumChangeType === 'RemoveTemplateDetail' ? (
                                        <RemoveTemplateDetailChange
                                            readOnly={!canAmend}
                                            key={detail.id}
                                            detail={detail}
                                            editAgreementAddendumDetail={editAgreementAddendumDetail}
                                        />
                                    ) : detail.addendumChangeType === 'ApplyMinimumDiscountToProduct' ? (
                                        <ApplyMinimumDiscountToProductDetailChange
                                            readOnly={!canAmend}
                                            key={detail.id}
                                            detail={detail}
                                            type="Minimum"
                                        />
                                    ) : detail.addendumChangeType === 'ApplyMaximumDiscountToProduct' ? (
                                        <ApplyMinimumDiscountToProductDetailChange
                                            readOnly={!canAmend}
                                            key={detail.id}
                                            detail={detail}
                                            type="Maximum"
                                        />
                                    ) : detail.addendumChangeType === 'RemoveProduct' ? (
                                        <RemoveProductDetailChange
                                            readOnly={!canAmend}
                                            key={detail.id}
                                            detail={detail}
                                            editAgreementAddendumDetail={editAgreementAddendumDetail}
                                        />
                                    ) : (
                                        <>
                                        <div key={detail.id}>{JSON.stringify(detail)}</div>
                                        <p>test</p>
                                        </>
                                    )}
                                </div>
                            ))}
                            {details.map(detail => (
                                <div key={detail.id} className="border-bottom py-3">
                                    {
                                        <AddendumProductDetailChange
                                            readOnly={!canAmend}
                                            key={detail.id}
                                            detail={detail}
                                            editAgreementDetail={editAgreementDetail}
                                        />
                                    }
                                </div>
                            ))}
                            <pre />
                        </SimpleCard>
                    )}
                </EditAgreementDetailModal>
            )}
        </EditAgreementAddendumDetailModal>
    );
};

export default AddendumChanges;
