import React, { useMemo } from 'react';
import { connect } from 'react-redux';

export const AgreementAddendumChangesContext = React.createContext({
    agreementHeaderId: undefined,
    addendumDetails: []
});

const AgreementAddendumDetailsProvider = ({
    agreementHeaderId,
    agreementAddendumDetails,
    newProduct,
    productFamily,
    agreementDetails,
    children
}) => {
    const addendumDetails = useMemo(
        () =>
            agreementAddendumDetails
                .filter(change => change.agreementHeaderId === agreementHeaderId && change.deleted == null)
                .map(change => {
                    const product = newProduct.find(x => x.id === change.applyToProductId);
                    const family = product
                        ? productFamily.find(family => product.productFamilyId === family.id)
                        : undefined;
                    return {
                        ...change,
                        product,
                        family,
                        agreementDetail: change.templateDetailId
                            ? agreementDetails.find(x => x.id === change.templateDetailId)
                            : undefined
                    };
                }),
        [agreementHeaderId, agreementAddendumDetails, newProduct, productFamily, agreementDetails]
    );

    return (
        <>
            <AgreementAddendumChangesContext.Provider value={{ agreementHeaderId, addendumDetails }}>
                {children}
            </AgreementAddendumChangesContext.Provider>
        </>
    );
};

const mapStateToProps = ({ agreementAddendumDetails, newProduct, productFamily, agreementDetails }) => ({
    agreementAddendumDetails,
    newProduct,
    productFamily,
    agreementDetails
});

export default connect(mapStateToProps)(AgreementAddendumDetailsProvider);
