import React from 'react';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import { templateColumnDefs } from '../template';
import { useTemplateContext } from '../Provider/TemplateProvider';
import { useSelector } from 'react-redux';
import { isAuthorisedToApproveAgreements } from '../../AgreementHeader/agreementHeaderStatus';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import { useIsLoading } from '../../common/Hooks/useIsLoading';
import { ENDPOINTS } from '../../../actions/Api/constants';
import SimpleCardCollapsable from '../../common/Card/SimpleCardCollapsable';

const getActionButtons = (user, action) =>
    !isAuthorisedToApproveAgreements(user)
        ? []
        : [
              {
                  text: 'Add Template',
                  fontAwesomeName: 'fa-plus',
                  onClick: () => action()
              }
          ];

export const TemplateTable = ({ templateAction }) => {
    const { templates, isLoading } = useTemplateContext();
    const isTemplateUpdateLoading = useIsLoading([ENDPOINTS.API.TEMPLATES_UPDATE]);
    const isTemplatePutLoading = useIsLoading([ENDPOINTS.API.TEMPLATE_PUT]);

    const user = useSelector(state => state.user);

    const tableDefinition = new TableDefinition([
        templateColumnDefs.name,
        templateColumnDefs.type,
        templateColumnDefs.activeCheckbox('Active'),
        templateColumnDefs.testButton('Test Template')
    ]);

    if (isTemplateUpdateLoading || isTemplatePutLoading) {
        return (
            <SimpleCardCollapsable collapseName={''} title={`Uploading the Template`} buttons={[]}>
                <ShowLoadingSpinner isLoading={isTemplateUpdateLoading || isTemplatePutLoading} size={20} />
            </SimpleCardCollapsable>
        );
    }

    return (
        <ChoiceListFilteredTableNoDashboardData
            id={'templates'}
            title={''}
            noItemsText={'There are no templates to show'}
            tableIsNotInCard={false}
            itemsPerPage={10}
            breakpoint={600}
            cardButtons={getActionButtons(user, templateAction)}
            isLoading={isLoading}
            tableDefinition={tableDefinition}
            rows={templates}
            columnDefsId={templateColumnDefs.id}
            tableDefinitionColumns={tableDefinition.columns}
            onRowClick={values => templateAction(values)}
        />
    );
};
