import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';

export const apiAgreementDetailDecline = (
    agreementDetailId,
    notes,
    successTitle,
    successMessage,
    callback
) => async dispatch => {
    const type = ENDPOINTS.API.AGREEMENT_DETAIL_DECLINE;
    const data = {
        ids: agreementDetailId,
        notes
    };

    dispatch(
        apiGetPost(
            type,
            ['agreement', 'detail', 'decline'],
            data,
            {},
            undefined,
            true,
            error => {
                toastr.error(type, `Response ${error.message}`);
            },
            undefined,
            successTitle,
            successMessage,
            callback
        )
    );
};
