import React from 'react';
import { connect } from 'react-redux';
import ActionButtons from '../../common/ActionButtons/ActionButtons';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { useCompanyContext } from '../Provider/CompanyProvider';
import { useCompanyBandingsContext } from '../Provider/CompanyBandingsProvider';
import _ from 'lodash';
import CreateAgreementModal from '../../AgreementHeader/Modals/CreateAgreementModal';
import { useCompanyOpportunitiesContext } from '../Provider/CompanyOpportunitiesProvider';
import EditCommunicationModal from '../../Communication/Modals/EditCommunicationModal';
import EditMeetingModal from '../../Meeting/Modals/EditMeetingModal';

function CompanyActions({ user }) {
    const { companyId } = useCompanyContext();
    const { createBanding } = useCompanyBandingsContext();
    const userTeams = _.get(user, 'agreementApprovalTeam');
    const userHasTeam = userTeams && userTeams.length > 0;
    const { addOpportunity } = useCompanyOpportunitiesContext();
    return (
        <ActionButtons>
            <EditMeetingModal>
                {({ createMeeting }) => (
                    <ActionButton
                        buttonText="Add Meeting"
                        fontawesomeIcon="fa-plus"
                        bootstrapColor="secondary"
                        onClickAction={() => createMeeting(companyId)}
                    />
                )}
            </EditMeetingModal>
            <EditCommunicationModal>
                {({ createCommunication }) => (
                    <ActionButton
                        buttonText="Add Communication"
                        fontawesomeIcon="fa-plus"
                        bootstrapColor="secondary"
                        onClickAction={() => createCommunication(companyId)}
                    />
                )}
            </EditCommunicationModal>
            <ActionButton
                buttonText="Add Opportunity"
                fontawesomeIcon="fa-plus"
                bootstrapColor="secondary"
                onClickAction={() => addOpportunity({ companyId })}
            />
            <ActionButton
                buttonText="Add Banding"
                fontawesomeIcon="fa-plus"
                bootstrapColor="secondary"
                onClickAction={() => createBanding(companyId)}
            />
            {userHasTeam && (
                <CreateAgreementModal user={user}>
                    {({ createAgreement }) => (
                        <ActionButton
                            buttonText="Add Agreement"
                            fontawesomeIcon="fa-plus"
                            bootstrapColor="secondary"
                            onClickAction={() => createAgreement(companyId)}
                        />
                    )}
                </CreateAgreementModal>
            )}
        </ActionButtons>
    );
}

const mapStateToProps = ({ user, people }) => ({ user, people });

export default connect(mapStateToProps)(CompanyActions);
