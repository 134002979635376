import React from 'react';
import ChoiceListFilteredTableNoDashboardData from '../../common/FilteredTable/ChoiceListFilteredTableNoDashboardData';
import { TableDefinition } from '../../common/FilteredTable/tableAndFilterHelpers';
import addressDef from '../addressDef';

const AddressTableCard = ({ rows, columns, isLoading, noItemsText, breakpoint, itemsPerPage }) => {
    const tableDefinition = new TableDefinition(columns);

    return (
        <ChoiceListFilteredTableNoDashboardData
            id="Demos"
            title="Demos"
            noItemsText={noItemsText}
            tableIsNotInCard={true}
            showFilter={true}
            itemsPerPage={itemsPerPage}
            breakpoint={breakpoint}
            cardButtons={[]}
            isLoading={isLoading}
            tableDefinition={tableDefinition}
            rows={rows}
            columnDefsId={addressDef.id}
            tableDefinitionColumns={tableDefinition.columns}
        />
    );
};

export default AddressTableCard;
