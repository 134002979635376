import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import useModalForm from '../../common/Hooks/useModalForm';
import DeclineAgreementDetailForm from '../Forms/DeclineAgreementDetailForm';

const DeclineAgreementDetailModal = ({ children }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();

    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} className="modal-sm">
                <AppModalHeader toggle={toggle}>Decline Agreement Detail</AppModalHeader>
                {isOpen ? (
                    <ModalBody>
                        <DeclineAgreementDetailForm isModal={true} id={formValue} toggle={toggle} />
                    </ModalBody>
                ) : null}
            </Modal>
            {children({
                declineAgreementDetail: id => {
                    openModal(id);
                },
                toggle
            })}
        </React.Fragment>
    );
};

export default DeclineAgreementDetailModal;
