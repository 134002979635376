import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const makeSelector = () =>
    createSelector(
        state => state.people,
        (_, personId) => personId,
        (people, personId) => personId && people.find(x => x.personId === personId)
    );

export const usePerson = personId => {
    const selector = useMemo(makeSelector, []);
    return useSelector(state => selector(state, personId));
};
