import React from 'react';
import { FormFeedback } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import _ from 'lodash';
import FormikFormGroup from './FormikFormGroup';
import { DisplayField } from '../DisplayField/index';
import { Alert } from 'reactstrap';
import { useChoiceList } from '../Hooks/useChoiceList';

const FormikSelectChoicelist = ({
	choiceList,
	filter,
	mapChoiceValues,
	values,
	formName,
	fieldName,
	setFieldValue,
	onFormValueChanged,
	isMulti,
	title,
	children,
	hideLabel,
	touched,
	options,
	errors,
	emptyOption,
	disabled,
	noOptionsText,
	isOptionDisabled,
	groupClassName,
	labelClassName,
	selectClassName
}) => {
	const optionsChoiceList = useChoiceList(
		choiceList,
		mapChoiceValues,
		options
	);

	let useOptions = optionsChoiceList.map(({ key, value }) => ({
		value: key,
		label: value
	}));

	if (filter) {
		useOptions = useOptions.filter(item => filter(item, values));
	}

	if (noOptionsText && options.length === 0) {
		return (
			<DisplayField
				title={title}
				value={<Alert color="warning">{noOptionsText}</Alert>}
			/>
		);
	}

	const invalid = touched[fieldName] && errors[fieldName];
	const rawValue = _.get(values, fieldName, '');

	return (
		<FormikFormGroup
			fieldName={fieldName}
			formName={formName}
			title={title}
			hideLabel={hideLabel}
			className={groupClassName}
			labelClassName={labelClassName}
		>
			<Select
				className={`react-select ${selectClassName}`}
				isMulti={isMulti}
				options={useOptions}
				styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
				onChange={a => {
					const value = isMulti
						? a.map(x => x.value).join(',')
						: a.value;
					setFieldValue(fieldName, value);
					if (onFormValueChanged) {
						onFormValueChanged(
							values,
							fieldName,
							value,
							setFieldValue
						);
					}
				}}
				isSearchable={false}
				components={makeAnimated()}
				isDisabled={disabled}
				value={
					rawValue === null
						? null
						: isMulti
						? rawValue
							? rawValue
									.split(',')
									.map(value =>
										useOptions.find(x => x.value === value)
									)
							: []
						: useOptions.find(x => x.value === rawValue)
				}
				placeholder={emptyOption}
				isOptionDisabled={isOptionDisabled}
			/>
			{invalid && (
				<FormFeedback tooltip className={invalid ? 'd-block' : ''}>
					{errors[fieldName]}
				</FormFeedback>
			)}
			{children}
		</FormikFormGroup>
	);
};

export default FormikSelectChoicelist;
