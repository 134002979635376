import React, { useContext } from 'react';
import { EditItemRowButton } from '../../common/Buttons/TableButtons';
import { AgreementHeaderContext } from '../../AgreementHeader/Provider/AgreementHeaderProvider';
import EditAgreementDetailModal from '../Modals/EditAgreementDetailModal';

const ButtonEditDetail = ({ product, agreementDetail, isDeleted }) => {
    const {
        agreementState: { canAmend },
        agreementHeaderId
    } = useContext(AgreementHeaderContext);

    const visibility = !agreementDetail || !canAmend || isDeleted ? 'invisible' : 'visible';

    return (
        <EditAgreementDetailModal>
            {({ editAgreementDetail }) => (
                <EditItemRowButton
                    visibility={visibility}
                    onClick={() => {
                        editAgreementDetail(agreementDetail.id, agreementHeaderId, product.productFamilyId, product.id);
                    }}
                />
            )}
        </EditAgreementDetailModal>
    );
};

export default ButtonEditDetail;
