import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';

export const apiAgreementDetailUpdateMultiple = (
    agreementDetails,
    successTitle,
    successMessage,
    callback,
    onError
) => async dispatch => {
    const type = ENDPOINTS.API.AGREEMENT_DETAIL_POST;

    dispatch(
        apiGetPost(
            type,
            ['agreement', 'detail', 'update'],
            agreementDetails,
            {},
            undefined,
            true,
            onError,
            undefined,
            successTitle,
            successMessage,
            callback
        )
    );
};
