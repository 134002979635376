import moment from 'moment';

export const isPropertySyncing = (object, propertyName) => object[`${propertyName}-#-syncing`];
export const isPropertyHasError = (object, propertyName) => object[`${propertyName}-#-error`];

export const setPropertySyncing = (object, propertyName) => (object[`${propertyName}-#-syncing`] = true);
export const setPropertyHasError = (object, propertyName) => (object[`${propertyName}-#-error`] = true);

export const setPropertiesSyncing = (local, remote) =>
    Object.keys(local).forEach(key => {
        if (local[key] !== remote[key]) {
            setPropertySyncing(remote, key);
        }
    });

export const setPropertiesHaveError = (local, remote, propertiesToIgnore) =>
    getFieldsNotUpdated(local, remote, propertiesToIgnore).forEach(x => setPropertyHasError(local, x.property));

export const getFieldsNotUpdated = (local, remote, propertiesToIgnore) =>
    Object.keys(local).reduce((acc, key) => {
        const l = local[key] || '';
        const r = remote[key] || '';

        if (propertiesToIgnore.indexOf(key) === -1 && l.toString().trim() !== r.toString().trim()) {
            acc.push({ property: key, changeTo: local[key], originalValue: remote[key] });
        }
        return acc;
    }, []);

export const isOfflne = () => process.env.REACT_APP_OFFLINE === 'true';

export const getDaysInPast = days =>
    Array(days)
        .fill()
        .map((_, i) =>
            moment()
                .add(-i, 'days')
                .format(process.env.REACT_APP_DATE_FORMAT)
        ).reverse();

export const getDaysInFuture = days =>
    Array(days)
        .fill()
        .map((_, i) =>
            moment()
                .add(i, 'days')
                .format(process.env.REACT_APP_DATE_FORMAT)
        );
