import _ from 'lodash';
import { ENDPOINTS } from '../../actions/Api/constants';
import { RESET_REDUCER } from '../../actions/resetReducers';

export const SERVICE_IS_LOADING = 'SERVICE_IS_LOADING';
export const SERVICE_HAS_ERROR = 'SERVICE_HAS_ERROR';
export const SERVICE_WAIT_CONFIRM = 'SERVICE_WAIT_CONFIRM';

export const dispatchServiceIsLoading = (dispatch, service, isLoading, id) =>
    dispatch({
        type: SERVICE_IS_LOADING,
        service,
        isLoading,
        id
    });

export const dispatchServiceHasError = (dispatch, service, hasError, errorMessage, id) =>
    dispatch({
        type: SERVICE_HAS_ERROR,
        service,
        hasError,
        errorMessage,
        id
    });

export const dispatchServiceIsWaitingConfirm = (dispatch, service, isWaitingConfirmation, id) =>
    dispatch({
        type: SERVICE_WAIT_CONFIRM,
        service,
        isWaitingConfirmation,
        id
    });

const defaultState = {
    ANY: {
        isLoading: false,
        hasError: false,
        errorMessage: {},
        isWaitingConfirm: false
    }
};

export function loadingStates(state = defaultState, action) {
    switch (action.type) {
        case RESET_REDUCER:
            return defaultState;
        case SERVICE_IS_LOADING:
            return isLoadingUpdated(action, state);
        case SERVICE_HAS_ERROR:
            return isErrorUpdated(action, state);
        case SERVICE_WAIT_CONFIRM:
            return isWaitingConfirmation(action, state);
        default:
            return state;
    }
}

const getItem = (service, state) =>
    state[service]
        ? state[service]
        : {
              isLoading: false,
              hasError: false,
              errorMessage: {},
              isWaitingConfirm: false
          };

const isWaitingConfirmation = (action, state) => {
    const { service } = action;
    const item = getItem(service, state);

    if (item.hasError === false && item.errorMessage === action.errorMessage && action.id === item.id) {
        return state;
    }
    let newState = _.clone(state);
    if (!newState[service]) {
        newState[service] = { isLoading: false };
    }
    newState[service].isLoading = false;
    newState[service].isWaitingConfirmation = action.isWaitingConfirmation;
    newState[service].hasError = false;
    newState[service].errorMessage = '';
    newState[service].id = action.id;
    updateAnyLoading(newState);
    return newState;
};

const isErrorUpdated = (action, state) => {
    const { service } = action;
    const item = getItem(service, state);
    if (item.hasError === action.hasError && item.errorMessage === action.errorMessage && action.id === item.id) {
        return state;
    }
    let newState = _.clone(state);
    if (!newState[service]) {
        newState[service] = { isLoading: false };
    }
    newState[service].isLoading = false;
    newState[service].isWaitingConfirmation = false;
    newState[service].hasError = action.hasError;
    newState[service].errorMessage = action.errorMessage;
    newState[service].id = action.id;
    updateAnyLoading(newState);
    return newState;
};

const isLoadingUpdated = (action, state) => {
    const { service } = action;
    const item = getItem(service, state);

    if (item.isLoading === action.isLoading && item.hasError === false && action.id === item.id) {
        return state;
    }

    let newState = _.clone(state);
    if (!newState[service]) {
        newState[service] = { isLoading: false };
    }
    item.isLoading = action.isLoading;
    item.isWaitingConfirmation = false;
    item.hasError = false;
    item.errorMessage = '';
    item.id = item.isLoading ? action.id : {};
    newState[service] = item;

    updateAnyLoading(newState);
    return newState;
};

const updateAnyLoading = newState => {
    newState['ANY'] = { isLoading: false }; //set to false so some does not match
    newState['ANY'] = {
        isLoading: Object.keys(newState)
            .map(x => newState[x])
            .some(x => x.isLoading)
    };
};

export const isAnyLoading = loadingStates => (loadingStates['ANY'] ? loadingStates['ANY'].isLoading : false);

//TODO: Other properties
export const getLoadingState = (loadingStates, endpointType) => {
    let isLoading = false;
    let hasError = false;

    if (!loadingStates) {
        return {
            isLoading: true,
            hasError: false
        };
    }

    const state = loadingStates[ENDPOINTS.API[endpointType]];
    if (state) {
        isLoading = state.isLoading;
        hasError = state.hasError;
    }
    return {
        isLoading,
        hasError
    };
};
