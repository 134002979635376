import React from 'react';
import PageSection from '../../common/PageSection';
import CompanyPeopleTable from '../../Person/Tables/CompanyPeopleTable';
import CompanyTitle from '../../Company/Components/CompanyTitle';
import CompanyProvider from '../../Company/Provider/CompanyProvider';
import { useHistory, useParams } from 'react-router';

export default function PeoplePage() {
    const { goBack } = useHistory();
    const { companyId } = useParams();
    const intCompanyId = parseInt(companyId, 10);

    return (
        <CompanyProvider companyId={intCompanyId}>
            <PageSection onBackClicked={goBack} title={<CompanyTitle />}>
                <CompanyPeopleTable />
            </PageSection>
        </CompanyProvider>
    );
}
