import React, { useContext } from 'react';
import VisitActionsTableCard from '../../VisitActions/Tables/VisitActionsTableCard';
import { CompanyVisitActionsContext } from '../../Company/Provider/CompanyVisitActionsProvider';

const CompanyVisitActionsTableCard = () => {
    const { isLoading, visitActions, companyId } = useContext(CompanyVisitActionsContext);
    return (
        <VisitActionsTableCard
            companyId={companyId}
            isLoading={isLoading}
            title="Visit Actions"
            rows={visitActions}
            breakpoint={860}
        />
    );
};

export default CompanyVisitActionsTableCard;
