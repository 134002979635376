import React, { useContext } from 'react';
import SimpleCard from '../../common/Card/SimpleCard';
import EditAgreementHeaderForm from './EditAgreementHeaderForm';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';
import AgreementHeaderAddendumDetails from './AgreementHeaderAddendumDetails';
import { ENDPOINTS } from '../../../actions/Api/constants';
import ShowLoadingSpinner from '../../common/ShowLoadingSpinner';
import { useIsLoading } from '../../common/Hooks/useIsLoading';

export default function EditAgreementHeaderFormCard() {
    const {
        agreementHeader,
        agreementState: { title }
    } = useContext(AgreementHeaderContext);

    const isLoading = useIsLoading([ENDPOINTS.API.AGREEMENT_FILTER]);

    return (
        <SimpleCard title={title}>
            {isLoading ? (
                <ShowLoadingSpinner isLoading={true} title="Loading Agreement" />
            ) : (
                <>
                    <AgreementHeaderAddendumDetails />
                    <EditAgreementHeaderForm agreementHeader={agreementHeader} disableTemplateField={true} />
                </>
            )}
        </SimpleCard>
    );
}
