export const SET_INCLUDE_MEETING_NOTES_IN_EMAIL = 'SET_INCLUDE_MEETING_NOTES_IN_EMAIL';

export const setIncludeMeetingNotesInEmail = (communicationId, includeNotesInVisitReportEmail) => dispatch => {
    dispatch({
        type: SET_INCLUDE_MEETING_NOTES_IN_EMAIL,
        data: {
            communicationId,
            includeNotesInVisitReportEmail
        }
    });
};
