import React, { useContext } from 'react';
import AgreementHeaderTableCard from './AgreementHeaderTableCard';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';

export const filterAddendumAgreements = agreementHeaderId => agreementHeaders => {
    return agreementHeaders.filter(
        agreementHeader =>
            agreementHeader.addendumId === agreementHeaderId && agreementHeader.addendumStatus === 'Pending'
    );
};

const title = 'Pending Addendum';

const columns = [
    agreementHeaderColumnDefs.selected('Apply Addendum'),
    agreementHeaderColumnDefs.companyName,
    agreementHeaderColumnDefs.startDate,
    agreementHeaderColumnDefs.endDate,
    agreementHeaderColumnDefs.agreementType,
    agreementHeaderColumnDefs.removeAgreementFromAddendum
];

const PendingAddendumTable = ({ isLoading }) => {
    const {
        agreementHeaderId,
        agreementState: { displayAddendumAgreementsTable }
    } = useContext(AgreementHeaderContext);

    if (!displayAddendumAgreementsTable) {
        return null;
    }

    return (
        <AgreementHeaderTableCard
            filter={filterAddendumAgreements(agreementHeaderId)}
            isLoading={isLoading}
            title={title}
            noItemsText="There are no agreements to show"
            checkboxColumn={true}
            checkButtonLabel={count =>
                count === 0 ? 'Apply' : `Apply ${count} ${count === 1 ? 'Addendum' : 'Addenda'}`
            }
            submitFunctionType={'Apply'}
            columns={columns}
        />
    );
};

export default PendingAddendumTable;
