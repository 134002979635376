import useModalForm from "../Hooks/useModalForm";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import ReactiveForm from "../../common/ReactiveForm";
import React from "react";
import {Modal, ModalBody} from "reactstrap";
import {AppModalHeader} from "../AppModalHeader";
import { apiDeleteAgreement } from "../../../actions/Api/AgreementHeader/apiDeleteAgreement";

export const DeleteAgreementModal = ({agreementHeaderIds, children}) => {
    const dispatch = useDispatch();
    const {toggle, isOpen, openModal} = useModalForm();
    const { goBack } = useHistory();
    const initialValues ={}


    const handleSubmit = (form, dispatch) => {
        dispatch(apiDeleteAgreement(agreementHeaderIds, 'Success', 'Agreement Deleted', "You have successfully deleted this agreement.", goBack));
        toggle();
    }
    return (
        <React.Fragment>
            <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
                <AppModalHeader toggle={toggle}>
                    Delete Agreement Confirmation
                </AppModalHeader>
                <ModalBody>
                <ReactiveForm
            initialValues={
                initialValues
            }
            saveButtonContent='Delete'
            cancel={toggle}
            handleSubmit={form => handleSubmit(form, dispatch)}
        >
            <ReactiveForm.Section title="">
                <ReactiveForm.Warning title ={'Warning: '} message ="Deleting this will remove this agreement from the company. Should you wish to 'Cancel', please click the cancel button below or click 'Delete' to confirm deletion.'"/>
            </ReactiveForm.Section>
        </ReactiveForm>
                </ModalBody>
            </Modal>
            {children({
                triggerModal: () => openModal(),
                toggle
            })}
        </React.Fragment>
    )
}

export default DeleteAgreementModal;