import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';

export const apiCancelAgreement = (
    AgreementHeaderId,
    notes,
    successTitle,
    successMessage,
    callback
) => async dispatch => {
    const type = ENDPOINTS.API.AGREEMENT_CANCEL;
    const data = {
        notes
    };

    dispatch(
        apiGetPost(
            type,
            ['agreement', 'cancel', AgreementHeaderId],
            data,
            {},
            undefined,
            true,
            (dispatch, type, error) => {
                toastr.error('Cancel Agreement', `${error.message}`);
            },
            undefined,
            successTitle,
            successMessage,
            callback
        )
    );
};
