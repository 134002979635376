import { IMeeting } from '.';

interface IState {
	meetings: IMeeting[];
}

export const meetingsSelector: (state: any) => IMeeting[] = state => state.meetings;

export const selectMeetingFromRouteProps: (state: IState, ownProps: any) => IMeeting | undefined = (
	state,
	ownProps
) => {
	const meetingId: number = parseInt(ownProps.match.params.meetingId);
	return state.meetings.find(x => x.meetingId === meetingId);
};
