import { makeGetRequest } from '../apiHelpers';

function openAgreementDocument(agreementHeaderId) {
    makeGetRequest(['agreement', agreementHeaderId, 'document', agreementHeaderId + '.Agreement.pdf']).then(
        response => {
            //console.log(response)
            const link = document.createElement('a');
            link.href = response.data;
            link.setAttribute('download', 'file.pdf');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    );
}

export default openAgreementDocument;
