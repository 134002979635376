import { Button } from 'reactstrap';
import React from 'react';

/**
 * @return {null}
 */
export const FilteredTableCheckboxButtons = ({
    checkboxColumn,
    submitFunction,
    rows,
    selectedRows,
    agreementHeaderId,
    selectAll,
    checkButtonLabel,
    submitFunctionType,
    isLoading
}) => {
    if (!checkboxColumn) {
        return null;
    }

    const length = selectedRows.length;
    const label = typeof checkButtonLabel === 'function' ? checkButtonLabel(length) : checkButtonLabel;
    return (
        <div>
            <Button style={{ marginRight: 5, width: 82.88 }} onClick={() => selectAll(rows, selectedRows)}>
                {length > 0 ? 'Deselect All' : 'Select All'}
            </Button>
            <Button
                className="pull-right"
                onClick={() =>
                    submitFunction(
                        agreementHeaderId,
                        submitFunctionType === 'Apply' ? rows.filter(x => selectedRows.includes(x.id)) : selectedRows,
                        'Success',
                        'Addendum Set'
                    )
                }
                disabled={length === 0 || isLoading}
            >
                {label}
            </Button>
            {/*<span style={{ float: 'right' }}>Selected: {length}</span>*/}
        </div>
    );
};

export default FilteredTableCheckboxButtons;
