import React from 'react';
import PageSection from '../../common/PageSection';
import RedirectNotLoggedIn from '../RedirectNotLoggedIn';
import { TemplateTable } from '../../Template/Tables/TemplateTable';
import TemplateProvider from '../../Template/Provider/TemplateProvider';
import { TemplateModal } from '../../Template/Modal/TemplateModal';
import { apiTemplateGet } from '../../../actions/Api/Template/apiTemplateGet';

export const TemplatePage = () => {
    return (
        <PageSection title={'Templates'}>
            <RedirectNotLoggedIn onLoggedInDispatchArray={[apiTemplateGet()]} />
            <TemplateProvider>
                <TemplateModal>
                    {({ templateAction }) => <TemplateTable templateAction={templateAction} />}
                </TemplateModal>
            </TemplateProvider>
        </PageSection>
    );
};
