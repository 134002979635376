import React from 'react';
import { MiniCalendarDay } from './MiniCalendarDay';

export const MiniCalendarWeek = ({ week, highlightedDay, clickDay }) => (
    <tr>
        {week.map(day => (
            <MiniCalendarDay key={day.day.date()} day={day} highlightedDay={highlightedDay} clickDay={clickDay} />
        ))}
    </tr>
);
