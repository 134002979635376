import React from 'react';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { apiCreateDraftAgreement } from '../../../actions/Api/AgreementHeader/apiCreateDraftAgreement';
import { useDispatch } from 'react-redux';
import { openNewAgreementOnResult } from '../Forms/EditAgreementHeaderForm';

export default function({ agreementHeader }) {
    const dispatch = useDispatch();

    return (
        <ActionButton
            buttonText={'Clone Agreement'}
            fontawesomeIcon="fa-clone"
            bootstrapColor="secondary"
            onClickAction={() => {
                dispatch(
                    apiCreateDraftAgreement(
                        { ...agreementHeader, parentId: agreementHeader.id, parentType: 'Agreement', id: -1 },
                        'Success',
                        'Agreement Created',
                        undefined,
                        openNewAgreementOnResult(-1)
                    )
                );
            }}
        />
    );
}
