import { ENDPOINTS } from '../../../actions/Api/constants';
import { RESET_REDUCER } from '../../../actions/resetReducers';
import moment from 'moment';
import _ from 'lodash';

const initialState = [];

function filterToMatchFunction({ assigneeId, companyId, endDate, startDate, stages, ...rest }) {
    const start = startDate === undefined ? undefined : moment(startDate);
    const end = endDate === undefined ? undefined : moment(endDate);
    return opportunity => {
        const startDate = moment(opportunity.startDate);
        return (
            (!assigneeId || opportunity.assigneeId === assigneeId) &&
            (!companyId || opportunity.companyId === companyId) &&
            (!stages || stages.indexOf(opportunity.stage) > -1) &&
            ((!start && !end) || ((!end || startDate.isBefore(end)) && (!start || startDate.isAfter(start))))
        );
    };
}

const filterState = (state, filter, newData) => {
    const matchesState = filterToMatchFunction(filter);
    return state.filter(x => !newData.some(newComm => x.opportunityId === newComm.opportunityId) && !matchesState(x));
};

export const opportunities = (state = initialState, action) => {
    switch (action.type) {
        case RESET_REDUCER:
            return initialState;
        case ENDPOINTS.API.OPPORTUNITY_FILTER_COMMIT:
            const {
                payload: { data, filter }
            } = action;

            const dataOpportunities = _.get(data, 'opportunities', []);

            return [...dataOpportunities, ...filterState(state, filter, dataOpportunities)];
        case ENDPOINTS.API.COMPANY_DATA_GET:
            return action.data.fetchOpportunities
                ? [
                      ...action.data.opportunities,
                      ...filterState(state, { companyId: action.data.companyId }, action.data.opportunities)
                  ]
                : state;
        case ENDPOINTS.API.OPPORTUNITY_POST_ROLLBACK:
            const rollbackTo = action.meta.unmodifiedOpportunity;

            if (rollbackTo.opportunityId < 0) {
                return [...state.filter(opportunity => rollbackTo.opportunityId !== opportunity.opportunityId)];
            }
            return updateOpportunityInState(rollbackTo, state);
        case ENDPOINTS.API.OPPORTUNITY_POST_COMMIT:
            const {
                meta: { opportunity: localOpportunity },
                payload: { data: remoteOpportunity }
            } = action;
            return [
                remoteOpportunity,
                ...state.filter(
                    x =>
                        localOpportunity.opportunityId !== x.opportunityId &&
                        remoteOpportunity.opportunityId !== x.opportunityId
                )
            ];
        case ENDPOINTS.API.OPPORTUNITY_POST:
            return updateOpportunityInState(action.data, state);
        default:
            return state;
    }
};

const updateOpportunityInState = (opportunity, state) => [
    opportunity,
    ...state.filter(x => opportunity.opportunityId !== x.opportunityId)
];
