import {
    basicColumnDef,
    choiceListColumnDef,
    dateColumnDef,
    idColumnDef
} from '../common/FilteredTable/tableAndFilterHelpers';

const agreementStatusChangeColumnDefs = {
    id: idColumnDef('id', 'id'),
    agreementHeaderId: idColumnDef('Agreement Header Id', 'agreementHeaderId'),
    notes: {
        ...basicColumnDef('Notes', 'notes'),
        searchable: false,
        type: 'text'
    },
    statusFrom: {
        ...choiceListColumnDef('Status From', 'statusFrom', 'AgreementHeaderStatus')
    },
    statusTo: {
        ...choiceListColumnDef('Status To', 'statusTo', 'AgreementHeaderStatus')
    },
    createdDate: {
        ...dateColumnDef('Changed', 'createdDate')
    },
    updatedDate: {
        ...dateColumnDef('Updated', 'updatedDate')
    },
    createdBy: {
        ...choiceListColumnDef('Created By', 'createdBy', 'userAuthors'),
        searchable: false
    },
    updatedBy: {
        ...choiceListColumnDef('Updated By', 'updatedBy', 'userAuthors'),
        searchable: false
    }
};

export default agreementStatusChangeColumnDefs;
