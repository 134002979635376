import React, { useEffect, useMemo, useState } from 'react';
import connect from 'react-redux/es/connect/connect';
import Table from '../../common/Table/index';
import { AlertIfNoRows } from '../Table/AlertIfNoRows';
import SimpleCardCollapsable from '../Card/SimpleCardCollapsable';
import CardDropDownButton from '../Buttons/CardDropDownButton';
import ChoiceListAndTextFilteredRows from './ChoiceListAndTextFilteredRows';
import { makeTableColumns, makeTableFilters, makeTableSearchFields } from './tableAndFilterHelpers';
import { FilterTableRowButton } from '../Buttons/TableButtons';
import { FieldsGetHeadings } from '../../Dashboard/Factories/DashboardHelpers';
import { mapToRows } from '../Table/addColumnsToRow';
import FilteredTableCheckboxButtons from './FilteredTableCheckboxButtons';
import useToggleOpen from '../Hooks/useToggleOpen';

function RenderButtons({ cardButtons, filters, searchFields, toggleShowFilter }) {
    const items = [];

    if ((filters && filters.length) || searchFields.length) {
        items.push({
            text: 'Filter',
            fontAwesomeName: 'fa-filter',
            onClick: toggleShowFilter
        });
    }

    if (items.length === 1 && (!cardButtons || cardButtons.length === 0)) {
        return <FilterTableRowButton onClick={toggleShowFilter} />;
    }

    return <CardDropDownButton items={[...cardButtons, ...items]} />;
}

export function RenderTable({ id, noItemsText, breakpoint, itemsPerPage, showCount, filteredRows, headings }) {
    return (
        <AlertIfNoRows message={noItemsText || 'No items to display'} rows={filteredRows}>
            <Table
                id={id}
                breakpoint={breakpoint}
                itemsPerPage={itemsPerPage}
                headings={headings}
                rows={filteredRows}
                showCount={showCount}
            />
        </AlertIfNoRows>
    );
}

function getFieldsFiltersColumns(fields, columnDefs, filterDefs, searchFieldDefs, tableDefinition) {
    return (
        tableDefinition || {
            columns: makeTableColumns(columnDefs, fields),
            filters: makeTableFilters(filterDefs, fields),
            searchFields: makeTableSearchFields(searchFieldDefs, fields)
        }
    );
}

const useSelectedRowsCannotContainIdThatIsNotInRowsEffect = (selectedRows, rows, setSelectedRows) => {
    useEffect(() => {
        const checkSelectedRows = selectedRows.filter(id => rows.some(row => row.id === id));
        if (checkSelectedRows.length !== selectedRows.length) {
            setSelectedRows(checkSelectedRows.map(x => x.id));
        }
    }, [selectedRows, rows, setSelectedRows]);
};

const ChoiceListFilteredTableNoDashboardData = ({
    id,
    noItemsText,
    breakpoint,
    itemsPerPage,
    showCount,
    rows,
    isLoading,
    tableIsNotInCard,
    title,
    showFilter: propsShowFilter,
    children,
    insertAboveTable,
    fields,
    columnDefs,
    filterDefs,
    searchFieldDefs,
    tableDefinition,
    checkboxColumn,
    columnDefsId,
    cardButtons,
    resources,
    tableDefinitionColumns,
    onRowClick,
    submitFunction,
    agreementHeaderId,
    actions,
    checkButtonLabel,
    submitFunctionType
}) => {
    const { columns, filters, searchFields } = useMemo(
        () => getFieldsFiltersColumns(fields, columnDefs, filterDefs, searchFieldDefs, tableDefinition),
        [fields, columnDefs, filterDefs, searchFieldDefs, tableDefinition]
    );

    const { isOpen: showFilter, toggle: toggleShowFilter } = useToggleOpen(propsShowFilter);
    const [selectedRows, setSelectedRows] = useState([]);

    const updateSingleRow = id => {
        if (selectedRows.indexOf(id) === -1) {
            setSelectedRows([...selectedRows, id]);
        } else {
            setSelectedRows(selectedRows.filter(x => x !== id));
        }
    };

    const selectAll = (tableRows, selectedRows) => {
        if (selectedRows.length === 0) {
            setSelectedRows(tableRows.map(x => x.id));
        } else {
            setSelectedRows([]);
        }
    };

    useSelectedRowsCannotContainIdThatIsNotInRowsEffect(selectedRows, rows, setSelectedRows);

    if (!rows) return null;

    if (!tableDefinition) {
        console.warn(`Table ${title} should use tableDefinition not ColumnDefs/FilterDefs etc`);
    }

    const tableRowsSelected = rows.map(x => ({ ...x, selected: selectedRows.some(id => x.id === id) }));

    return (
        <>
            <ChoiceListAndTextFilteredRows
                rows={mapToRows(tableRowsSelected, columnDefsId, resources, tableDefinitionColumns, onRowClick, {
                    ...actions,
                    updateSingleRow
                })}
                searchFields={searchFields}
                showFilter={showFilter}
                filters={filters}
            >
                {({ filter, filteredRows }) => {
                    const count = rows.length;
                    return tableIsNotInCard ? (
                        <React.Fragment>
                            {filter}
                            <RenderTable
                                id={id}
                                noItemsText={noItemsText}
                                breakpoint={breakpoint}
                                itemsPerPage={itemsPerPage}
                                showCount={showCount}
                                filteredRows={filteredRows}
                                headings={FieldsGetHeadings(columns)}
                            />
                            {count !== 0 && (
                                <FilteredTableCheckboxButtons
                                    checkboxColumn={checkboxColumn}
                                    submitFunction={submitFunction}
                                    rows={rows}
                                    selectedRows={selectedRows}
                                    agreementHeaderId={agreementHeaderId}
                                    selectAll={selectAll}
                                    checkButtonLabel={checkButtonLabel}
                                    submitFunctionType={submitFunctionType}
                                />
                            )}
                        </React.Fragment>
                    ) : (
                        <SimpleCardCollapsable
                            collapseName={title}
                            title={`${title} (${count})`}
                            isLoading={isLoading}
                            buttons={
                                <RenderButtons
                                    cardButtons={cardButtons}
                                    filters={filters}
                                    searchFields={searchFields}
                                    toggleShowFilter={toggleShowFilter}
                                />
                            }
                        >
                            {insertAboveTable && <div className="position-relative w-100 mb-4">{insertAboveTable}</div>}
                            {filter}
                            <div>
                                <RenderTable
                                    id={id}
                                    noItemsText={noItemsText}
                                    breakpoint={breakpoint}
                                    itemsPerPage={itemsPerPage}
                                    showCount={showCount}
                                    filteredRows={filteredRows}
                                    headings={FieldsGetHeadings(columns)}
                                />
                            </div>
                            {children && (
                                <div className="position-relative w-100">
                                    {typeof children === 'function' ? children({ rows: tableRowsSelected }) : children}
                                </div>
                            )}
                            {count !== 0 && (
                                <FilteredTableCheckboxButtons
                                    checkboxColumn={checkboxColumn}
                                    submitFunction={submitFunction}
                                    rows={rows}
                                    isLoading={isLoading}
                                    selectedRows={selectedRows}
                                    agreementHeaderId={agreementHeaderId}
                                    selectAll={selectAll}
                                    checkButtonLabel={checkButtonLabel}
                                    submitFunctionType={submitFunctionType}
                                />
                            )}
                        </SimpleCardCollapsable>
                    );
                }}
            </ChoiceListAndTextFilteredRows>
        </>
    );
};

const mapStateToProps = state => ({
    resources: state.resources
});

export default connect(mapStateToProps)(ChoiceListFilteredTableNoDashboardData);
