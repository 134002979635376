import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { redirectIfNotLoggedIn } from '../../actions/Navigation/redirectIfNotLoggedIn';

const RedirectNotLoggedIn = ({ change, redirectIfNotLoggedIn, onLoggedIn, onLoggedInDispatchArray }) => {
    useEffect(() => {
        redirectIfNotLoggedIn(onLoggedIn, onLoggedInDispatchArray);
        // eslint-disable-next-line
    }, [change, redirectIfNotLoggedIn, onLoggedIn]);

    return <></>;
};

const mapDispatchToPropsRedirectNotLoggedIn = dispatch => ({
    redirectIfNotLoggedIn: (action, dispatchArray) =>
        dispatch(
            redirectIfNotLoggedIn(() => {
                action && action();
                dispatchArray && dispatchArray.forEach(func => dispatch(func));
            })
        )
});

export default connect(
    null,
    mapDispatchToPropsRedirectNotLoggedIn
)(RedirectNotLoggedIn);
