import { useMemo } from 'react';
import { ENDPOINTS } from '../../../actions/Api/constants';
import _ from 'lodash';

export const endpointsWhichMayBeLoadingHeaders = [
    ENDPOINTS.API.AGREEMENT_FILTER,
    ENDPOINTS.API.AGREEMENT_GET,
    ENDPOINTS.API.AGREEMENT_CREATE_DRAFT,
    ENDPOINTS.API.FINALISE_AGREEMENT,
    ENDPOINTS.API.AGREEMENT_APPROVE,
    ENDPOINTS.API.AGREEMENT_DECLINE,
    //ENDPOINTS.API.ADDENDUM_GET,
    ENDPOINTS.API.PROMOTION_GET,
    ENDPOINTS.API.AGREEMENT_CREATE_TEMPLATE,
    ENDPOINTS.API.SWITCH_AGREEMENT_TEMPLATE
    //ENDPOINTS.API.ADDENDUM_AGREEMENT_SEARCH
];

function useAgreementHeadersIsLoading(loadingStates, isLoading, additionalLoadingEndpoints = []) {
    return useMemo(() => {
        const endpointsWhichMayBeLoadingHeaders = [
            ENDPOINTS.API.AGREEMENT_FILTER,
            ENDPOINTS.API.AGREEMENT_GET,
            ENDPOINTS.API.AGREEMENT_CREATE_DRAFT,
            ENDPOINTS.API.FINALISE_AGREEMENT,
            ENDPOINTS.API.AGREEMENT_APPROVE,
            ENDPOINTS.API.AGREEMENT_DECLINE,
            //ENDPOINTS.API.ADDENDUM_GET,
            ENDPOINTS.API.PROMOTION_GET,
            ENDPOINTS.API.AGREEMENT_CREATE_TEMPLATE,
            ENDPOINTS.API.SWITCH_AGREEMENT_TEMPLATE
            //ENDPOINTS.API.ADDENDUM_AGREEMENT_SEARCH
        ];
        return (
            isLoading ||
            [...additionalLoadingEndpoints, ...endpointsWhichMayBeLoadingHeaders].some(name =>
                _.get(loadingStates, `${name}.isLoading`, false)
            )
        );
    }, [loadingStates, isLoading, additionalLoadingEndpoints]);
}

export default useAgreementHeadersIsLoading;
