import React, { useContext } from 'react';
import { DeleteTableRowButton } from '../../common/Buttons/TableButtons';
import { AgreementHeaderContext } from '../../AgreementHeader/Provider/AgreementHeaderProvider';
import { apiAgreementDetailDelete } from '../../../actions/Api/AgreementDetail/apiAgreementDetailDelete';
import ConfirmButton from '../../common/Buttons/ConfirmButton';
import { useDispatch } from 'react-redux';

const ButtonDeleteDetail = ({ agreementDetail, isDeleted, toggle }) => {
    const dispatch = useDispatch();
    const {
        agreementState: { canAmend }
    } = useContext(AgreementHeaderContext);

    const visibility = !agreementDetail || !canAmend || isDeleted ? 'invisible' : 'visible';
    const agreementDetailId = agreementDetail ? agreementDetail.id : null;
    const popoverId = `d${agreementDetailId}`;

    return (
        <ConfirmButton
            popoverId={popoverId}
            title="Delete Product"
            isWorking={false}
            isWorkingTitle="Deleting Product"
            onConfirm={() =>
                dispatch(apiAgreementDetailDelete([agreementDetailId], 'Success', 'Agreement Detail Deleted', toggle))
            }
        >
            {({ toggle }) => <DeleteTableRowButton visibility={visibility} id={popoverId} onClick={toggle} />}
        </ConfirmButton>
    );
};

export default ButtonDeleteDetail;
