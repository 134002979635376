import React, { useContext, useMemo } from 'react';
import AgreementHeaderTableCard from './AgreementHeaderTableCard';
import agreementHeaderColumnDefs from '../agreementHeaderColumnDefs';
import { agreementsQuery } from '../../../actions/Api/AgreementHeader/apiAgreementFilter';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';
import RedirectNotLoggedIn from '../../app/RedirectNotLoggedIn';

export const filterPromotions = agreementHeaderId => agreementHeaders =>
    agreementHeaders.filter(agreementHeader => agreementHeader.parentId === agreementHeaderId);

const AgreementsInPromotionTable = ({ showFilter }) => {
    const {
        agreementHeaderId,
        agreementState: { isPromotion }
    } = useContext(AgreementHeaderContext);

    const filter = useMemo(() => filterPromotions(agreementHeaderId), [agreementHeaderId]);

    if (!isPromotion || agreementHeaderId === -1) {
        return null;
    }

    return (
        <>
            <RedirectNotLoggedIn
                change={1}
                onLoggedInDispatchArray={[
                    agreementsQuery()
                        .filterAgreementType('Agreement')
                        .filterParentId(agreementHeaderId)
                        .shouldFetchDetails(false)
                        .shouldFetchPersons(false)
                        .shouldFetchCompanies(true)
                        .run()
                ]}
            />

            <AgreementHeaderTableCard
                tableIsNotInCard={false}
                title="Agreements in Promotion"
                noItemsText="There are no agreements to show"
                filter={filter}
                columns={[
                    agreementHeaderColumnDefs.name,
                    agreementHeaderColumnDefs.companyName,
                    agreementHeaderColumnDefs.startDate,
                    agreementHeaderColumnDefs.endDate,
                    agreementHeaderColumnDefs.termsType,
                    agreementHeaderColumnDefs.status
                ]}
            />
        </>
    );
};

export default AgreementsInPromotionTable;
