import React from 'react';
import { connect } from 'react-redux';
import { apiRemoveAgreementFromAddendum } from '../../../actions/Api/AgreementHeader/apiRemoveAgreementFromAddendum';
import { Button } from 'reactstrap';

const AddendumRemoveAgreementButton = ({ removeAgreementFromAddendum, addendumId, agreementHeaderId }) => {
    return (
        <Button
            title={'Remove Agreement From Addendum'}
            className={`simple-card-dimmed  btn-sm bg-white-not-hover btn-outline-danger mx-1`}
            onClick={() => removeAgreementFromAddendum(addendumId, agreementHeaderId, 'Success', 'Agreement Removed')}
        >
            <i className={`fa d-inline fa-lg fa-trash`} />
        </Button>
    );
};

const mapDispatchToProps = dispatch => ({
    removeAgreementFromAddendum: (addendumId, agreementHeaderId, successTitle, successMessage) =>
        dispatch(apiRemoveAgreementFromAddendum(addendumId, agreementHeaderId, successTitle, successMessage))
});

export default connect(
    null,
    mapDispatchToProps
)(AddendumRemoveAgreementButton);
