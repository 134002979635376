import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

export const CompanyAgreementHeadersContext = React.createContext({
    companyId: undefined,
    agreementHeaders: []
});

const CompanyAgreementHeadersProvider = ({ companyId, agreementHeaders, children }) => {
    const companyAgreementHeaders = useMemo(
        () =>
            _.orderBy(
                agreementHeaders.filter(
                    agreementHeader =>
                        agreementHeader.companyId === companyId && agreementHeader.agreementType === 'Agreement'
                ),
                'endDate',
                'asc'
            ),
        [companyId, agreementHeaders]

    );

    return (
        <CompanyAgreementHeadersContext.Provider value={{ companyId, agreementHeaders: companyAgreementHeaders }}>
            {children}
        </CompanyAgreementHeadersContext.Provider>
    );
};

const mapStateToProps = ({ agreementHeaders }) => ({ agreementHeaders });

export default connect(mapStateToProps)(CompanyAgreementHeadersProvider);
