import React from 'react';
import { connect } from 'react-redux';
import UpdatePasswordForm from './UpdatePasswordForm';

const ChangePasswordForm = ({ className, token }) => {
    return (
        <UpdatePasswordForm
            class={className}
            token={token}
            formText={'Please enter a new password.'}
            buttonText={'Change Password'}
        />
    );
};

const mapStateToProps = state => ({
    loadingStates: state.loadingStates
});

export default connect(mapStateToProps)(ChangePasswordForm);
