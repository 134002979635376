import React, { useContext } from 'react';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { useDispatch } from 'react-redux';
import { apiAgreementMakeTemplateEditable } from '../../../actions/Api/AgreementHeader/apiAgreementMakeTemplateEditable';
import { AgreementHeaderContext } from '../Provider/AgreementHeaderProvider';

export default function TemplateToDraftButton() {
    const { agreementHeader } = useContext(AgreementHeaderContext);
    const dispatch = useDispatch();

    return (
        <ActionButton
            buttonText={'Edit Template'}
            fontawesomeIcon="fa-edit"
            bootstrapColor="secondary"
            onClickAction={() => dispatch(apiAgreementMakeTemplateEditable(agreementHeader))}
        />
    );
}
