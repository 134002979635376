import React from 'react';
import ReactiveForm from '../../common/ReactiveForm';
import { apiSupportRequest } from '../../../actions/Api/Support/apiSupportRequest';
import { connect } from 'react-redux';
import { basicColumnDef } from '../../common/FilteredTable/tableAndFilterHelpers';
import { cookieGetExpires, cookieGetUserId } from '../../../reducers/cookies';
import * as yup from 'yup';

const mandatoryString = message =>
    yup
        .string()
        .nullable()
        .min(1)
        .required(message);

const validation = yup.object().shape({
    email: mandatoryString('Email is required'),
    phoneNumber: mandatoryString('Phone is required'),
    issue: mandatoryString('Issue is required'),
    notes: mandatoryString('Notes is required'),
    contactTime: mandatoryString('Contact time is required')
});

const SupportForm = ({ resources, save, cancel, user }) => {
    const initialValues = {
        userId: cookieGetUserId(),
        tokenExpires: cookieGetExpires(),
        email: user.email,
        roles: user.roles,
        phoneNumber: user.mobile,
        errors: user.errors, //TODO: convert errors to an array of strings
        system: process.env.REACT_APP_DASHBOARD,
        issue: '',
        notes: '',
        contactTime: ''
    };

    return (
        <ReactiveForm
            initialValues={initialValues}
            cancel={cancel}
            resources={resources}
            formName={'SUPPORT FORM'}
            validationSchema={validation}
            handleSubmit={form => save(form, cancel)}
        >
            <ReactiveForm.Section title="Contact Details">
                <ReactiveForm.Text columnDef={{ ...basicColumnDef('Email', 'email'), type: 'email' }} />
                <ReactiveForm.Text columnDef={{ ...basicColumnDef('Phone', 'phoneNumber'), type: 'text' }} />
            </ReactiveForm.Section>
            <ReactiveForm.Section title="Describe the problem">
                <ReactiveForm.Text columnDef={{ ...basicColumnDef('Issue', 'issue'), type: 'text' }} />
                <ReactiveForm.Text columnDef={{ ...basicColumnDef('Notes', 'notes'), type: 'textarea' }} />
                <ReactiveForm.Text
                    columnDef={{ ...basicColumnDef('Best Time to Contact', 'contactTime'), type: 'text' }}
                />
            </ReactiveForm.Section>
        </ReactiveForm>
    );
};
const mapStateToProps = ({ user, errors, resources }) => ({ user, errors, resources });

const mapDispatchToProps = dispatch => ({
    save: (form, cancel) => {
        dispatch(apiSupportRequest(form));
        cancel();
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupportForm);
