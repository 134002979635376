import React, { useContext } from 'react';
import Tabs, { TabsButtons, TabsContent, TabsGroup, TabsTab } from '../../common/Tabs';
import { AddTableRowButton, FilterTableRowButton } from '../../common/Buttons/TableButtons';
import BaseCard from '../../common/Card/BaseCard';
import UpdateFleetProfileEditForm from '../../FleetProfile/Forms/UpdateFleetProfileEditForm';
import CompanyDemosTable from '../../Demos/Tables/CompanyDemosTable';
import AddOpportunityToCompanyButton from '../Components/AddOpportunityToCompanyButton';
import useToggleOpen from '../../common/Hooks/useToggleOpen';
import { useCompanyOpportunitiesContext } from '../Provider/CompanyOpportunitiesProvider';
import { useCompanyBandingsContext } from '../Provider/CompanyBandingsProvider';
import { useCompanyDemosContext } from '../Provider/CompanyDemosProvider';
import { CompanyAgreementHeadersContext } from '../Provider/CompanyAgreementHeadersProvider';
import CompanyOpportunitiesTable from '../../Opportunity/Tables/CompanyOpportunitiesTable';
import CompanyBandingsTable from '../../Banding/Tables/CompanyBandingsTable';
import CompanyAgreementsTable from '../../AgreementHeader/Tables/CompanyAgreementsTable';

const CompanyTabs = () => {
    const { toggle, isOpen } = useToggleOpen();
    const { toggle: toggleBanding, isOpen: isBandingOpen } = useToggleOpen();
    const { opportunities, companyId } = useCompanyOpportunitiesContext();
    const { bandings, createBanding } = useCompanyBandingsContext();
    const { demos } = useCompanyDemosContext();
    const { agreementHeaders } = useContext(CompanyAgreementHeadersContext);

    return (
        <BaseCard>
            <Tabs>
                <TabsGroup>
                    <TabsTab>Profile</TabsTab>
                    <TabsContent>
                        <UpdateFleetProfileEditForm />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTab>Bandings ({bandings.length})</TabsTab>
                    <TabsButtons>
                        <FilterTableRowButton onClick={toggleBanding} />
                        <AddTableRowButton onClick={() => createBanding(companyId)} />
                    </TabsButtons>
                    <TabsContent id="bandings">
                        <CompanyBandingsTable showFilter={isBandingOpen} />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTab>Opportunities ({opportunities.length})</TabsTab>
                    <TabsButtons>
                        <FilterTableRowButton onClick={toggle} />
                        <AddOpportunityToCompanyButton companyId={companyId} />
                    </TabsButtons>
                    <TabsContent>
                        <CompanyOpportunitiesTable showFilter={isOpen} />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTab>Demos ({demos.length})</TabsTab>
                    <TabsContent id="demo">
                        <CompanyDemosTable />
                    </TabsContent>
                </TabsGroup>
                <TabsGroup>
                    <TabsTab>Agreements ({agreementHeaders.length})</TabsTab>
                    <TabsContent id="agreementHeaders">
                        <CompanyAgreementsTable />
                    </TabsContent>
                </TabsGroup>
            </Tabs>
        </BaseCard>
    );
};

export default CompanyTabs;
