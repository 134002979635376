import React from 'react';
import FormikSearchHolder from './FormikSearchHolder';
import { FormikSearchOptionsTableCheckbox } from './FormikSearchOptionsTable';

const FormikSearchSelect = ({ formikProps: { values, handleChange }, field, checkedText, uncheckedText, label }) => {
    const { value, disabled, collapsed } = values[field];

    return (
        <FormikSearchHolder description={value ? checkedText : uncheckedText} collapsed={collapsed} disabled={disabled}>
            <FormikSearchOptionsTableCheckbox
                id={`${field}.value`}
                onChange={handleChange}
                checked={value}
                label={label}
            />
        </FormikSearchHolder>
    );
};

export default FormikSearchSelect;
