import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import EditFieldDisplayValue from '../EditField/EditFieldDisplayValue';
import { Formik } from 'formik';
import SmallSaveCancelButtons from './SmallSaveCancelButtons';
import FormikText from '../FormkControls/FormikText';

class SelectInlineText extends PureComponent {
    state = { isEditing: false };

    submitForm = values => {
        const { save, propertyName } = this.props;
        this.setState({ isEditing: false });
        save(propertyName, values.value);
    };

    renderEditContents = () => {
        const { type, title: oTitle, value, propertyName, required } = this.props;

        const title = oTitle || propertyName;
        const initialValue = (value || '').toString();

        return (
            <Formik
                initialValues={{ value: initialValue }}
                validate={values => (required && !values.value ? { value: 'Required' } : {})}
                onSubmit={this.submitForm}
                render={formikProps => {
                    const { values, handleSubmit, handleReset } = formikProps;
                    formikProps.formName = title;
                    return (
                        <form onSubmit={handleSubmit}>
                            <FormikText type={type} fieldName="value" title={title} {...formikProps}>
                                <SmallSaveCancelButtons
                                    showSave={initialValue.toString() !== values.value}
                                    showCancel={true}
                                    onCancel={event => {
                                        this.setState({ isEditing: false });
                                        handleReset(event);
                                    }}
                                />
                            </FormikText>
                        </form>
                    );
                }}
            />
        );
    };

    renderNotEditing = (formId, isEdit) => {
        const { isSyncing, isError, value, title, propertyName, displayJSX, primary, stack, fontawesome } = this.props;
        return (
            <EditFieldDisplayValue
                onClick={() => this.setState({ isEditing: true })}
                stack={stack}
                isSyncing={isSyncing}
                isError={isError}
                fontawesome={fontawesome || 'fa-pencil'}
                value={displayJSX || value}
                title={title || propertyName}
                primary={primary}
                show={isEdit}
            />
        );
    };

    render() {
        const { objectId, propertyName } = this.props;
        const { isEditing } = this.state;

        const formId = `${objectId}-${propertyName}`;
        return isEditing ? this.renderEditContents() : this.renderNotEditing(formId, isEditing);
    }
}

const mapStateToProps = state => ({
    resources: state.resources
});

export default connect(mapStateToProps)(SelectInlineText);
