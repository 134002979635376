import React from 'react';
import { connect } from 'react-redux';
import IconFactory from '../IconFactory';

const FilterOption = ({ label, color, optionName, field, value, onClick, count, icon }) => {
    const classNames = ['mr-1', 'badge'];

    const style = {};

    if (!value) {
        classNames.push('badge-light');
        classNames.push('line-through');
    } else if (color) {
        classNames.push(`text-${color[1]}`);

        if (color[0].indexOf('#') === 0) {
            style.backgroundColor = color[0];
        } else {
            classNames.push(`badge-${color[0]}`);
        }
    } else {
    }

    const className = classNames.join(' ');
    //TODO compare with CaptionBadge and refactor
    return (
        <span className={className} style={style} onClick={() => onClick(field, optionName, !value)}>
            <IconFactory icon={icon} isVisible={value} /> {label}{' '}
            {value && count !== undefined && <span className="badge badge-light ml-2"> {count}</span>}
        </span>
    );
};

const mapStateToProps = state => ({ resources: state.resources });

export default connect(mapStateToProps)(FilterOption);
