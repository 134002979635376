import { offlinePost } from '../apiHelpers';
import { ENDPOINTS } from '../constants';

export const apiEventPost = event => async (dispatch, getState) => {
    dispatch(
        offlinePost(
            ENDPOINTS.API.EVENT_POST,
            ENDPOINTS.API.EVENT_POST_COMMIT,
            ENDPOINTS.API.EVENT_POST_ROLLBACK,
            event,
            getUnmodified(event, getState),
            getPostData(event, getState),
            event.eventId,
            ['event'],
            {}
        )
    );
};

const getUnmodified = (event, getState) => {
    let unmodified = getState().events.find(x => event.eventId === x.eventId);
    if (!unmodified) {
        unmodified = { ...event };
    }
    return unmodified;
};

const getPostData = (event, getState) => {
    const data = { ...event };
    if (data.eventId < 0) {
        data.eventId = null;
    }

    if (!data.endDate || data.endDate === '0001-01-01T00:00:00') {
        data.endDate = data.startDate;
    }
    return data;
};
