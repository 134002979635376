import { toastr } from 'react-redux-toastr';
import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';

export const apiAgreementDetailApprove = (
    agreementDetailIds,
    successTitle,
    successMessage,
    callback
) => async dispatch => {
    const type = ENDPOINTS.API.AGREEMENT_DETAIL_APPROVE;

    dispatch(
        apiGetPost(
            type,
            ['agreement', 'detail', 'approve'],
            agreementDetailIds,
            {},
            undefined,
            true,
            error => {
                toastr.error(type, `Response ${error.message}`);
            },
            undefined,
            successTitle,
            successMessage,
            callback
        )
    );
};
