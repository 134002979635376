import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'reactstrap';
import MapArray from '../../common/MapArray';
import ActionButton from './ActionButton';

export const ActionButtonGroup = ({ title, children }) => {
    return (
        <ListGroup className="mb-4">
            <ListGroupItem active tag="h5" key={'title'}>
                {title || 'Actions'}
            </ListGroupItem>
            {children}
        </ListGroup>
    );
};

const ActionButtons = ({ title, actions, children }) => {
    return (
        <ListGroup className="mb-4 action-buttons">
            <ListGroupItem active tag="h5" key={'title'}>
                {title || 'Actions'}
            </ListGroupItem>
            {children}
            <MapArray from={actions}>
                <ActionButton />
            </MapArray>
        </ListGroup>
    );
};

ActionButtons.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.shape(ActionButton.defaultProps))
};

ActionButtons.defaultProps = {
    actions: []
};

export default ActionButtons;
