import { ENDPOINTS } from '../constants';
import { toastr } from 'react-redux-toastr';
import { apiGetPost } from '../apiHelpers';

export const apiAgreementSetAddendum = (
    agreementHeaderId,
    agreementHeaderIds,
    successTitle,
    successMessage
) => async dispatch => {
    const type = ENDPOINTS.API.AGREEMENT_SET_ADDENDUM;

    dispatch(
        apiGetPost(
            type,
            ['agreement', 'setpendingaddendum', agreementHeaderId],
            agreementHeaderIds,
            {},
            undefined,
            true,
            (dispatch, type, error) => {
                toastr.error('Add agreements to addendum', `Response ${error.message}`);
            },
            undefined,
            successTitle,
            successMessage
        )
    );
};
