import React, { useContext } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ENDPOINTS } from '../../../actions/Api/constants';
import CompanyCommunicationsProvider from './CompanyCommunicationsProvider';
import CompanyDemosProvider from './CompanyDemosProvider';
import CompanyPeopleProvider from './CompanyPeopleProvider';
import CompanyVisitActionsProvider from './CompanyVisitActionsProvider';
import CompanyOpportunitiesProvider from './CompanyOpportunitiesProvider';
import CompanyMeetingsProvider from './CompanyMeetingsProvider';
import CompanyBandingsProvider from './CompanyBandingsProvider';
import CompanyAgreementHeadersProvider from './CompanyAgreementHeadersProvider';

export const CompanyContext = React.createContext({
    company: { descriptor: '', profile: {}, addresses: [] },
    resources: {},
    isLoading: true
});

export const CompanyConsumer = CompanyContext.Consumer;

const CompanyProvider = ({
    loadingStates,
    companyId,
    profile,
    resources,
    company,
    isLoading,
    children,
    companyOnly = false
}) => {
    if (companyOnly) {

        return (
            <CompanyContext.Provider
                value={{
                    loadingStates,
                    companyId,
                    profile,
                    resources,
                    company,
                    isLoading
                }}
            >
                {children}
            </CompanyContext.Provider>
        );
    }

    return (
        <CompanyCommunicationsProvider companyId={companyId}>
            <CompanyMeetingsProvider companyId={companyId}>
                <CompanyDemosProvider companyId={companyId}>
                    <CompanyVisitActionsProvider companyId={companyId}>
                        <CompanyPeopleProvider companyId={companyId}>
                            <CompanyOpportunitiesProvider companyId={companyId}>
                                <CompanyBandingsProvider companyId={companyId}>
                                    <CompanyAgreementHeadersProvider companyId={companyId}>
                                        <CompanyContext.Provider
                                            value={{
                                                loadingStates,
                                                companyId,
                                                profile,
                                                resources,
                                                company,
                                                isLoading
                                            }}
                                        >
                                            {children}
                                        </CompanyContext.Provider>
                                    </CompanyAgreementHeadersProvider>
                                </CompanyBandingsProvider>
                            </CompanyOpportunitiesProvider>
                        </CompanyPeopleProvider>
                    </CompanyVisitActionsProvider>
                </CompanyDemosProvider>
            </CompanyMeetingsProvider>
        </CompanyCommunicationsProvider>
    );
};

export const useCompanyContext = () => {
    const state = useContext(CompanyContext);
    if (state === undefined) {
        throw new Error(`useCompanyContext must be used inside CompanyProvider`);
    }
    return state;
};

const makeMapStateToProps = () => {
    const getState = createSelector(
        [
            state => state.loadingStates,
            (state, { companyId }) => state.fleetProfiles.find(x => x.companyId === companyId),
            state => state.resources,
            (state, { companyId }) =>
                state.companies.find(company => company.companyId === companyId) || { addresses: [] }
        ],
        (loadingStates, profile, resources, company) => ({
            loadingStates,
            isLoading: _.get(loadingStates, `${ENDPOINTS.API.COMPANY}.isLoading`, false),
            resources,
            profile,
            company
        })
    );

    return (state, props) => getState(state, props);
};

export default connect(makeMapStateToProps)(CompanyProvider);
