const appSettings = {
    timeout: 1000 * 60 * 60,
    auth: {
        passwordEntropy: {
            minLength: 8,
            minUpper: 1,
            minLower: 1,
            minNumber: 1,
            minSpecial: 1,
            validSpecials: ' !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~',
            error:
                'Passwords must be 8 characters, with at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character'
        }
    },
    permissions: {
        canAddAddress: false,
        canEditAddress: false
    },
    miscSettings: {
        calendarEventType: 'standard'
    },
    sidebarUserMenuItems: {
        currentVersion: { title: 'Software Version' }
    }
};

export default appSettings;
