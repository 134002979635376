import React from 'react';
import SimpleCard from '../../common/Card/SimpleCard';
import meetingDef from '../meeting';
import { useMeetingContext } from '../Provider/MeetingProvider';
import { DashboardFieldColumnDef } from '../../Dashboard/Components/DashboardField';

export default function MeetingPageOverview() {
    const { meeting } = useMeetingContext();
    return (
        <SimpleCard title="Overview">
            <DashboardFieldColumnDef item={meeting} columnDef={meetingDef.note} />
        </SimpleCard>
    );
}
