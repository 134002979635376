import { RESET_REDUCER } from '../../../actions/resetReducers';
import { ENDPOINTS } from '../../../actions/Api/constants';

export const RESET_TEMPLATE_REDUCER = 'RESET_TEMPLATE_REDUCER';
const initialState = [];

export const templates = (state = initialState, action) => {
    switch (action.type) {
        case RESET_REDUCER:
            return initialState;
        case ENDPOINTS.API.TEMPLATES_GET:
            return action.data;
        case ENDPOINTS.API.TEMPLATES_UPDATE:
            return action.data;
        case ENDPOINTS.API.TEMPLATE_PUT:
            return action.data.templates;
        default:
            return state;
    }
};

export const testTemplateData = (state = {}, action) => {
    switch (action.type) {
        case RESET_REDUCER:
            return {};
        case ENDPOINTS.API.TEMPLATE_TEST:
            return {};
        case RESET_TEMPLATE_REDUCER:
            return action.data;
        case ENDPOINTS.API.TEST_TEMPLATE_COMPANY_AGREEMENTS_FILTER:
            return action.data;
        default:
            return state;
    }
};
