import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const makeCompanySelector = () =>
    createSelector(
        state => state.companies,
        (_, companyId) => companyId,
        (companies, companyId) => companies.find(company => company.companyId === companyId)
    );

export const useCompanyById = companyId => {
    const selectCompany = useMemo(makeCompanySelector, []);
    return useSelector(state => selectCompany(state, companyId));
};
