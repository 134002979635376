import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import CancelMeetingForm from '../Forms/CancelMeetingForm';
import useModalForm from '../../common/Hooks/useModalForm';

const CancelMeetingModal = ({ children }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();

    return (
        <>
            {isOpen ? (
                <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
                    <AppModalHeader toggle={toggle}>Cancel Meeting</AppModalHeader>
                    <ModalBody>
                        <CancelMeetingForm
                            onCancel={toggle}
                            meetingId={formValue.meetingId}
                            companyId={formValue.companyId}
                        />
                    </ModalBody>
                </Modal>
            ) : null}
            {children({
                cancelMeeting: ({ companyId, meetingId }) => openModal({ companyId, meetingId })
            })}
        </>
    );
};

export default CancelMeetingModal;
