import React from 'react';
import _ from 'lodash';

const PageLinkButton = props => {
    const { className, children, ...rest } = props;
    return (
        <li className={`page-item ${className ? className : ''}`}>
            <button className="page-link" {...rest} type="button">
                {children}
            </button>
        </li>
    );
};

class Pagination extends React.Component {
    render = () => {
        const { page, rowCount, itemsPerPage, onPageChange, className, maxPages, toggleShowAll, showAll } = this.props;
        let numberOfPages = Math.floor((rowCount + itemsPerPage - 1) / itemsPerPage);

        if (numberOfPages < 2) {
            return <div />;
        }

        numberOfPages = maxPages && maxPages < numberOfPages ? maxPages : numberOfPages;

        return (
            <div className={`w-100 ${className}`} style={{ display: 'grid' }}>
                <nav style={{ justifySelf: 'end' }}>
                    <ul className="pagination">
                        {!showAll ? (
                            <React.Fragment>
                                {page >= 5 && <PageLinkButton onClick={() => onPageChange(0)}>First</PageLinkButton>}

                                <PageLinkButton
                                    onClick={() => onPageChange(page - 1)}
                                    className={page === 0 ? 'disabled' : ''}
                                >
                                    Previous
                                </PageLinkButton>

                                {_.range(Math.max(0, page - 4), Math.min(page + 5, numberOfPages)).map(x => (
                                    <PageLinkButton
                                        onClick={() => onPageChange(x)}
                                        className={page === x ? 'active' : ''}
                                        key={x}
                                    >
                                        {x + 1}
                                    </PageLinkButton>
                                ))}
                                <PageLinkButton
                                    onClick={() => onPageChange(page + 1)}
                                    className={page === numberOfPages - 1 ? 'disabled' : ''}
                                >
                                    Next
                                </PageLinkButton>
                                {page <= numberOfPages - 6 && (
                                    <PageLinkButton onClick={() => onPageChange(numberOfPages - 1)}>
                                        Last
                                    </PageLinkButton>
                                )}
                            </React.Fragment>
                        ) : null}
                        <PageLinkButton onClick={() => toggleShowAll()}>
                            <i className={`text-primary fa ${showAll ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                        </PageLinkButton>
                    </ul>
                </nav>
            </div>
        );
    };
}

export default Pagination;
