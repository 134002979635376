export const schema = () => ({
    meeting: {
        createName: 'Create Meeting',
        updateName: 'Update Meeting',
        sections: [
            {
                name: '',
                fields: [
                    {
                        label: 'Event Type',
                        type: 'choiceList',
                        multiple: false,
                        field: 'eventType',
                        choiceList: 'communicationEventType',
                        emptyOption: 'Select an Event Type'
                    }
                ]
            }
        ]
    },
    fleetProfile: [
        {
            fields: [
                {
                    label: 'Total Fleet Size',
                    field: 'fleetSize',
                    type: 'sum',
                    fields: ['numberOfCars', 'numberOfCommercials']
                },
                {
                    label: 'Number of Cars',
                    type: 'number',
                    field: 'numberOfCars'
                },
                {
                    label: 'LCV',
                    type: 'number',
                    field: 'numberOfCommercials'
                },
                {
                    label: 'Number of Grey Fleet',
                    type: 'number',
                    field: 'numberOfGrey'
                },
                {
                    label: 'Number of Salary Sacrifice Employees',
                    type: 'number',
                    field: 'salarySacrifice'
                },
                {
                    label: "Salary Sacrifice?",
                    type: 'checkbox',
                    field: 'hasSalarySacrifice'
                }
            ]
        },
        {
            fields: [
                {
                    label: 'Funding Method',
                    type: 'choiceList',
                    multiple: true,
                    field: 'fundingMethod',
                    choiceList: 'fleetprofilePurchaseMethod'
                },
                {
                    label: 'Change Cycle (Miles)',
                    type: 'number',
                    field: 'changeCycleMiles'
                },
                {
                    label: 'Change Cycle (Months)',
                    type: 'number',
                    field: 'changeCycleMonths'
                },
                {
                    label: 'Manufacturer Policy',
                    type: 'choiceList',
                    field: 'manufacturerPolicy',
                    choiceList: 'ManufacturerPolicy',
                    multiple: true
                },
                {
                    label: 'Reason',
                    type: 'choiceList',
                    field: 'reason',
                    choiceList: 'Reasons',
                    multiple: true
                }
            ]
        }
    ]
});
