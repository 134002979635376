import React from 'react';
import ActionButtons from '../../common/ActionButtons/ActionButtons';
import {
    NewAddendumActionButton,
    NewPromotionActionButton,
    NewTemplateActionButton
} from './createTypesOfAgreementButtons';
import { useSelector } from 'react-redux';
import { isAuthorisedToApproveAgreements } from '../agreementHeaderStatus';
import CreateAgreementModal from '../Modals/CreateAgreementModal';

const AgreementsPageActionButtons = () => {
    const user = useSelector(state => state.user);
    //No actions currently available if user cannot authorise agreements
    if (!isAuthorisedToApproveAgreements(user)) return null;

    return (
        <CreateAgreementModal agreementType="Promotion" user={user}>
            {({ createTemplate, createPromotion, createAddendum }) => (
                <ActionButtons>
                    <NewPromotionActionButton onClick={createPromotion} />
                    <NewTemplateActionButton onClick={createTemplate} />
                    <NewAddendumActionButton onClick={createAddendum} />
                </ActionButtons>
            )}
        </CreateAgreementModal>
    );
};

export default AgreementsPageActionButtons;
