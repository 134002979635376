import * as yup from 'yup';
import opportunity from '../opportunity';
import _ from 'lodash';

const mandatoryString = message =>
    yup
        .string()
        .nullable()
        .min(1)
        .required(message);

export default yup
    .object()
    .shape({
        description: mandatoryString('Description is required'),
        estimatedConversionDate: yup.date('Estimated conversiond date is required'),
        stage: mandatoryString('Stage is required'),
        unitPotential: yup
            .number()
            .positive()
            .min(1, 'Opportunites must have the potential to sell at least one vehicle'),
        model: mandatoryString('Please select at least 1 model'),
        note: mandatoryString('Notes are required')
    })
    .test('lost_stage-has_reason', function(value) {
        const stage = _.get(value, opportunity.stage.field, '');
        const lostReason = _.get(value, opportunity.wonLostReason.field, '');
        return (
            stage !== 'L' ||
            (lostReason !== undefined && lostReason !== '') ||
            this.createError({
                // Formik will use the error "path" as the key in the errors; set this to whatever you want
                path: opportunity.wonLostReason.field,
                message: 'Please supply a Lost Reason'
            })
        );
    })
    .test('lost_stage-has_reason_and_other', function(value) {
        const stage = _.get(value, opportunity.stage.field, '');
        const lostReason = _.get(value, opportunity.wonLostReason.field, '');
        const lostReasonOther = _.get(value, opportunity.wonLostOther.field, '');

        return (
            stage !== 'L' ||
            lostReason !== 'LOther' ||
            (lostReasonOther !== undefined && lostReasonOther !== '') ||
            this.createError({
                path: opportunity.wonLostOther.field,
                message: 'Please provide further details about why the opportunity was lost'
            })
        );
    });
