import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Th from './Th';
import Tr from './Tr';
import { setTableSortColumn } from '../../../actions/table_sort_order';
import Pagination from './Pagination';
import SortDropdown from './SortDropdown';
import MapArray from '../../common/MapArray';
import { Button } from 'reactstrap';
import ShowLoadingSpinner from '../ShowLoadingSpinner';
import useToggleOpen from '../Hooks/useToggleOpen';
import useWindowEventListener from '../Hooks/useWindowEventListener';
import { createSelector } from 'reselect';

const makeSelector = () =>
    createSelector(
        state => state.tableSortOrders,
        (_, id) => id,
        (tableSortOrders, id) => tableSortOrders.find(orders => orders.table === id)
    );

export const useTableSortOrder = id => {
    const selector = useMemo(makeSelector, []);
    return useSelector(state => selector(state, id));
};

const Table = ({ id, headings, rows, itemsPerPage, isLoading, breakpoint, loadingText, onLoadMore, showLoadMore }) => {
    const dispatch = useDispatch();
    const setSortColumn = (table, column) => dispatch(setTableSortColumn(table, column));

    //const tableSortOrders = useSelector(state => state.tableSortOrders);
    const sortOrder = useTableSortOrder(id);
    const [collapsed, setCollapsed] = useState(false);
    const [page, setPage] = useState(0);
    const { isOpen: showAll, toggle: toggleShowAll } = useToggleOpen(false);

    const updateDimensionsCallback = useCallback(() => {
        if (window.innerWidth < breakpoint && !collapsed) {
            setCollapsed(true);
        } else if (window.innerWidth > breakpoint && collapsed) {
            setCollapsed(false);
        }
    }, [breakpoint, collapsed, setCollapsed]);

    useWindowEventListener('resize', updateDimensionsCallback);

    const sortRows = rows => {
        if (!sortOrder) {
            return rows;
        }
        const rawColumnName = `${sortOrder.column}-raw`;

        let sortedRows = _.sortBy(
            rows,
            rows.some(row => row.hasOwnProperty(rawColumnName)) ? rawColumnName : `${sortOrder.column}`
        );

        if (!sortOrder.sort_asc) {
            sortedRows = _.reverse(sortedRows);
        }
        return sortedRows;
    };

    const slice = rows => {
        if (showAll) {
            return rows;
        }

        if (!itemsPerPage) {
            return rows;
        }
        let max = (page + 1) * itemsPerPage;
        max = max > rows.length ? rows.length : max;
        return rows.slice(page * itemsPerPage, max);
    };

    const rowCount = rows.length;

    useEffect(() => {
        const maxPage = Math.round(rowCount / itemsPerPage);
        if (page > maxPage) {
            setPage(maxPage);
        }
    }, [rowCount, itemsPerPage, setPage, page]);

    return (
        <React.Fragment>
            {collapsed && headings.some(heading => heading.sort) ? (
                <SortDropdown key="DropdownSort" headings={headings} tableName={id} />
            ) : null}
            <table className="table position-relative" key={id}>
                <tbody>
                    {!collapsed ? (
                        <tr key="headrow">
                            <MapArray
                                from={headings}
                                data={{ sortOrder }}
                                map={(heading, index, { sortOrder }) => ({
                                    ...heading,
                                    sortOrder
                                })}
                            >
                                <Th setTableSortColumn={setSortColumn} tableId={id} />
                            </MapArray>
                        </tr>
                    ) : null}
                    {isLoading && (
                        <tr key="loading">
                            <td colSpan={headings.length}>
                                <ShowLoadingSpinner isLoading title={loadingText} />
                            </td>
                        </tr>
                    )}
                    {slice(sortRows(rows)).map(row => (
                        <tr
                            key={row.id}
                            className={row.onClick ? 'hover-pointer hover-background' : ''}
                            onClick={row.onClick ? () => row.onClick() : null}
                        >
                            <Tr key={row.id} headings={headings} collapsed={collapsed} {...row} />
                        </tr>
                    ))}
                </tbody>
            </table>
            {itemsPerPage && (
                <Pagination
                    key="pagination"
                    page={page}
                    itemsPerPage={itemsPerPage}
                    rowCount={rows.length}
                    onPageChange={page => setPage(page)}
                    toggleShowAll={toggleShowAll}
                    showAll={showAll}
                />
            )}
            {showLoadMore && (
                <div className="text-center">
                    {' '}
                    <Button outline color="info" onClick={onLoadMore}>
                        Load More
                    </Button>
                </div>
            )}
        </React.Fragment>
    );
};

export default Table;
