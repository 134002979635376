import * as yup from 'yup';

const validation = yup.object().shape({
    startDate: yup.date(),
    activityRegarding: yup.string().required('Activity Regarding is required'),
    status: yup.string().required('Status is required'),
    action: yup.string().required('Action is required'),
    assigneeId: yup.string().required('User is required'),
    note: yup.string().required('Notes are required'),
    personId: yup.number().required('Please select a person')
});

export default validation;
