import { basicColumnDef, idColumnDef, choiceListColumnDef, simpleCheckboxColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

const companySummaryDef = {
    id: idColumnDef('id', 'companyId'),
    descriptor: {
        ...basicColumnDef('Company', 'descriptor')
    },
    postcode: {
        ...basicColumnDef('Postcode', 'postcode')
    },
    ucn: {
        ...basicColumnDef('UCN Number', 'ucn')
    },
    status: {
        ...choiceListColumnDef('Status', 'status', 'companyStatus')
    },
    accountManager: {
        ...choiceListColumnDef('Account Manager', 'accountManagerId', 'userAuthors', {
            renderAsBasicText: true
        })
    },
    numberOfCars: {
        ...basicColumnDef('Number of Cars', 'numberOfCars')
    },
    salarySacrifice:{
        ...simpleCheckboxColumnDef("Salary Sacrifice?","hasSalarySacrifice")
    }
};

export default companySummaryDef;
