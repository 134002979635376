import { useMemo } from 'react';
import _ from 'lodash';
import { createSelector } from 'reselect';

export const makeCreateSelectorDetailsForAgreementHeader = () =>
    createSelector(
        state => state.agreementDetails,
        (_, agreementHeaderId) => agreementHeaderId,
        (agreementDetails, agreementHeaderId) =>
            agreementDetails.filter(agreementDetail => agreementDetail.agreementHeaderId === agreementHeaderId)
    );

function useFindDetailsBelongingToHeaderId(agreementDetails, agreementHeaderId) {
    return useMemo(
        () =>
            _.orderBy(
                agreementDetails.filter(agreementDetail => agreementDetail.agreementHeaderId === agreementHeaderId),
                'createdDate'
            ),
        [agreementDetails, agreementHeaderId]
    );
}

export default useFindDetailsBelongingToHeaderId;
