import axios from 'axios';
import { isLoading } from '../../Loading/isLoading';
import { isError } from '../../Loading/isError';
import { toastr } from 'react-redux-toastr';
import { makePath, makePostRequest } from '../../Api/apiHelpers';
import { push } from 'connected-react-router';
import { API_LOGIN_ENDPOINTS } from '../ApiAuthEndpoints';
import { makeAuthUrl } from '../apiAuthHelpers';

export const apiAuthPasswordReset = (token, password, emailAddress) => async (dispatch, getState) => {
    const type = API_LOGIN_ENDPOINTS.API.API_AUTH_PASSWORD_RESET;

    isLoading(dispatch, type, true, {});
    try {
        const data = { token, password, emailAddress };
        const result = await axios({
            method: 'POST',
            url: makeAuthUrl(getState(), makePath(['auth', 'password', 'reset']), {}),
            headers: { 'Content-Type': 'application/json' },
            data
        });
        isLoading(dispatch, type, false, {});
        toastr.success('Password Updated');
        dispatch(push('/'));
        return result || true;
    } catch (error) {
        isError(dispatch, type, true, error.message, {});

        if (error.response.status === 400) {
            toastr.error(error.response.data);
        } else {
            toastr.error(type, error.response.data || `Response ${error.message}`);
        }
        return false;
    }
};

export const apiAuthPasswordChange = (emailAddress, token, password, currentPassword) => async (dispatch, getState) => {
    const type = API_LOGIN_ENDPOINTS.API.API_AUTH_PASSWORD_CHANGE;

    try {
        const result = await makePostRequest(
            ['auth', 'password', 'change'],
            {},
            { emailAddress, token, password, currentPassword }
        );
        if (result && result.status === 200) {
            dispatch(push('/'));
            toastr.success('Change Password', 'Your password has been changed.');
        } else {
            toastr.error('Change Password', result.data);
        }
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        toastr.error('Change Password', 'Could not change password. Please check details and try again.');
        return false;
    }
};

export const apiAuthPasswordChangeLoggedIn = (emailAddress, token, password, currentPassword) => async (
    dispatch,
    getState
) => {
    const type = API_LOGIN_ENDPOINTS.API.API_AUTH_PASSWORD_CHANGE;

    try {
        const result = await makePostRequest(
            ['auth', 'password', 'changeLoggedIn'],
            {},
            { emailAddress, token, password, currentPassword }
        );
        if (result && result.status === 200) {
            dispatch(push('/'));
            toastr.success('Change Password', 'Your password has been changed.');
        } else {
            toastr.error('Change Password', result.data);
        }
    } catch (error) {
        isError(dispatch, type, true, error.message, {});
        toastr.error('Change Password', 'Could not change password. Please check details and try again.');
        return false;
    }
};
