import React from 'react';
import _ from 'lodash';
import IconFactory from '../IconFactory';

const CaptionBadge = ({ title, caption = '', resources, choiceList, deselected, className, onClick, children }) => {
    let value = (caption || '').toString();

    const choiceListArray = _.get(resources, `choiceList.${choiceList}`, []);

    let choice = choiceListArray.find(x => toLower(x.key) === toLower(value) || toLower(x.value) === toLower(value));

    if (!choice) {
        choice = choiceListArray.find(x => x.key.toString() === value || x.value === value.toString());
    }

    let style = {};
    let type = 'badge-light';
    if (deselected) {
        type = 'badge-light line-through';
    } else {
        if (choice) {
            if (choice.color && choice.color[0].indexOf('#') === 0) {
                type = `text-${choice.color[1]}`;
                style = { backgroundColor: choice.color[0] };
            } else if (choice.color) {
                type = `badge-${choice.color[0]} text-${choice.color[1]}`;
            }
        }
    }
    if (choice) {
        value = choice.value || value;
    }

    return (
        <span
            title={title}
            className={`${className} badge ${type}`}
            style={style}
            onClick={onClick ? () => onClick() : undefined}
        >
            <IconFactory icon={_.get(choice, 'icon')} isVisible={!deselected} />
            {children || value}
        </span>
    );
};

const toLower = val => (typeof val === 'string' ? val.toLowerCase() : val);

export default CaptionBadge;
