import { handleErrorWithToastUnlessUnauthorised, makeGetRequest } from '../apiHelpers';
import { ENDPOINTS } from '../constants';

const type = ENDPOINTS.API.TEMPLATES_GET;

export const apiTemplateGet = templateId => async (dispatch, getState) => {
    makeGetRequest(['template'], templateId)
        .then(({ data }) => {
            dispatch({ type, data });
        })
        .catch(error => handleErrorWithToastUnlessUnauthorised(dispatch, getState, error, type, 'Templates'));
};
