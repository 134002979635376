import * as yup from 'yup';

const editVisitActionFormValidation = yup.object().shape({
    startDate: yup.date(),
    activityRegarding: yup.string().required('Activity Regarding is required'),
    status: yup.string().required('Status is required'),
    action: yup.string().required('Action is required'),
    assigneeId: yup.string().required('User is required'),
    note: yup.string().required('Details are required')
});

export default editVisitActionFormValidation;
