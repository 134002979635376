import React from 'react';
import agreementStatusChangeColumnDefs from '../agreementStatusChangeColumnDefs';
import { DashboardFieldColumnDef } from '../../Dashboard/Components/DashboardField';
import { FormatDate } from '../../common/FormatDate';
import { DisplayField } from '../../common/DisplayField';
import { Link } from 'react-router-dom';
import ActionButton from '../../common/ActionButtons/ActionButton';
import { apiDownloadAgreementTemplate } from '../../../actions/Api/AgreementHeader/apiDownloadAgreementTemplate';

const AgreementStatusChange = ({ agreementStatusChange, userChoiceList, statusFromChoiceList, statusToChoiceList }) => {
    const user = userChoiceList.find(x => x.key === agreementStatusChange.updatedBy.toString());

    const statusFrom = statusFromChoiceList.find(x => x.key === agreementStatusChange.statusFrom);

    const statusTo = statusToChoiceList.find(x => x.key === agreementStatusChange.statusTo);

    const isClonedEvent = agreementStatusChange.notes && agreementStatusChange.notes.startsWith('Cloned');
    const clonedId = isClonedEvent ? agreementStatusChange.notes.split(':')[1] : undefined;

    return (
        <div className="border-bottom py-3">
            <span className="mr-1">
                {isClonedEvent ? <Link to={`/dashboard/agreement/${clonedId}`}>Cloned</Link> : 'Updated'}
            </span>
            <b>
                <FormatDate showTime={true} date={agreementStatusChange.updatedDate} />
            </b>
            {user ? (
                <span className="mr-1 ml-1">
                    by <b>{user.value}</b>
                </span>
            ) : null}
            {statusFrom && statusTo ? (
                <DisplayField title="Status Changed" value={`From: ${statusFrom.value} to: ${statusTo.value}`} />
            ) : null}
            {isClonedEvent ? null : (
                <DashboardFieldColumnDef
                    item={agreementStatusChange}
                    columnDef={agreementStatusChangeColumnDefs.notes}
                />
            )}
            {agreementStatusChange.notes === 'Template File Updated' ||
            agreementStatusChange.notes === 'PDF Template File' ? (
                <ActionButton
                    buttonText={`${agreementStatusChange.statusFrom}`}
                    fontawesomeIcon="fa-download"
                    bootstrapColor="white"
                    onClickAction={() => apiDownloadAgreementTemplate(agreementStatusChange.statusTo)}
                    key={'templateFile'}
                />
            ) : null}
        </div>
    );
};

export default AgreementStatusChange;
