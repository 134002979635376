import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import useModalForm from '../../common/Hooks/useModalForm';
import EditAgreementAddendumDetailForm from '../Forms/EditAgreementAddendumDetailForm';

const EditAgreementAddendumDetailModal = ({ children }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();
    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
                <AppModalHeader toggle={toggle}>Edit Agreement Addendum Detail</AppModalHeader>
                <ModalBody>
                    <EditAgreementAddendumDetailForm {...formValue} toggle={toggle} />
                </ModalBody>
            </Modal>
            {children({
                editAgreementAddendumDetail: detail => {
                    openModal({
                        detail
                    });
                },
                toggle
            })}
        </>
    );
};

export default EditAgreementAddendumDetailModal;
