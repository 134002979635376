import { basicColumnDef, choiceListColumnDef } from '../common/FilteredTable/tableAndFilterHelpers';

const companyDef = {
    name: {
        ...basicColumnDef('Company Name', 'name'),
        type: 'companyName'
    },
    businessPhone: {
        ...basicColumnDef('Company Telephone Number', 'businessPhone'),
        type: 'phone'
    },
    webSite: {
        ...basicColumnDef('Company Website', 'webSite'),
        type: 'link'
    },
    completedMeetings: {
        ...basicColumnDef('Number of Completed Meetings', 'completedMeetings'),
        type: 'text'
    },
    lastMeetingDate: {
        ...basicColumnDef('Last Completed Meeting Date', 'lastMeetingDate'),
        type: 'date'
    },
    demosInLast12Months: {
        ...basicColumnDef('Number of Demos Over 12 Months', 'demosInLast12Months'),
        type: 'text'
    },
    totalDemos: {
        ...basicColumnDef('Total Number of Demos', 'totalDemos'),
        type: 'text'
    },
    descriptor: {
        ...basicColumnDef('Company', 'descriptor')
    },
    postcode: {
        ...basicColumnDef('Postcode', 'postcode')
    },
    ucn: {
        ...basicColumnDef('UCN Number', 'ucn'),
        type: 'text'
    },
    externalUcn: {
        ...basicColumnDef('Hyundai UCN', 'ucn')
    },
    accountManager: {
        ...choiceListColumnDef('Account Manager', 'accountManager.id', 'userAuthors', {
            renderAsBasicText: true
        })
    },
    status: {
        ...choiceListColumnDef('Company Status', 'status', 'companyStatus', { renderAsBasicText: true })
    },
    numberOfVisitsPerAnnum: {
        ...choiceListColumnDef(
            'Number of Visits Per Annum',
            'numberOfVisitsPerAnnum',
            'companyNumberOfVisitsPerAnnum',
            { renderAsBasicText: true }
        )
    },
    meetingBookingOwner: {
        ...choiceListColumnDef('Meeting Booking Owner', 'meetingBookingOwner', 'companyMeetingOwner', {
            renderAsBasicText: true
        })
    }
};

export default companyDef;
