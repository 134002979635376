import { isLoading } from '../../Loading/isLoading';
import _ from 'lodash';
import { handleErrorWithToastUnlessUnauthorised, makePostRequest } from '../apiHelpers';
import { ENDPOINTS } from '../constants';
import { apiCompany } from '../Company/apiCompany';
import { apiOpportunityFilter } from '../Opportunity/apiOpportunityFilter';
import { isOfflne } from '../../../reducers/Api/helper';
import moment from 'moment';

export const visitActionsQuery = (query = {}, assignedToCurrentUser = false, fetchCompany = true, user) => ({
    setFetchCompany: shouldFetch => visitActionsQuery(query, assignedToCurrentUser, shouldFetch, user),
    filterCompany: companyId => visitActionsQuery({ companyId, ...query }, assignedToCurrentUser, fetchCompany, user),
    filterMeeting: meetingId => visitActionsQuery({ meetingId, ...query }, assignedToCurrentUser, fetchCompany, user),
    isAssignedToCurrentUser: () => visitActionsQuery(query, true, fetchCompany, user),
    isBetween: (startDate, endDate) =>
        visitActionsQuery(
            {
                startDate: startDate instanceof moment ? startDate.format('YYYY-MM-DD') : startDate,
                endDate: endDate instanceof moment ? endDate.format('YYYY-MM-DD') : endDate,
                ...query
            },
            assignedToCurrentUser,
            fetchCompany,
            user
        ),
    //Next7Days
    statusIn: status => visitActionsQuery({ status, ...query }, assignedToCurrentUser, fetchCompany, user),
    run: () => apiVisitActionsFilter(query, assignedToCurrentUser, user, fetchCompany)
});

export const apiVisitActionsFilter = (
    filter,
    assignedToCurrentUser = false,
    user = undefined,
    fetchCompany = true
) => async (dispatch, getState) => {
    const { companyId, meetingId, startDate, endDate, status } = filter;

    if (!getState().offline.online) {
        return false;
    }

    const type = ENDPOINTS.API.VISIT_ACTIONS_FILTER_COMMIT;

    const data = {
        companyId,
        meetingId,
        startDate,
        endDate,
        status,
        assigneeId: assignedToCurrentUser ? (user ? user : getState().user.userId) : undefined
    };

    isLoading(dispatch, type, true, {});

    makePostRequest(['visitaction', 'filter'], {}, data)
        .then(response => {
            isLoading(dispatch, type, false, {});
            const companies = getState().companies;
            const companyIds = _.uniq(
                response.data.map(x => x.company.id).filter(x => !companies.some(c => x === c.companyId))
            );

            if (isOfflne() && fetchCompany) {
                companyIds.forEach(companyId => {
                    dispatch(apiCompany(companyId));
                    dispatch(apiOpportunityFilter({ companyId }));
                });
            }

            dispatch({
                type: ENDPOINTS.API.VISIT_ACTIONS_FILTER_COMMIT,
                payload: { data: response.data, filter: data }
            });
            isLoading(dispatch, type, false, {});
            return true;
        })
        .catch(error => {
            isLoading(dispatch, type, false, {});
            handleErrorWithToastUnlessUnauthorised(dispatch, getState, error, type, 'Visit Actions');
        });
};
