import React from 'react';
import SelectInlineText from '../../common/InlineEdit/SelectInlineText';
import { DisplayIcon } from '../../common/DisplayIcon';
import { webSiteHttp } from '../../../reducers/util';
import { PreventClickThroughAllowDefault } from '../../common/Table/PreventClickThrough';

const DashboardFieldEditLink = props => {
    const {
        field,
        fieldProps: {
            title,
            value,
            meta: { isSyncing, isError, save }
        }
    } = props;
    return (
        <SelectInlineText
            isError={isError}
            isSyncing={isSyncing}
            propertyName={field}
            displayJSX={
                <span onClick={PreventClickThroughAllowDefault}>
                    {' '}
                    <DisplayIcon href={webSiteHttp(value)} />
                </span>
            }
            required
            save={(x, val) => save(val)}
            stack
            title={title}
            type={'text'}
            value={value}
        />
    );
};

export default DashboardFieldEditLink;
