import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const makeDemosByCompanyByIdSelector = () =>
    createSelector(
        state => state.demos,
        (_, companyId) => companyId,
        (demos, companyId) => demos.filter(demo => demo.companyId === companyId)
    );

export const useDemosByCompanyById = companyId => {
    const selectDemos = useMemo(makeDemosByCompanyByIdSelector, []);
    return useSelector(state => selectDemos(state, companyId));
};
