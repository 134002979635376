import { ENDPOINTS } from '../constants';
import { apiGetPost } from '../apiHelpers';
import { toastr } from 'react-redux-toastr';

export const apiCreateDraftAgreement = (
    agreement,
    successTitle,
    successMessage,
    callback,
    onResult
) => async dispatch => {
    const type = ENDPOINTS.API.AGREEMENT_CREATE_DRAFT;
    agreement.id = null;

    dispatch(
        apiGetPost(
            type,
            ['agreement', 'clone'],
            agreement,
            {},
            onResult,
            true,
            error => {
                toastr.error(type, `Response ${error.message}`);
            },
            undefined,
            successTitle,
            successMessage,
            callback
        )
    );
};
