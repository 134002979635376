import React from 'react';

export default function RemoveTemplateDetailChange({ detail }) {
    return (
        <>
            {JSON.stringify(detail, null, 2)}
            <br />
            <br />
        </>
    );
}
