import React from 'react';
import DeletedText from './DeletedText';

export default function NormalColumn({ children, className, style = {}, deleted }) {
    return (
        <div className={`p-2 ${className}`} style={{ alignSelf: 'center', ...style }}>
            <DeletedText deleted={deleted}>{children}</DeletedText>
        </div>
    );
}
