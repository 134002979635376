import React from 'react';
import { FieldValue } from '../DisplayField/FieldValue';
import { DisplayField } from '../DisplayField/index';
import { PreventClickThrough } from './PreventClickThrough';

const Tr = props => {
    const { headings, collapsed } = props;

    return collapsed ? (
        <td>
            {headings.map(heading => {
                const column = props[heading.field];
                return (
                    <DisplayField
                        key={heading.field}
                        primary={heading.primary}
                        inline
                        title={heading.value}
                        value={column}
                    />
                );
            })}
        </td>
    ) : (
        headings.map(heading => {
            const column = props[heading.field];
            return (
                <td
                    key={heading.field}
                    className={`${heading.right ? 'text-right' : ''}`}
                    onClick={heading.preventClickThrough ? PreventClickThrough : undefined}
                >
                    <FieldValue primary={heading.primary}>{column}</FieldValue>
                </td>
            );
        })
    );
};

export default Tr;
