import React from 'react';
import { FormatAgreementDiscount } from '../../AgreementDetails/AgreementDetailsTable/DetailRow';
import AddendumProductName from './AddendumProductName';
import AddendumDetailDeleteButton from '../Buttons/AddendumDetailDeleteButton';

const ApplyMinimumDiscountToProductDetailChange = ({ detail, type, readOnly }) => {
    if (!detail.family || !detail.product) {
        console.error('ApplyMinimumDiscountToProductDetailChange Detail does not have product or product family', {
            detail
        });
        return null;
    }

    return (
        <>
            {!readOnly && (
                <>
                    <AddendumDetailDeleteButton detail={detail} />
                </>
            )}
            <AddendumProductName familyName={detail.family.name} productName={detail.product.name} />
            <ul style={{ paddingLeft: 25 }}>
                {detail.manufacturerDiscount ? (
                    <li>
                        {type} Manufacturer discount will be{' '}
                        <b>
                            <FormatAgreementDiscount field="manufacturerDiscount" agreementDetail={detail} />
                        </b>
                    </li>
                ) : null}
                {detail.dealerDiscount ? (
                    <li>
                        {type} Dealer discount will be{' '}
                        <b>
                            <FormatAgreementDiscount field="dealerDiscount" agreementDetail={detail} />
                        </b>
                    </li>
                ) : null}
            </ul>
        </>
    );
};

export default ApplyMinimumDiscountToProductDetailChange;
