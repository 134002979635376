import React from 'react';
import { useCompanyContext } from '../../Company/Provider/CompanyProvider';
import AddressTableCard from './AddressTableCard';
import addressDef from '../addressDef';

const AddressTable = ({ breakpoint }) => {
    const {
        company: { addresses, loading }
    } = useCompanyContext();

    return (
        <AddressTableCard
            rows={addresses}
            isLoading={loading}
            breakpoint={breakpoint}
            columns={[
                addressDef.address1,
                addressDef.address2,
                addressDef.address3,
                addressDef.address4,
                addressDef.city,
                addressDef.postCode
            ]}
        />
    );
};

export default AddressTable;
