import React from 'react';
import { HashLoader } from 'react-spinners';
import { CrossTableRowButton, TickTableRowButton } from './TableButtons';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import useToggleOpen from '../Hooks/useToggleOpen';

const ConfirmButton = ({ popoverId, children, isWorking, isWorkingTitle, title, notes, onConfirm }) => {
    const { isOpen, toggle } = useToggleOpen(false);
    return (
        <div className="d-inline">
            {isOpen || isWorking ? (
                <Popover placement="top" isOpen={isOpen || isWorking} target={popoverId} toggle={toggle}>
                    <PopoverHeader>{isWorking ? isWorkingTitle : title}</PopoverHeader>
                    <PopoverBody>
                        {isWorking ? (
                            <div className="text-center load-center">
                                <HashLoader color={'#123abc'} loading={isWorking} size={20} />
                            </div>
                        ) : (
                            <>
                                {notes ? <div className="text-left">{notes}</div> : null}
                                <div className={notes ? 'text-right' : 'text-center'}>
                                    <TickTableRowButton
                                        onClick={e => {
                                            onConfirm();
                                            toggle();
                                        }}
                                    />
                                    <CrossTableRowButton onClick={e => toggle()} />
                                </div>
                            </>
                        )}
                    </PopoverBody>
                </Popover>
            ) : null}

            {children({
                toggle
            })}
        </div>
    );
};

export default ConfirmButton;
