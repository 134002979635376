import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AppModalHeader } from '../../common/AppModalHeader';
import useModalForm from '../../common/Hooks/useModalForm';
import EditAgreementHeaderForm from '../Forms/EditAgreementHeaderForm';
import moment from 'moment';
import TemplateProvider from '../../Template/Provider/TemplateProvider';

const startsToday = () =>
    moment()
        .set('hours', 9)
        .set('minutes', 0)
        .set('seconds', 0);

const endsAfterYear = () =>
    moment()
        .add(1, 'year')
        .set('hours', 9)
        .set('minutes', 0)
        .set('seconds', 0);

const endsAfterMonth = () =>
    moment()
        .add(30, 'day')
        .set('hours', 9)
        .set('minutes', 0)
        .set('seconds', 0);

function getNewAgreementHeader(
    agreementType,
    { companyId, startDate, endDate, addendumUpdatesStartDate, addendumUpdatesEndDate, agreementApprovalTeam = '' }
) {
    return {
        id: '-1',
        companyId,
        personId: '',
        startDate,
        endDate,
        activityRegarding: '',
        agreementType,
        agreementApprovalTeam,
        action: '',
        subject: '',
        assigneeId: '',
        status: 'Draft',
        hyundaiAgreementId: '',
        notes: '',
        addendumUpdatesStartDate,
        addendumUpdatesEndDate,
        addendumUpdatesStartDateEnabled: '',
        addendumUpdatesEndDateEnabled: ''
    };
}

const CreateAgreementModal = ({ children, user }) => {
    const { toggle, isOpen, formValue, openModal } = useModalForm();
    const endYearDate = moment(`${moment().year()}/12/31`).format();
    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
                <AppModalHeader toggle={toggle}>Create {formValue.agreementType}</AppModalHeader>
                <ModalBody>
                    <TemplateProvider>
                        <EditAgreementHeaderForm onSaveOrCancel={toggle} agreementHeader={formValue} />
                    </TemplateProvider>
                </ModalBody>
            </Modal>
            {children({
                createAddendum: () => {
                    openModal(
                        getNewAgreementHeader('Addendum', {
                            addendumUpdatesStartDate: startsToday(),
                            addendumUpdatesEndDate: endsAfterYear()
                        })
                    );
                },
                createAgreement: companyId => {
                    openModal(
                        getNewAgreementHeader('Agreement', {
                            companyId,
                            startDate: startsToday(),
                            endDate: endYearDate,
                            agreementApprovalTeam: user.agreementApprovalTeam
                        })
                    );
                },
                createPromotion: () => {
                    openModal(
                        getNewAgreementHeader('Promotion', {
                            startDate: startsToday(),
                            endDate: endsAfterMonth()
                        })
                    );
                },
                createTemplate: () => {
                    openModal(getNewAgreementHeader('Template', {}));
                },
                toggle
            })}
        </>
    );
};

export default CreateAgreementModal;
