import { ENDPOINTS } from '../constants';
import { toastr } from 'react-redux-toastr';
import { apiGetPost } from '../apiHelpers';

export const productsQuery = (query = {}) => ({
    run: () => apiProductsFilter(query)
});

const apiProductsFilter = query => async dispatch => {
    dispatch(
        apiGetPost(ENDPOINTS.API.PRODUCTS_FILTER, ['product', 'filter'], {}, {}, undefined, true, error => {
            toastr.error(ENDPOINTS.API.PRODUCTS_FILTER, `Response ${error.message}`);
        })
    );
};
