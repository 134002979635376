import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const makeHasAgreementDetailsSelector = () =>
    createSelector(
        state => state.agreementDetails,
        (_, agreementHeaderId) => agreementHeaderId,
        (agreementDetails, agreementHeaderId) =>
            agreementDetails.filter(
                agreementDetail =>
                    agreementDetail.agreementHeaderId === agreementHeaderId && agreementDetail.deleted == null
            )
    );

export const useNotDeletedAgreementDetailsByHeaderId = agreementHeaderId => {
    const selector = useMemo(makeHasAgreementDetailsSelector, []);
    return useSelector(state => selector(state, agreementHeaderId));
};
