import React, { useContext } from 'react';
import { AgreementHeaderContext } from '../../AgreementHeader/Provider/AgreementHeaderProvider';
import EditAgreementDetailModal from '../Modals/EditAgreementDetailModal';
import SimpleTextFilter from '../../common/FilteredTable/SimpleTextFilter';
import ProductAndDetailsDropdownFilter from './ProductAndDetailsDropdownFilter';

export const AgreementDetailsTableContext = React.createContext({
    agreementDetails: [],
    showActionsColumn: false
});

const AgreementDetailsTableProviderFiltered = ({ familiesProductsDetails, productRowsFilter, searchBox, children }) => {
    const {
        agreementHeader,
        agreementHeaderId,
        agreementState,
        agreementState: { canAmend, canApprove }
    } = useContext(AgreementHeaderContext);

    const hideActions = ['Live', 'Approved', 'Expired'];

    const showActionsColumn = hideActions.includes(agreementHeader.status) ? false : canApprove || canAmend;
    return (
        <EditAgreementDetailModal>
            {({ editAgreementDetail, addProductsToAgreementDetails }) => (
                <AgreementDetailsTableContext.Provider
                    value={{
                        agreementHeaderId,
                        agreementState,
                        editAgreementDetail: editAgreementDetail,
                        familiesProductsDetails,
                        addProductsToAgreementDetails,
                        showActionsColumn,
                        productRowsFilter,
                        searchBox
                    }}
                >
                    {children}
                </AgreementDetailsTableContext.Provider>
            )}
        </EditAgreementDetailModal>
    );
};

export default function AgreementDetailsTableProvider({ children }) {
    const {
        agreementState: { detailsFilter },
        agreementHeaderId
    } = useContext(AgreementHeaderContext);

    return (
        <SimpleTextFilter placeholder="Product Search" column="name">
            {({ searchTerm, searchBox }) => (
                <ProductAndDetailsDropdownFilter
                    searchTerm={searchTerm}
                    agreementHeaderId={agreementHeaderId}
                    detailsFilter={detailsFilter}
                >
                    {({ familiesProductsDetails, productRowsFilter }) => (
                        <AgreementDetailsTableProviderFiltered
                            familiesProductsDetails={familiesProductsDetails}
                            productRowsFilter={productRowsFilter}
                            searchBox={searchBox}
                        >
                            {children}
                        </AgreementDetailsTableProviderFiltered>
                    )}
                </ProductAndDetailsDropdownFilter>
            )}
        </SimpleTextFilter>
    );
}
