import React from 'react';
import { connect } from 'react-redux';
import MapArray from '../../common/MapArray';
import { FieldValue } from '../../common/DisplayField/FieldValue';
import { openMeeting } from '../../../actions/Navigation/Meeting/meetingSelected';
import AgendaDay from '../Components/AgendaDay';
import moment from 'moment';

const mapDay = (day, index, { ...data }) => ({
    day: day,
    first: index === 0,
    ...data
});

const UnfilteredAgendaCard = ({ agenda, openMeeting, choiceList, showDayName }) => {
    const now = moment().startOf('day');
    const days = new Array(7).fill().map((x, index) =>
        now
            .clone()
            .add(index, 'd')
            .format('DD/MM/YYYY')
    );

    return days.length === 0 ? (
        <FieldValue>No Events</FieldValue>
    ) : (
        <MapArray
            from={days}
            map={mapDay}
            data={{
                agenda,
                openMeeting,
                choiceList,
                showDayName
            }}
            mapKey={day => day}
        >
            <AgendaDay />
        </MapArray>
    );
};

const mapDispatchToProps = dispatch => ({
    openMeeting: ({ meetingId, descriptor }) => dispatch(openMeeting(meetingId, descriptor))
});

export default connect(
    undefined,
    mapDispatchToProps
)(UnfilteredAgendaCard);
