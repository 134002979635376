import React, { useContext, useState } from 'react';
import moment from 'moment';

const AgreementEvidenceContext = React.createContext({
    evidence: []
});

const AgreementEvidenceProvider = ({ children }) => {
    const [evidence, setEvidence] = useState([]);

    const setAgreementEvidence = ({ agreementEvidence = [] }) =>
        setEvidence(
            [...agreementEvidence, ...evidence.filter(x => !agreementEvidence.some(y => y.id === x.id))]
                .sort((a, b) => moment(a.createdDate).isBefore(b.createdDate))
                .reverse()
        );

    return (
        <AgreementEvidenceContext.Provider value={{ agreementEvidence: evidence, setAgreementEvidence }}>
            {children}
        </AgreementEvidenceContext.Provider>
    );
};

export const useAgreementEvidenceContext = () => {
    const state = useContext(AgreementEvidenceContext);
    if (state === undefined) {
        throw new Error(`useAgreementEvidenceContext must be used inside AgreementEvidenceProvider`);
    }
    return state;
};

export default AgreementEvidenceProvider;
