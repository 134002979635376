import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const makeUnusedPromotionsByCompanyIdSelector = () =>
    createSelector(
        state => state.agreementHeaders,
        (_, companyId) => companyId,
        (agreementHeaders, companyId) =>
            agreementHeaders.filter(
                promotion =>
                    promotion.agreementType === 'Promotion' &&
                    ['Approved', 'Live'].indexOf(promotion.status) > -1 &&
                    !agreementHeaders.some(
                        agreement => agreement.parentId === promotion.id && agreement.companyId === companyId
                    )
            )
    );

export const useUnusedPromotionsByCompanyId = companyId => {
    const selectUnusedPromotions = useMemo(makeUnusedPromotionsByCompanyIdSelector, []);
    return useSelector(state => selectUnusedPromotions(state, companyId));
};
